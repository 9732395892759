import styled from "styled-components";

export const AdminMenuStyles = styled.ul`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // position: fixed;
  // top: 10px;
  // left: 10px;
  // width: 74px;
  // background-color: white;
  // padding-top: 27px;
  // padding-bottom: 27px;
  // padding-left: 7px;
  // padding-right: 7px;
  // border-radius: 52px;
  // box-shadow: var(--box-shadow-light);
  z-index: 99;

  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000001c;
  border-radius: 8px;
  opacity: 1;
  padding: 12px 5px;
`;
