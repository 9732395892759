/* eslint-disable no-unused-vars */
import React from "react";
import { useAuction } from "../../context/auctions.context";
import { safeParse } from "../../scripts/util/safeParse";
import {
  RelevanceSelectWrapper,
  RedSortAscendingOutlined,
  RedSortDescendingOutlined,
  RedFallOutlined,
  RedRiseOutlined,
  CustomizedSelect,
} from "./RelevanceSelect.style";

const { Option } = CustomizedSelect;

const RelevanceSelect = (props) => {
  // const { auctions, setAuctions } = useAuction();

  const HandleChange = (value) => {
    // console.log(value)
    props.setFilterVal(value);
    // const parsedAuctions = auctions.map((auction) => {
    //   const parsedData = safeParse(auction.metadata?.data).title;
    //   return {
    //     ...auction,
    //     metadata: {
    //       ...auction.metadata,
    //       data: parsedData,
    //     },
    //   };
    // });
    // // console.log("parsed", parsedAuctions);
    // switch (value) {
    //   case 1:
    //     const TitleSorted = parsedAuctions.sort((a, b) => {
    //       return a.parsedAuctions?.metadata?.data >
    //         b.parsedAuctions?.metadata.data
    //         ? 1
    //         : -1;
    //     });
    //     // console.log("TitleSorted", TitleSorted);
    //     break;
    //   case 2:
    //     break;
    //   case 3:
    //     const sortByPriceLowToHigh = parsedAuctions.sort((a, b) => {
    //       return Number(a.buyNow) - Number(b.buyNow);
    //     });
    //     console.log("sortByPriceLowToHigh", sortByPriceLowToHigh);
    //     setAuctions((prev) => {
    //       console.log({ prev });
    //       return [...sortByPriceLowToHigh];
    //     });
    //     break;
    //   case 4:
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <RelevanceSelectWrapper>
      <CustomizedSelect defaultValue={"Relevance"} onChange={HandleChange}>
        <Option value="nameAsc">
          <RedSortAscendingOutlined />
          {"  Name A-Z"}
        </Option>
        <Option value="nameDsc">
          <RedSortDescendingOutlined />
          {"  Name Z-A"}
        </Option>
        <Option value="lowprice">
          <RedFallOutlined />
          {"  Lowest price"}
        </Option>
        <Option value="highprice">
          <RedRiseOutlined />
          {"  Highest price"}
        </Option>
      </CustomizedSelect>
    </RelevanceSelectWrapper>
  );
};

export default RelevanceSelect;
