import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import "./index.css";
import App from "./App";
import store from "./redux/store";
// import { GlobalStyles } from "./components/GlobalStyles";
import * as config from "./config/keys";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "./antdCustomizer.css";
// import * as fcl from "@onflow/fcl";
// import keys from "./config/keys";
// const config = require("./config/index")

const client = new ApolloClient({
  uri: `${config.serverURL}/graphql`
});


// fcl
//   .config()
//   .put("env", "testnet")
//   // .put("accessNode.api", "https://access-testnet.onflow.org/testnet")
//   .put("accessNode.api","https://rest-testnet.onflow.org")
//   .put("app.detail.title", "DAAM Agency")
//   .put(
//     "app.detail.icon",
//     `${keys.clientURL}/static/media/worded_logo.79555dfd.svg`
//   )
//   .put("0xFlowToken", "0x7e60df042a9c0868");

// console.log(
//   "%c\n                  ,d\"=≥,.,qOp,\n                 ,7'  ''²$(  )\n                ,7'      '?q$7'\n             ..,$$,.\n   ,.  .,,--***²\"\"²***--,,.  .,\n ²   ,p²''              ''²q,   ²\n:  ,7'                      '7,  :\n ' $      ,db,      ,db,      $ '\n  '$      ²$$²      ²$$²      $'    \n  '$                          $'        \n   '$.     .,        ,.     .$'\n    'b,     '²«»«»«»²'     ,d'\n     '²?bn,,          ,,nd?²'\n       ,7$ ''²²²²²²²²'' $7,\n     ,² ²$              $² ²,\n     $  :$              $:  $\n     $   $              $   $\n     'b  q:            :p  d'\n      '²«?$.          .$?»²'\n         'b            d'\n       ,²²'?,.      .,?'²²,\n      ²==--≥²²==--==²²≤--==²\n",
//   "font-weight: bold; font-size: 15px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 0px 4px 0 rgb(226,91,14) , 0px 5px 0 rgb(245,221,8) , 0px 6px 0 rgb(5,148,68) , 0px 7px 0 rgb(2,135,206) , 0px 8px 0 rgb(4,77,145) , 0px 9px 0 rgb(42,21,113)"
// );

// console.log(
//   "%cWelcome to Metaverse",
//   "font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 4px 4px 0 rgb(226,91,14) , 5px 6px 0 rgb(245,221,8) , 6px 6px 0 rgb(5,148,68) , 7px 7px 0 rgb(2,135,206) , 8px 8px 0 rgb(4,77,145) , 9px 9px 0 rgb(42,21,113)"
// );

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <React.StrictMode>
        {/* <GlobalStyles> */}
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
