import styled from "styled-components"

export const Panel = styled.ul`
    display:flex;
    flex-direction:column;
    position:absolute;
    top:120%;
    right:0;
    width:190px;
    background-color:white;
    border-radius:5px;
    border:1px solid #E9E9E9;
    box-shadow:var(--box-shadow-light);
    z-index:2;
`
export const PanelItem = styled.li`
    display:flex;
    align-items:center;
    width:100%;
    border-top: 1px solid #ccc;
    padding:8px;
    transition-property:background-color;
    transition-duration:.15s;
    &:first-of-type {
        border-top:none;
    }
    &:hover {
        cursor:pointer;
        background-color:rgba(0,0,0,.03);
    }
`
export const ItemImage = styled.img`
    display:flex;
    width:24px;
    height:24px;
    background-color:#ccc;
    margin-right:8px;
    ${ props => props }
`
export const ItemLabel = styled.span`
    display:flex;
    color:#2F3234;
    font-size:16px;
    font-weight:500;
`