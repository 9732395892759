import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";

export const checkProfile = async (address) => {
  const result = await fcl.query({
    cadence: `
    // check_profile.cdc
    import DAAM_Profile from ${keys.profile}
    
    pub fun main(address: Address): Bool {
        return DAAM_Profile.check(address)
    }
        `,
    args: (arg, t) => [arg(address, t.Address)]
  });
  return result;
};
