/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/************************************************
The submitStep has 3 main "SubSteps" called by the following functions (TODO/: separate into components):
  1- SetPriceSubStep.
  2- SubmitNFTSubStep.
  3- NFTSubmissionSentSubStep.
************************************************/
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Space,
  Typography,
  Slider,
  Avatar,
  Spin,
} from "antd";
import {
  PriceBox,
  PriceNestedBox,
  InputElement,
  TextPrice,
  TextCurrency,
  TextColor,
  TitleSubmitNFT,
  TextLeft,
  CardSubStep,
  ColSubStep,
} from "./Step2.styled";
import {
  UserOutlined,
  FormOutlined,
  LinkOutlined,
  Loading3QuartersOutlined,
  RollbackOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { MintContext } from "../../../context/mintContext";
import { getSingleMetadata } from "../../../scripts/getSingleMetadata";
import { AuthContext } from "../../../context/authContext";
import { listToMarketplace } from "../../../scripts/listToMarketplace";
import { listAuctions } from "../../../scripts/listAuctions";
// import fetchAuctionDetails from "../../../scripts/getAuctionDetails";
import { getBalance } from "../../../scripts/getBalance";
import { AcceptRoyaltie } from "../../../scripts/acceptRoyaltie";
import { useAuction } from "../../../context/auctions.context";

const { Paragraph, Text } = Typography;
const { Meta } = Card;

const marks = {
  0: "0%",
  50: "15%",
  100: {
    style: {
      color: "#f50",
    },
    label: <strong>30%</strong>,
  },
};

const SubmitStep = (props) => {
  const history = useHistory();
  const { mintResult } = useContext(MintContext);
  const { isCreator, user } = useContext(AuthContext);
  const [price, setPrice] = useState(null);
  const [isNFTSubmitted, setIsNFTSubmitted] = useState(false);
  const [isNFTPriceSet, setIsNFTPriceSet] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const { getAuctions } = useAuction();
  const { id } = useParams();

  const fetchAuctions = async () => {
    let auctions = await getAuctions();
  };
  useEffect(() => {
    if (isCreator && id && user?.addr) {
      getAuctions();
      getBalance(user?.addr).then((res) =>{});
      getSingleMetadata(user?.addr, parseInt(id)).then((res) =>
        setMetadata(res[1][0])
      );
    }
  }, [id, isCreator]);
  // ************************** 1-setPriceSubStep **************************

  const [royaltie, setRoyaltie] = useState(true);
  // useEffect(() => {
  //   if (metadata) {
  //     checkRoyalties(user?.addr, Number(metadata.mid)).then((res) => {
  //       setRoyaltie(res);
  //     });
  //   }
  // }, [metadata]);

  const safeParse = (string) => {
    try {
      return JSON.parse(string);
    } catch (error) {
      return null;
    }
  };
  const [royaltyNumber, setRoyalty] = useState(15);
  const SetPriceSubStep = () => {
    return royaltie ? (
      <>
        <Row>
          <Col span={24}>
            <TextPrice>Set Price</TextPrice>
            <PriceBox>
              <PriceNestedBox>
                <InputElement
                  name="price"
                  placeholder={10}
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
                {/*<Title style={{ color: '#e74c3c' }}>10,000</Title>*/}
              </PriceNestedBox>
              <TextCurrency>USD$</TextCurrency>
            </PriceBox>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <br />
            <Paragraph>
              This price will be made public. Bidders will not be able to bid
              below this price. Once a bid has been placed, a 24 hour auction
              for the piece will begin.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space>
              <div>
                <Paragraph>
                  <Text type="secondary">
                    A 15% service fee will be charged based on the final sale
                    price of the artwork.
                  </Text>
                </Paragraph>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  style={{ borderColor: "black" }}
                >
                  <Text>Preview</Text>
                </Button>
              </div>
              <div>
                <Paragraph>
                  <Text type="secondary">
                    While your NFT is listed on DAAM, it will be escrowed within
                    DAAM's smart contracts.
                  </Text>
                </Paragraph>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  style={{ backgroundColor: "black", borderColor: "black" }}
                >
                  <TextColor
                    onClick={() => {
                      listToMarketplace({ price: Number(price), mid: id }).then(
                        (res) => history.push("/categories")
                      );
                    }}
                  >
                    Submit NFT
                  </TextColor>
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Text type="danger">
              <FormOutlined />
            </Text>

            {" DAAM Agency set at "}
            <Text size="small" type="danger">
              10%
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="danger">
              <LinkOutlined />
            </Text>
            {" Lean how auctions work. "}
          </Col>
        </Row>
      </>
    ) : (
      <>
        <Slider
          trackStyle={{ backgroundColor: "#e74c3c" }}
          handleStyle={{ borderColor: "#e74c3c" }}
          marks={marks}
          step={1}
          onAfterChange={(e) => setRoyalty(e)}
          defaultValue={royaltyNumber}
        />

        <Button
          type="primary"
          size="large"
          ghost
          style={{ backgroundColor: "black", borderColor: "black" }}
        >
          <TextColor
            onClick={() =>
              AcceptRoyaltie({
                mid: Number(id),
                percentage: ((royaltyNumber / 100) * 0.3).toFixed(2),
              }).then(() => window.location.reload(false))
            }
          >
            Accept Royaltie
          </TextColor>
        </Button>
      </>
    );
  };

  // ************************** 2-submitNFTSubStep **************************
  const SubmitNFTSubStep = () => {
    const { loading } = useContext(MintContext);
    // Wallet transaction confirmation
    // minting();

    //Uncomment to ByPass Wallet transaction confirmation (should comment minting() call)
    if (loading) return null;
    setTimeout(() => {
      setIsNFTSubmitted(true);
    }, 1000);

    return (
      <>
        <Row>
          <Col span={24}>
            <TitleSubmitNFT>Submit NFT</TitleSubmitNFT>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextLeft>
              Confirm this transaction with your wallet to continue. Doing this
              will sign your wallet as the original creator of the NFT.
            </TextLeft>
            <Spin
              indicator={
                <Loading3QuartersOutlined
                  style={{ color: "#FC4448", fontSize: "3em" }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      </>
    );
  };

  // ************************** 3-NFTSubmissionSentSubStep **************************
  const NFTSubmissionSentSubStep = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <TitleSubmitNFT>
              <CheckOutlined style={{ color: "#FC4448" }} />
              {" NFT Submission Sent"}
            </TitleSubmitNFT>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextLeft>
              Your arwork has been sent for Agency Approval, you'll receive a
              notification when it's been minted.
            </TextLeft>
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            size="large"
            ghost
            style={{ borderColor: "black" }}
          >
            <Text
              onClick={() =>
                AcceptRoyaltie({ mid: Number(id), percentage: 0.15 })
              }
            >
              Preview NFT
            </Text>
          </Button>
        </Row>
        <br />
        <Row>
          <Col>
            <Text type="danger">
              <LinkOutlined />
            </Text>

            {" Lean more about how auctions work "}
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="danger">
              <RollbackOutlined />
            </Text>
            {" Return to the dashboard. "}
          </Col>
        </Row>
      </>
    );
  };

  // ************************** RENDERING SubmitStep **************************
  return (
    <>
      <Row>
        <Col span={8}>
          <CardSubStep
            hoverable
            cover={<img alt="example" src={metadata?.file} />}
          >
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={<h4>{metadata && safeParse(metadata.data)?.title}</h4>}
              description={
                <h5>
                  {metadata &&
                    safeParse(metadata.data)?.description?.slice(0, 100)}
                  ...
                </h5>
              }
            />
          </CardSubStep>
        </Col>
        <ColSubStep span={16}>
          {isNFTSubmitted ? (
            <NFTSubmissionSentSubStep />
          ) : isNFTPriceSet ? (
            <SubmitNFTSubStep />
          ) : (
            <SetPriceSubStep />
          )}
        </ColSubStep>
      </Row>
    </>
  );
};

export default SubmitStep;
