/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  IconNFTCategoriesMenu,
  LinkLi,
  SubMenTitle,
  SubMenuCol,
  SubMenuUlWrapper
} from "./CategoriesSubmenu.styled";
import { Space, Row } from "antd";
import { Link } from "react-router-dom";
import * as Routes from "../../../Routes";

const CategoriesSubmenu = (props) => {
  const { isAuth = false } = props;
  const [isHover, toggleHover] = useState(false);
  const toggleHoverMenu = () => {
    toggleHover(!isHover);
    //toggleHover(true);
  };
  const { filterList } = useSelector((obj) => obj);

  return (
    <ul
      onMouseEnter={toggleHoverMenu}
      onMouseLeave={() => toggleHover(false)}
      style={{ padding: 0, margin: 0 }}
    >
      {/* <a href="#">Marketplace</a> */}
      <Link
        to={{
          pathname: isAuth ? Routes.AUTH_CATEGORIES : Routes.PUBLIC_CATEGORIES,
          state: true
        }}
      >
        Categories
      </Link>
      {isHover && (
        <SubMenuUlWrapper location={window.location.pathname}>
          <Row gutter={[16, 16]}>
            <SubMenuCol withrightborder={"true"} span={12}>
              <Space direction="vertical" size={"middle"}>
                <SubMenTitle>DIGITAL</SubMenTitle>
                {filterList.digital.map((item) => (
                  <LinkLi
                    as={Link}
                    to={{
                      pathname: `${isAuth ? "/app" : ""}${item.url}`,
                      state: true
                    }}
                    key={Math.random()}
                    onClick={() => toggleHover(false)}
                  >
                    <IconNFTCategoriesMenu src={item.icon} alt={item.value} />
                    {item.label}
                  </LinkLi>
                ))}
              </Space>
            </SubMenuCol>
            <SubMenuCol withrightborder={"false"} span={12}>
              <Space direction="vertical" size={"middle"}>
                <SubMenTitle level={5}>PHYSICAL</SubMenTitle>
                {filterList.physical.map((item) => (
                  <LinkLi
                    to={{
                      pathname: `${isAuth ? "/app" : ""}${item.url}`,
                      state: true
                    }}
                    key={Math.random()}
                    onClick={() => toggleHover(false)}
                  >
                    <Space>
                      <IconNFTCategoriesMenu src={item.icon} alt={item.value} />
                      {item.label}
                    </Space>
                  </LinkLi>
                ))}
              </Space>
            </SubMenuCol>
            {/* <SubMenuCol span={8}>
              <SubMenTitle level={5}>
                {" "}
                <Space> GENRES</Space>
              </SubMenTitle>
              {filterList.genre.map((item) => (
                <Link to={`${isAuth ? "/app" : ""}${item.url}`}>
                  <IconNFTCategoriesMenu src={item.icon} alt={item.value} />
                  {item.label}
                </Link>
              ))}
            </SubMenuCol> */}
          </Row>
        </SubMenuUlWrapper>
      )}
    </ul>
  );
};

export default CategoriesSubmenu;
