/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthContext, AuthProvider } from "./context/authContext";
import { Home, Login, AuthApplication } from "./pages";
import { AuctionProvider } from "./context/auctions.context";
import "react-toastify/dist/ReactToastify.css";
import Sider from "antd/lib/layout/Sider";
import "react-toastify/dist/ReactToastify.css";
import CreateAccountContainer from "./containers/CreateAccount";
import CreatorPersonalDetails from "./containers/CreateAccount/personaldetails/CreatorPersonalDetails";
import ForgotPWContainer from "./containers/ForgotPassword";
import Globalloader from "./common/globalLoader";
import { Redirect } from "react-router-dom";
import socket from "./common/socketClient";
import { AdminActions, loginActions } from "./redux/actions";

import "./config";

export const App = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((obj) => obj);
  useEffect(() => {

    if (auth?._id) {
      socket.emit("clientInfo", {
        ...auth,
        socketId: socket.id
      });
      // dispatch(loginActions._auth({ ...auth, socketId: socket.id }));
    }
  }, []);

  return (
    <Router>
      {/* <Route path="/creator-profile" component={CreatorPersonalDetails} /> */}
      <AuthProvider>
        <AuctionProvider>
          <ToastContainer />
          <Globalloader />
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/create" exact component={CreateAccountContainer} />
            <Route path="/forgotpassword" exact component={ForgotPWContainer} />
            <Route path="/app" component={AuthApplication} />
            <Route path="/" component={Home} />
            {/* <Route exact path="/">
              {user?.addr ? <Redirect to="/app" /> : <Home />}
            </Route> */}
          </Switch>
        </AuctionProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
