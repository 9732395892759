import { Spin } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import axios from "./axios";

export const GloballoaderDiv = styled.div`
  ${(props) =>
    props.showLoader &&
    `
 position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    
 `}
`;
export const LoaderContainer = styled.div`
  /* position: fixed; */
  ${(props) =>
    props.showLoader &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    `}
`;

const Globalloader = () => {
  const [showLoader, setLoader] = useState(false);

  axios.interceptors.request.use((request) => {
    setLoader(true);
    return request;
  });
  axios.interceptors.response.use(
    function (response) {
      setLoader(false);
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return (
    <GloballoaderDiv showLoader={showLoader}>
      <LoaderContainer showLoader={showLoader}>
        {showLoader && <Spin size="large" />}
      </LoaderContainer>
    </GloballoaderDiv>
  );
};

export default Globalloader;
