export const LOGIN = "/login";
export const SIGN_UP = "/create";
export const ForgotPassword = "/forgotpassword";
export const VERIFY = "/verify";
export const PERSONAL = "/personal";
export const SELECTROLE = "/selectrole";
export const COLLABARATOR = "/creaTE";
export const HOME = "/home";
export const AGENT = "/agent-dashboard";
export const CREATOR = "/creator-dashboard";
export const ADMIN = "/admin-dashboard";
export const DASHBOARD = "/app/dashboard";
export const MINT = "/app/mint";
export const MINTING = "/app/minting";
export const MINT_START = "/app/mint/:filterParam";
export const MANAGE_CREATOR_AGENT = "/app/manage";
export const ADMIN_INVITE = "/app/admin";
// export const NFT_DETAILS_PAGE = "/app/list";
export const AUTH_CATEGORIES = "/app/categories";
export const NFT_DETAILS_PAGE = "/app/art";

export const PROFILE = "/app/profile";
export const COLLECTIONS = "/app/collections";
export const DETAILS = "/app/details";
export const PUBLIC_DETAILS = "/details";
export const PUBLIC_CATEGORIES = "/categories";
