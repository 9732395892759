import styled from "styled-components";

export const DashboardRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.exploreNft ? "0px" : "96px")};
`;
export const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  padding: 0px 12px;
`;
export const DashboardHeaderMore = styled.div`
  display: flex;
  align-items: center;
  color: #ff4040;
  font-size: 16px;
  font-weight: 600;
  flex-direction: column;
  img {
    display: inline-flex;
    margin-left: 8px;
  }
  &:hover {
    cursor: pointer;
  }
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #fc4448;
  border-radius: 8px;
  opacity: 1;
`;
export const DashboardTitle = styled.h3`
  display: flex;
  text-align: left;
  align-items: center;
  font: normal normal 600 34px/51px Oswald;
  letter-spacing: 1.02px;
  color: #1a1d1f;
  opacity: 1;
`;
export const DashboardTitleCount = styled.h3`
  display: flex;
  color: #ff4040;
  font-size: 24px;
  line-height: 24px;
  margin-right: 8px;
`;
export const DashboardList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 120px;
`;

export const ContentItem = styled.span`
  text-align: left;
  font: normal normal 300 16px/22px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
  padding: 5px;
  word-spacing: 3px;
  border-bottom: 1px solid #e7e7e7;
  :last-of-type {
    border: none;
  }
  cursor: pointer;
`;
