/* eslint-disable react-hooks/exhaustive-deps */
import OpenSeaDragon from "openseadragon";
import React, { useEffect, useState } from "react";

const OpenSeaDragonViewer = ({ image, filetype }) => {
  const [viewer, setViewer] = useState(null);
  

  //   const titleSource = {
  //     Image: {
  //       xmlns: "http://schemas.microsoft.com/deepzoom/2008",
  //       Url: image,
  //       Format: filetype,
  //       Overlap: "2",
  //       TileSize: "256",
  //       Size: {
  //         Width: '100%',
  //         Height: "642px"
  //       }
  //     }
  //   };

  useEffect(() => {
    if (image && viewer) {
      viewer.open(image);
    }
  }, [image]);

  const InitOpenseadragon = () => {
    viewer && viewer.destroy();
    setViewer(
      OpenSeaDragon({
        id: "openSeaDragon",
        // prefixUrl: "openseadragon-images/",
        prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        animationTime: 0.5,
        blendTime: 0.1,
        // constrainDuringPan: true,
        //maxZoomPixelRatio: 2,
        //minZoomLevel: 1,
        //visibilityRatio: 1,
        zoomPerScroll: 2,
        tileSources: {
          type: "image",
          url: image
        }
        // tileSources: titleSource
      })
    );
  };

  useEffect(() => {
    InitOpenseadragon();
    return () => {
      viewer && viewer.destroy();
    };
  }, []);

  return (
    <div
      id="openSeaDragon"
      style={{
        width: "100%",
        height: "642px"
      }}
    ></div>
  );
};

export default OpenSeaDragonViewer;
