/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { Collapse } from "antd";

const { Panel } = Collapse;
export const FormUnit = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.w ? props.w : "100%")};
  ${(props) => props.pl && `padding-left:${props.pl}`}
  ${(props) => props.pr && `padding-right:${props.pr}`}
    ${(props) => props.mb && `margin-bottom:${props.mb}`}
`;
export const FormUnits = styled.div`
  display: flex;
  width: 100%;
  ${(props) => props.mb && `margin-bottom:${props.mb}`}
  ${(props) => props.fd && `flex-direction:${props.fd}`}
`;
export const FormUnitTitle = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  color: black;
  font-size: 14px;
  font-weight: 500;
`;

// Input field
export const FormField = styled.input`
  display: flex;
  height: 54px;
  color: #1a1d1f;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;

  padding-left: 16px;
  background-color: #f5f5f5;
  border: 1px solid #999b9e;
  border-radius: 5px;
  &::placeholder {
    color: #1a1d1f;
  }
`;

// Textarea
export const FormTextArea = styled.textarea`
  display: flex;
  background-color: #f5f5f5;
  padding-top: 16px;
  padding-left: 16px;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  border: 1px solid #999b9e;
  border-radius: 5px;
  resize: none;
  &::placeholder {
    font-family: Arial;
    color: #1a1d1f;
  }
`;

// Textarea
export const FormCollapse = styled(Collapse)`
  display: flex;
  font-size: 14px;
  background-color: #f5f5f5;
  font-weight: 600;
  color: #1a1d1f;
  border: 1px solid #999b9e;
  border-radius: 5px;
  resize: none;
  width: 100%;
  &::placeholder {
    font-family: Arial;
    color: #1a1d1f;
  }
  & .ant-collapse-header {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

// Form File Upload
export const FormFileUpload = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  border: 1px solid #999b9e;
  border-radius: 5px;
  ${(props) => (props.h ? `height:${props.h}` : "height:164px")};
`;
export const FormFileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px dashed gray;
`;

// Form Row
export const FormRow = styled.div`
  display: flex;
  ${(props) => props.fd && `flex-direction:${props.fd};`}
  ${(props) => props.mt && `margin-top:${props.mt};`}
`;
