import { io } from "socket.io-client";
import keys from "../config/keys";

const socket = io(keys.serverURL);
//socket.connect();
socket.on("connect", () => {
  console.log("Connected", socket.id);
});

export default socket;
