import styled from "styled-components";

export const CommonCardContainer = styled.div`
  width: 327px;
  height: 424px;
  margin: 10px;

  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000b;
  border: 1px solid #e6e6e6;
  border-radius: 22px;
  opacity: 1;
`;

export const CardWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
`;

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 327px;
  height: 286px;
  border: 1px solid var(--unnamed-color-cdd0d3);
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0d3;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
`;
export const CardTopImage = styled.div`
  width: 288px;
  height: 207px;
  background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  opacity: 1;
`;
export const CardBottom = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
`;
export const CardBottomTop = styled.span`
  height: 50px;
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const CardBottomBottom = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  /* flex-direction: row; */
`;
export const CardBottomLeft = styled.div``;
export const CardBottomRight = styled.div``;
export const PriceList = styled.div`
  color: var(--unnamed-color-ff4040);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: ${(props) => props.color && props.color};
  opacity: 1;
  margin-bottom: 10px;
`;
// export const CardTop = styled.div``;
// export const CardTop = styled.div``;
// export const CardTop = styled.div``;
