import React from "react";
import {
  FooterIcon,
  ReadMore,
  HomeFooterLeft,
  HomeFooterRight
} from "../Home.styled";
import { Col, Row, Typography } from "antd";
import Image from "../../../components/Image";
import thunderIcon from "../../../images/icons/homePage/thunder.png";
import bookIcon from "../../../images/icons/homePage/book.png";
// import WordedLogo from "../../../images/worded_logo.svg";
import { Footer } from "../../../components/Footer";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

// const { Search } = Input;
const {
  Title // Text
} = Typography;
const HomeFooter = (props) => {
  return (
    <>
      <Row>
        <Col xs={24} xl={12} xxl={12} md={12} sm={24}>
          <HomeFooterLeft>
            <FooterIcon mt>
              <Image src={bookIcon} w="60px" style={{ marginRight: "20px" }} />
            </FooterIcon>
            <Title level={4}>Documentation</Title>
            <span className="get-started">
              Learn more about how to utilize DAAM to manage your digital
              creations.
            </span>
            <Link to={"/"} type="danger">
              <ReadMore>
                Read more <RightOutlined style={{ height: "14px" }} />
              </ReadMore>
              {/* {"Link >"} */}
            </Link>
          </HomeFooterLeft>
        </Col>
        <Col xs={24} xl={12} xxl={12} md={12} sm={24}>
          <HomeFooterRight>
            <FooterIcon>
              <Image
                src={thunderIcon}
                w="60px"
                style={{ marginRight: "14px" }}
              />
            </FooterIcon>
            <Title level={4}>Get started </Title>
            <span className=" get-started">
              Get up and running quickly as a Collector or Creator or Apply to
              become an Agent today.
            </span>
            <Link type="danger" to={"#"}>
              <ReadMore>
                Get Minted Now <RightOutlined style={{ height: "14px" }} />
              </ReadMore>
            </Link>
          </HomeFooterRight>
        </Col>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </>
  );
};

export default HomeFooter;
