import { GlobalStyles } from "../GlobalStyles";
import { LayoutStyles } from "./styles";

export default function AgencyLayout({children}) {
    return (
        <LayoutStyles>
            <GlobalStyles/>
            {children}
        </LayoutStyles>
    )
}