import styled from "styled-components";
import { Select } from "antd";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  FallOutlined,
  RiseOutlined,
} from "@ant-design/icons";

export const RelevanceSelectWrapper = styled.div`
  width: 100%;
  padding: 0;
`;
export const CustomizedSelect = styled(Select)`
  width: 100%;
  background-color: transparent;
`;

export const RedSortAscendingOutlined = styled(SortAscendingOutlined)`
  color: "red";
`;
export const RedSortDescendingOutlined = styled(SortDescendingOutlined)`
  color: "red";
`;
export const RedFallOutlined = styled(FallOutlined)`
  color: "red";
`;
export const RedRiseOutlined = styled(RiseOutlined)`
  color: "red";
`;
