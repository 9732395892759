/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Image } from "antd";
import React, { useEffect, useState } from "react";
import { TopBar } from "../selectrole/SelectRole.styled";
import { toast } from "react-toastify";

import {
  ButtonDiv,
  FormItemVerify,
  OTPVerificationInputVerify,
  VerifyCenterDiv,
  VerifyCenterDivItem,
  VerifyCodeValidTitle,
  VerifyEmailTitle,
  VerifyFormItem,
  VerifyMainDiv,
  VerifySubTitle,
  VerifySubTitleBottom,
  VerifyTitle,
} from "./Verify.styled";
import * as Routes from "../../../Routes";

import Logo from "../../../images/worded_logo.svg";
import {
  ClickButton,
  RegisterText,
} from "../CreateAccount.styled";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Verify({ onValidateOtp, onResendEmail }) {
  const [seconds, setSeconds] = useState(15);
  const [otp, setOtp] = useState("");
  const history = useHistory();

  const { user } = useSelector((obj) => obj);
  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
      // history.push(Routes.LOGIN);
    }
    return () => clearInterval(interval);
  }, [history, seconds]);

  const clearOtp = () => setOtp("");

  // eslint-disable-next-line no-unused-vars
  const validate = async (value) => {
    if (value.toString() === user?.otp.toString()) {
      history.push(Routes.PERSONAL);
    } else {
      setOtp("");
      toast("Invalid Verification Code", {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: true,
      });
      clearOtp();
    }
  };
  return (
    <>
      <VerifyMainDiv>
        <TopBar>
          <Image preview={false} src={Logo} width="100%" />
        </TopBar>
        <VerifyCenterDiv>
          <VerifyCenterDivItem>
            <VerifyTitle>Verify Account</VerifyTitle>
            <VerifySubTitle>
              Please enter the 6-digit verification code that was sent to
              <VerifyEmailTitle> email address.</VerifyEmailTitle>
            </VerifySubTitle>
            <VerifyCodeValidTitle>
              Your code is valid for 24 hours
            </VerifyCodeValidTitle>
            <VerifyFormItem>
              <Form
                name="basic"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <FormItemVerify>
                  <OTPVerificationInputVerify
                    length={6}
                    placeholder=""
                    onCompleted={onValidateOtp}
                    onChange={setOtp}
                    value={otp}
                  />
                </FormItemVerify>
                <Form.Item>
                  <RegisterText>
                    <span className="rtext" style={{ textAlign: "center" }}>
                      <a href="#">Still haven’t received the email?</a>
                    </span>
                  </RegisterText>
                </Form.Item>
                <Form.Item>
                  <VerifySubTitleBottom>
                    Please verify the address is correct and check your spam
                    folder, check mail is working normally.
                  </VerifySubTitleBottom>
                </Form.Item>
                <ButtonDiv>
                  <Form.Item>
                    <ClickButton
                      inverse={"true"}
                      htmlType="button"
                      disabled={seconds > 0}
                      onClick={() => onResendEmail()}
                    >
                      {seconds > 0
                        ? `Resend Code in (${seconds}s)`
                        : `Resend Code`}
                    </ClickButton>
                  </Form.Item>
                </ButtonDiv>
              </Form>
            </VerifyFormItem>
          </VerifyCenterDivItem>
        </VerifyCenterDiv>
      </VerifyMainDiv>
    </>
  );
}
