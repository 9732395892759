import { Button, Menu, Modal, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

export const TopNavigationStyles = styled.nav`
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  // background: #CDD0D3;
  // padding-top: 12px;
  // padding-left: var(--x-gutter);
  // padding-right: var(--x-gutter);
  z-index: 2;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const CenterStyles = styled.div`
  display: flex;
  // a {
  //   display: flex;
  //   margin: 0 16px;

  //   letter-spacing: 1.01px;
  //   color: #1a1d1f;
  //   text-transform: uppercase;
  //   opacity: 1;
  //   font: normal normal bold 16px/21px Oswald;
  //   & :hover {
  //     color: red;
  //   }
  //   & :after {
  //     background: none repeat scroll 0 0 transparent;
  //     // bottom: 0;
  //     // content: "";
  //     // display: block;
  //     // height: 2px;
  //     // left: 50%;
  //     // position: absolute;
  //     background: red;
  //     transition: width 0.3s ease 0s, left 0.3s ease 0s;
  //     // width: 0;
  //   }
  //   & :hover {
  //     & :after {
  //       width: 100%;
  //       left: 0;
  //     }
  //   }
  // }
  // &:after {
  //   background: none repeat scroll 0 0 transparent;
  //   // bottom: 0;
  //   // content: "";
  //   // display: block;
  //   // height: 2px;
  //   // left: 50%;
  //   // position: absolute;
  //   background: red;
  //   transition: width 0.3s ease 0s, left 0.3s ease 0s;
  //   // width: 0;
  // }
`;
export const RightStyles = styled.div`
  display: flex;
  align-items: center;
  &.anticon {
    vertical-align: 0.2em !important;
  }
`;

export const SiderStyles = styled(Sider)`
  max-width: 100% !important;
  width: 100% !important;
  background: transparent;
`;

export const LoginButton = styled(Button)`
  width: 92px;
  height: 40px;
  background: #1a1d1f 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  text-align: center;
  font: normal normal 500 14px/21px Oswald;
  letter-spacing: 1.34px;
  color: #f4f4f4;
  text-transform: uppercase;
  margin-right: 8px;
  & :hover {
    background: none;
    color: #1a1d1f;
  }
`;

export const CreateButton = styled(Button)`
  width: 92px;
  height: 40px;
  background: #2bce88 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #2bce88;
  text-align: center;
  font: normal normal 500 14px/21px Oswald;
  letter-spacing: 1.34px;
  color: #1a1d1f;
  text-transform: uppercase;
  margin-right: 8px;
  &:hover {
    // background: none;
    // color: #1a1d1f;
    background: #2bce88 0% 0% no-repeat padding-box;

    border-color: #1a1d1f;
  }
`;

export const StartButton = styled(Button)`
  width: 92px;
  height: 40px;
  background: #2bce88 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #2bce88;
  text-align: center;
  font: normal normal 500 14px/21px Oswald;
  letter-spacing: 1.34px;
  color: #1a1d1f;
  text-transform: uppercase;
  margin-right: 8px;
  & :hover {
    background: none;
    color: #ff4040;
  }
`;

export const MenuLink = styled.div`
  display: flex;
  margin: 0 16px;
  letter-spacing: 1.01px;
  color: #1a1d1f;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal 500 14px/21px Oswald;
  & a {
    color: #1a1d1f;
  }
  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    // left: 50%;
    position: absolute;
    background: red;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    top: 60px;
  }
  & :hover {
    color: #1a1d1f;
    & :after {
      width: 100%;
      left: 0;
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  & .ant-divider-horizontal {
    margin: 2px 0px;
  }
  margin: 0px 3px;
  font: normal normal normal 16px/22px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
  &:hover {
    background: #ff4040 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  word-spacing: ${(props) => props.ws && `80px`};
`;

export const RightMenu = styled(Menu)`
  & .ant-divider-horizontal {
    margin: 2px 0px;
  }
`;

export const LogoutButton = styled(Button)`
  background: #2bce88 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid #2bce88;
  margin: 10px;
  width: 92%;
`;

export const AccountSetupModal = styled(Modal)`
  & .ant-modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    font: normal normal 500 16px Lato;
    letter-spacing: 0.2px;
    color: #1a1d1f;
  }
  & .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #0a714b;
    background: #0a714b;
    & .ant-steps-icon {
      color: #fff;
    }
  }
`;

export const CopyableTypo = styled(Typography.Paragraph)`
  margin-bottom: 0em !important;
  & div {
    display: inline-flex !important;
  }
`;
