import * as ax from "axios";
import axios from "../../../common/axios";
import * as config from "../../../config/keys";
import * as types from "../../constTypes/userTypes";
import * as toast from "../../../common/toast";

const _users = (data) => {
  return {
    type: types.ALL_USERS,
    payload: data
  };
};

const _user = (data) => {
  return {
    type: types.USER_LOGIN,
    payload: data
  };
};

const _collections = (data) => {
  return {
    type: types.COLLECTIONS,
    payload: data
  };
};

export const getUsers = () => async (dispatch) => {
  await axios({
    method: "get",
    url: `${config.serverURL}/users/getUserList`
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_users(res.data.data));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateUserStatus = (data, onSucess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/updateUserById`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          if (onSucess) {
            onSucess();
          }
          getUsers();
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast.error(e.message);
    });
};
export const updateUserStatusByaddress = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/updateUserByAddress`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          getUsers();
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast.error(e.message);
    });
};

export const deleteUser = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/deleteuser`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
        } else {
          console.log("error");
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      // toast(e.message, {
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   closeButton: false, // Remove the closeButton
      //   theme: "colored",
      //   icon: false,
      // });
      console.log("catch error", JSON.stringify(e));
    });
};

export const getUsersForAgent = (address) => async (dispatch) => {
  await axios({
    method: "get",
    url: `${config.serverURL}/users/getUserListforAgents/${address}`
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_users(res.data.data));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const sendInviteMail = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/sendinviteemail`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          toast.success("Verification Code has been send to you mail id.");
        } else {
          toast.error(res.data.data.name + " your " + res.data.data.status);
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast.error(e.message);
      console.log("catch error", JSON.stringify(e));
    });
};

export const get_users_with_collection = () => async (dispatch) => {
  await axios({
    method: "get",
    url: `${config.serverURL}/users/getuserswithcollection`
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_collections(res.data.data));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getUserDetailsByAddress = (address) => async (dispatch) => {
  await axios({
    method: "get",
    url: `${config.serverURL}/users/getUserByaddress?address=${address}`
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_user(res.data.data));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const get_exchange_rate = async () => {
  return await ax({
    method: "get",
    url: `https://api.coingecko.com/api/v3/simple/price?ids=flow&vs_currencies=USD`
  })
    .then((res) => {
      return res?.data?.flow?.usd;
    })
    .catch((e) => {
      console.log("catch error", e);
    });
};
