import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import * as config from "../../config/keys";
import * as actions from "../../redux/actions";
import * as encryptdecrypt from "../../common/encryptdecrypt";
import CreatorManage from "./CreatorManage";

import { ManageContainer, ManageContainerWrapper } from "./manage.styled";

const ManageCreators = () => {
  const dispatch = useDispatch();
  const { userList, auth } = useSelector((obj) => obj);

  useEffect(() => {
    dispatch(actions.AdminActions.getUsersForAgent(auth?.address));
  }, [auth.address, dispatch]);

  const onInvite = (data) => {
    const reqData = {
      email: data.value,
      agentAddress: auth.address,
      agentEmail: auth.email,
      agentName: auth.name,
      isAgent: data.isAgent,
      time: new Date().getTime()
    };

    const ciphertext = encryptdecrypt.encrypt(reqData);
    const url = `${config.clientURL}/create?flqr=${ciphertext}`;
    reqData.url = url;

    dispatch(actions.AdminActions.sendInviteMail(reqData));
  };

  useEffect(() => {}, [userList]);

  return (
    <ManageContainer style={{}}>
      <ManageContainerWrapper style={{}}>
        <Row justify="center" align="top">
          <Col xl={18} lg={18} md={18} sm={24} xs={24}>
            {userList && userList?.creator && (
              <CreatorManage
                users={userList?.creator?.filter(
                  (x) => x.agentAddress === auth?.address
                )}
                type={`Creators`}
                onInvite={onInvite}
              />
            )}
          </Col>
        </Row>
      </ManageContainerWrapper>
    </ManageContainer>
  );
};
export default ManageCreators;
