import { ListItem, Options, Title, OptionContainer, OptionTrigger } from '../ListItem'
import Image from '../Image'
import { useState } from 'react'
import CreatorManagementPanel from '../CreatorManagementPanel'

export default function CreatorListItem({image, title}) {
    const [creatorManagementPanelVisibility, setCreatorManagementPanelVisibility] = useState(false)
    return (
        <ListItem>
            <Image src={image} w='56px' />
            <Title>{title}</Title>
            <Options>
                <OptionContainer>
                    <OptionTrigger onClick={() => setCreatorManagementPanelVisibility(!creatorManagementPanelVisibility)} />
                    {
                        creatorManagementPanelVisibility && <CreatorManagementPanel />
                    }
                </OptionContainer>
            </Options>
        </ListItem>
    )
}