import React from "react";
import {
  RedCloseOutlined,
  MessagesWrapper,
  DividerMessage,
} from "./SysMessages.styled";
import { Popover, Typography, Button, Row, Col, Space } from "antd";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SystemMessages = (props) => {
  const text = (
    <Space size={"large"}>
      <Col flex="auto">
        <Text strong>{"DAAM SYSTEM MESSAGES"}</Text>
      </Col>
      <Col flex="5px">
        <RedCloseOutlined />
      </Col>
    </Space>
  );
  const content = (
    <MessagesWrapper>
      <Text strong>{"Mint Log"}</Text>
      <br />
      <br />
      <Row>
        <Col flex="auto">{"inprogress - item name"}</Col>
        <Col flex="5px">
          <LoadingOutlined />
        </Col>
      </Row>
      <DividerMessage dashed />
      <Row>
        <Col flex="auto">{"inprogress - item name"}</Col>
        <Col flex="5px">
          <LoadingOutlined />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">{"The item name"}</Col>
        <Col flex="5px">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">{"The item name"}</Col>
        <Col flex="5px">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Col>
      </Row>
      <DividerMessage dashed />
      <Row>
        <Col flex="auto">{"The item name"}</Col>
        <Col flex="5px">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Col>
      </Row>
      <br />
      {"What else?"}
      <br />
      <br />
      <Row>
        <Col span={12}>{"View all"}</Col>
        <Col span={12}>{"Sort date"}</Col>
      </Row>
    </MessagesWrapper>
  );
  return (
    <Popover placement="right" title={text} content={content} trigger="click">
      <Button>CLICK HERE TO TEST - DAAM SYSTEM MESSAGES POPOVER</Button>
    </Popover>
  );
};

export default SystemMessages;
