/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Space, Tag, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import Tiff from "tiff.js";
import { Document, Page, pdfjs } from "react-pdf";
import {
  AreaCenter,
  CenterIcon,
  CenterLabel,
  UploadDropArea,
  UploadDropAreaContainer,
  UploadNFTBrowseFilesStyles,
  UploadImageButton,
  CustomUpload,
  PageControls
} from "./styles";
import Media from "../../images/filedrop.png";
import { MintContext } from "../../context/mintContext";
import { error } from "../../common/toast";
import { VideoSrc } from "../../common/commonStyles";
import PDFViewer from "../PDFViewer";

export default function UploadNFTBrowseFiles({ setErrorMsgFile }) {
  
  const [fileList, setFileList] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    setPreviewVisible(true);
  };

  const convertTiffImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const { mintResult, setMintResult } = useContext(MintContext);
  const handleChange = async ({ fileList, file }) => {
    if (file) {
      if (!file.type.startsWith("image/tif"))
        file.preview = await getBase64(fileList[0].originFileObj);
      else {
        const tifff = await convertTiffImage(fileList[0].originFileObj);
        const tmp = new Tiff({ buffer: tifff });
        file.preview = tmp.toDataURL();
      }
    }
    setErrorMsgFile("");
    

    setMintResult((prev) => ({
      ...prev,
      file: fileList[0].preview,
      fileName: fileList[0].name,
      fileSize: fileList[0].size,
      fileType: fileList[0].type,
      fileLastModified: fileList[0].lastModified
    }));
  };

  const checkValidUpload = (file) => {
    const isValidFile = mintResult.allowedExtensions.includes(
      file?.name?.split(".").pop()
    );
    if (!isValidFile) {
      error(`${file.name} is not a ${mintResult.mintLabel} file`);
      // message.error(`${file.name} is not a ${mintResult.mintLabel} file`);
    }
    return isValidFile || CustomUpload.LIST_IGNORE;
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    
    setNumPages(numPages);
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <>
      {/* <UploadNFTBrowseFilesStyles> */}
      <UploadDropAreaContainer>
        {mintResult.file && (
          <DeleteFilled
            style={{ color: "red" }}
            onClick={() => {
              setMintResult((prev) => ({
                ...prev,
                file: null,
                preview: null,
                fileName: null,
                fileSize: null,
                fileType: null,
                fileLastModified: null
              }));
              setPreviewImage(null);
            }}
          />
        )}
        <UploadDropArea>
          <AreaCenter>
            
            {mintResult.file ? (
              mintResult.mintType === "Audio" ||
              mintResult.mintType === "Video" ? (
                <VideoSrc controls>
                  <source src={mintResult.file} type={mintResult.fileType} />
                </VideoSrc>
              ) : mintResult.fileType.startsWith("application/pdf") ? (
                <PDFViewer mintResult={mintResult} />
              ) : (
                <img
                  style={{ width: "250px" }}
                  src={mintResult.file}
                  alt={"alter"}
                />
              )
            ) : (
              <>
                <CustomUpload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={checkValidUpload}
                >
                  <CenterIcon src={Media} />
                  <CenterLabel>{`Drag & Drop`}</CenterLabel>
                  <Space direction="vertical">
                    <Tag color={"magenta"} className="pb-1 font-weight-bold">
                      <p
                        style={{ whiteSpace: "normal" }}
                      >{`Allowed Extensions:  ${mintResult.allowedExtensions}`}</p>
                    </Tag>
                    <UploadImageButton>Browse</UploadImageButton>
                  </Space>
                </CustomUpload>
              </>
            )}
            {/* <Button order='full-red' size='regular' corners='full-radius'>Browse Files</Button> */}
          </AreaCenter>
        </UploadDropArea>
      </UploadDropAreaContainer>
      {/* </UploadNFTBrowseFilesStyles> */}
    </>
  );
}
