import {
  CheckCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import React from "react";
import {
  CloseIcon,
  NotificationContainer,
  NotificationHeader,
  NotificationTable,
  NotificationTableData,
  NotificationTableHead,
  NotificationTableRow,
  NotificationTableWrapper,
  NotificationTitle,
} from "./style.styled";
const Notification = (props) => {
  const fakeData = [
    { name: "The Item name", sold: "true" },
    { name: "The Item name", sold: "true" },
    { name: "The Item name", sold: "false" },
    { name: "The Item name", sold: "false" },
    { name: "The Item name", sold: "false" },
    { name: "The Item name", sold: "false" },
  ];
  return (
    <NotificationContainer>
      <NotificationHeader>
        <i
          className="fa fa-bell"
          aria-hidden="true"
          style={{ color: "#FF4040", fontSize: "24px" }}
        ></i>
        <NotificationTitle>Notification Menu</NotificationTitle>

        <CloseIcon onClick={() => props.setNotification(false)}>
          <CloseOutlined
            style={{
              border: "1px solid red",
              borderRadius: "50%",
              color: "#FF4040",
              padding: "3px",
              fontSize: "15px",
            }}
          />
        </CloseIcon>
      </NotificationHeader>
      <NotificationTableWrapper className="">
        <NotificationTable>
          <NotificationTableRow>
            <NotificationTableHead sym>System Message</NotificationTableHead>
            <NotificationTableHead colorAndUnderLine>
              Selling
            </NotificationTableHead>
            <NotificationTableHead colorAndUnderLine>
              Sold
            </NotificationTableHead>
          </NotificationTableRow>
          {fakeData.map((item) => {
            return (
              <NotificationTableRow>
                <NotificationTableData color={item.sold === "true"}>
                  {item.name}
                </NotificationTableData>
                <NotificationTableData>selling</NotificationTableData>
                <NotificationTableData>
                  {item.sold === "true" ? (
                    <CheckCircleOutlined style={{ color: "#2BCE88" }} />
                  ) : (
                    <LoadingOutlined />
                  )}
                </NotificationTableData>
              </NotificationTableRow>
            );
          })}
        </NotificationTable>
      </NotificationTableWrapper>
    </NotificationContainer>
  );
};

export default Notification;
