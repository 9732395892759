/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Row, Col, Card, Spin, Space, Typography } from "antd";
import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle";
import Image from "../../../components/Image";
import { MintContext } from "../../../context/mintContext";
import { MetaDataHeader, MetaDataHeaderText } from "../../art/Art.styled";
import { ButtonCancel } from "../uploadStep/Step1.styled";
import Button from "../../../components/Button";
import Cancel from "../../../images/times2x.png";
import ArrowLeft from "../../../images/arrow-left.png";
import {
  SubHeadings,
  SetPriceParagraph,
  PriceHighlightedValue,
  Label,
  LabelValue
} from "./confirmNFT.styled";
import { MintingButton, MintingText, ToastText } from "../Minting.styled";
import { VideoSrc } from "../../../common/commonStyles";
import { PDFViewer } from "../../../components";

const { Title, Paragraph, Text, Link } = Typography;

const ConfirmNFTDetails = ({ onConfirm, onCancelClick, onPreviousClick }) => {
  const { mintResult, minting, mint_and_list } = useContext(MintContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const toastId = React.useRef(null);
  const [data, setData] = useState(null);
  const [metadata, setMetaData] = useState(null);

  const getFilesize = (size) => {
    let _size = size,
      i = 0;
    const fSExt = ["Bytes", "KB", "MB", "GB"];
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    const exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  const mintNFT = () => {
    setIsDisabled(true);

    toastId.current = toast(
      <Space size="large">
        <Spin size="large" />
        <ToastText>Minting your NFT</ToastText>
      </Space>,
      {
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        pauseOnFocusLoss: false,
        icon: false
      }
    );

    if (mintResult.saleType === "auction") {
      mint_and_list((res) => {
        if (res !== undefined) {
          onConfirm(res);
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Miniting success, waiting for admin approval 👌`}</ToastText>
            ),
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
        } else {
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Minting unsuccessfull 🤯, Try Again..`}</ToastText>
            ),
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
          setIsDisabled(false);
        }
      });
    } else {
      minting((res) => {
        if (res !== undefined) {
          onConfirm(res);
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Miniting success, waiting for admin approval 👌`}</ToastText>
            ),
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
        } else {
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Minting unsuccessfull 🤯, Try Again..`}</ToastText>
            ),
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
          setIsDisabled(false);
        }
      });
    }
  };
  return (
    <Spin spinning={isDisabled}>
      <Row align="middle" justify="end">
        <Col span={1} className="arrow-left">
          <ButtonCancel
            order="tertiary"
            size="regular"
            ml="16px"
            onClick={onPreviousClick}
          >
            <Image src={ArrowLeft} w="16px" />
          </ButtonCancel>
        </Col>
        <Col span={20}>
          <PageTitle
            m={"1em"}
            style={{
              font: "normal normal 600 34px Oswald",
              letterSpacing: "0.48px"
            }}
          >
            Confirm NFT Details
          </PageTitle>
        </Col>
        <Col span={1} justify="end" style={{ marginRight: "20px" }}>
          <Button order="tertiary" size="regular" onClick={onCancelClick}>
            <Image src={Cancel} w="16px" />
          </Button>
        </Col>
      </Row>

      <Row gutter={[32, 32]}>
        <Col
          span={10}
          lg={{ span: 10, offset: 2 }}
          sm={24}
          xs={24}
          md={{ span: 10, offset: 2 }}
        >
          {mintResult.file && (
            <Card
              hoverable
              cover={
                mintResult.mintType === "Audio" ||
                mintResult.mintType === "Video" ? (
                  <VideoSrc controls>
                    <source src={mintResult.file} type={mintResult.fileType} />
                  </VideoSrc>
                ) : mintResult.fileType.startsWith("application/pdf") ? (
                  <PDFViewer mintResult={mintResult} />
                ) : (
                  <img
                    style={{ height: "90%", width: "100%" }}
                    src={mintResult.file}
                    alt="mint-file"
                  />
                )
                // mintResult.fileType.startsWith("image/tif") ? (
                //     <embed
                //       style={{ height: "90%", width: "100%" }}
                //       src={mintResult.file}
                //       type={mintResult.fileType}
                //       negative="yes"
                //     />
                //   ) : (
                //     <img
                //       style={{ height: "90%", width: "100%" }}
                //       src={mintResult.file}
                //       alt="mint-file"
                //     />
                //   )
              }
            >
              <Card.Meta
                title={mintResult.title}
                description={mintResult.description}
              />
            </Card>
          )}
        </Col>
        <Col lg={{ span: 10 }} sm={24} xs={24} md={{ span: 10 }}>
          <Row>
            <Col span={24}>
              <SubHeadings>{"Description"}</SubHeadings>
            </Col>
            <Col span={24}>
              <SetPriceParagraph>{mintResult.description}</SetPriceParagraph>
            </Col>
            <Col span={12}>
              <SubHeadings>{"Price"}</SubHeadings>
            </Col>
            <Col span={12}>
              <SubHeadings>{"Royalty"}</SubHeadings>
            </Col>
            <Col span={12}>
              <PriceHighlightedValue>{`$${mintResult.price}`}</PriceHighlightedValue>
            </Col>
            <Col span={12}>
              <PriceHighlightedValue>{`${mintResult.royaltyPer}%`}</PriceHighlightedValue>
            </Col>
            <Col span={24}>
              <SubHeadings>{"Technical Metadata"}</SubHeadings>
              <hr />
              <Row justify="space-evenly">
                <Col span={6}>
                  <Label>{"Date Created:"}</Label>
                </Col>
                <Col span={6} justify="end">
                  <LabelValue>
                    {new Date(mintResult?.mintedDate?._d).toLocaleDateString(
                      "en-US"
                    )}
                  </LabelValue>
                </Col>
                <Col span={6}>
                  <Label>{"Type:"}</Label>
                </Col>
                <Col span={6} justify="end">
                  <LabelValue>{mintResult.fileType}</LabelValue>
                </Col>
              </Row>
              <Row justify="space-evenly">
                <Col span={6}>
                  <Label> {"Date Minted:"}</Label>
                </Col>
                <Col span={6} justify="end">
                  <LabelValue>
                    {new Date(mintResult?.mintedDate?._d).toLocaleDateString(
                      "en-US"
                    )}
                  </LabelValue>
                </Col>

                <Col span={6}>
                  <Label>{"Extension:"}</Label>
                </Col>
                <Col span={6}>
                  <LabelValue>
                    {mintResult.fileName.split(".").pop()}{" "}
                  </LabelValue>{" "}
                </Col>
              </Row>

              <Row justify="space-evenly">
                <Col span={6}>
                  <Label> {"Collection:"}</Label>
                </Col>
                <Col span={6}>
                  <LabelValue> {mintResult?.collection} </LabelValue>
                </Col>
                <Col span={6}>
                  <Label>{"Size:"}</Label>
                </Col>
                <Col span={6}>
                  <LabelValue>{`${getFilesize(
                    mintResult.fileSize
                  )}`}</LabelValue>
                </Col>
              </Row>
              <Row justify="space-evenly">
                <Col span={12}>
                  <Label> {"Label:"}</Label>
                  {mintResult?.fileName}
                </Col>
                {/* <Col span={6}>
                  <Label> {"Token ID:"}</Label>
                </Col>
                <Col span={6}> <LabelValue>{data?.auctionID}</LabelValue></Col> */}
                <Col span={6}>
                  <Label>{"Additions:"}</Label>
                </Col>
                <Col span={6}>
                  <LabelValue>
                    {mintResult.isUnlimited
                      ? `Unlimited`
                      : `1 of ${mintResult.series}`}
                  </LabelValue>
                </Col>
              </Row>
              {/* <Row justify="space-evenly">
                <Col span={24} >
                  <Label > {"Label:"}</Label> &nbsp; &nbsp;&nbsp;
                  {mintResult?.fileName}
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row align="middle" justify="center">
        <MintingButton
          // onClick={minting}
          onClick={mintNFT}
          type="primary"
          size="large"
          className="mintingNextButton"
          style={{ height: "60px" }}
        >
          <MintingText>Submit NFT</MintingText>
        </MintingButton>
      </Row>
    </Spin>
  );
};

export default ConfirmNFTDetails;
