/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Space, Dropdown, Spin } from "antd";
import {
  InstagramOutlined,
  ShareAltOutlined,
  SlackOutlined,
  SnippetsOutlined,
  TwitterOutlined
} from "@ant-design/icons";
import {
  TitleNFT,
  CardStep3,
  ParagraphElement,
  ViewNFT,
  ShareNFT,
  CardMeta,
  ShareMenu,
  ShareText,
  ViewOnFlowScan
} from "./Step3.styled";
import { MintContext } from "../../../context/mintContext";
import { listToMarketplace } from "../../../scripts/listToMarketplace";
import { useSelector } from "react-redux";
import { VideoSrc } from "../../../common/commonStyles";
import { PDFViewer } from "../../../components";
import { Paragraph } from "../uploadStep/Step1.styled";
import keys from "../../../config/keys";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { ToastText } from "../Minting.styled";
import { addMidToCollection } from "../../../scripts/addNewCollection";

const { Text } = Typography;

const SubmitStep = (props) => {
  const history = useHistory();
  const toastId = React.useRef(null);
  const { mintResult } = useContext(MintContext);
  const { auth } = useSelector((obj) => obj);
  const { mintedData, onListToMarketplace, listing } = props;

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (mintResult?.collection && mintResult?.collection !== "") {
      setIsDisabled(true);

      addMidToCollection(
        {
          mid: parseInt(mintedData?.events[0]?.data?.mid),
          feature: false,
          name: mintResult.collection.trim(),
          index: parseInt(
            auth?.collections.findIndex(
              (x) => x.collectionName.trim() === mintResult.collection.trim()
            )
          )
        },
        {
          onStart: () => {
            toastId.current = toast(
              <Space size="large">
                <Spin size="large" />
                <Typography.Text style={{ color: "#fff" }}>{`Adding ${
                  mintResult?.title
                } item in ${mintResult.collection.trim()} collection `}</Typography.Text>
              </Space>,
              {
                type: toast.TYPE.INFO,
                autoClose: false,
                closeButton: false, // Remove the closeButton
                theme: "colored",
                pauseOnFocusLoss: false,
                icon: false
              }
            );
          },
          onSuccess: () => {
            toast.update(toastId.current, {
              render: (
                <Typography.Text style={{ color: "#fff" }}>{`${
                  mintResult?.title
                } successfully added to ${mintResult.collection.trim()}.👌`}</Typography.Text>
              ),
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
              theme: "colored",
              pauseOnFocusLoss: false,
              icon: true
            });
          },
          onError: (err) => {
            toast.update(toastId.current, {
              render: (
                <Typography.Text
                  style={{ color: "#fff" }}
                >{`🤯 Error : ${err}`}</Typography.Text>
              ),
              type: toast.TYPE.ERROR,
              autoClose: 3000,
              theme: "colored",
              pauseOnFocusLoss: false,
              icon: true
            });
          },
          onComplete: () => {
            setIsDisabled(false);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const menu = (
    <ShareMenu>
      <ShareMenu.Item key="twitter">
        <Space>
          <TwitterOutlined />
          {`Twitter`}
        </Space>
      </ShareMenu.Item>
      <ShareMenu.Item key="discord">
        <Space>
          <SlackOutlined />
          {`Discord`}
        </Space>
      </ShareMenu.Item>
      <ShareMenu.Item key="instagram">
        <Space>
          <InstagramOutlined />
          {`Instagram`}
        </Space>
      </ShareMenu.Item>
    </ShareMenu>
  );

  return (
    <Spin spinning={isDisabled}>
      <Row>
        <Col span={24}>
          <TitleNFT>{" Your NFT has been created!"}</TitleNFT>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <CardStep3
            cover={
              mintResult.mintType === "Audio" ||
              mintResult.mintType === "Video" ? (
                <VideoSrc controls>
                  <source src={mintResult.file} type={mintResult.fileType} />
                </VideoSrc>
              ) : mintResult.fileType.startsWith("application/pdf") ? (
                <PDFViewer mintResult={mintResult} />
              ) : (
                <img
                  style={{ height: "90%", width: "100%", padding: "50px" }}
                  src={mintResult.file}
                  alt="mint-file"
                />
              )
            }
          >
            <CardMeta
              style={{ background: "#FFFF", borderRadius: "0px 0px 22px 22px" }}
              title={mintResult.title}
              description={mintResult.description}
            />
          </CardStep3>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <ParagraphElement>
                Your NFT has been successfully created on our marketplace.
              </ParagraphElement>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            {mintResult?.saleType === "sell" && !mintedData?.events[1].auctionID && (
              <Col xl={10} lg={10} md={10} sm={12} xs={24}>
                <ViewNFT
                  type="primary"
                  size="large"
                  block
                  onClick={() =>
                    mintResult.saleType === "sell"
                      ? onListToMarketplace(mintedData)
                      : null
                  }
                >
                  {`List to Marketplace`}
                </ViewNFT>
              </Col>
            )}
            <Col xl={10} lg={10} md={10} sm={12} xs={24}>
              <ShareNFT
                size="large"
                block
                onClick={() =>
                  mintedData
                    ? history.push(
                        `/app/art/${auth?.address}/${parseInt(listing)}`
                      )
                    : null
                }
              >
                {`View NFT`}
              </ShareNFT>
            </Col>
            {/* <Col xl={10} lg={10} md={10} sm={12} xs={24}>
              <ShareNFT
                size="large"
                block
                onClick={() => {
                  history.push("/art");
                }}
              >
                {`Share NFT`}
              </ShareNFT>
            </Col> */}
          </Row>
          <br />
          <Row>
            <Col>
              <Space
                size={"large"}
                style={{ display: "flex", alignItems: "baseline" }}
              >
                <Space>
                  {/* <Text type="danger">
                    <SnippetsOutlined />
                  </Text>

                  <ShareText>{"View on Flowscan"}</ShareText> */}

                  <ViewOnFlowScan
                    copyable={{
                      text: `${keys.flowScanLink}/${mintedData?.events[0].transactionId}`
                    }}
                  >
                    {"  View on flowscan"}
                  </ViewOnFlowScan>
                </Space>

                <Space>
                  <Dropdown overlay={menu} placement="bottom">
                    <Space>
                      <Text type="danger">
                        <ShareAltOutlined />
                      </Text>

                      <ShareText>{"Share"}</ShareText>
                    </Space>
                  </Dropdown>
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default SubmitStep;
