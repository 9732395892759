/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Row, Col, Form, Card, Avatar } from "antd";
import Image from "../../../components/Image";
import { SignupContext } from "../../../context/signupContext";
import {
  FrameWrapper,
  Paragraph,
  FormItemSelectable,
  ClickButton,
  RegisterText,
  Column,
} from "../CreateAccount.styled";
import Logo from "../../../images/worded_logo.svg";
import agent from "../../../images/agent.png";
import collector from "../../../images/collector.png";
import creator from "../../../images/creator.png";
import SelectRole from "./SelectRole";

const SelectRoleContainer = ({ onCreateAccount }) => {
  const { setSignupData } = useContext(SignupContext);
  const [role, setRole] = useState("");

  const onRoleClick = (value) => {
    setRole(value.userType);
    setSignupData((prev) => ({
      ...prev,
      isAgent: false,
      isCreator: false,
      isCollector: false,
      isAdmin: false,
      ...value,
    }));
  };

  return (
    <>
      <SelectRole onCreateAccount={onCreateAccount} />
    </>
  );
};

export default SelectRoleContainer;
