/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Row,
  Avatar,
  Col,
  Typography,
  Spin,
  Space,
  Dropdown,
  Menu
} from "antd";
import Card from "../../../components/Card";
import avatarImg from "../../../images/profile_placeholder.png";
import collectionImage from "../../../images/folder.png";
import { getMetadata } from "../../../scripts/getList";
import { AuthContext } from "../../../context/authContext";
import * as Routes from "../../../Routes";
import {
  CreationWrapper,
  TitleCollectionCount,
  TitleCollectionName
} from "./../dashboard.styled";
import Button from "../../../components/Button";
import {
  listToMarketplace,
  accept_default
} from "../../../scripts/listToMarketplace";
import * as config from "../../../config/keys";
import { useAuction } from "../../../context/auctions.context";
import { Collection } from "../../../components/collection";
import {
  ArrowRightOutlined,
  CheckOutlined,
  DollarOutlined,
  EllipsisOutlined,
  StarOutlined
} from "@ant-design/icons";
import * as actions from "../../../redux/actions";
import { MenuItem } from "../../../components/collection/styles";

const { Title } = Typography;

const DAAMDashboardContainer = () => {
  const { user } = useContext(AuthContext);
  const { auctions, getAuctions } = useAuction();
  const [data, setData] = useState(null);
  let { id } = useParams();
  const dispatch = useDispatch();
  const { auth, collections, userList } = useSelector((obj) => obj);

  const safeParse = (string) => {
    try {
      return JSON.parse(string);
    } catch (error) {
      return "invalid";
    }
  };

  useEffect(() => {
    if (user?.addr) {
      dispatch(actions.AdminActions.get_users_with_collection());
      dispatch(actions.AdminActions.getUsers());
      getAuctions();
    }
  }, [user?.addr]);

  // useEffect(() => {
  //   if (collections && collections.length > 0) {
  //     console.log(collections);
  //     console.log(
  //       collections?.reduce((n, { collections }) => n + collections.length, 0)
  //     );
  //   }
  // }, [collections]);

  // useEffect(() => {
  //   if (auctions?.length > 0) {
  //     console.log(auctions);
  //   }
  // }, [auctions]);

  const dashboardInfo = {
    numberOfCreationPieces: "8",
    numberOfSoldPieces: "3",
    numberOfCollections: "2"
  };

  const assetMenu = () => {
    return (
      <Menu>
        <MenuItem
          key="featured"
          style={{ font: "normal normal normal 12px Lato", color: "#2F3234" }}
        >
          <Space>
            <StarOutlined style={{ color: "red" }} />
            {`Featured`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="edit">
          <Space>
            <DollarOutlined />
            {`Sold`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="listed">
          <Space>
            <CheckOutlined />
            {`Listed`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="5">
          <Space>
            <ArrowRightOutlined />
            {`Transferred`}
          </Space>
        </MenuItem>
      </Menu>
    );
  };

  const ShowCreationPieces = () => {
    return (
      <Spin spinning={auctions === null}>
        <Title style={{ marginBottom: "0.5vh" }} level={2}>
          {auctions !== null && (
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <TitleCollectionName>
                Assets
                <TitleCollectionCount>{`(${
                  auctions?.length || 0
                })`}</TitleCollectionCount>
              </TitleCollectionName>
              <Dropdown
                placement="bottomRight"
                overlay={assetMenu}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  onClick={(e) => e.preventDefault()}
                  style={{
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #CDD0D3",
                    borderRadius: "5px",
                    fontSize: "30px"
                  }}
                  size="small"
                />
              </Dropdown>
            </Space>
          )}
        </Title>
        <Row>
          {auctions &&
            auctions
              .filter((x) => x.metaDataInfo !== null)
              .sort((a, b) => (Number(a.mid) < Number(b.mid) ? 1 : -1))
              // eslint-disable-next-line array-callback-return
              .map(({ auctionInfo, metaDataInfo }) => {
                const { creatorInfo, edition, thumbnail, mid } = metaDataInfo;
                const { auctionID = 0, buyNow = 0 } = auctionInfo;
                const filterAdd = userList?.users?.find((x) => {
                  return x.address === creatorInfo?.creator;
                });

                try {
                  return (
                    <Col
                      xl={4}
                      lg={6}
                      md={8}
                      sm={12}
                      xs={24}
                      key={Math.random()}
                    >
                      <Collection
                        data={{ auctionInfo, metaDataInfo }}
                        mid={mid}
                        // disable={item.counter > 1}
                        disable={false}
                        address={creatorInfo?.creator}
                        to={{
                          pathname: auth.address
                            ? `/app/art/${creatorInfo?.creator}/${
                                auctionID > 0 ? auctionID : mid
                              }`
                            : `/art/${creatorInfo?.creator}/${
                                auctionID > 0 ? auctionID : mid
                              }`,
                          state: true
                        }}
                        title={edition?.name}
                        price={parseFloat(buyNow).toFixed(2)}
                        flowPrice={(buyNow / 12).toFixed(2)}
                        image={thumbnail[Object.keys(thumbnail)[0]].file}
                        avatarImage={auth?.avtarImg}
                        authorLink={`/app/details/${auth?.address}`}
                        showFilters={true}
                      />
                    </Col>
                  );
                } catch (ex) {
                  console.log("ERROR");
                }
              })}
        </Row>
      </Spin>
    );
  };

  const ShowCollections = () => {
    return (
      <>
        <Space>
          <TitleCollectionName>Collections</TitleCollectionName>
          <TitleCollectionCount>{`(${
            collections?.reduce(
              (n, { collections }) => n + collections.length,
              0
            ) || 0
          })`}</TitleCollectionCount>
        </Space>
        <Row>
          {collections?.map((userItem) => {
            return userItem.collections
              .filter((x) => x.collectionName !== "")
              .map((item) => {
                const metaDataInfo = {
                  category: [{ name: "" }, { name: "" }],
                  creatorInfo: {
                    creator: auth?.addr,
                    agent: null,
                    firstSale: null,
                    status: true
                  },
                  description: item.description,
                  edition: { name: item.collectionName, number: 1, max: 1 },
                  thumbnail: {
                    jpg: item?.collectionImage
                      ? `${config.serverURL}/files/images/${item.collectionImage}`
                      : collectionImage
                  }
                };
                return (
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
                    <Collection
                      isCollection={true}
                      data={{metaDataInfo}}
                      disable={item.counter > 1}
                      to={Routes.COLLECTIONS}
                      title={item.collectionName}
                      price={"1"}
                      flowPrice={(parseInt(0) / 12).toFixed(2)}
                      image={
                        item?.collectionImage
                          ? `${config.serverURL}/files/images/${item.collectionImage}`
                          : collectionImage
                      }
                      avatarImage={userItem.avtarImg}
                      authorLink={`/app/details/${userItem?.address}`}
                      showFilters={true}
                    />
                  </Col>
                );
              });
          })}
        </Row>
      </>
    );
  };

  return (
    <CreationWrapper>
      {collections?.length > 0 && <ShowCollections />}
      {<ShowCreationPieces />}
    </CreationWrapper>
  );
};

export default DAAMDashboardContainer;
