import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// answer_agent_invite.cdc
// Answer the invitation to be an Agent.
// Answer the invitation to be a Minter. Typically only for Auctions & Marketplaces
import ${keys.daamImport} from ${keys.daam}

transaction(submit: Bool) {
    let signer: AuthAccount

    prepare(signer: AuthAccount) {
        self.signer = signer
    }

    execute {
        let agent  <- ${keys.daamImport}.answerAgentInvite(newAgent: self.signer, submit: submit)

        if agent != nil && submit {
            let old_admin <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.adminStoragePath)
            self.signer.save<@${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(<- agent!, to: ${keys.daamImport}.adminStoragePath)!
            let agentRef = self.signer.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath)!
            destroy old_admin

            let old_request <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.requestStoragePath)
            let requestGen <- agentRef.newRequestGenerator()!
            self.signer.save<@${keys.daamImport}.RequestGenerator>(<- requestGen, to: ${keys.daamImport}.requestStoragePath)!
            destroy old_request

            log("You are now a ${keys.daamImport}.Agent: ".concat(self.signer.address.toString()) )
            
            // Minter
            if ${keys.daamImport}.isMinter(self.signer.address) == false { // Received Minter Invitation
                let old_minter <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.minterStoragePath)
                let minter  <- ${keys.daamImport}.answerMinterInvite(newMinter: self.signer, submit: submit)
                self.signer.save<@${keys.daamImport}.Minter>(<- minter!, to: ${keys.daamImport}.minterStoragePath)
                log("You are now a ${keys.daamImport}.Minter: ".concat(self.signer.address.toString()) )
                destroy old_minter
            }
            
        } else {
            destroy agent
        }

        if !submit { log("Thank You for your consideration.") }
    }
}
`;

export const answer_agent_invite = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(true, types.Bool)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit),
    ],
    opts
  );
};
