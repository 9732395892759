/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Steps } from "antd";
import { toast } from "react-toastify";
import SignupContainer from "./signup";
import VerifyContainer from "./verify";
import PersonalContainer from "./personaldetails";
import SelectRoleContainer from "./selectrole";
import { loginActions } from "../../redux/actions";
import { SignupProvider, SignupContext } from "../../context/signupContext";
import * as Routes from "../../Routes";
import { decrypt } from "../../common/encryptdecrypt";
import { AuthContext } from "../../context/authContext";
import Verify from "./verify/Verify";

const CreateAccount = ({ filterParam }) => {
  const [current, setCurrent] = React.useState(0);
  const { user, auth } = useSelector((obj) => obj);
  const { signupData, setSignupData } = useContext(SignupContext);
  const { setUser } = useContext(AuthContext);
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");

  const descriptionRef = useRef();
  const titleRef = useRef();
  const history = useHistory();

  const next = (event) => {
    if (current === 1 && signupData?.query) {
      // setCurrent(current + 2);
      dispatch(
        loginActions.addNewUser(
          {
            ...signupData,
            userType: signupData.isAgent ? "agent" : "creator",
            collections: [],
            approved: false,
            invitation: "pending"
          },
          onSuccessLogin
        )
      );
      //setCurrent(current + 2);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const c = window.location.search;

    if (c) {
      const ctext = c.split("=")[1];
      const a = decrypt(ctext);
      if (a) {
        setSignupData((prev) => ({
          ...prev,
          email: a.email,
          isAgent: a.isAgent,
          isCreator: !a.isAgent,
          isCollector: false,
          isAdmin: false,
          userType: "creator",
          agentAddress: a.agentAddress,
          query: { ...a }
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (user?.data?.acknowledged && user?.data?.insertedId) {
      history.push(Routes.LOGIN);
    }
  }, [history, user]);

  const onSuccess = () => {
    next();
  };

  const createUserAccount = () => {
    dispatch(loginActions.sendVerificationMail({ ...signupData }, onSuccess));
  };

  const cancel = () => {
    history.push("/create");
  };

  useEffect(() => {
    if (auth?._id) {
      setUser(auth);
      history.push(Routes.DASHBOARD);
      // if (auth.isAgent) history.push(Routes.AGENT);
      // else if (auth.isCreator) history.push(Routes.CREATOR);
      // else if (auth.isCreator) history.push("/");
    }
  }, [auth]);

  const onSuccessLogin = () => {
    dispatch(
      loginActions.UserLogin(
        {
          email: signupData.email,
          password: signupData.password
        },
        onSuccessMail
      )
    );
  };
  const onSuccessMail = () => {
    dispatch(loginActions.welcomeMessage({ ...signupData }));
  };
  const validateOtp = (value) => {
    if (value.toString() === user?.otp.toString()) {
      dispatch(
        loginActions.addNewUser(
          {
            ...signupData,
            collections: [],
            approved: false,
            invitation: "pending"
          },
          onSuccessLogin
        )
      );
    } else {
      toast("Invalid Verification Code", {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: true
      });
    }
  };

  const steps = [
    {
      content: <SignupContainer createAccount={next} />
    },
    {
      content: <PersonalContainer onNextClick={next} />
    },
    {
      content: <SelectRoleContainer onCreateAccount={createUserAccount} />
    },
    {
      content: (
        <Verify
          onValidateOtp={validateOtp}
          onResendEmail={() =>
            dispatch(loginActions.sendVerificationMail(signupData, () => {}))
          }
        />
      )
    }
  ];

  return (
    <>
      <Steps current={current}></Steps>
      {steps[current]?.content}
    </>
  );
};

const CreateAccountContainer = () => {
  return (
    <SignupProvider>
      <CreateAccount />
    </SignupProvider>
  );
};

export default CreateAccountContainer;
