import React, { useContext } from "react";
import {
  BtnTitle,
  ButtonDiv,
  Content,
  EmptyDashboardBottom,
  EmptyDashboardContainer,
  EmptyDashboardTop,
  IconDiv,
  TitleOfEmptyDashboard,
} from "./style.styled";
import * as ROUTES from "../../../Routes";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const EmptyDashboard = (props) => {
  const history = useHistory();
  const {
    user,
    hasFUSDWallet,
    hasAuctionWallet,
    hasDaamAccount,
    isCreator,
    isAgent,
  } = useContext(AuthContext);

  const { auth } = useSelector((obj) => obj);

  const CheckUserAccountSetUp = () => {
    if (hasFUSDWallet && hasAuctionWallet && hasDaamAccount) {
      if ((isCreator || isAgent) && user?.approved) {
        if (Number(auth.hasFUSDWallet).toFixed(2) > 1) {
          history.push(ROUTES.MINTING);
        } else {
          toast("Please fund your wallet ", {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true,
          });
        }
      } else {
        toast("Wait for invititation", {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
          closeButton: false, // Remove the closeButton
          theme: "colored",
          icon: true,
        });
      }
    } else {
      toast("Please setup your account", {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: true,
      });
    }
  };
  return (
    <EmptyDashboardContainer>
      <EmptyDashboardTop
        onClick={() =>
          props.createNFT ? CheckUserAccountSetUp() : props.setVisible(true)
        }
      >
        <Content>
          <ButtonDiv>
            <BtnTitle>{props.buttonTitle}</BtnTitle>
          </ButtonDiv>
        </Content>
      </EmptyDashboardTop>
      <EmptyDashboardBottom>
        <IconDiv>{props.icons}</IconDiv>
        <TitleOfEmptyDashboard>{props.name}</TitleOfEmptyDashboard>
      </EmptyDashboardBottom>
    </EmptyDashboardContainer>
  );
};

export default EmptyDashboard;
