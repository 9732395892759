import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import axios, { setAuthorizationToken } from "../../../common/axios";
import { AUTH, USER_LOGIN, REDIRECT } from "../../constTypes/userTypes";
import * as config from "../../../config/keys";
import * as Routes from "../../../Routes";

export const SIGN_UP = gql`
  mutation (
    $name: String
    $username: String
    $email: String
    $password: String
    $isAgent: Boolean
    $isCreator: Boolean
    $isAdmin: Boolean
  ) {
    addUser(
      name: $name
      username: $username
      email: $email
      password: $password
      isAgent: $isAgent
      isCreator: $isCreator
      isAdmin: $isAdmin
    ) {
      id
      username
      name
      email
      password
      isAgent
      isCreator
      isAdmin
    }
  }
`;

export const Login = gql`
  query ($username: String, $password: String) {
    userLogin(username: $username, password: $password) {
      id
      name
      username
      email
      password
      isAgent
      isCreator
      isAdmin
    }
  }
`;

const _addNewUser = (data) => {
  return {
    type: USER_LOGIN,
    payload: { ...data }
  };
};

export const addNewUser = (data, onSuccess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/addnewuser`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          toast("Welcome To DAAM 🙏🏼", {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true
          });
          if (onSuccess) {
            onSuccess();
          }
          // dispatch(_addNewUser({...res.data, redirectToLogin: true}));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateUsersBlockchainAddress = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/updateUserById`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_auth(res.data.data));
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateUser = (data, onSuccess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/updateUserById`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_auth(res.data.data));
          toast("Successful...", {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true
          });
        }
        if (onSuccess) {
          onSuccess();
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateUserCollection = (data, onSuccess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/updateUserById`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          dispatch(_auth(res.data.data));
        }
        if (onSuccess) {
          onSuccess();
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const _auth = (data) => {
  return {
    type: AUTH,
    payload: { ...data, redirectToLogin: true }
  };
};

export const _rediect = (data) => {
  return {
    type: REDIRECT,
    payload: { ...data }
  };
};
export const UserLogin = (data, onSuccess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/login`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          sessionStorage.setItem("aceessToken", res.data.data.accessToken);
          // just to check validtoken
          // dispatch(verifytoken(sessionStorage.getItem("aceessToken")));
          // toast("Login Sucessful", {
          //   type: toast.TYPE.SUCCESS,
          //   autoClose: 2000,
          //   closeButton: false, // Remove the closeButton
          //   theme: "colored",
          //   icon: true,
          // });
          sessionStorage.setItem("ObjectId", res.data.data._id);
          setAuthorizationToken(res.data.data.accessToken);
          dispatch(_auth(res.data.data));
          if (onSuccess) {
            onSuccess();
          }
        } else {
          toast("Invalid Username or Password", {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true
          });
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const ForgotPassword = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/forgotpassword`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          toast("Password Reset Sucessful", {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true
          });
          setTimeout(() => {
            window.location.href = Routes.LOGIN;
          }, 1000);
          // dispatch(_rediect({...res.data,RedirectTo:"LOGIN"}))
        } else {
          toast("Something Wrong", {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: true
          });
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const FormFileUpload = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/upload`,
    data
  })
    .then((res) => {
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

//just to test
export const verifytoken = (data) => async (dispatch) => {
  await axios({
    method: "get",
    url: `${config.serverURL}/users/auth`
    // headers: {'token': data},
    // data,
  }).then((res) => console.log("res", res));
};

export const sendVerificationMail = (data, onSucess) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/sendverificationemail`,
    data
  })
    .then((res) => {
      try {
        if (res.data.success) {
          toast("Verification Code has been send to you mail id.", {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: false
          });
          dispatch(_addNewUser({ ...res.data, moveToNext: true }));
          onSucess();
        } else {
          dispatch(_addNewUser({ ...res.data, moveToNext: false }));

          toast(res.data.data.status, {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            closeButton: false, // Remove the closeButton
            theme: "colored",
            icon: false
          });
        }
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      toast(e.message, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: false
      });
      console.log("catch error", JSON.stringify(e));
    });
};

export const welcomeMessage = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${config.serverURL}/users/sendWelcomeEmail`,
    data
  })
    .then((res) => {
      console.log("res", res);
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
