import styled from "styled-components";
import {
  Checkbox,
  InputNumber,
  Collapse,
  Button as AntButton,
  Tabs,
  Typography,
  Tag
} from "antd";
import { FormCollapse } from "../../../components/Form";
import Button from "../../../components/Button";

const { Panel } = Collapse;

export const CheckboxMint = styled(Checkbox)`
  margin: "0.5em";
  font-size: "11px";
  float: "left";
  font: normal normal normal 16px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
`;

export const PanelWrapper = styled(Panel)`
  // text-align: left;
  width: 100%;
  & .ant-collapse-header {
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: #000000;
    ${(props) => props.hideArrow && `flex-direction: row; `}
  }
`;

export const Paragraph = styled(CheckboxMint)``;

export const DivElement = styled(CheckboxMint)``;

export const InputForNumber = styled(InputNumber)`
  ${(props) => props.w && `width:${props.w};`}
`;

export const FormCollapseNoHeader = styled(FormCollapse)`
  & .ant-collapse-item {
    & .ant-collapse-header {
      display: none;
    }
  }
`;

export const ButtonCancel = styled(Button)`
  border: 1px solid #cdd0d3;
  opacity: 1;
  heigth: 46px;
  width: 46px;
`;

export const CollectionButton = styled(AntButton)`
  width: 72px;
  height: 30px;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 15px;
`;

export const Tabpanel = styled(Tabs)`
  border: 1px solid #999b9e;
  border-radius: 5px;
  opacity: 1;
  & .ant-tabs-tabpane {
    padding: 0 15px 10px;
  }
  & .ant-tabs-tab {
    font: normal normal 500 20px Lato;
    letterspacing: 0px;
    color: #2f3234;
  }
  & .ant-tabs-tab-active {
    border-bottom-color: #fc4448;
    border-width: 2px;
  }
`;

export const TabLabel = styled(Typography.Text)`
  font: normal normal bold 14px Lato;
  color: #2f3234;
`;

export const SelectedCollectionTag = styled(Tag)`
  height: 30px;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
`;
