/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Row,
  Avatar,
  Col,
  Typography,
  Spin,
  Space,
  Dropdown,
  Menu
} from "antd";
import Card from "../../../components/Card";
import avatarImg from "../../../images/profile_placeholder.png";
import collectionImage from "../../../images/folder.png";
import {
  getMetadata,
  getMetaDataofAgentCreators
} from "../../../scripts/getList";
import { getSingleMetadata } from "../../../scripts/getSingleMetadata";
import { AuthContext } from "../../../context/authContext";
import * as Routes from "../../../Routes";
import {
  CreationWrapper,
  TitleCollectionCount,
  TitleCollectionName
} from "./../dashboard.styled";
import Button from "../../../components/Button";
import {
  listToMarketplace,
  accept_default
} from "../../../scripts/listToMarketplace";
import * as config from "../../../config/keys";
import { useAuction } from "../../../context/auctions.context";
import { Collection } from "../../../components/collection";
import {
  ArrowRightOutlined,
  CheckOutlined,
  DollarOutlined,
  EllipsisOutlined,
  FileOutlined,
  FolderOpenOutlined,
  StarOutlined
} from "@ant-design/icons";
import { MenuItem } from "../../../components/collection/styles";
import EmptyDashboard from "../emptyDashboard";
import { ModalCom } from "../../minting/uploadStep/Collection";
import { getAgentCreators } from "../../../scripts/getAgentsCreator";
import { AdminActions } from "../../../redux/actions";
// import { listAllMetadata } from "../../../scripts/listAllMetadata";

const { Title } = Typography;

const CreatorDashboardContainer = () => {
  const { user, listOwned, owned = [] } = useContext(AuthContext);
  const { auctions, getAuctions } = useAuction();
  const [data, setData] = useState(undefined);
  const [flowPrice, setFlowPrice] = useState(1);
  const [changeData, setChangeData] = useState(false);
  let { id } = useParams();
  const { auth, userList } = useSelector((obj) => obj);
  const [visible, setVisible] = useState(false);
  const [metaDatas, setMetaDatas] = useState([]);
  const [filterName, setfilterName] = useState("");
  // const safeParse = (string) => {
  //   try {
  //     return JSON.parse(string);
  //   } catch (error) {
  //     return "invalid";
  //   }
  // };

  const dashbordFilter = (menu) => {
    if (menu === "owned") {
      setfilterName("Owned");
      const data = [];
      owned.forEach((element) => {
        data.push({ auctionInfo: {}, metaDataInfo: element.metadata });
      });
      setMetaDatas(data);
    } else if (menu === "unfeatured") {
      setfilterName("Un-Featured");
      getMetadata(id || user.addr).then((res) => {
        const data = [];

        res.forEach((element) => {
          data.push({ auctionInfo: {}, metaDataInfo: element });
        });
      });
    } else if (menu === "listed") {
      setfilterName("Listed");

      var listedMetadatas = auctions?.filter(
        (x) =>
          x.auctionInfo?.creatorInfo?.creator === user.address &&
          x.metaDataInfo !== null
      );

      setMetaDatas(listedMetadatas);
    } else if (menu === "sold") {
      setfilterName("Sold");
      setMetaDatas([]);
      var SoldMetadatas = auctions?.filter(
        (x) =>
          x.auctionInfo?.creatorInfo?.creator === user.addr &&
          x.metaDataInfo === null
      );
      var solditems = [];
      SoldMetadatas.forEach((x) => {
        getSingleMetadata(user.addr, x.auctionInfo.mid).then((res) => {});
        // getMetadata(user.addr,x.auctionInfo.auctionID).then(res =>{console.log(res)})
      });
    }
  };

  useEffect(async () => {
    const fprice = await AdminActions.get_exchange_rate();
    setFlowPrice(fprice);
  }, []);
  const assetMenu = () => {
    return (
      <Menu>
        <MenuItem
          onClick={() => dashbordFilter("unfeatured")}
          key="featured"
          style={{ font: "normal normal normal 12px Lato", color: "#2F3234" }}
        >
          <Space>
            <StarOutlined style={{ color: "red" }} />
            {`Un-Featured`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem onClick={() => dashbordFilter("listed")} key="listed">
          <Space>
            <CheckOutlined />
            {`Listed`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem onClick={() => dashbordFilter("sold")} key="sold">
          <Space>
            <DollarOutlined />
            {`Sold`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem onClick={() => dashbordFilter("owned")} key="owned">
          <Space>
            <DollarOutlined />
            {`Owned`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="5" onClick={() => dashbordFilter("transferred")}>
          <Space>
            <ArrowRightOutlined />
            {`Transferred`}
          </Space>
        </MenuItem>
      </Menu>
    );
  };

  // useEffect(() => {
  //   if (auctions && auctions.length > 0) {
  //     var listedMetadatas = auctions?.filter(
  //       (x) =>
  //         x.auctionInfo?.creatorInfo?.creator === user.address &&
  //         x.metaDataInfo !== null
  //     );
  //     // .map((data) =>
  //     //   data ? { ...data.metaDataInfo, price: data.auctionInfo.buyNow } : data
  //     // );
  //     setMetaDatas(listedMetadatas);
  //   }
  // }, [auctions]);

  useEffect(() => {
    getAuctions();
    listOwned();
    if (id || user?.addr) {
      if (auth?.isAgent) {
        getAgentCreators(id || user.addr).then((re) => {
          getMetaDataofAgentCreators(re)
            .then((r) => {
              setData(r);
              const data = [];
              r.forEach((element) => {
                data.push({ auctionInfo: {}, metaDataInfo: element });
              });
              setMetaDatas(data);
            })
            .catch((e) => console.log("getMetadata1 error", e));
        });
      } else {
        getMetadata(id || user?.addr) //id || user.addr
          .then((res) => {
            setData(res);
            const data = [];
            res.forEach((element) => {
              data.push({ auctionInfo: {}, metaDataInfo: element });
            });
            setMetaDatas(data);
          })
          .catch((er) => {
            console.log("getMetadata");
          });
      }
    }
  }, [user?.addr]);

  // useEffect(() => {
  //   console.log(auctions, metaDatas);
  // }, [auctions, metaDatas]);

  const ShowCreationPieces = () => {
    return (
      <Spin spinning={data === null}>
        <Title style={{ marginBottom: "0.5vh" }} level={2}>
          {metaDatas === null && (
            <TitleCollectionName>Fetching Creations</TitleCollectionName>
          )}
          {metaDatas !== null && (
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <TitleCollectionName>
                Assets
                <TitleCollectionCount>{`(${
                  metaDatas?.length || 0
                })`}</TitleCollectionCount>
              </TitleCollectionName>
              <TitleCollectionName>
                {(metaDatas?.length || 0) > 0 ? filterName : ``}
              </TitleCollectionName>
              <Dropdown
                placement="bottomRight"
                overlay={assetMenu}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  onClick={(e) => e.preventDefault()}
                  style={{
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #CDD0D3",
                    borderRadius: "5px",
                    fontSize: "30px"
                  }}
                  size="small"
                />
              </Dropdown>
            </Space>
          )}
        </Title>
        <Row>
          {metaDatas && metaDatas?.length >= 1 ? (
            metaDatas
              // ?.sort((a, b) => (Number(a.mid) <Row Number(b.mid) ? 1 : -1))
              // eslint-disable-next-line array-callback-return
              .map(({ auctionInfo, metaDataInfo }) => {
                try {
                  const { creatorInfo, edition, thumbnail, mid } = metaDataInfo;
                  const { auctionID = 0, buyNow = 0 } = auctionInfo;
                  const filterAdd = userList?.users?.find((x) => {
                    return x.address === creatorInfo?.creator;
                  });
                  // console.log(item, JSON.parse(item.data));
                  return (
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      sm={8}
                      xs={24}
                      key={Math.random()}
                    >
                      <Collection
                        setChangeData={setChangeData}
                        data={{ auctionInfo, metaDataInfo }}
                        mid={mid}
                        // disable={item.counter > 1}
                        disable={false}
                        address={creatorInfo?.creator}
                        to={{
                          pathname: auth.address
                            ? `/app/art/${creatorInfo?.creator}/${
                                auctionID > 0 ? auctionID : mid
                              }`
                            : `/art/${creatorInfo?.creator}/${
                                auctionID > 0 ? auctionID : mid
                              }`,
                          state: true
                        }}
                        // to={`${Routes.NFT_DETAILS_PAGE}/${creatorInfo?.creator}/${mid}`}
                        // to={
                        //   item.counter > 1
                        //     ? `${Routes.DASHBOARD}` //"/creator-dashboard"
                        //     : `${Routes.NFT_DETAILS_PAGE}/${item?.creator}/${item?.mid}`
                        // }
                        title={edition?.name}
                        price={parseFloat(buyNow).toFixed(2)}
                        flowPrice={(parseFloat(buyNow)*flowPrice).toFixed(2)}
                        image={thumbnail[Object.keys(thumbnail)[0]].file}
                        avatarImage={auth?.avtarImg}
                        authorLink={`/app/details/${auth?.address}`}
                        showFilters={true}
                      />
                    </Col>
                  );
                } catch (ex) {
                  console.log("ERROR", ex);
                }
              })
          ) : (
            <Col xl={4} lg={4} md={6} sm={8} xs={24}>
              <EmptyDashboard
                name="NFT"
                buttonTitle="New"
                icons={<FileOutlined style={{ fontSize: "36px" }} />}
                createNFT
              />
            </Col>
          )}
        </Row>
      </Spin>
    );
  };

  const ShowCollections = () => {
    return (
      <>
        <Space>
          <TitleCollectionName>Collections</TitleCollectionName>
          <TitleCollectionCount>{`(${auth?.collections?.length})`}</TitleCollectionCount>
        </Space>
        <Row>
          <Col xl={4} lg={4} md={6} sm={8} xs={24}>
            <EmptyDashboard
              name="Collection"
              buttonTitle="New"
              icons={<FolderOpenOutlined style={{ fontSize: "36px" }} />}
              setVisible={setVisible}
            />
          </Col>
          {auth?.collections?.map((item) => {
            const metaDataInfo = {
              category: [{ name: "" }, { name: "" }],
              creatorInfo: {
                creator: auth?.addr,
                agent: null,
                firstSale: null,
                status: true
              },
              description: item.description,
              edition: { name: item.collectionName, number: 1, max: 1 },
              thumbnail: {
                jpg: item?.collectionImage
                  ? `${config.serverURL}/files/images/${item.collectionImage}`
                  : collectionImage
              }
            };
            return (
              <Col xl={4} lg={4} md={6} sm={8} xs={24} key={Math.random()}>
                <Collection
                  isCollection={true}
                  data={{ metaDataInfo }}
                  disable={item.counter > 1}
                  to={Routes.COLLECTIONS}
                  title={item.collectionName}
                  price={0}
                  flowPrice={(parseInt(0) / 12).toFixed(2)}
                  image={
                    item?.collectionImage
                      ? `${config.serverURL}/files/images/${item.collectionImage}`
                      : collectionImage
                  }
                  avatarImage={auth?.avtarImg}
                  authorLink={`/app/details/${auth?.address}`}
                  showFilters={false}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  const ShowOwned = () => {
    return (
      <>
        <Space>
          <TitleCollectionName>Owned</TitleCollectionName>
          <TitleCollectionCount>{`(${
            metaDatas?.length || 0
          })`}</TitleCollectionCount>
        </Space>
        <Row>
          {metaDatas.length > 0 &&
            metaDatas?.map((item) => {
              const { creatorInfo, edition, thumbnail } = item?.metadata;
              const filterAdd = userList?.users?.find((x) => {
                return x.address === creatorInfo?.creator;
              });

              return (
                <Col xl={4} lg={4} md={6} sm={8} xs={24} key={Math.random()}>
                  <Collection
                    title={edition?.name}
                    price={parseInt(0)}
                    image={thumbnail[Object.keys(thumbnail)[0]].file}
                    avatarImage={filterAdd?.avtarImg}
                    authorLink={`/app/details/${creatorInfo?.creator}`}
                    data={item?.metadata}
                    // author={creatorInfo?.creator}
                    disable={true}
                  />
                </Col>
              );
            })}
        </Row>
      </>
    );
  };

  const onStartCollection = () => {};
  const onCollectionComplete = () => {};
  return (
    <CreationWrapper>
      {
        <ModalCom
          visible={visible}
          setVisible={setVisible}
          // onCollectionCreated={onCollectionCreated}
          onStartCollection={onStartCollection}
          onCollectionComplete={onCollectionComplete}
        />
      }
      {<ShowCollections />}
      {data !== null && <ShowCreationPieces />}
      {/* <br />
      {showSoldPieces(soldPieces)}
      <br />*/}
      {/* {owned.length > 0 && <ShowOwned />} */}
    </CreationWrapper>
  );
};

export default CreatorDashboardContainer;
