import styled from "styled-components";

export const ProfileImageStyles = styled.img`
    display:flex;
    width:48px;
    height:48px;
    border-radius:100%;
    ${props => props.ml && `margin-left:${props.ml};`}
    &:hover {
        cursor:pointer;
    }
`