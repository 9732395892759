// import * as fcl from '@onflow/fcl';
import { send, decode, script, args, arg, cdc } from "@onflow/fcl";
import { Address } from "@onflow/types";
import keys from "../config/keys";

const CODE = cdc`
// get_NFTs_metadata.cdc

import NonFungibleToken from ${keys.nonFungibleToken}
import ${keys.daamImport} from ${keys.daam}

pub fun main(account: Address): [&${keys.daamImport}.NFT] {
  let collectionRef = getAccount(account).getCapability<&{NonFungibleToken.CollectionPublic}>(${keys.daamImport}.collectionPublicPath).borrow()
  ?? panic("Could not borrow capability from public collection")
  
  let daamRef = getAccount(account).getCapability<&{${keys.daamImport}.CollectionPublic}>(${keys.daamImport}.collectionPublicPath).borrow()
  ?? panic("Could not borrow capability from public collection")

  let ids = collectionRef.getIDs()
  var nfts: [&${keys.daamImport}.NFT] = []

  for id in ids { nfts.append(daamRef.borrow${keys.daamImport}(id: id)) }
  return nfts
}
`;
// eslint-disable-next-line no-unused-vars
const VIEW_METADATAS = cdc`
// view_metadatas.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(creator: Address): [${keys.daamImport}.MetadataHolder] {
    let metadataRef = getAccount(creator)
        .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath)
        .borrow()!
        
    return metadataRef.viewMetadatas()
}
`;

export async function getOwned(address) {
  if (address === null) return Promise.resolve(false);

  return send([script(CODE), args([arg(address, Address)])]).then(
    decode
  );
}
