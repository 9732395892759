import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// invite_creator.cdc
// Used for Admin / Agent to invite a Creator.
// The invitee Must have a DAAM_Profile before receiving or accepting this Invitation

import ${keys.daamImport} from ${keys.daam}

transaction(creator: Address, agentCut: UFix64)
{
    let admin   : &${keys.daamImport}.Admin{${keys.daamImport}.Agent}
    let creator : Address
    let agentCut: UFix64

    prepare(agent: AuthAccount) {
        self.admin    = agent.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath)!
        self.creator  = creator
        self.agentCut = agentCut
    }

    pre {
        ${keys.daamImport}.isAdmin(creator)   == nil : creator.toString().concat(" is already an Admin.")
        ${keys.daamImport}.isAgent(creator)   == nil : creator.toString().concat(" is already an Agent.")
        ${keys.daamImport}.isCreator(creator) == nil : creator.toString().concat(" is already an Creator.")
    }
    
    execute {
        self.admin.inviteCreator(self.creator, agentCut: self.agentCut)
        log("Creator Invited")
    }

    post { ${keys.daamImport}.isCreator(self.creator) != nil : self.creator.toString().concat(" invitation has not been sent.") }
}
`;

export const inviteCreatorByAgent = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(address, types.Address), fcl.arg("0.01", types.UFix64)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};

const CODE_INVITE_CREATOR_BY_ADMIN = fcl.cdc`
// invite_creator.cdc
// Used for Admin / Agent to invite a Creator.
// The invitee Must have a DAAM_Profile before receiving or accepting this Invitation

import ${keys.daamImport} from ${keys.daam}

transaction(creator: Address, agentCut: UFix64)
{
    let admin   : &${keys.daamImport}.Admin{${keys.daamImport}.Agent}
    let creator : Address
    let agentCut: UFix64

    prepare(agent: AuthAccount) {
        self.admin    = agent.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath)!
        self.creator  = creator
        self.agentCut = agentCut
    }

    pre {
        ${keys.daamImport}.isAdmin(creator)   == nil : creator.toString().concat(" is already an Admin.")
        ${keys.daamImport}.isAgent(creator)   == nil : creator.toString().concat(" is already an Agent.")
        ${keys.daamImport}.isCreator(creator) == nil : creator.toString().concat(" is already an Creator.")
    }
    
    execute {
        self.admin.inviteCreator(self.creator, agentCut: self.agentCut)
        log("Creator Invited")
    }

    post { ${keys.daamImport}.isCreator(self.creator) != nil : self.creator.toString().concat(" invitation has not been sent.") }
}
`;

export const inviteCreatorByAdmin = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE_INVITE_CREATOR_BY_ADMIN),
      fcl.args([fcl.arg(address, types.Address)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
