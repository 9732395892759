/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Space, Tooltip, Typography } from "antd";
import { useAuction } from "../../context/auctions.context";
import {
  CreationWrapper,
  TitleCollectionCount,
  TitleCollectionName
} from "../dashboard/dashboard.styled";
import { Card } from "./collection.styled";
import * as config from "../../config/keys";
import {
  getCollection,
  getCollection_new
  //  getMetadataByMID
} from "../../scripts/getCollections";
import { listAllMetadata } from "../../scripts/listAllMetadata";

const Collections = (props) => {
  const { auth } = useSelector((obj) => obj);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [collections, setCollections] = useState([]);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   if (!auctions || auctions === null || auctions.length <= 0) {
  //     getAuctions();
  //   }
  // }, [auth?.addr]);

  useEffect(async () => {
    getCollection(auth?.address).then((res) => setCollections(res));
    //    getMetadataByMID(148).then((res) => console.log("res", res));
    await listAllMetadata().then((res) => {
      let result = [];

      result = Object.values(res).filter((item) => item.length > 0);
      // .map((item) => item);

      setData([].concat.apply([], result));
    });
  }, []);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
  const [ellipsis, setEllipsis] = useState(true);

  return (
    <CreationWrapper>
      <Space>
        <TitleCollectionName>Collections</TitleCollectionName>
        <TitleCollectionCount>{`(${collections?.length})`}</TitleCollectionCount>
      </Space>
      <Row>
        {data?.length > 0 &&
          collections &&
          collections?.map((item) => {
            const { display } = item;
            const mids = Object.keys(item.mid);
            const meta = data?.filter((x) => mids.includes(x.mid));
            let count = 0;
            let shouldSkip = false;
            return (
              <Col
                xl={3}
                lg={4}
                md={6}
                sm={6}
                xs={8}
                xxl={2}
                key={Math.random()}
              >
                <Card
                  hoverable
                  style={{ width: 150 }}
                  cover={
                    mids?.length === 0 ? (
                      <img
                        alt="example"
                        // src={`${config.serverURL}/files/images/${item.collectionImage}`}
                        src={`${display?.externalURL?.url}`}
                        style={{ height: 150, width: 150, padding: 5 }}
                      />
                    ) : (
                      <div>
                        <Row>
                          {meta
                            ?.filter((x, index) => index < 4)
                            .map((x) => {
                              const k = Object.keys(x.thumbnail);
                              count++;
                              return (
                                <Col span={12}>
                                  <img
                                    alt="example"
                                    // src={`${config.serverURL}/files/images/${item.collectionImage}`}
                                    src={x.thumbnail[k].file}
                                    style={{
                                      height: 75,
                                      width: 75,
                                      padding: 1
                                    }}
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    )
                  }
                  onClick={() => setSelectedCollection(display.name)}
                >
                  <Card.Meta
                    title={display.name}
                    description={
                      <Tooltip title={display.description} color={"orange"}>
                        <Typography.Paragraph
                          ellipsis={{
                            rows: 1,
                            expandable: false,
                            symbol: "more...",
                            // suffix: (
                            //   <Button
                            //     type="link"
                            //     onClick={() => setEllipsis(!ellipsis)}
                            //   >{`less...`}</Button>
                            // ),
                            onEllipsis: (ellipsis) => {
                              return setEllipsis(!ellipsis);
                            }
                            // prefix: (
                            //   <Button
                            //     type="link"
                            //     onClick={() => setEllipsis(!ellipsis)}
                            //   >{`less...`}</Button>
                            // )
                          }}
                        >
                          {display.description}
                        </Typography.Paragraph>
                      </Tooltip>
                    }
                  />
                </Card>
              </Col>
            );
          })}
      </Row>
      <Row>
        {/* <Card
                author={item.collectionName}
                status={false}
                enableList={true}
                image={`${config.serverURL}/files/images/${item.collectionImage}`}
                title={item.description}
              /> */}
      </Row>
    </CreationWrapper>
  );
};

export default Collections;
