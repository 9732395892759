import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// invite_admin_minter.cdc
// Used for Admin to invite another Admin.
// Used for Admin to give Minter access.
// The invitee Must have a DAAM_Profile before receiving or accepting this Invitation

import ${keys.daamImport} from ${keys.daam}

transaction(newAgent: Address, minterAccess: Bool)
{
    let admin    : &${keys.daamImport}.Admin
    let newAgent : Address 

    prepare(admin: AuthAccount) {
        self.admin    = admin.borrow<&${keys.daamImport}.Admin>(from: ${keys.daamImport}.adminStoragePath)!
        self.newAgent = newAgent
    }

    pre {
        ${keys.daamImport}.isAdmin(newAgent)   == nil : newAgent.toString().concat(" is already an Admin.")
        ${keys.daamImport}.isAgent(newAgent)   == nil : newAgent.toString().concat(" is already an Agent.")
        ${keys.daamImport}.isCreator(newAgent) == nil : newAgent.toString().concat(" is already a Creator.")
        ${keys.daamImport}.isMinter(newAgent)  == nil : newAgent.toString().concat(" is already a Minter.")
    }
    
    execute {
        self.admin.inviteAgent(self.newAgent)
        log("Agent Invited")

        if(minterAccess) {
            self.admin.inviteMinter(self.newAgent)
            log("Minter Invited")
        }
    }
}
 `;

export const inviteAgentAsMinter = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(address, types.Address), fcl.arg(true, types.Bool)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
