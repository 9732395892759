import { combineReducers } from "redux";
import * as userReducer from "./userReducer";
import * as filterReducer from "./filtersReducer";
import * as flowReducer from "./flowReducers";

const rootReducer = combineReducers({
  ...userReducer,
  ...filterReducer,
  ...flowReducer
});

export default rootReducer;
