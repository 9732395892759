import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// answer_creator_invite.cdc
// Answer the invitation to be a Creator.

import ${keys.daamImport} from ${keys.daam}

transaction(submit: Bool) {
  let signer: AuthAccount
  let submit: Bool

  prepare(signer: AuthAccount) {
      self.signer = signer
      self.submit = submit     
  }

  execute {
      let creator <- ${keys.daamImport}.answerCreatorInvite(newCreator: self.signer, submit: self.submit)
      if creator != nil {
          let old_creator <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.creatorStoragePath)
          self.signer.save<@${keys.daamImport}.Creator>(<- creator!, to: ${keys.daamImport}.creatorStoragePath)
          let creatorRef = self.signer.borrow<&${keys.daamImport}.Creator>(from: ${keys.daamImport}.creatorStoragePath)!
          destroy old_creator

          let old_mg <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.metadataStoragePath)
          let metadataGen <- creatorRef.newMetadataGenerator()
          self.signer.link<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint, ${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath, target: ${keys.daamImport}.metadataStoragePath)
          self.signer.save<@${keys.daamImport}.MetadataGenerator>(<- metadataGen, to: ${keys.daamImport}.metadataStoragePath)
          destroy old_mg

          let old_request <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.requestStoragePath)
          let requestGen  <- creatorRef.newRequestGenerator()
          self.signer.save<@${keys.daamImport}.RequestGenerator>(<- requestGen, to: ${keys.daamImport}.requestStoragePath)
          destroy old_request

          log("You are now a ${keys.daamImport}.Creator." )        
      } else {
          destroy creator
          log("Thank You for your Consoderation.")
      }
  }
}
`;

export const answerCreatorInvite = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(true, types.Bool)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
