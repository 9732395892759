import styled from "styled-components";
import { MoreOutlined } from "@ant-design/icons";
import { Popover, Divider } from "antd";

export const AgentRequestsInfoSelectWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

export const CustomizedPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  background: none;
`;

export const CircledMoreOutlined = styled(MoreOutlined)`
  color: red;
`;

export const CustomizedDivider = styled(Divider)`
  padding: 0;
  margin: 0.2em;
`;
