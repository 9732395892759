import React from 'react';
import AgencyLayout from '../components/Layout/AgencyLayout';
import AgencyManagementHeader from '../components/AgencyManagementHeader';
import AgencyManagementLists from '../components/AgencyManagementLists';
const Manage = () => {
  return (
    <AgencyLayout>
      <AgencyManagementHeader />
      <AgencyManagementLists />
    </AgencyLayout>
  );
};

export default Manage;
