/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../config";
import * as fcl from "@onflow/fcl";
import { answerCreatorInvite } from "../scripts/answerInvite";
import { answerAdminInvite } from "../scripts/answerAdminInvite";
import { checkAuctionWallet } from "../scripts/checkAuctionWallet";
import { checkProfile } from "../scripts/checkProfile";
import { checkFUSDWallet } from "../scripts/checkFUSDWallet";
import { checkDaamAcccount } from "../scripts/checkDammAccount";
import { createProfile } from "../scripts/createProfile";
import { createAuctionWallet } from "../scripts/createAuctionWallet";
import { createFUSDWallet } from "../scripts/createFUSDWallet";
import { getOwned } from "../scripts/getOwned";
import { createDaamAccount } from "../scripts/createDaamAccount";
import * as Routes from "../Routes";
import * as actions from "../redux/actions/Login";
import { useSelector } from "react-redux";
import { answer_agent_invite } from "../scripts/answer_agent_invite";
import socket from "../common/socketClient";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((obj) => obj);
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState(auth);
  const [isCreator, setIsCreator] = useState(auth.isCreator);
  const [isCollector, setIsCollector] = useState(auth.isCollector);
  const [isAdmin, setIsAdmin] = useState(auth.isAdmin);
  const [isAgent, setIsUserAgent] = useState(auth.isAgent);
  const [hasAuctionWallet, setHasAuctionWallet] = useState(
    auth.hasAuctionWallet
  );
  const [hasFUSDWallet, setHasFUSDWallet] = useState(auth.hasFUSDWallet);
  const [hasDaamAccount, setHasDaamAccount] = useState(auth.hasDaamAccount);
  const [hasProfile, setHasProfile] = useState(auth.hasProfile);

  const [owned, setOwned] = useState([]);
  const [address, setAddress] = useState("");
  const [mintState, setMintState] = useState(null);

  const listOwned = async () => {
    if (address) {
      getOwned(address)
        .then((res) => {
          setOwned(res || []);
        })
        .catch((er) => {
           console.log("getowned error");
        });
    }
  };

  // verify profile setup
  const verifyProfile = async () => {
    if (address) {
      await checkProfile(address)
        .then((res) => {
          dispatch(actions._auth({ ...auth, hasProfile: true }));
          setHasProfile(true);
        })
        .catch((ex) => console.log("check profile error"));
    }
  };
  const setupProfile = async (opts = {}) => {
    await createProfile(auth, {
      ...opts,
      onSuccess: () => {
        verifyProfile(address);
        if (opts?.onSuccess) {
          opts?.onSuccess();
        }
      }
    });
  };

  // verify daam account
  const verifyDaamAccount = () => {
    if (address) {
      checkDaamAcccount(address)
        .then((res) => {
          dispatch(actions._auth({ ...auth, hasDaamAccount: res }));
          setHasDaamAccount(true);
        })
        .catch((ex) => console.log("checkDaamAcccount Error"));
    }
  };
  const setupDaamAccount = async (opts = {}) => {
    await createDaamAccount({
      ...opts,
      onSuccess: () => {
        verifyDaamAccount(address);
        if (opts?.onSuccess) {
          opts?.onSuccess();
        }
      }
    });
  };

  // verify auction wallet
  const verifyAuctionWallet = async () => {
    if (address)
      await checkAuctionWallet(address)
        .then((res) => {
          dispatch(actions._auth({ ...auth, hasAuctionWallet: res }));
          setHasAuctionWallet(true);
        })
        .catch((er) => {
          dispatch(actions._auth({ ...auth, hasAuctionWallet: true }));
          setHasAuctionWallet(true);
          console.log("checkDaamAcccount Error");
        });
  };
  const setupAuctionWallet = async (opts = {}) => {
    await createAuctionWallet({
      ...opts,
      onSuccess: () => {
        verifyAuctionWallet(address);
        if (opts?.onSuccess) {
          opts?.onSuccess();
        }
      }
    });
  };

  // verify fusd wallet
  const verifyFUSDWallet = () => {
    if (address) {
      checkFUSDWallet(address)
        .then((res) => {
          dispatch(actions._auth({ ...auth, hasFUSDWallet: res }));

          setHasFUSDWallet(true);
        })
        .catch((e) => console.log("verifyFUSDWallet Error"));
    }
  };
  const setupFUSDWallet = async (opts = {}) => {
    await createFUSDWallet({
      ...opts,
      onSuccess: () => {
        verifyFUSDWallet(address);
        if (opts?.onSuccess) {
          opts?.onSuccess();
        }
      }
    });
    //verifyFUSDWallet(address);
  };

  const verifyAll = async () => {
    await verifyProfile(address);
    await verifyDaamAccount(address);
    await verifyFUSDWallet(address);
    await verifyAuctionWallet(address);
  };
  // verify steps
  useEffect(() => {
    setIsAdmin(user?.userType === "admin" ? user?.isAdmin : null);
    setIsCreator(user?.userType === "creator" ? user?.isCreator : null);
    setIsCollector(user?.userType === "collector" ? user?.isCollector : null);
    setIsUserAgent(user?.userType === "agent" ? user?.isAgent : null);

    if (user?.blockChainAddress?.length > 0) {
      setAddress(user?.blockChainAddress[0].addr);
      setRefresh(!refresh);
      const s = user?.blockChainAddress[0].services.filter(
        (x) => x.type === "authn"
      );

      if (s.length > 0) {
        fcl.authenticate().then((res) => {
          verifyAll(user?.blockChainAddress[0].addr);
        });
      } else {
        if (user?.addr) {
          verifyAll(user?.addr);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const acceptCreatorInvite = async (opts) => {
    await answerCreatorInvite({ ...opts });
    verifyAll();
  };

  const acceptAdminInvite = async (opts) => {
    await answerAdminInvite({ ...opts });
    verifyAll();
  };

  const acceptAgentInvite = async (callbacks) => {
    await answer_agent_invite({ ...callbacks });
    verifyAll();
  };

  const signIn = (onSuccess, onError) => {
    fcl
      .authenticate()
      .then((res) => {
        if (res?.addr !== null) {
          setAddress(res?.addr);
          if (
            user?.blockChainAddress === undefined ||
            user?.blockChainAddress?.length <= 0
          ) {
            const update = {
              where: { _id: user._id },
              update: {
                blockChainAddress: [{ ...res }],
                address: res?.addr
              }
            };
            dispatch(actions.updateUsersBlockchainAddress(update));
            socket.emit("clientInfo", {
              ...auth,
              address: res?.addr,
              socketId: socket.id
            });
          }
          setUser((prev) => ({
            ...prev,
            blockChainAddress: [{ ...res }],
            ...res
          }));
          if (onSuccess) onSuccess();
        }
      })
      .catch((ex) => {
        if (onError) onError();
        console.log("Authentication Failed", ex);
      });
  };

  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    fcl.unauthenticate();
    setIsCreator(null);
    setIsCollector(null);
    setUser(null);
    window.location = Routes.LOGIN;
  };

  useEffect(() => {
    fcl.currentUser().subscribe((res) => {
      setUser((prev) => ({
        ...prev,
        ...res
      }));
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        signIn,
        signOut,
        isCreator,
        isCollector,
        isAdmin,
        isAgent,
        setupAuctionWallet,
        setupFUSDWallet,
        setupProfile,
        setupDaamAccount,
        hasAuctionWallet,
        hasDaamAccount,
        hasFUSDWallet,
        hasProfile,
        acceptCreatorInvite,
        acceptAdminInvite,
        acceptAgentInvite,
        listOwned,
        owned,
        mintState,
        setMintState,
        verifyAll
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
