import styled from 'styled-components';
import { Input, Typography, Card, Col } from 'antd';

const { Text, Title, Paragraph } = Typography;

export const PriceBox = styled.div`
  border-radius: 10px;
  position: relative;
  width: 50%;
  height: 50px;
  padding: 5px;
  padding-left: 30%;
  background: black;
`;
export const PriceNestedBox = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 5px;
  left: 5px;
  background: white;
  width: 50%;
  height: 40px;
`;

export const InputElement = styled(Input)`
  height: 40px;
  color: #e74c3c;
  font-size: 30px;
  font-weight: bold;
`;

export const TextPrice = styled(Text)`
  font-size: 30px;
  font-weight: bold;
`;

export const TextCurrency = styled(Text)`
  font-size: 1.5em;
  color: white;
`;

export const TextColor = styled(Text)`
  color: white;
`;
export const TitleSubmitNFT = styled(Title)`
  text-align: left;
  font-size: 1.5em;
`;

export const TextLeft = styled(Paragraph)`
  text-align: left;
`;

export const CardSubStep = styled(Card)`
  width: 240;
  margin: 2em 0 0 auto;
`;

export const ColSubStep = styled(Col)`
  text-align: left;
  padding: 0 3em 3em 3em;
`;
