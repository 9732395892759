/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mint } from "../scripts/mint";
import { mintAsAgent } from "../scripts/mintAsAgent";
import { mint_and_list_to_marketplace } from "../scripts/creator/submit_and_auction";
import { agent_mint_and_list_to_marketplace } from "../scripts/submitAuctionAsAgent";

import { useAuction } from "./auctions.context";
import { authenticate } from "@onflow/fcl";
import { useSelector } from "react-redux";

export const MintContext = createContext();

export const MintProvider = ({ children }) => {
  const { auth } = useSelector((obj) => obj);

  const [loading, setLoading] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0); // 0 = CREATENFT, OTHER = STEPS
  const history = useHistory();
  const { getAuctions } = useAuction();
  const [mintResult, setMintResult] = useState({
    series: 1,
    title: "",
    agency: "",
    description: "",
    isPhysical: true,
    price: null,
    // thumbnail
    thumbnail: {
      jpg: "(jpeg data)",
      raw: "(raw data)"
    },
    // file
    file: null
  });

  const minting = async (res) => {
    // const { file, thumbnail, series,  ...others } = mintResult;
    const {
      file,
      thumbnail,
      series,
      allowedExtensions,
      auctionExpireDatetime,
      auctionExpireDatetimeString,
      auctionStartDatetime,
      auctionStartDatetimeString,
      creationDate,
      creationDateString,
      fileLastModified,
      mintedDate,
      ...others
    } = mintResult;

    const s = {
      series: mintResult.isUnlimited ? 0 : mintResult.series,
      categories: [mintResult.category, mintResult.mintType],
      data: JSON.stringify({
        title: mintResult.title,
        description: mintResult.description,
        ...others
      }),
      thumbnail: mintResult.file,
      file: mintResult.file,
      percentage: mintResult.royaltyPer / 100
    };

    const onSuccess = (val) => {
      res(val);
    };
    const onError = (val) => {
      res(undefined);
    };

    const onComplete = (val) => {};
    if (auth.isAgent) {
      await mintAsAgent(
        {
          ...mintResult,
          mintForCreator: mintResult.mintForCreator,
          series: mintResult.isUnlimited ? 0 : mintResult.series,
          categories: [mintResult.category, mintResult.mintType],
          data: JSON.stringify({
            title: mintResult.title,
            description: mintResult.description,
            ...others
          }),
          thumbnail: mintResult.file,
          file: mintResult.file,
          percentage: mintResult.royaltyPer / 100
        },
        { onSuccess, onError, onComplete }
      );
    } else {
      await mint(
        {
          ...mintResult,
          series: mintResult.isUnlimited ? 0 : mintResult.series,
          categories: [mintResult.category, mintResult.mintType],
          data: JSON.stringify({
            title: mintResult.title,
            description: mintResult.description,
            ...others
          }),
          thumbnail: mintResult.file,
          file: mintResult.file,
          percentage: mintResult.royaltyPer / 100,
          auth
        },
        { onSuccess, onError, onComplete }
      );
    }
  };

  const mint_and_list = async (res) => {
    // const { file, thumbnail, series,  ...others } = mintResult;
    const {
      file,
      thumbnail,
      series,
      allowedExtensions,
      auctionExpireDatetime,
      auctionExpireDatetimeString,
      auctionStartDatetime,
      auctionStartDatetimeString,
      creationDate,
      creationDateString,
      fileLastModified,
      mintedDate,
      ...others
    } = mintResult;

    const s = {
      series: mintResult.isUnlimited ? 0 : mintResult.series,
      categories: [mintResult.category, mintResult.mintType],
      data: JSON.stringify({
        ...others
      }),
      thumbnail: mintResult.file,
      file: mintResult.file
    };

    const onSuccess = (val) => {
      res(val);
      getAuctions();
    };
    const onError = (val) => {
      res(undefined);
    };

    const onComplete = (val) => {
      console.log("onComplete", val);
    };

    if (auth.isAgent) {
      await agent_mint_and_list_to_marketplace(
        {
        
            ...mintResult,
            mintForCreator: mintResult.mintForCreator,
            series: mintResult.isUnlimited ? 0 : mintResult.series,
            categories: [mintResult.category, mintResult.mintType],
            data: JSON.stringify({
              title: mintResult.title,
              description: mintResult.description,
              ...others
            }),
            thumbnail: mintResult.file,
            file: mintResult.file,
            percentage: mintResult.royaltyPer / 100
          },
          { onSuccess, onError, onComplete }

      )
    }
    else {
      await mint_and_list_to_marketplace(
        {
          ...mintResult,
          series: mintResult.isUnlimited ? 0 : mintResult.series,
          categories: [mintResult.category, mintResult.mintType],
          data: JSON.stringify({
            title: mintResult.title,
            description: mintResult.description,
            ...others
          }),
          thumbnail: mintResult.file,
          file: mintResult.file,
          percentage: mintResult.royaltyPer / 100
        },
        { onSuccess, onError, onComplete }
      );
    }
  
  };

  return (
    <MintContext.Provider
      value={{ mintResult, setMintResult, minting, mint_and_list }}
    >
      {children}
    </MintContext.Provider>
  );
};
