import { Avatar, Card, Image, Row, Form } from "antd";
import React, { useContext, useState } from "react";
import {
  ButtonDiv,
  CenterItem,
  CenterItemDiv,
  CenterSubTitle,
  CenterTitle,
  SelectRoleDiv,
  SelectRoleItemAgent,
  SelectRoleItemCollector,
  SelectRoleItemCreator,
  SelectRoleMainDiv,
  TopBar,
} from "./SelectRole.styled";
import { SignupContext } from "../../../context/signupContext";

import Logo from "../../../images/worded_logo.svg";
import {
  ClickButton,
  Column,
  FormItemSelectable,
  RegisterText,
} from "../CreateAccount.styled";
import agent from "../../../images/agent.png";
import creator from "../../../images/creator.png";
import collector from "../../../images/collector.png";

export default function SelectRole({ onCreateAccount }) {
  const { setSignupData } = useContext(SignupContext);
  const [role, setRole] = useState("");

  const onRoleClick = (value) => {
    setRole(value.userType);
    setSignupData((prev) => ({
      ...prev,
      isAgent: false,
      isCreator: false,
      isCollector: false,
      isAdmin: false,
      ...value,
    }));
  };
  return (
    <SelectRoleMainDiv>
      <TopBar>
        <Image preview={false} src={Logo} width="100%" />
      </TopBar>
      <CenterItem>
        <CenterItemDiv>
          <CenterTitle>Tell Us What You Do</CenterTitle>
          <CenterSubTitle>What type of user are you?</CenterSubTitle>
          <SelectRoleDiv>
            <Form
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              className="mt-5"
              onFinish={onCreateAccount}
            >
              <SelectRoleItemAgent role={role === "agent" && true}>
                <FormItemSelectable
                  className={role === "agent" ? "selected" : ""}
                  style={{
                    position: "relative",
                    //   bottom: " -5px",
                    zIndex: "1",
                  }}
                >
                  <Card
                    hoverable
                    id="agent"
                    onClick={() => {
                      onRoleClick({ userType: "agent", isAgent: true });
                    }}
                    className={role === "agent" ? "selected" : ""}
                  >
                    <Row justify="center" align="bottom">
                      <Column span={24}>
                        <Avatar
                          className="card-img"
                          src={agent}
                          size={64}
                          alt="agentImg"
                        />
                      </Column>
                      <Column span={24}>
                        <RegisterText>Agent</RegisterText>
                      </Column>
                    </Row>
                  </Card>
                </FormItemSelectable>
              </SelectRoleItemAgent>
              <SelectRoleItemCreator>
                <FormItemSelectable
                  className={role === "creator" ? "selected" : ""}
                >
                  <Card
                    hoverable
                    id="creator"
                    onClick={() =>
                      onRoleClick({
                        userType: "creator",
                        isCreator: true,
                      })
                    }
                    className={role === "creator" ? "selected" : ""}
                  >
                    <Row align="bottom" justify="center">
                      <Column span={24}>
                        <Avatar
                          src={creator}
                          size={64}
                          className="card-img"
                          alt="..."
                        />
                      </Column>
                      <Column span={24}>
                        <RegisterText>Creator</RegisterText>
                      </Column>
                    </Row>
                  </Card>
                </FormItemSelectable>
              </SelectRoleItemCreator>
              <SelectRoleItemCollector>
                <FormItemSelectable
                  className={role === "collector" ? "selected" : ""}
                >
                  <Card
                    hoverable
                    id="collector"
                    onClick={() =>
                      onRoleClick({
                        userType: "collector",
                        isCollector: true,
                      })
                    }
                    className={role === "collector" ? "selected" : ""}
                  >
                    <Row justify="center" align="bottom">
                      <Column span={24}>
                        <Avatar
                          size={64}
                          src={collector}
                          className="card-img"
                          alt="..."
                        />
                      </Column>
                      <Column span={24}>
                        <RegisterText>Collector</RegisterText>
                      </Column>
                    </Row>
                  </Card>
                </FormItemSelectable>
              </SelectRoleItemCollector>
              <ButtonDiv>
                <Form.Item className="mt-4">
                  <ClickButton
                    inverse={"true"}
                    disabled={role === ""}
                    htmlType="submit"
                  >
                    Create Account
                  </ClickButton>
                </Form.Item>
              </ButtonDiv>
            </Form>
          </SelectRoleDiv>
        </CenterItemDiv>
      </CenterItem>
    </SelectRoleMainDiv>
  );
}
