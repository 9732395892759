import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import CreatorManage from "./CreatorManage";
import * as actions from "../../redux/actions";
import { ManageContainer, ManageContainerWrapper } from "./manage.styled";
import * as encryptdecrypt from "../../common/encryptdecrypt";
import * as config from "../../config/keys";

const Manage = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((obj) => obj);
  const { auth } = useSelector((obj) => obj);

  const onInvite = (data) => {
    const reqData = {
      email: data.value,
      agentAddress: auth.address,
      agentEmail: auth.email,
      agentName: auth.name,
      isAgent: data.isAgent,
      time: new Date().getTime()
    };

    const ciphertext = encryptdecrypt.encrypt(reqData);
    const url = `${config.clientURL}/create?flqr=${ciphertext}`;
    reqData.url = url;

    dispatch(actions.AdminActions.sendInviteMail(reqData));
  };

  useEffect(() => {
    dispatch(actions.AdminActions.getUsers());
  }, [dispatch]);

  return (
    <ManageContainer style={{}}>
      <ManageContainerWrapper style={{}}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            {userList && userList?.creator && (
              <CreatorManage
                users={userList?.agent}
                type={`Agents`}
                onInvite={onInvite}
              />
            )}
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            {userList && userList?.creator && (
              <CreatorManage
                users={userList?.creator}
                type={`Creators`}
                onInvite={onInvite}
              />
            )}
          </Col>
        </Row>
      </ManageContainerWrapper>
    </ManageContainer>
  );
};
export default Manage;
