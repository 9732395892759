import styled from "styled-components";
import { Typography, Button } from 'antd';

const { Text } = Typography;
export const MintingWrapper = styled.div`
  // margin: 4em ;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  border-radius: 5px;
`;

export const MintingButton = styled(Button)`
  background-color: #000000;
  border-color: #000000;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 4px;
  width: 380px;
  &:hover {
    background-color: #FC4448;
    border-color: #FC4448;
  }
`;
export const MintingText = styled(Text)`
  color: #ffffff;
`;

export const StepsAction = styled.div`
  margin-top: 24px;
`;

export const MintingCenter = styled.div`
  display: block;
  text-align: center;
`;

export const ToastText = styled(Typography.Text)`
  font: normal normal 600 15px Lato;
  color: #FFF;
  letter-spacing: 1.75px;
`;
