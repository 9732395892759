/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import AgencyLayout from "../components/Layout/AgencyLayout";
import SliderShow from "../components/SliderShow";
import HomeLatestCards from "../components/HomeLatestCards";
import CreateNFT from "../components/ExploreNFTinDashboard";
import HomeFooter from "../containers/home/footer";
import { useAuction } from "../context/auctions.context";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AdminActions } from "../redux/actions";

const LandingPage = () => {
  const { auctions, getAuctionsforPublicURL } = useAuction();
  const [auctionData, setAuctionData] = useState(
    auctions?.sort((a, b) => (Number(a.start) < Number(b.start) ? 1 : -1))
  );

  const dispatch = useDispatch();
  const { userList } = useSelector((obj) => obj);

  useEffect(() => {
    dispatch(AdminActions.getUsers());
    getAuctionsforPublicURL();
  }, []);

  useEffect(() => {
    if (auctions !== null) {
      // Shuffle array
      // const shuffled = auctions.sort(() => 0.5 - Math.random());
      // // Get sub-array of first n elements after shuffled
       let selected = auctions.slice(0, 12);
      // console.log(auctions,shuffled,selected);
      setAuctionData(selected);
    }
  }, [auctions]);

  return (
    <>
      <AgencyLayout>
        {auctionData !== undefined && auctionData !== null && (
          <SliderShow
            data={auctionData?.filter((x) => x.metaDataInfo !== null)}
          />
        )}
        <CreateNFT />
        {auctionData && <HomeLatestCards data={auctionData} />}
        {/* <TopSellers /> */}
        <HomeFooter />
      </AgencyLayout>
    </>
  );
};

export default LandingPage;
