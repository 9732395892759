import CryptoJS from "crypto-js";

export const encrypt = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "DAAMAgency"
  ).toString();

  return ciphertext;
};

export const decrypt = (data) => {
  
  const bytes = CryptoJS.AES.decrypt(data, "DAAMAgency");
  const originalBody = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalBody);
};
