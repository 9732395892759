import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import { tx } from "./util/tx";
import * as types from "@onflow/types";

const CODE = fcl.cdc`
// setup_daam_account.cdc
// Create A DAAM_Mainnet Wallet to store DAAM_Mainnet NFTs
// Includes: /multitoken/setup_mft_receiver.cdc

import NonFungibleToken   from ${keys.nonFungibleToken}
import FungibleToken      from ${keys.fungibleToken}
import MetadataViews      from ${keys.metadataViews}
import MultiFungibleToken from ${keys.multipleFungibleToken}
import ${keys.daamImport}       from ${keys.daam}

transaction(public: Bool)
{
    let public: Bool
    let acct: AuthAccount
    let have_collection: Bool
    let have_mft: Bool

    prepare(acct: AuthAccount) {
        if acct.borrow<&${keys.daamImport}.Collection>(from: ${keys.daamImport}.collectionStoragePath) != nil {
            self.have_collection = true
            //panic("You already have a ${keys.daamImport} Collection.")
        } else {
            self.have_collection = false
        }

        if acct.borrow<&MultiFungibleToken.MultiFungibleTokenManager{MultiFungibleToken.MultiFungibleTokenBalance}>(from: MultiFungibleToken.MultiFungibleTokenStoragePath) != nil {
            self.have_mft = true
            //panic("You already have a Multi-FungibleToken-Manager.")
        } else {
            self.have_mft = false
        }

        self.public = public
        self.acct   = acct
    }

    execute {
        if !self.have_collection {
            let collection <- ${keys.daamImport}.createEmptyCollection()    // Create a new empty collection
            self.acct.save<@NonFungibleToken.Collection>(<-collection, to: ${keys.daamImport}.collectionStoragePath) // save the new account
            
            if self.public {
                self.acct.link<&${keys.daamImport}.Collection{${keys.daamImport}.CollectionPublic, NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, MetadataViews.Resolver}>(${keys.daamImport}.collectionPublicPath, target: ${keys.daamImport}.collectionStoragePath)
                log("${keys.daamImport} Account Created. You have a ${keys.daamImport} Collection (Public) to store NFTs'")
            } else {
                log("${keys.daamImport} Account Created. You have a ${keys.daamImport} Collection (Non-Public) to store NFTs'")
            }
        }

        if !self.have_mft {
            // MultiFungibleToken
            let mft <- MultiFungibleToken.createEmptyMultiFungibleTokenReceiver()    // Create a new empty collection
            self.acct.save<@MultiFungibleToken.MultiFungibleTokenManager>(<-mft, to: MultiFungibleToken.MultiFungibleTokenStoragePath) // save the new account
            
            self.acct.link<&MultiFungibleToken.MultiFungibleTokenManager{FungibleToken.Receiver}>
                (MultiFungibleToken.MultiFungibleTokenReceiverPath, target: MultiFungibleToken.MultiFungibleTokenStoragePath)
            
            self.acct.link<&MultiFungibleToken.MultiFungibleTokenManager{MultiFungibleToken.MultiFungibleTokenBalance}>
                (MultiFungibleToken.MultiFungibleTokenBalancePath, target: MultiFungibleToken.MultiFungibleTokenStoragePath)
            
            log("MultiFungibleToken Receiver Created")
        }
    }
}
`;

export const createDaamAccount = async (opts = {}) => {
    const { authorization } = fcl.currentUser();
    return tx(
        [
            fcl.transaction(CODE),
            fcl.args([fcl.arg(true, types.Bool)]),
            fcl.proposer(authorization),
            fcl.payer(authorization),
            fcl.authorizations([authorization]),
            fcl.limit(keys.fclLimit)
        ],
        opts
    );
};
