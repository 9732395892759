
import { send, decode, script, args, arg, cdc } from "@onflow/fcl"
import { Address, UInt64 } from "@onflow/types"
import keys from "../config/keys"

const CODE = cdc`
// item_info.cdc
// Return item info of auction
import ${keys.daamImport}          from ${keys.daam}
import ${keys.auctionHouseImport}  from ${keys.auctionHouse}

pub fun main(auction: Address, aid: UInt64): ${keys.daamImport}.MetadataHolder? {    
    let auctionHouse = getAccount(auction)
        .getCapability<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>
        (${keys.auctionHouseImport}.auctionPublicPath)
        .borrow()!

    let mRef = auctionHouse.item(aid) as &${keys.auctionHouseImport}.Auction{${keys.auctionHouseImport}.AuctionPublic}?
    let metadata = mRef!.itemInfo()

    return metadata
}
`

export default function fetchAuctionDetails(address, aid) {
  if (address == null) return Promise.resolve(false)
  if (aid == null) return Promise.resolve(false)

  // prettier-ignore
  return send([
    script(CODE),
    args([
      arg(address, Address),
      arg(aid, UInt64)

    ])
  ]).then(decode)
}