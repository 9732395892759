import { Panel, ItemImage, PanelItem, ItemLabel } from "../Panel";

export default function CreatorManagementPanel() {
    return (
        <Panel>
            <PanelItem>
                <ItemImage />
                <ItemLabel>Message Creator</ItemLabel>
            </PanelItem>
            <PanelItem>
                <ItemImage />
                <ItemLabel>Remove Creator</ItemLabel>
            </PanelItem>
            <PanelItem>
                <ItemImage />
                <ItemLabel>View NFTs</ItemLabel>
            </PanelItem>
        </Panel>
    )
}