import styled from "styled-components";

export const ExploreNFTWrapper = styled.div`
  position: relative;
  // width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  // padding-left: var(--x-gutter);
  // padding-right: var(--x-gutter);
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #b5b7b9;
  border-radius: 18px;
  opacity: 1;
  background: linear-gradient(90deg, #f4f4f4 50%, #e9e9e9 50%);
  margin-top: 60px;
`;
export const HomePageCardsWrapper = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 4em;
`;
/***************************** Footer styles *****************/
export const HomeFooterWrapper = styled.div``;
export const FooterMainDiv = styled.div``;

export const HomeFooterWrapperRight = styled.div`
  padding: 2em;
  // padding-top: 3em;
  width: 50%;
  display: block;
  float: right;
  height: 150px;

  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #d9d8d8;
  opacity: 1;
`;

export const HomeFooterRight = styled.div`
  padding: 1em;
  height: 150px;

  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #d9d8d8;
  opacity: 1;
`;

export const HomeFooterLeft = styled.div`
  padding: 1em;
  height: 150px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d9d8d8;
  opacity: 1;
`;
export const HomeFooterWrapperLeft = styled.div`
  padding: 2em;
  // padding-top: 3em;
  // padding-right: 6em;
  width: 50%;
  display: block;
  float: left;
  height: 150px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d9d8d8;
  opacity: 1;
`;

export const HomeFooterWrapperBottom = styled.div`
  padding: 4em;
  background: #cdd0d3 0% 0% no-repeat padding-box;
  border: 1px solid #d9d8d8;
`;

export const FooterIcon = styled.div`
  display: block;
  float: left;
  margin: ${(props) => (props.mt ? `1px` : `5px`)};
  margin-right: 20px;
  width: 60px;
  height: 100%;
`;
export const HomeFooterWrapperCopyright = styled.div`
  padding: 1em;
  padding-left: 10em;
  padding-right: 10em;
  text-align: left;
  font: normal normal bold 14px/34px Lato;
  letter-spacing: 0px;
  color: #59625a;
  opacity: 1;
`;

export const TextCopyright = styled.div`
  display: "block";
  float: ${(props) => (props.right ? "right" : "left")};
`;

export const ReadMore = styled.span`
  text-align: left;
  font: normal normal bold 14px/34px sans-serif;
  letter-spacing: 0.32px;
  color: #ff4040;
  opacity: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  // margin-top: 1rem;
`;
