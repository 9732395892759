/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Input } from "antd";
import Select from "react-select";
import Image from "../../../components/Image";
import countryList from "react-select-country-list";
import * as Routes from "../../../Routes";
import "react-phone-input-2/lib/style.css";

import {
  FrameWrapper,
  ImageWrapper,
  Paragraph,
  FormItem,
  ClickButton,
  RegisterText,
  PhoneInput
} from "../CreateAccount.styled";
import Logo from "../../../images/worded_logo.svg";
import verifyImage from "../../../images/login/d16948c99e21735a3118fa494a13aa09.png";
import { SignupContext } from "../../../context/signupContext";

const PersonalContainer = ({ onNextClick }) => {
  const options = useMemo(() => countryList().getData(), []);
  const history = useHistory();
  const { signupData, setSignupData } = useContext(SignupContext);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...signupData });
  }, [signupData]);

  return (
    <>
      <div style={{ backgroundColor: "#F7F7F7", padding: "30px 0px 0px 30px" }}>
        <Image src={Logo} w="200px" />
      </div>
      <Row
        style={{
          padding: "2em 0em",
          background: "#F7F7F7",
          minHeight: "calc(100vh - 60px)",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Col
          style={{
            backgroundColor: "#E9E9E9",
            padding: "3em",
            borderRadius: "77px",
            boxShadow: "10px 10px 20px #00000029",
            justifyContent: "center",
            alignItems: "center"
          }}
          justify="center"
        >
          <Row justify="center">
            <Paragraph>Tell Us Who You Are</Paragraph>
          </Row>
          <Row justify="center">
            <RegisterText>
              <span className="rtext">
                This will be used to contact you. <br />
                Details can be changed later.
              </span>
            </RegisterText>
          </Row>
          <Form
            requiredMark={false}
            form={form}
            name="basic"
            labelCol={{
              span: 24
            }}
            wrapperCol={{
              span: 24
            }}
            onFinish={onNextClick}
          >
            <FormItem
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your Name" }]}
            >
              <Input
                style={{
                  border: "1px solid #999B9E",
                  borderRadius: "4px",
                  opacity: 1,
                  height: "40px"
                }}
                onChange={(e) =>
                  setSignupData((prev) => ({
                    ...prev,
                    name: e.target.value
                  }))
                }
              />
            </FormItem>
            <FormItem
              label="Nationality"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: "Please select your nationality"
                }
              ]}
            >
              <Select
                options={options}
                onChange={(e) =>
                  setSignupData((prev) => ({
                    ...prev,
                    nationality: e
                  }))
                }
                value={signupData?.nationality}
                placeholder={"Select a Country"}
              />
            </FormItem>
            <FormItem
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number"
                },
                {
                  min: 10,
                  message: "PhoneNumber must be minimum 10 digits."
                }
              ]}
            >
              <PhoneInput
                country={
                  signupData?.nationality?.value
                    ? signupData?.nationality?.value.toLowerCase()
                    : "ca"
                }
                defaultCountry="ca"
                placeholder="Enter your phone number"
                onChange={(value, country) => {
                  return setSignupData((prev) => ({
                      ...prev,
                      phone: value,
                    }));
                }}
              />
            </FormItem>
            <Form.Item>
              <RegisterText>
                <span
                  className="rtext"
                  style={
                    {
                      // textAlign: "center",
                      // fontSize: "13px",
                      // fontWeight: "bold",
                    }
                  }
                >
                  We’ll inform you by phone for emergency cases only
                </span>
              </RegisterText>
            </Form.Item>

            <Form.Item>
              <ClickButton
                inverse={"true"}
                htmlType="submit"
                // onClick={() => history.push(Routes.SELECTROLE)}
                // onClick={onNextClick}
              >
                Next
              </ClickButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PersonalContainer;
