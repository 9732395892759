import { Form } from "antd";
import styled from "styled-components";

export const NewProfile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: var(--x-gutter);
  padding-right: var(--x-gutter);
  padding-top: 56px;
`;
export const NewProfileHeader = styled.header`
  display: flex;
  flex-direction: column;
`;
export const NewProfileHeaderRow = styled.header`
  display: flex;
  margin-top: 32px;
  margin-bottom: 24px;
  &:first-of-type {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;
export const HeaderTitle = styled.h2`
  display: flex;
  justify-content: center;
  font: normal normal 500 34px/106px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
`;
export const NewProfileForm = styled.form`
  display: flex;
`;
export const FormColumn = styled.h2`
  display: flex;
  flex-direction: column;
  width: 50%;
  &:first-of-type {
    padding-right: 8px;
  }
  &:last-of-type {
    padding-left: 8px;
  }
`;

// export const SubmitButton = styled.Button`
//     height: 40px;
//     /* background: var(--unnamed-color-1a1d1f) 0% 0% no-repeat padding-box; */
//     /* border: 1px solid var(--unnamed-color-1a1d1f); */
//     background: #1A1D1F 0% 0% no-repeat padding-box;
//     box-shadow: 0px 1px 1px #00000029;
//     border: 1px solid #1A1D1F;
//     border-radius: 4px;
//     opacity: 1;
// `;
export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
  }
  label {
    text-align: left;
    font: normal normal bold 16px/76px Lato;
    letter-spacing: 0.51px;
    color: #1a1d1f;
  }
`;
