import styled from "styled-components";

export const CreatorContainer = styled.div`
  padding: 60px;
`;
export const LatestContainer = styled.div``;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  margin-left: 10px;
`;
export const Title = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 500 34px/51px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  margin-right: 20px;
`;
export const FeaturedContainer = styled.div`
  margin-top: 40px;
`;

// dashboard
export const DashBoardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 427px;
  background-color: #43a6ab;
`;
export const DashBoardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DashBoardTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
// export const DashboardContent = styled.div`
//   display: flex;
//   align-items: right;
//   justify-content: space-between;
// `;
export const DashboardRight = styled.div`
  position: absolute;
  right: 80px;
  top: 15px;
`;
export const DashboardRightTop = styled.div`
  width: 294px;
  height: 187px;
  padding: 25px;
  margin-top: 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 27px;
  opacity: 0.88;
`;
export const DashboardRightBottom = styled.div`
  margin-top: 40px;
  width: 294px;
  height: 187px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 27px;
  opacity: 0.88;
`;
export const DashboardRightBottomContent = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  padding: 0px 20px;
  display: flex;
  align-items: left;
  flex-direction: column;
`;
export const FollowedBy = styled.span`
  margin-left: 15px;
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 16px/66px Lato;
  letter-spacing: 0.51px;
  color: #1a1d1f;
  text-transform: capitalize;
  opacity: 1;
`;
export const FollowedByAvatar = styled.div``;

export const About = styled.h5``;
export const Description = styled.span`
  width: 245px;
  height: 104px;
  color: var(--unnamed-color-2f3234);
  text-align: left;
  font: normal normal 500 16px/28px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;
export const UserType = styled.div`
  width: 87px;
  height: 26px;
  margin-left: 10px;
  background: #81d8b2 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Type = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const ViewPresentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 229px;
  height: 50px;
  background: var(--unnamed-color-1a1d1f) 0% 0% no-repeat padding-box;
  background: #1a1d1f 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  opacity: 1;
  cursor: pointer;
`;
export const Text = styled.span`
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;
`;
export const TitleName = styled.div`
  /* width: 320px; */
  /* height: 89px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;
export const TitleItem = styled.span`
  color: var(--unnamed-color-fafafa);
  text-align: left;
  font: normal normal 500 60px/89px Oswald;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
`;
// export const CreatorContainer = styled.div``;
// export const CreatorContainer = styled.div``;
// export const CreatorContainer = styled.div``;
// export const CreatorContainer = styled.div``;
// export const CreatorContainer = styled.div``;
