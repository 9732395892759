import styled from "styled-components";
import * as AntdComp from "antd";

export const ManageCreatorDiv = styled.div`
  // justify-content: center;
  // align-items: center;
  //margin: auto;
  flex: 1;
  margin: 0px 15px;
  margin-left: 30px;
  /* margin-top: 55px; */
  // position: relative;
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h2`
  /* UI Properties */
  text-align: left;
  font: normal normal 500 34px/51px Oswald;
  letter-spacing: 0.41px;
  color: #1a1d1f;
  opacity: 1;
`;

export const InviteCreator = styled.button`
  width: 138px;
  letter-spacing: 0.2px;
  color: #ffffff;
  font: normal normal bold 12px/17px Lato;
  opacity: 1;
  border-radius: 15px;

  margin: 22px 0px;
  padding: 5px;

  background: #fc4448 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  border-radius: 15px;
  opacity: 1;
  &:hover {
    background: #1a1d1f 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border: 1px solid #1a1d1f;
  }
`;

export const SearchInput = styled(AntdComp.Input)`
  width: 100%;
  height: 45px;
  margin-bottom: 15px;

  /* UI Properties */
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #000000;
  border-radius: 8px;
  opacity: 1;
  ::placeholder {
    /* UI Properties */
    color: var(--unnamed-color-1a1d1f);
    text-align: left;
    font: normal normal 400 16px/19px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
    opacity: 1;
  }
`;

export const ErrorEmail = styled.span`
  color: #ff4040;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddAgency = styled.div``;

export const InActiveCreator = styled.div`
  margin-top: 23px;
`;
export const InActiveTitle = styled.span`
  font-size: 20px;
  margin-left: 5px;
  /* UI Properties */
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 500 20px/30px Oswald;
  letter-spacing: 0.68px;
  color: #1a1d1f;
  opacity: 1;
`;

export const ComponentContainer = styled.div`
  width: 100%;
  height: 69px;
  background: #ffffff;
  margin-top: 9px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000001a;
  opacity: 1;
`;
export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  ${(props) => props.cursor && `cursor:pointer`}
`;
export const InActiveName = styled.span`
  margin-left: 15px;
  height: 19px;
  /* UI Properties */
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;

export const IconContainer = styled.button`
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--unnamed-color-1a1d1f) 0% 0% no-repeat padding-box;
  background: #1a1d1f 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 50%;
`;

export const ContainerRight = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
// export const TitleBlock = styled.div``;
