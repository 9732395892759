import styled from "styled-components";
import { InputNumber, Slider, Typography } from "antd";
const { Paragraph } = Typography;

export const SetPriceInputNumber = styled(InputNumber)`
  height: 50px;
  & .ant-input-number-group {
    border: 2px solid #1a1d1f;
    border-radius: 10px;
    background-color: #1a1d1f;
    & .ant-input-number {
      font-size: xx-large;
      border-radius: 10px;
      color: #ff4040;
    }
    & .ant-input-number-group-addon {
      border-radius: 0 10px 10px 0;
      background: #1a1d1f;
      color: #fff;
      font-size: x-large;
    }
  }
`;

export const SetPriceParagraph = styled(Paragraph)`
  text-align: left;
  font: normal normal normal 12px/18px Lato;
  letter-spacing: 0.34px;
  color: #1a1d1f;
  opacity: 1;
`;

export const Label = styled(Typography.Text)`
  text-align: left;
  font: normal normal bold 14px/30px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const LabelValue = styled(Typography.Text)`
  text-align: right;
  font: normal normal normal 14px/30px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const SubHeadings = styled(Typography.Text)`
  text-align: left;
  font-family: Oswald;
  font-weight: medium;
  font-size: 20px;
  font: normal normal medium 20px/49px Oswald;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const PriceHighlightedValue = styled(Typography.Text)`
  text-align: left;
  font-family: Lato;
  font-weight: medium;
  font-size: 20px;
  font: normal normal medium 24px/29px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  opacity: 1;
`;

export const RoyalitySlider = styled(Slider)`
  font: normal normal bold 9px/12px Lato;
  & .ant-slider-mark {
    top: -18px;
    & span {
      font: normal normal bold 9px/12px Lato;
    }
  }
`;

export const DAMMServiceFees = styled(Typography.Text)`
  font: normal normal normal 12px/30px Lato;
  letter-spacing: 0.34px;
  color: #1a1d1f;
`;

export const ToastText = styled(Typography.Text)`
  font: normal normal 600 15px Lato;
  color: #FFF;
  letter-spacing: 1.75px;
`;
