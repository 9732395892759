import styled from "styled-components";
import { Avatar, Typography } from "antd";

export const DashboardWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  // padding: 2em 0em;
  // padding: 4em;
`;

export const CreationWrapper = styled.div`
  margin-left: 45px;
  margin-right: 45px;
  background: transparent;
  position: relative;
  // top: -100px;
`;
export const AvatarBanner = styled(Avatar)`
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-bottom: 1em;
`;
export const TitleBanner = styled.div`
  /* width: 30vw; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 0rem 2rem;

  & .title {
    & .userTitle {
      // font: normal normal 600 40px/50px Oswald;
      // @media (min-width: 1024px) {
      //   font: normal normal 600 60px/89px Oswald;
      // }
      // @media (min-width: 1440px) {
      //   font: normal normal 600 40px/50px Oswald;
      // }
      // @media (min-width: 2560px) {
      //   font: normal normal 600 80px/89px Oswald;
      // }
      // @media (min-width: 768px) {
      //   font: normal normal 600 40px/50px Oswald;
      // }
    }
    & img {
      height: 35px;
      // margin-top: -1.5em;
    }
    width: 100%;
    // margin-bottom: 0px;
    // font: normal normal 600 68px/101px Oswald;
    // font: normal normal 600 60px/89px Oswald;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
  background: var(--unnamed-color-fafafa) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-e9e9e9);
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000019;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  opacity: 0.77;

  @media (min-width: 1024px) {
    width: auto;
    padding: 1.5rem 2rem;
  }
  @media (min-width: 1440px) {
    width: auto;
    padding: 1rem 3.5rem;
  }
  @media (min-width: 2560px) {
    width: auto;
    padding: 1rem 4rem;
  }
  @media (min-width: 768px) {
    width: auto;
    padding: 1rem 1.5rem;
  }
`;
export const DashboardBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #cdd0d3;
  height:350px;
  // margin-bottom: 10px
  // @media (min-width: 768px) {
  //   height: 300px;
  // }
  // @media (min-width: 1024px) {
  //   height: 330px;
  // }
  // @media (min-width: 1440px) {
  //   // height: 440px;
  //   height: 350px;
  // }
  // @media (min-width: 2560px) {
  //   height: 40vh;
  // }
`;
export const BannerItem = styled.div`
  // display: inline-block;
  // margin-left: 2em;
  & .ant-space-vertical {
    gap: 0px !important;
    display: flex;
    align-items: center;
  }
`;
export const BannerItemWrapper = styled.div`
  padding: 0.5rem;
  // padding-bottom: 100px;
  /* background-color: blue; */
`;
export const CercleItem = styled.div`
  // height: 60px;
  // width: 60px;
  // background-color: white;

  // padding: 0.5em;

  height: 40px;
  width: 40px;
  /* border-radius: 50%; */
  background: #f7f7f7 0% 0% no-repeat padding-box;
  /* border: 1px solid #e9e9e9; */

  font: normal normal 500 13px/17px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  & img {
    width: 30px;
  }
`;

export const SalesDetails = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 600 36px/53px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;

export const SalesItemName = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 500 15px/18px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;

export const BannerChild = styled.div`
  width: 11em;
  position: absolute;
  right: 10%;
`;

export const BannerChildButton = styled.div`
  font-size: smaller;
  height: 50%;
  text-align: left;
  padding: 0.5em;
  padding-left: 1em;
  margin: 0.2em;
  top: ${(props) => (props.Bottom ? "35%" : "15%")};
  right: 10%;
  background: var(--unnamed-color-fafafa) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-e9e9e9);
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000019;
  border: 1px solid #e9e9e9;
  opacity: 1;
`;

export const DashboardAvtar = styled(Avatar)`
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding-top: 3px;
  padding-left: 1px;
  color: #1a1d1f;
  margin-right: 6px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  font: normal normal 300 39px/47px Font Awesome 5 Pro;
  height: 72px;
  width: 72px;
`;

export const TitleCollectionName = styled(Typography.Text)`
  text-align: left;
  font: normal normal normal 28px/51px Oswald;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const TitleCollectionCount = styled(Typography.Text)`
  text-align: left;
  font: normal normal normal 28px/51px Oswald;
  letter-spacing: 0px;
  color: #717579;
  opacity: 1;
`;

export const BlockchainAddress = styled(Typography.Title)`
  font: normal normal 600 16px Oswald !important;
`;

export const UserType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props?.color && `${props.color}`} 0% 0% no-repeat
    padding-box;
  border-radius: 98px;
  opacity: 1;
  padding: 10px 15px;
  width: fit-content;
  @media (min-width: 1024px) {
    width: 100%;
    padding: 8px 10px;
  }
  @media (min-width: 1440px) {
    width: 100%;
    padding: 7px 12px;
  }
  @media (min-width: 2560px) {
    width: 100%;
    padding: 10px 15px;
  }
  @media (min-width: 768px) {
    width: 100%;
    padding: 3px 8px;
  }
`;

export const UserTypeName = styled.span`
  text-align: left;
  font: normal normal bold 16px/21px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  /* margin-left: 3px; */
  text-transform: capitalize;

  @media (min-width: 1024px) {
    font: normal normal bold 28px/34px Lato;
  }
  @media (min-width: 1440px) {
    font: normal normal bold 30px/35px Lato;
  }
  @media (min-width: 2560px) {
    font: normal normal bold 36px/45px Lato;
  }
  @media (min-width: 768px) {
    font: normal normal bold 18px/24px Lato;
  }
`;

export const UserTypeWrapper = styled.div`
  position: relative;
  & .d-flex {
  }
`;
