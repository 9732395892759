import { ButtonStyles } from './styles'

export default function Button({children, size, order, br, ml, mr, corners, onClick}) {
    return (
        <ButtonStyles
            size={size}
            order={order}
            br={br}
            ml={ml}
            mr={mr}
            onClick={onClick}
            corners={corners}
        >
            {children}
        </ButtonStyles>
    )
}