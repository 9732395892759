import React, { useState } from "react";
import { useContext } from "react";
import { Row, Col, Form, Spin } from "antd";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import Logo from "../../../images/worded_logo.svg";
import loginImage from "../../../images/login/d16948c99e21735a3118fa494a13aa09.png";
import {
  FrameWrapper,
  Paragraph,
  FormItem,
  ClickButton,
  RegisterText,
  InputText,
  InputPassword,
} from "../../CreateAccount/CreateAccount.styled";
import { ForgotPWContext } from "../../../context/forgotPasswordContext";

export default function ChangePassword({
  forgotPWContent,
  pwd,
  Resetpassword,
}) {
  const { forgotPWData, setforgotPWData } = useContext(ForgotPWContext);
  const [disabled, setDisabled] = useState(true);
  // useEffect(()=>console.log(forgotPWData),[forgotPWData]);

  return (
    <div>
      <Spin spinning={false}>
        <Row align="top" justify="center">
          <Col lg={8} xl={8} md={8} sm={24} xs={24}>
            <Row
              align="top"
              justify="center"
              className="pt-5"
              style={{ marginTop: "150px" }}
            >
              <Col
                span={20}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-18px",
                }}
              >
                <Image src={Logo} w="230px" />
              </Col>
              <Col span={20}>
                <FrameWrapper>
                  <Col>
                    <Row style={{ align: "center" }} justify="center">
                      <Col className="pt-">
                        <Paragraph style={{ align: "center" }}>
                          {!pwd
                            ? `Enter your email to reset.`
                            : "Reset  password"}
                        </Paragraph>
                      </Col>
                    </Row>
                    <Row
                      style={{ align: "center" }}
                      justify="center"
                      gutter={[16, 16]}
                    >
                      <Col>
                        <RegisterText
                          style={{
                            align: "center",
                            justify: "center",
                            marginTop: "20px",
                          }}
                        ></RegisterText>
                      </Col>
                    </Row>
                  </Col>

                  <Form
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="text-center"
                    onFinish={(value) => {
                      // setforgotPWData({...value,email:value.email})
                      // console.log("val is",{...value,email:value.email},forgotPWData);
                      // test(value);
                      // console.log(forgotPWData)
                      forgotPWContent(forgotPWData);
                    }}
                  >
                    {!pwd && (
                      <>
                        <FormItem
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email you want to set.",
                            },
                          ]}
                        >
                          <InputText
                            style={{
                              border: "1px solid #999B9E",
                              borderRadius: "4px",
                              opacity: 1,
                              height: "40px",
                            }}
                            placeholder={"email"}
                            onChange={(e) =>
                              setforgotPWData((prev) => ({
                                ...prev,
                                email: e.target.value,
                              }))
                            }
                          />
                        </FormItem>
                      </>
                    )}
                    {pwd && (
                      <>
                        <FormItem
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter password you want to set.",
                            },
                          ]}
                        >
                          <InputPassword
                            style={{
                              border: "1px solid #999B9E",
                              borderRadius: "4px",
                              opacity: 1,
                              height: "40px",
                            }}
                            placeholder={"Password"}
                          />
                        </FormItem>
                        <FormItem
                          name="confirmpassword"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  setDisabled(false);

                                  return Promise.resolve();
                                }
                                setDisabled(true);

                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <InputPassword
                            style={{
                              border: "1px solid #999B9E",
                              borderRadius: "4px",
                              opacity: 1,
                              height: "40px",
                            }}
                            placeholder={"Confirm Password"}
                            onChange={(e) =>
                              setforgotPWData((prev) => ({
                                ...prev,
                                password: e.target.value,
                              }))
                            }
                          />
                        </FormItem>
                      </>
                    )}

                    {pwd ? (
                      <Form.Item>
                        <ClickButton
                          htmlType="submit"
                          inverse={"true"}
                          disabled={disabled}
                          onClick={() => Resetpassword()}
                        >
                          {" "}
                          Reset
                        </ClickButton>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        <ClickButton
                          htmlType="submit"
                          inverse={"true"}
                          disabled={false}
                        >
                          {" "}
                          Next
                        </ClickButton>
                      </Form.Item>
                    )}
                  </Form>
                </FrameWrapper>
              </Col>

              <Col
                lg={14}
                xl={14}
                md={14}
                sm={24}
                xs={24}
                style={{ textAlign: "center", marginTop: "100px" }}
              >
                {/*Homepage redirect style added*/}
                <Link
                  to={"/"}
                  style={{
                    textAlign: "center",
                    font: "normal normal 500 16px/106px Lato",
                    letterSpacing: 0,
                    color: "#FF4040",
                  }}
                  // onClick={() => history.push("/")}
                >
                  🡰 Back to Homepage
                </Link>
              </Col>
            </Row>
          </Col>

          <Col lg={14} xl={14} md={14} sm={24} xs={24}>
            {/* <ImageWrapper> */}
            <img src={loginImage} alt="signup" style={{ height: "950px" }} />
            {/* </ImageWrapper> */}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
