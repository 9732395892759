/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Menu, Avatar, Typography, Badge, Spin, Space } from "antd";
import Sider from "antd/lib/layout/Sider";
import { SettingFilled, SettingOutlined } from "@ant-design/icons";

import WordedLogo from "../../images/worded_logo.svg";
import MLogo from "../../images/daam_wordmark.svg";
import Pencil from "../../images/icons/pencil.png";
import ProfileImagePlaceholder from "../../images/profile_placeholder.png";
import { ProfileImage, Image } from "../";

import { AuthContext } from "../../context/authContext";
import CategoriesSubmenu from "../../containers/categories/submenu/CategoriesSubmenu";
import AdminMenu from "../AdminMenu";
import {
  CenterStyles,
  Container,
  RightStyles,
  TopNavigationStyles,
  SiderStyles,
  LoginButton,
  MenuLink,
  MenuItem,
  StartButton
} from "./styles";
import { Button } from "../../containers/Common/common.styled";
import * as ROUTES from "../../Routes";
import { NavLink } from "react-router-dom";

export default function TopNavigation() {
  const {
    user,
    signOut,
    signIn,
    isCreator,
    isAdmin,
    isAgent,
    hasProfile,
    hasAuctionWallet,
    hasDaamAccount,
    hasFUSDWallet,
    setupAuctionWallet,
    setupDaamAccount,
    setupFUSDWallet,
    setupProfile,
    acceptCreatorInvite,
    acceptAdminInvite
  } = useContext(AuthContext);

  const [alertCount, setAlertCount] = useState(0);

  const history = useHistory();

  const acceptInvitationLabel = () => {

    let label = "";
    if (isAdmin !== null && !isAdmin) {
      label = "Admin";
    } else if (isCreator !== null && !isCreator) {
      label = "Creator";
    } else if (isAgent !== null && !isAgent) {
      label = "Agent";
    } else return null;
    // setAlertCount((prev) => prev.alertCount + 1);
    return `Accept ${label} Invitation`;
  };

  const acceptInvite = () => {
    if (!isAdmin) {
      acceptAdminInvite();
    } else if (!isCreator) {
      acceptCreatorInvite();
    } else if (isAgent) {
    }
    // setAlertCount((prev) => prev.alertCount - 1);
  };

  const menu = (
    <Menu style={{ flexDirection: "column" }}>
      {isCreator && (
        <>
          <MenuItem
            key="0"
            onClick={() =>
              window.open(
                "https://testnet-faucet.onflow.org/fund-account",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            <strong>{` ${
              hasFUSDWallet && Number(hasFUSDWallet).toFixed(2)
            } FUSD`}</strong>
          </MenuItem>
          <MenuItem onClick={() => history.push("/creator-dashboard")} key="1">
            <div>Dashboard</div>
          </MenuItem>
        </>
      )}
      {/* {(((isCreator !== null && !isCreator) ||
        (isAgent !== null && !isAgent) ||
        (isAdmin !== null && !isAdmin)) &&
        user.invitation === "sent") && (
          <MenuItem key="2" danger onClick={acceptInvite}>
            {acceptInvitationLabel()}
          </MenuItem>
        )} */}
      {user?.invitation === "sent" && (
        <MenuItem key="2" danger onClick={acceptInvite}>
          {acceptInvitationLabel()}
        </MenuItem>
      )}
      {user?.addr &&
        isCreator === null &&
        isAgent === null &&
        isAdmin === null && (
          <MenuItem key="2" danger onClick={acceptInvite}>
            <Space size="middle">
              <Spin size="small" />
              <MenuItem>{`Wait for Invitation`}</MenuItem>
            </Space>
          </MenuItem>
        )}

      {!hasProfile && (
        <MenuItem key="2" danger onClick={setupProfile}>
          {`Create Account`}
        </MenuItem>
      )}
      {hasProfile && !hasAuctionWallet && (
        <MenuItem key="2" success onClick={setupAuctionWallet}>
          {`Create Auction Wallet`}
        </MenuItem>
      )}
      {!hasFUSDWallet && (
        <MenuItem key="2" success onClick={setupFUSDWallet}>
          {`Setup FUSD Wallet`}
        </MenuItem>
      )}
      {isCreator && (
        <>
          {!hasDaamAccount && (
            <MenuItem key="2" success onClick={setupDaamAccount}>
              {`Setup DAAM account`}
            </MenuItem>
          )}
        </>
      )}

      <MenuItem key="2" onClick={signOut}>
        <div>Sign out</div>
      </MenuItem>
    </Menu>
  );

  return (
    // <SiderStyles
    //   breakpoint="lg"
    //   collapsedWidth="0"
    //   onBreakpoint={(broken) => {
    //     console.log(broken);
    //   }}
    //   onCollapse={(collapsed, type) => {
    //     console.log(collapsed, type);
    //   }}
    // >
    <TopNavigationStyles>
      <Container>
        <Link to="/" className="d-none d-sm-none d-md-block">
          <Image src={WordedLogo} w="130px" />
        </Link>
        <Link to="/" className="d-md-none">
          <Image src={MLogo} w="30px" />
        </Link>
        <CenterStyles>
          <MenuLink to="/categories">
            <Link className="link" to="/">
              FEATURED
            </Link>
          </MenuLink>
          <MenuLink to="/categories">
            <CategoriesSubmenu />
          </MenuLink>
          <MenuLink className="link" to="/collections">
            <Link className="link" to="/collections">
              ADMIN
            </Link>
          </MenuLink>
        </CenterStyles>
        <RightStyles>
          {/* <Button
                    onClick={acceptAdminInvite}
                    order="primary"
                    size="regular"
                    ml="16px"
                    mr="16px"
                  >
                    Accept admin invitation
                  </Button> */}
          {/* <strong style={{ color: "grey", marginRight: "10px" }}>
            {user?.addr}
          </strong> */}

          {/* {hasProfile && !hasAuctionWallet && (
            <Button
              onClick={setupAuctionWallet}
              order="primary"
              size="regular"
              ml="16px"
              mr="16px"
            >
              Create Auction Wallet
            </Button>
          )} */}

          {/* {user?.addr &&
            isCreator === null &&
            isUserAgent === null &&
            isAdmin === null && (
              <h4 style={{ marginRight: "10px", color: "red" }}>
                Wait for your invitation...
              </h4>
            )} */}

          {user?.addr ? (
            <>
              {isCreator && (
                <>
                  {!hasDaamAccount && (
                    <Button
                      onClick={setupDaamAccount}
                      order="primary"
                      size="regular"
                      ml="16px"
                      mr="16px"
                    >
                      Setup DAAM account
                    </Button>
                  )}
                  {/* {
                    <Button
                    onClick={setupFUSDWallet}
                    order="primary"
                    size="regular"
                    ml="16px"
                    mr="16px"
                  >
                    Setup FUSD Wallet
                  </Button>
                  } */}
                  {/* {
                 ( hasFUSDWallet && !hasAuctionWallet) && <Button
                    onClick={setupAuctionWallet}
                    order="primary"
                    size="regular"
                    ml="16px"
                    mr="16px"
                  >
                    Setup Auction Wallet
                  </Button>
                  } */}

                  {hasFUSDWallet && hasAuctionWallet && hasDaamAccount && (
                    <>
                      {/* <Button order="tertiary" size="regular" ml="16px">
                        <Image src={Pencil} w="24px" />
                      </Button> */}

                      <Button
                        order="primary"
                        size="regular"
                        style={{ marginRight: 12 }}
                        onClick={() => history.push("/create")}
                      >
                        Create
                      </Button>
                    </>
                  )}
                </>
              )}

              {/* {((isCreator !== null && !isCreator) ||
                (isAgent !== null && !isAgent)) && (
                <>
                  <Button
                    onClick={acceptCreatorInvite}
                    order="primary"
                    size="regular"
                    ml="16px"
                    mr="16px"
                  >
                    {`Accept ${!isCreator ? "Creator" : "Agent"} Invitation`}
                  </Button>
                </>
              )} */}
              {/* {isAdmin !== null && !isAdmin && (
                <>
                  <Button
                    onClick={acceptAdminInvite}
                    order="primary"
                    size="regular"
                    ml="16px"
                    mr="16px"
                  >
                    Accept admin invitation
                  </Button>
                </>
              )} */}
              {/* {!hasProfile && (
                <Button
                  onClick={setupProfile}
                  order="primary"
                  size="regular"
                  ml="16px"
                  mr="16px"
                >
                  Create Account
                </Button>
              )} */}
              <Dropdown
                overlay={<AdminMenu />}
                trigger={["click"]}
                placement="bottom"
              >
                <Avatar
                  // src={<Icon src={SettingFilled} />}
                  size="large"
                  icon={<SettingOutlined />}
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    textAlign: "center",
                    paddingTop: "3.6px",
                    paddingLeft: "1px",
                    backgroundColor: "white",
                    color: "#FF4040",
                    border: "1px solid #CDD0D3",
                    marginRight: "6px",
                    font: "normal normal 300 34px/41px Font Awesome 5 Pro"
                  }}
                />
              </Dropdown>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                arrow
                placement="bottomRight"
              >
                <Badge count={2}>
                  <Avatar
                    src={<Image src={ProfileImagePlaceholder} />}
                    size="large"
                  />
                </Badge>
              </Dropdown>
            </>
          ) : (
            <>
              <LoginButton onClick={() => history.push(ROUTES.LOGIN)}>
                Login
              </LoginButton>
              {/* <LoginButton onClick={signIn}>
                Login
              </LoginButton> */}
              <StartButton onClick={() => history.push(ROUTES.SIGN_UP)}>
                Start
              </StartButton>
            </>
          )}
        </RightStyles>
      </Container>
    </TopNavigationStyles>
    // </SiderStyles>
  );
}
