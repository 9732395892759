import styled from "styled-components"

export const AgencyManagementListsStyles = styled.div`
    display:flex;
    width:100%;
    padding-left:var(--x-gutter);
    padding-right:var(--x-gutter);
`
export const AgencyManagementList = styled.div`
    display:flex;
    flex-direction:column;
    width:50%;
    &:first-of-type {
        padding-right:24px;
    }
    &:last-of-type {
        padding-left:24px;
    }
`
export const ListHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin-bottom:32px;
`
export const HeaderTitle = styled.div`
    display:flex;
    color:black;
    font-size:24px;
    font-weight:600;
`
export const HeaderOptions = styled.div`
    display:flex;
    margin-left:auto;
`
export const ListItems = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:column;
    margin-bottom:32px;
`