import { Button, Col, Input, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect, useState, useContext } from "react";
import Card from "../components/Card";
import { listAllMetadata } from "../scripts/listAllMetadata";
import avatarImg from "../images/profile_placeholder.png";
import { AcceptNft } from "../scripts/acceptNFT";
import { AuthContext } from "../context/authContext";
import { safeParse } from "../scripts/util/safeParse";
import { inviteCreatorByAgent } from "../scripts/admin/inviteCreator";
import { inviteAdmin } from "../scripts/admin/inviteAdmin";
import { removeAdmin, removeCreator } from "../scripts/admin/removeCreator";
import * as actions from "../redux/actions";
import { useDispatch } from "react-redux";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { isAdmin, user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [address, setaddress] = useState(null);

  useEffect(() => {
    if (user?.addr) {
      listAllMetadata().then((res) => {
        let result = [];

        result = Object.values(res)
          .filter((item) => item[1].length > 0)
          .map((item) => item[1]);

        setData([].concat.apply([], result));
      });
    }
  }, [user]);
  const onSuccess = async () => {
    const update = {
      where: { address: address },
      update: {
        invitation: "sent"
      }
    };
    dispatch(actions.AdminActions.updateUserStatusByaddress(update));
    onComplete();
  };
  const onError = () => {
    console.log("error");
  };
  const onComplete = () => {
    console.log("complete");
  };

  return isAdmin ? (
    <>
      <Row>
        <Col>
          <div style={{ display: "flex", margin: "20px", gap: "10px" }}>
            <Input
              onChange={(e) => setaddress(e.target.value)}
              value={address}
            />
            <Button
              onClick={() =>
                inviteCreatorByAgent(
                  { address },
                  { onSuccess, onError, onComplete }
                )
              }
            >
              <h5>Invite creator</h5>
            </Button>
            <Button
              onClick={() =>
                inviteAdmin({ address }, { onSuccess, onError, onComplete })
              }
            >
              <h5>Invite Admin</h5>
            </Button>

            <Button onClick={() => removeCreator({ address })}>
              <h5>Remove Creator</h5>
            </Button>

            <Button onClick={() => removeAdmin({ address })}>
              <h5>Remove Admin</h5>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {data &&
          data.map((item) => (
            <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
              <Card
                author={item?.creator}
                to={`//art/${item?.mid}`}
                image={item?.file}
                title={safeParse(item.data).title}
                status="Submited"
                avatar={<Avatar src={avatarImg} alt="avatar" />}
              />
              <Button onClick={() => AcceptNft(item?.mid)}>Accept</Button>
            </Col>
          ))}
      </Row>
    </>
  ) : (
    <> </>
  );
};

export default AdminDashboard;
