import { Button, Form, Input, Col } from "antd";
import ReactVerificationInput from "react-input-verification-code";
import styled from "styled-components";
import ReactPhoneInput from "react-phone-input-2";

export const FrameWrapper = styled.div`
  // max-width: 80%;
  // padding-top: 10em;
  // padding-left: 25em;
  padding-top: 10%;
`;

export const ImageWrapper = styled.div`
  max-width: 80%;
  max-height: 50%;
`;

export const Paragraph = styled.h3`
  display: flex;
  justify: center;
  justify-content: center;

  text-align: center;
  font: normal normal 500 30px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
`;

export const RegisterText = styled.p`
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  // justify:"center"
  & .rlink {
    justify: center;
    text-align: center;
    font: normal normal bold 16px Lato;
    letter-spacing: 0px;
    color: #ff4040;
    text-decoration: none;
  }
  & .rtext {
    justify: center;
    text-align: center;
    font: normal normal 500 16px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
  cardlabel {
    text-align: left;
    font: normal normal bold 16px/48px Oswald;
    letter-spacing: 0.72px;
    color: #2f3234;
    text-transform: uppercase;
    opacity: 1;
  }
  @media (max-width: 1536px) {
    font: normal normal 600 13px/20px Oswald !important;
  }
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
  .ant-form-item-label {
    padding: 0;
    justify: center;
  }
  label {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    ${"" /* text-transform: capitalize; */}
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
  placeholder {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    text-transform: capitalize;
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
`;

export const ClickButton = styled(Button)`
  width: 100%;

  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  height: 45px;
  text-align: center;
  // font: normal normal bold 20px/24px "Lato";
  font-family: "Lato";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 20px/24px;
  letter-spacing: 0.4px;
  color: #1a1d1f;
  opacity: 1;
  background-color: ${(props) => (props.inverse === true ? "#1a1d1f" : "#fff")};
  color: ${(props) => (props.inverse === true ? "#fff" : "#1a1d1f")};
  &:hover {
    background-color: ${(props) =>
      props.inverse === true ? "#fff" : "#1a1d1f"};
    color: ${(props) => (props.inverse === true ? "#1a1d1f" : "#fff")};
  }

  @media (max-width: 1240px) {
    height: 35px;
  }
  @media (max-width: 1024px) {
    height: 35px;
  }
`;

export const Column = styled(Col)`
  display: flex;
  justify-content: center;
  align-item: end;
  & p {
    padding-top: 3px;
    font: normal normal 500 20px/0px Oswald;
    letter-spacing: 0.72px;
    color: #2f3234;
    text-transform: uppercase;
  }
  & .card-img {
    @media (max-width: 1536px) {
      width: 47px !important;
      height: 49px !important;
    }
  }
`;

export const InputText = styled(Input)`
  border: "1px solid #999B9E";
  border-radius: 4px;
  opacity: 1;
  height: 40px;
  font-weight: bold;
  &::placeholder {
    color: #cdcfd1;
  }
`;

export const InputPassword = styled(Input.Password)`
  border: "1px solid #999B9E";
  border-radius: 4px;
  opacity: 1;
  height: 40px;
  font-weight: bold;
  &::placeholder {
    color: #cdcfd1;
  }
`;

export const OTPVerificationInput = styled(ReactVerificationInput)`
  width: 100% !important;
  div {
    background-color: red;
  }
  & .ReactInputVerificationCode__item {
    background-color: red;
  }
  &.ReactInputVerificationCode__item {
    background-color: red !important;
  }
  & div {
    width: 40px;
    height: 50px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
    border-radius: 10px;
    opacity: 1;
  }
`;
export const PhoneInput = styled(ReactPhoneInput)`
  & .form-control {
    width: 84%;
    height: 40px;
    margin-left: 58px;
    padding-left: 7px !important ;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  placeholder {
    padding-left: 7px !important ;
  }
  .react-tel-input .form-control {
    padding-left: 7px !important ;
  }
  &input {
    width: 100% !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const FormItemSelectable = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  //border: 1px solid red;
  & .ant-card-body {
    // min-width: 100px;
    min-height: 173px;
    /* padding: 5px; */
    display: flex;
    justify-content: center;
    align-content: center;
    @media (max-width: 1710px) {
      min-height: 125px;
      padding: 5px !important ;
    }
    @media (max-width: 1536px) {
      min-height: 0px;
      padding: 5px !important ;
    }
    @media (max-width: 1440px) {
      min-height: 0px;
      padding: 5px !important ;
    }
    @media (max-width: 1240px) {
      min-height: 80px !important;
      padding: 5px !important ;
    }
    @media (max-width: 1024px) {
      min-height: 80px !important;
      padding: 5px !important ;
    }
  }
  & .ant-avatar-image {
    background: #2f3234;
    padding: 12px;
  }
  & .selected {
    // border-color: #FF4040;
    color: #ff4040;
    // border: 2px solid #FF4040:
    & .ant-card {
      color: #ff4040;
    }
    & .ant-avatar-image {
      background: #ff4040;
    }
    & p {
      color: #ff4040;
    }
  }
  /* @media (max-width: 1536px) {
    height: 100px;
  }
  @media (max-width: 1440px) {
    width: 60%;
    height: 100px;
  }
  @media (max-width: 1240px) {
    width: 50%;
    height: 30%;
  }
  @media (max-width: 1024px) {
    width: 40%;
    height: 30%;
  } */
`;
