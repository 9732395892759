import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

import { ec as EC } from "elliptic";

import { SHA3 } from "sha3";

const ec = new EC("p256");

const CODE = fcl.cdc`
// submit_accept.cdc
// Creator uses to submit Metadata & Approve Rpyalty
import FungibleToken from ${keys.fungibleToken}  
import Categories    from ${keys.categories}
import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}  from ${keys.daam}

// argument have two modes:
// when ipfs = true; first arument is cid, second argument is path 
// when ipfs = false; first arument thumbnail String, second argument is thumbnailType and can not be nil
pub fun setFile(ipfs: Bool, string_cid: String, type_path: String?): {MetadataViews.File} {
    pre { ipfs || !ipfs && type_path != nil }
    if ipfs { return MetadataViews.IPFSFile(cid: string_cid, path: type_path) }
    switch type_path! {
        case "http": return MetadataViews.HTTPFile(url: string_cid)
        default: return ${keys.daamImport}.OnChain(file: string_cid)
    }
}

transaction(name: String, max: UInt64?, categories: [String], description: String, misc: String, // Metadata information
    ipfs_thumbnail: Bool, thumbnail_cid: String, thumbnailType_path: String, // Thumbnail setting: IPFS, HTTP(S), FILE(OnChain)
    ipfs_file: Bool, file_cid: String, fileType_path: String,                // File setting: IPFS, HTTP(S), FILE(OnChain)
    interact: AnyStruct?,  percentage: UFix64?)                               // Royalty percentage for Creator(s), when nil only submitting and entering bargin mode unless
                                                                                // within default range, then accept default can be used.
{    
    let creatorCap  : Capability<&AnyResource{FungibleToken.Receiver}>
    let requestGen  : &${keys.daamImport}.RequestGenerator
    let metadataGen : &${keys.daamImport}.MetadataGenerator

    let name        : String
    let max         : UInt64?
    var categories  : [Categories.Category]
    let interact    : AnyStruct?
    let description : String
    let misc        : String
    let thumbnail   : {String : {MetadataViews.File}}
    let file        : {String : MetadataViews.Media}
    let percentage  : UFix64?

    prepare(creator: AuthAccount) {
        self.creatorCap  = creator.getCapability<&AnyResource{FungibleToken.Receiver}>(MetadataViews.getRoyaltyReceiverPublicPath())
        self.metadataGen = creator.borrow<&${keys.daamImport}.MetadataGenerator>(from: ${keys.daamImport}.metadataStoragePath)!
        self.requestGen  = creator.borrow<&${keys.daamImport}.RequestGenerator>( from: ${keys.daamImport}.requestStoragePath)!

        self.name         = name
        self.max          = max
        self.description  = description
        self.interact     = nil //interact
        self.misc         = misc
        self.thumbnail    = {thumbnailType_path : setFile(ipfs: ipfs_thumbnail, string_cid: thumbnail_cid, type_path: thumbnailType_path)}
        let fileData      = setFile(ipfs: ipfs_file, string_cid: file_cid, type_path: fileType_path)
        let fileType      = ipfs_file ? "ipfs" : fileType_path
        self.file         = {fileType : MetadataViews.Media(file: fileData, mediaType: fileType)}
        
        self.categories = []
        for cat in categories {
            self.categories.append(Categories.Category(name: cat))
        }
        self.percentage = percentage
    }

    execute {
        let mid = self.metadataGen.addMetadata(name: self.name, max: self.max, categories: self.categories,
        description: self.description, misc: self.misc, thumbnail: self.thumbnail, file: self.file, interact: self.interact)
        
        log("Metadata Submitted: ".concat(mid.toString()))

        if self.percentage != nil {
            let royalties_init: [MetadataViews.Royalty] = [ MetadataViews.Royalty(
                receiver: self.creatorCap,
                cut: percentage!,
                description: "Creator Royalty"
            )]
            let royalties = MetadataViews.Royalties(royalties_init) 
            log(royalties)
            if self.percentage! >= 0.01 && self.percentage! <= 0.3 {
                self.requestGen.acceptDefault(mid: mid, metadataGen: self.metadataGen, royalties: royalties)
                log("Deal Accepted: ".concat(mid.toString()))
            } else {
                self.requestGen.createRequest(mid: mid, royalty: royalties)
                log("Bargining Started: ".concat(mid.toString()))
            }
        }
    }
    
}
`;
const getAccount = async (addr) => {
  const { account } = await fcl.send([fcl.getAccount(addr)]);
  return account;
};

const hashMsg = (msg) => {
  const sha = new SHA3(256);
  sha.update(Buffer.from(msg, "hex"));
  return sha.digest();
};

const signWithKey = (privateKey, msg) => {
  const key = ec.keyFromPrivate(Buffer.from(privateKey, "hex"));
  const sig = key.sign(hashMsg(msg));
  const n = 32;
  const r = sig.r.toArrayLike(Buffer, "be", n);
  const s = sig.s.toArrayLike(Buffer, "be", n);
  return Buffer.concat([r, s]).toString("hex");
};

// eslint-disable-next-line no-unused-vars
const authorizeMinter = (address) => {
  return async (account) => {
    const user = await getAccount(address);
    const key = user.keys[1];
    console.log("USER", user);
    const sign = signWithKey("f8e188e8af0b8b414be59c4a1a15cc666c898fb34d94156e9b51e18bfde754a5", "THIS IS TEST");
    const pk = "f8e188e8af0b8b414be59c4a1a15cc666c898fb34d94156e9b51e18bfde754a5";
    console.log(sign);
    return {
      ...account,
      tempId: `${user.address}-${key.index}`,
      addr: fcl.sansPrefix(user.address),
      keyId: Number(key.index),
      signingFunction: (signable) => {
        console.log(signable);
        return {
          addr: fcl.withPrefix(user.address),
          keyId: Number(key.index),
          signature: signWithKey(pk, signable.message)
        };
      }
    };
  };
};





export const mint = async (mintResult, opts = {}) => {
  const {
    title,
    description,
    series,
    categories,
    data,
    // thumbnail,
    file,
    percentage,
    fileName
  } = mintResult;

  if (series == null)
    throw new Error("createSaleOffer(mid, price) -- series required");
  if (data == null)
    throw new Error("createSaleOffer(mid, price) -- data required");

  const { authorization } = fcl.currentUser();

  // const t = await authorizeMinter(mintResult.auth.address);
  // console.log(t);
  // console.log(await getAccount(mintResult.auth.address),authorization);

  const seriesValue = series === 0 ? null : series;
  const seriesValueType = seriesValue === null ? types.Optional(types.UInt64) : types.UInt64;

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        // fcl.arg(series, types.UInt64),
        // fcl.arg(categories, types.Array(types.String)),
        // fcl.arg(data, types.String),
        // fcl.arg(thumbnail, types.String),
        // fcl.arg(file, types.String),
        // fcl.arg(percentage, types.UFix64),
        fcl.arg(title, types.String),
        fcl.arg(seriesValue, seriesValueType),
        // fcl.arg(false, types.Bool),
        fcl.arg(categories, types.Array(types.String)),
        // fcl.arg(null, types.Optional(types.Dictionary)),
        fcl.arg(description, types.String),

        fcl.arg(" ", types.String),

        fcl.arg(false, types.Bool),
        // fcl.arg(thumbnail, types.String),
        // fcl.arg(fileName.split(".").pop(), types.String),
        fcl.arg(file, types.String),
        fcl.arg(fileName.split(".").pop(), types.String),

        fcl.arg(false, types.Bool),
        fcl.arg(file, types.String),
        fcl.arg(fileName.split(".").pop(), types.String),
        // fcl.arg(data, types.String),
        // fcl.arg(thumbnail, types.String),
        // fcl.arg(file, types.String),
        fcl.arg(null, types.Optional(types.Struct)),
        fcl.arg(percentage, types.UFix64)
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
  // return toast.promise(
  //   tx(
  //     [
  //       fcl.transaction(CODE),
  //       fcl.args([
  //         fcl.arg(series, types.UInt64),
  //         fcl.arg(data, types.String),
  //         fcl.arg(thumbnail, types.String),
  //         fcl.arg(file, types.String),
  //       ]),
  //       fcl.proposer(authorization),
  //       fcl.payer(authorization),
  //       fcl.authorizations([authorization]),
  //       fcl.limit(keys.fclLimit),
  //     ],
  //     opts
  //   ),
  //   {
  //     pending: "Minting your NFT",
  //     success: (res) => {
  //       console.log(res);
  //       return "Miniting success, waiting for admin approval 👌";
  //     },
  //     error: "Promise rejected 🤯",
  //   }
  // );
};
