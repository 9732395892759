/* eslint-disable no-unused-vars */
import { Layout } from "antd";
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { TopNavigation, AdminFooter } from "../components";
import {
  AgentProfile,
  Minting,
  CreatorDashboard,
  CreatorProfile,
  AgentDashboard,
  DaamDashboard,
  Categories,
  TestComponents,
  AdminDashboard,
  MintPage,
  ListNFTPage,
  NFTExplorer,
  Manage,
  Art,
  LandingPage,
  ManageUser,
  ManageCreator,
  Profile
} from ".";
import AuthNavigation from "../components/TopNavigation/authNavigation";
import Dashboard from "./Dashboard";
import { AuthContext } from "../context/authContext";
import AdminAuthNavigation from "../components/TopNavigation/adminNavigation";
import * as Routes from "../Routes";
import UploadCollection from "../containers/minting/uploadStep/UploadCollection";
import CreatorComponent from "../containers/CreatorN/CreatorPage";
import Collections from "../containers/collections";
import CreatorNew from "../containers/CreatorN";

// const Minting = lazy(() => require("./Minting"));
// const Dashboard = lazy(() => require("./Dashboard"));
// const MintPage = lazy(() => require("./Mint"));
// const UploadCollection = lazy(() =>
//   require("../containers/minting/uploadStep/UploadCollection")
// );

const AuthApplication = () => {
  const { user } = useContext(AuthContext);
  const style1 = {
    padding: "15px 30px",
    position: "absolute",
    "z-index": "9999",
    width: "100%",
    background: "0% 0% no-repeat padding-box rgba(255,255,255,0.75)",
    // opacity: 0.8
  };

  const style2 = {
    background:window.location.pathname.startsWith(Routes.DASHBOARD)?"#CDD0D3":"transparent",
    padding: "15px 30px"
  };
  return (
    <Layout
      style={{
        background: "#F7F7F7 0% 0% no-repeat padding-box",
        minHeight: "100vh"
      }}
    >
      <Layout.Header
        style={
          window.location.pathname.startsWith(Routes.DETAILS) ? style1 : style2
        }
        // style={{
        //   background:
        //     window.location.pathname === Routes.DASHBOARD
        //       ? "#CDD0D3"
        //       : "transparent",
        //   padding: "20px 30px 0px 30px",
        // }}
      >
        {/* {user?.isAdmin ? <AdminAuthNavigation /> : <AuthNavigation />} */}
        <AuthNavigation />
      </Layout.Header>

      <Layout.Content style={{ position: "relative" }}>
        <Switch>
          {/* <Route exact path="/app" component={LandingPage} /> */}
          <Route exact path="/app" component={Dashboard} />
          <Route exact path="/app/dashboard" component={Dashboard} />
          <Route exact path="/app/mint" component={MintPage} />
          <Route exact path="/app/mint/:filterParam" component={Minting} />
          <Route exact path="/app/minting" component={Minting} />
          <Route
            exact
            path="/app/uploadCollection"
            component={UploadCollection}
          />
          {/* <Route exact path="/app/details/:id" component={CreatorComponent} /> */}
          <Route exact path="/app/details/:id" component={CreatorNew} />

          <Route
            exact
            path="/app/manage"
            component={user?.isAdmin ? ManageUser : ManageCreator}
          />
          <Route exact path="/app/manageold" component={Manage} />
          <Route exact path="/app/list/:id" component={ListNFTPage} />
          {/* <Route exact path="/app/art" component={Art} /> */}
          <Route exact path="/app/categories" component={Categories} />
          <Route
            exact
            path="/app/categories/:filterParam"
            component={Categories}
          />
          <Route exact path="/app/art/:addr/:id" component={Art} />
          <Route exact path="/app/profile" component={Profile} />
          <Route exact path="/app/collections" component={Collections} />
          {user?.isAdmin && (
            <Route exact path="/app/admin" component={AdminDashboard} />
          )}
          {/*
          <Route exact path="/create" component={MintPage} />        
          {/* <Route exact path="/mint" component={Minting} />
          <Route exact path="/mint/:filterParam" component={Minting} />
          <Route exact path="/create" component={MintPage} />
        
          <Route exact path="/management" component={Manage} />
          <Route exact path="/art/:addr/:id" component={Art} />
          <Route exact path="/explore-nft" component={NFTExplorer} />
          <Route exact path="/explore-nft/:id" component={NFTExplorer} />
          <Route exact path="/agent-profile" component={AgentProfile} />
          <Route exact path="/creator-profile" component={CreatorProfile} />
          <Route exact path="/creator-dashboard" component={CreatorDashboard} />
          <Route exact path="/agent-dashboard" component={AgentDashboard} />
          <Route exact path="/daam-dashboard" component={DaamDashboard} />
          <Route exact path="/test-components" component={TestComponents} />
          <Route exact path="/admin" component={AdminDashboard} /> */}
        </Switch>
      </Layout.Content>
      <Layout.Footer
        style={{
          padding: 0
        }}
      >
        <AdminFooter />
      </Layout.Footer>
    </Layout>
  );
};

export default AuthApplication;
