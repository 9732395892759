import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";

export const getSaleHistory = async (mid) => {
  const result = await fcl.query({
    cadence: `
    // get_sale_history.cdc
// Return all (nil) or spcific sale history of TokenID

//import DAAM_Mainnet from ${keys.daam}
import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(id: UInt64?): {UInt64: ${keys.auctionHouseImport}.SaleHistory}?  {    
    return ${keys.auctionHouseImport}.getSaleHistory(id: id) // Get SaleHostory {TokenID : SaleHstory}
}
        `,
    args: (arg, types) => [arg(mid, types.UInt64)]
  });
  return result;
};
