import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { toast } from "react-toastify";
import { tx } from "./util/tx";


const CODE = fcl.cdc`
// accept_default.cdc
// Creator selects Royalty between 10% to 30%

import FungibleToken from ${keys.fungibleToken} 
import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}  from ${keys.daam}

transaction(mid: UInt64, percentage: UFix64) {
  let mid         : UInt64
  let percentage  : UFix64
  let requestGen  : &${keys.daamImport}.RequestGenerator
  let metadataGen : &${keys.daamImport}.MetadataGenerator
  let royalties   : MetadataViews.Royalties

  prepare(creator: AuthAccount) {
      self.mid     = mid
      self.percentage  = percentage
      self.requestGen  = creator.borrow<&${keys.daamImport}.RequestGenerator>( from: ${keys.daamImport}.requestStoragePath)!
      self.metadataGen = creator.borrow<&${keys.daamImport}.MetadataGenerator>(from: ${keys.daamImport}.metadataStoragePath)!

      let royalties    = [ MetadataViews.Royalty(
          receiver: creator.getCapability<&AnyResource{FungibleToken.Receiver}>(MetadataViews.getRoyaltyReceiverPublicPath()),
          cut: percentage,
          description: "Creator Royalty" )
      ]
      self.royalties = MetadataViews.Royalties(royalties)
  }

  pre { percentage >= 0.01 || percentage <= 0.3 }

  execute {
      self.requestGen.acceptDefault(mid: self.mid, metadataGen: self.metadataGen, royalties: self.royalties)
      log("Request Made")
  }
}
`

export function AcceptRoyaltie({ mid, percentage }, opts = {}) {
  if (mid == null)
    throw new Error("createSaleOffer(mid, price) -- mid required")
  if (percentage == null)
    throw new Error("createSaleOffer(mid, price) -- price required")

  const { authorization } = fcl.currentUser();
  // prettier-ignore
  // console.log(Number(mid))


  return toast.promise(
    tx([
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(mid, types.UInt64),
        fcl.arg(percentage, types.UFix64)
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([
        authorization
      ]),
      fcl.limit(keys.fclLimit)
    ], opts),
    {
      pending: 'Accepting NFT royalty in progress',
      success: 'Success 👌',
      error: 'Promise rejected 🤯'
    })
}
