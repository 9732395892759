import { send, decode, script, args, arg, cdc, query } from "@onflow/fcl";
import * as t from "@onflow/types";
import keys from "../config/keys";

export const listAuctions = async () => {
  const result = await query({
    cadence: ` 
    // get_current_auctions.cdc
// Return all auctions

import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(): {Address : [UInt64] } {    
  return ${keys.auctionHouseImport}.getCurrentAuctions() // Get auctioneers and AIDs {Address : [AID]}
}
`
  });
  return result;
};

export const CODE_listAuctionsMetaDataInfo = cdc` 
// auction_info.cdc
// Return auction info in Auction Wallet. Identified by AuctionIDs
import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(auction: Address, aid: UInt64): [AnyStruct]
{    
    let auctionHouse = getAccount(auction)
        .getCapability<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>(${keys.auctionHouseImport}.auctionPublicPath)
        .borrow()!

    let mRef = auctionHouse.item(aid) as &${keys.auctionHouseImport}.Auction{${keys.auctionHouseImport}.AuctionPublic}?
    let auctionHolder  = mRef!.auctionInfo()
    let metadataHolder = mRef!.itemInfo()

    return [auctionHolder, metadataHolder]
}
`;

export async function listAuctionsMetaDataInfo(address, auctionId) {
  if (address === null) return Promise.resolve(false);

  return send([
    script(CODE_listAuctionsMetaDataInfo),
    args([arg(address, t.Address), arg(auctionId, t.UInt64)])
  ]).then(decode);
}
