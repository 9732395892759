/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { Avatar, Space, Image, Spin, Typography } from "antd";
import {
  ListItemWrapper,
  CreatorListitemLeft,
  CreatorListitemRight,
  CreatorName,
  ContentWrapper,
  ContentItems,
  Iconscontent,
  ContentName,
  UserType,
  UserTypeName,
  PopOverControl
} from "./creatorList.styled";
import {
  BankOutlined,
  EyeOutlined,
  MailOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  ScheduleOutlined
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { inviteCreatorByAgent } from "../../../scripts/admin/inviteCreator";
import * as actions from "../../../redux/actions";
import { inviteAgentAsMinter } from "../../../scripts/admin/inviteAgentAsMinter";
import { useState } from "react";
import * as toast from "../../../common/toast";
import * as config from "../../../config/keys";
import { removeAgent } from "../../../scripts/admin/removeAgent";
import { removeCreator } from "../../../scripts/admin/removeCreator";
import agentimg from "../../../images/Avtar/agentAvtar.png";
import creatorimg from "../../../images/Avtar/CreatorAvtar.png";
import socket from "../../../common/socketClient";

const style = {
  fontSize: "19px",
  color: "#FF4040"
};

export const PopOverCom = ({ data }) => {
  const dispatch = useDispatch();
  const [isPopuoOpen, setPopupOpen] = useState(false);
  const [actionStarted, setActionStarted] = useState(false);

  const { auth } = useSelector((obj) => obj);

  const agentPopup = [
    {
      name: "Invite",
      icon: <PlusOutlined style={style} />,
      action: (data) => invite(data),
      status: "pending"
    },
    {
      name: "Message Agent",
      icon: <MailOutlined style={style} />,
      action: (data) => messageAgent(data),
      status: "all"
    },
    {
      name: "Remove Agent",
      icon: <MinusCircleOutlined style={style} />,
      action: (data) => remove(data),
      status: "all"
    },
    {
      name: "View NFT’s",
      icon: <EyeOutlined style={style} />,
      action: "viewNFT",
      status: "all"
    },
    {
      name: "Set Commission",
      icon: <ScheduleOutlined style={style} />,
      action: "setcommission",
      status: "all"
    }
  ];

  const creatorPopup = [
    {
      name: "Invite",
      icon: <PlusOutlined />,
      action: (data) => invite(data),
      status: "pending"
    },
    {
      name: "Message Creator",
      icon: <MailOutlined />,
      action: "remove",
      status: "all"
    },
    {
      name: "Remove Creator",
      icon: <MinusCircleOutlined />,
      action: (data) => remove(data),
      status: "all"
    },
    {
      name: "View NFT’s",
      icon: <EyeOutlined />,
      action: "viewNFT",
      status: "all"
    }
  ];

  const messageAgent = (data) => {
    window.open(`mailto:${data.email}`);
  };

  const onSuccess = async () => {
    const update = {
      where: { _id: data._id },
      update: {
        invitation: "sent"
      }
    };
    if (auth.userType === "agent") {
      update.update = {
        ...update.update,
        agentAddress: auth.address
      };
    }
    dispatch(
      actions.AdminActions.updateUserStatus(update, () => {
        socket.emit("invitation", { id: data._id });
      })
    );
    onComplete();
  };

  const onRemoveSuccess = () => {
    const update = {
      where: { _id: data._id },
      update: {
        isDelete: true
      }
    };
    dispatch(actions.AdminActions.deleteUser({ email: data.email }));
    // dispatch(actions.AdminActions.updateUserStatus(update));

    onComplete();
  };

  const onError = (val) => {
    toast.error(val);
    // dispatch(actions.AdminActions.deleteUser({email: data.email}))
    // const update = {
    //   where: { _id: data._id },
    //   update: {
    //     isDelete: true,
    //   },
    // };
    // dispatch(actions.AdminActions.updateUserStatus(update));
    onComplete();
  };

  const onComplete = (val) => {
    setActionStarted(false);
    if (auth.userType === "agent") {
      dispatch(actions.AdminActions.getUsersForAgent(auth?.address));
    } else {
      dispatch(actions.AdminActions.getUsers());
    }
  };

  const invite = (data) => {
    setActionStarted(true);
    if (data.userType === "agent") {
      inviteAgentAsMinter(
        { address: data.blockChainAddress[0].addr },
        { onSuccess, onError, onComplete }
      );
    } else if (data.userType === "creator") {
      if (auth.userType === "agent") {
        inviteCreatorByAgent(
          { address: data.blockChainAddress[0].addr },
          { onSuccess, onError, onComplete }
        );
      } else {
        inviteCreatorByAgent(
          { address: data.blockChainAddress[0].addr },
          { onSuccess, onError, onComplete }
        );
      }
    }
  };

  const remove = (data) => {
    setActionStarted(true);
    if (
      data.blockChainAddress &&
      data.blockChainAddress[0].addr !== null &&
      data.invitation === "accepted"
    ) {
      if (data.userType === "agent") {
        removeAgent(
          { address: data.blockChainAddress[0].addr },
          { onSuccess: onRemoveSuccess, onError, onComplete }
        );
      } else {
        removeCreator(
          { address: data.blockChainAddress[0].addr },
          { onSuccess: onRemoveSuccess, onError, onComplete }
        );
      }
    } else if (data.invitation === "sent") {
      dispatch(actions.AdminActions.deleteUser({ email: data?.email }));
      onComplete();
    } else {
      onRemoveSuccess(data);
    }
  };

  const content = () => {
    let contentList = data.userType === "creator" ? creatorPopup : agentPopup;

    return (
      <ContentWrapper>
        <Spin spinning={actionStarted}>
          {contentList
            ?.filter((x) => ["all", data.invitation].includes(x.status))
            .map((el) => (
              <ContentItems
                onClick={() => (el.action ? el.action(data) : null)}
                key={Math.random()}
              >
                <Space size={"middle"}>
                  <Iconscontent>{el.icon}</Iconscontent>
                  <ContentName>{el.name}</ContentName>
                </Space>
              </ContentItems>
            ))}
        </Spin>
      </ContentWrapper>
    );
  };

  return (
    <PopOverControl
      placement="bottomRight"
      // content={content}
      color="#FAFAFA"
      trigger="click"
      content={content}
      onVisibleChange={(visible) => setPopupOpen(visible)}
    >
      {isPopuoOpen ? (
        <div
          style={{
            border: "1px solid red",
            borderRadius: "50%",
            height: "25px",
            width: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <MoreOutlined
            color="red"
            style={{ fontSize: "18px", color: "red" }}
          />
        </div>
      ) : (
        <MoreOutlined color="red" style={{ fontSize: "18px", color: "red" }} />
      )}
    </PopOverControl>
  );
};

const CreatorList = (props) => {
  return (
    <ListItemWrapper>
      <CreatorListitemLeft>
        <Avatar
          style={{ width: "50px", height: "50px", objectFit: "none" }}
          src={
            <Image
              src={
                props.data?.avtarImg
                  ? `${config.serverURL}/files/images/${props.data?.avtarImg}`
                  : props?.type === "Agents"
                  ? agentimg
                  : creatorimg
              }
            />
          }
        />
        <Space direction="vertical" size={"small"}>
          <CreatorName>{props.data.name}</CreatorName>
          {/* <Typography.Text type="success" ellipsis>
            {props.data.email}
          </Typography.Text> */}
          {props.data?.blockChainAddress &&
            props.data?.blockChainAddress.length > 0 &&
            props.data?.blockChainAddress[0].addr !== null && (
              <CreatorName>
                <Typography.Text code>
                  {props.data.blockChainAddress[0].addr}
                </Typography.Text>
              </CreatorName>
            )}
          <span style={{ height: "8px" }}>
            <Typography.Text type="warning">
              {" "}
              {`email: ${props.data?.email}`}{" "}
            </Typography.Text>{" "}
          </span>
          <span style={{ height: "8px" }}>
            <Typography.Text type="warning">
              {" "}
              {`password: ${props.data?.password}`}
            </Typography.Text>
          </span>
        </Space>
      </CreatorListitemLeft>
      <CreatorListitemRight>
        {props?.data.approved && props?.data?.invitation === "accepted" && (
          <UserType color={props?.type === "Agents" ? `#ff4040` : `#2BCE88`}>
            <BankOutlined />
            <UserTypeName>{props?.type.slice(0, -1)}</UserTypeName>
          </UserType>
        )}
        {props?.data.invitation === "sent" && (
          <UserType className="bg-warning">
            <BankOutlined />
            <UserTypeName>Pending</UserTypeName>
          </UserType>
        )}
        <PopOverCom data={props?.data} />
      </CreatorListitemRight>
    </ListItemWrapper>
  );
};

export default CreatorList;
