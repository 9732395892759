import styled from "styled-components";
import { Button, Upload } from "antd";

export const UploadNFTBrowseFilesStyles = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  border: 1px solid #999b9e;
  padding: 16px;
  border-radius: 5px;
`;
export const UploadDropAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // border: 1px dashed #999b9e;
  background-image: repeating-linear-gradient(
      -16deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      74deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      164deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      254deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  padding: 8px;
  min-height: 370px;
  border-radius: 5px;
`;
export const UploadDropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // margin: 20px 0;
`;
export const AreaCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // & canvas {
  //   width: 100% !important;
  //   height: 350px !important;
  // }
`;
export const CenterIcon = styled.img`
  display: flex;
  width: 40px;
`;
export const CenterLabel = styled.span`
  display: flex;
  color: #2f3234;
  font: normal normal bold 20px/52px Lato;
`;
export const UploadImageButton = styled(Button)`
  width: 98px;
  font: normal normal bold 12px/15px Lato;
  letter-spacing: 0.29px;
  color: #fafafa;
  text-transform: uppercase;
  background: #fc4448 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  border-radius: 15px;
  opacity: 1;

  &:hover {
    background-color: #1a1d1f;
    color: #fff;
  }
`;

export const CustomUpload = styled(Upload)`
  display: flex;
  justify-content: center;
  & div {
    width: 100%;
    & .ant-upload.ant-upload-select-picture-card {
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      & .ant-upload {
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin: 25px 0;
        position: absolute;
        top: 0;
      }
    }
  }
`;

export const PageControls = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  //opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  
  & button {
    &:hover {
      box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
      border: 0.5px solid grey;
    }
    & :first-child {
      margin-right: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & :last-child {
      margin-left: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }
`;
