import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// remove_creator.cdc
// Used for Admin / Agents to remove Creator

import ${keys.daamImport} from ${keys.daam}

transaction(exCreator: Address)
{
    let admin   : &${keys.daamImport}.Admin{${keys.daamImport}.Agent}
    let creator : Address

    prepare(agent: AuthAccount) {
        self.admin   = agent.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath) ?? panic(exCreator.toString().concat(" is not a Creator."))
        self.creator = exCreator
    }

    // Verify is Creator
    pre { ${keys.daamImport}.isCreator(exCreator) != nil : exCreator.toString().concat(" is not a Creator. Can not remove.") }
    
    execute {
        self.admin.removeCreator(creator: self.creator)
        log("Remove Creator")
    }

    post { ${keys.daamImport}.isCreator(self.creator) == nil : self.creator.toString().concat(" has Not been removed.") } // Verify is not a Creator
}
`;

export const removeCreator = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(address, types.Address)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit),
    ],
    opts
  );
};


const CODE_ADMIN = fcl.cdc`
// remove_admin.cdc
// Two Admins can remove another Admin. Must be run by two Admins.

import ${keys.daamImport} from ${keys.daam}

transaction(exMinter: Address)
{
    let admin    : &${keys.daamImport}.Admin
    let exMinter : Address

    prepare(admin: AuthAccount) {
        self.admin = admin.borrow<&${keys.daamImport}.Admin>(from: ${keys.daamImport}.adminStoragePath) ?? panic(exMinter.toString().concat(" is not a Minter."))
	    self.exMinter = exMinter
    }

    // Verify exMinter is an Admin
    pre { ${keys.daamImport}.isMinter(exMinter) != nil : admin.address.toString().concat(" does not have Minter Key.") }

    execute {
        self.admin.removeMinter(minter: self.exMinter)
        log("Removed Minter")
    }
}
`;

export const removeAdmin = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE_ADMIN),
      fcl.args([fcl.arg(address, types.Address)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit),
    ],
    opts
  );
};
