import {
  CardStyles,
  CardImage,
  CardDetails,
  CardRow,
  CardAuthor,
  CardTitle,
  CardPrices,
  CardPrice,
  CardFlowPrice,
  CardStatus,
  CardMain,
  ImageSrc,
} from "./styles";

export default function Card({
  disable,
  image,
  author,
  title,
  price,
  flowPrice,
  authorMore,
  titleMore,
  status,
  to,
}) {
  return (
    <CardStyles style={disable ? { opacity: ".5" } : {}} >
      <CardMain to={to}>
        <CardImage>
          <ImageSrc src={image && image} />
        </CardImage>
        <CardDetails>
          {author && (
            <CardRow>
              <CardAuthor>{author}</CardAuthor>
            </CardRow>
          )}
          <CardRow style={{ paddingBottom: 0, marginBottom: 0 }}>
            {title && <CardTitle>{title}</CardTitle>}
          </CardRow>
          {(price || flowPrice) && (
            <CardPrices style={{ paddingTop: 16 }}>
              {price && <CardPrice>{price} USD</CardPrice>}
              {flowPrice && <CardFlowPrice>{flowPrice} FLOW</CardFlowPrice>}
            </CardPrices>
          )}
        </CardDetails>
      </CardMain>
      {status && (
        <>
          {disable ? (
            <CardStatus style={{ color: "grey" }} disable>
              NFT Status: Listed / Sold
            </CardStatus>
          ) : (
            <CardStatus status={status} disable>
              NFT Status:&nbsp;{status}
            </CardStatus>
          )}{" "}
        </>
      )}
    </CardStyles>
  );
}
