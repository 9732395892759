/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
//import cryptoJS from "crypto-js";
import {
  Row,
  Col,
  Input, // Button,
  Form,
  Button,
} from "antd";
import Image from "../../components/Image";
import {
  LoginWrapper,
  ImageWrapper,
  Paragraph,
  FormItem,
  LoginButton,
  RegisterText,
} from "./Login.styled";
import Logo from "../../images/worded_logo.svg";
import loginImage from "../../images/login/login1.png";
import { loginActions } from "../../redux/actions";
import * as Routes from "../../Routes";
import { AuthContext } from "../../context/authContext";
import * as ROUTES from "../../Routes";
import keys from "../../config/keys";
import io from "../../common/socketClient";
import socket from "../../common/socketClient";

const LoginContainer = (props) => {
  const history = useHistory();
  const { user, setUser } = useContext(AuthContext);

  // const login = useQuery(loginActions.SIGN_UP, { variables: {} });
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState(null);
  // const [postData, setPostData] = useState(null);
  // const [login, { qdata, qloading, qerror }] = useLazyQuery(
  //   loginActions.Login,
  //   {
  //     fetchPolicy: "network-only",
  //     errorPolicy: "all",
  //     variables: {
  //       ...postData,
  //       email: "nikhil.mobilefirst@gmail.com",
  //     },
  //     onCompleted: (res) => {
  //         if (res && res.userLogin.length <= 0) {
  //         toast("Invalid Username or password", {
  //           type: toast.TYPE.SUCCESS,
  //           autoClose: 2000,
  //           closeButton: false, // Remove the closeButton
  //           theme: "colored",
  //           icon: false,
  //         });
  //       } else {
  //         history.push("/create");
  //       }
  //     },
  //     onError: (res) => {
  //         toast(JSON.stringify(res), {
  //         type: toast.TYPE.ERROR,
  //         autoClose: 2000,
  //         closeButton: false, // Remove the closeButton
  //         theme: "colored",
  //         icon: false,
  //       });
  //     },
  //   }
  // );
  const { auth } = useSelector((obj) => obj);

  // const [createAccount, { data, loading, error }] = useMutation(
  //   loginActions.SIGN_UP,
  //   {
  //     variables: {
  //       ...loginData,
  //       email: "nikhil.mobilefirst@gmail.com",
  //       isAgent: false,
  //       isCreator: false,
  //       isAdmin: false,
  //     },
  //     onCompleted: (res) => {
  //       toast("Account Created", {
  //         type: toast.TYPE.SUCCESS,
  //         autoClose: 2000,
  //         closeButton: false, // Remove the closeButton
  //         theme: "colored",
  //         icon: false,
  //       });
  //     },
  //     onError: (res) => {
  //       toast(JSON.stringify(res), {
  //         type: toast.TYPE.ERROR,
  //         autoClose: 2000,
  //         closeButton: false, // Remove the closeButton
  //         theme: "colored",
  //         icon: false,
  //       });
  //     },
  //   }
  // );

  // const onCreateAccount = () => {
  //   createAccount();
  //   // dispatch(
  //   //   loginActions.userLogin({
  //   //     ...loginData,
  //   //     email: "nikhil.mobilefirst@gmail.com",
  //   //     isAgent: false,
  //   //     isCreator: false,
  //   //     isAdmin: false,
  //   //   })
  //   // );
  // };

  const onLogin = () => {
    // setPostData(loginData);
    // login(loginData.email || "", loginData.password || "");
    // dispatch(loginActions.UserLogin({email:'riddhi.mobilefirst@gmail.com',password :'234234'}))
    dispatch(
      loginActions.UserLogin({
        email: loginData.username,
        password: loginData.password,
      })
    );
  };

  useEffect(async () => {
    if (auth?._id) {
      // const socket = await io(keys.serverURL, {
      //   auth: {
      //     address: auth.address,
      //     id: auth._id,
      //     email: auth.email
      //   }
      // });
      // //socket.connect();
      // socket.on("connect", () => {
      //   console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      //   setUser({ ...auth, socketId: socket.id });
      //   dispatch(loginActions._auth({ ...auth, socketId: socket.id }));
      //   history.push(Routes.DASHBOARD);
      // });
      setUser({ ...auth, socketId: socket.id });
      dispatch(loginActions._auth({ ...auth, socketId: socket.id }));
      history.push(Routes.DASHBOARD);
      socket.emit("clientInfo", { ...auth, socketId: socket.id });

      // if (auth.isAgent) history.push(Routes.AGENT);
      // else if (auth.isCreator) history.push(Routes.CREATOR);
      // else if (auth.isCreator) history.push("/");
    }
  }, [auth]);

  return (
    <>
      <Row align="top" justify="center">
        <Col
          lg={8}
          xl={8}
          md={8}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Row
            align="top"
            justify="center"
            className="pt-2 mt-2"
            style={{ width: "100%" }}
          >
            <Col
              span={20}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={Logo} w="200px" />
            </Col>
            <Col lg={15} xl={15} md={15} sm={20} xs={20}>
              <LoginWrapper>
                <Col>
                  <Row
                    style={{ align: "center", height: "50px" }}
                    justify="center"
                  >
                    <Paragraph style={{ align: "center" }}>
                      Please Login
                    </Paragraph>
                  </Row>
                  <Row
                    style={{ align: "center", height: "100px" }}
                    justify="center"
                    gutter={[3]}
                  >
                    <RegisterText
                      style={{
                        align: "center",
                        justify: "center",
                        marginBottom: "45",
                        marginTop: "10px",
                      }}
                    >
                      <span className="rtext">{`Don't have an account? `}</span>
                      <Link to={Routes.SIGN_UP}>
                        <span className="rlink">Register Here</span>
                      </Link>
                    </RegisterText>
                  </Row>
                </Col>

                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  onFinish={onLogin}
                >
                  <FormItem
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        border: "1px solid #999B9E",
                        borderRadius: "4px",
                        opacity: 1,
                        height: "45px",
                      }}
                      placeholder={"Email"}
                      onChange={(e) =>
                        setLoginData((prev) => ({
                          ...prev,
                          username: e.target.value,
                        }))
                      }
                    />
                  </FormItem>
                  <FormItem
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{
                        border: "1px solid #999B9E",
                        borderRadius: "4px",
                        opacity: 1,
                        height: "40px",
                      }}
                      placeholder="Password"
                      onChange={(e) =>
                        setLoginData((prev) => ({
                          ...prev,
                          password: e.target.value,
                          // password: cryptoJS.SHA256(
                          //   e.target.value,
                          //   "DAAMAgency"
                          // ).toString(),
                        }))
                      }
                    />
                  </FormItem>

                  <Form.Item>
                    <LoginButton htmlType="submit" inverse={"true"}>
                      Login
                    </LoginButton>
                  </Form.Item>

                  <Form.Item
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    <Button
                      style={{
                        textAlign: "center",
                        font: "normal normal 500 14px/76px Lato",
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "#FF4040",

                        marginTop: "-25px",
                        border: "none",
                      }}
                      onClick={() => history.push(Routes.ForgotPassword)}
                    >
                      Forgot your password ?
                    </Button>
                  </Form.Item>

                  <Form.Item
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    <Button
                      style={{
                        textAlign: "center",
                        font: "normal normal 500 14px/76px Lato",
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "#FF4040",

                        marginTop: "-25px",
                        border: "none",
                      }}
                      onClick={() => history.push("/")}
                    >
                      🡰 Back to Homepage
                    </Button>
                  </Form.Item>
                </Form>
              </LoginWrapper>
            </Col>
          </Row>
        </Col>

        <Col lg={14} xl={14} md={14} sm={24} xs={24}>
          <ImageWrapper>
            <img
              src={loginImage}
              alt="login"
              style={{ width: "100%", height: "100vh" }}
            />
          </ImageWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LoginContainer;
