import styled from "styled-components";

export const ListItem = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    background-color:white;
    box-shadow:var(--box-shadow-light);
    padding:16px;
    border-radius:5px;
    margin-top:16px;
    &:first-of-type {
        margin-top:0;
    }
`
export const Title = styled.div`
    display:flex;
    color:black;
    font-size:18px;
    font-weight:600;
    margin-left:24px;
`
export const Options = styled.div`
    display:flex;
    align-items:center;
    margin-left:auto;
`
export const OptionContainer = styled.div`
    display:flex;
    position:relative;
`
export const OptionTrigger = styled.div`
    display:flex;
    width:24px;
    height:24px;
    background:#ccc;
    &:hover {
        cursor:pointer;
    }
`