import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// agent_submit.cdc
// Agent uses to submit Metadata for their Creator

import Categories    from ${keys.categories}
import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}  from ${keys.daam}

// argument have two modes:
// when ipfs = true; first arument is cid, second argument is path 
// when ipfs = false; first arument thumbnail String, second argument is thumbnailType and can not be nil
pub fun setFile(ipfs: Bool, string_cid: String, type_path: String?): {MetadataViews.File} {
    pre { ipfs || !ipfs && type_path != nil }
    if ipfs { return MetadataViews.IPFSFile(cid: string_cid, path: type_path) }
    switch type_path! {
        case "http": return MetadataViews.HTTPFile(url: string_cid)
        default: return ${keys.daamImport}.OnChain(file: string_cid)
    }
}

transaction(creator: Address, name: String, max: UInt64?, categories: [String], description: String, misc: String, // Metadata information
    ipfs_thumbnail: Bool, thumbnail_cid: String, thumbnailType_path: String, // Thumbnail setting: IPFS, HTTP(S), FILE(OnChain)
    ipfs_file: Bool, file_cid: String, fileType_path: String,                // File setting: IPFS, HTTP(S), FILE(OnChain)
    interact: AnyStruct?)
{    
    let creator     : Address   
    let metadataGen : &${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint, ${keys.daamImport}.MetadataGeneratorPublic}
    let agent       : &${keys.daamImport}.Admin{${keys.daamImport}.Agent}

    let name        : String
    let max         : UInt64?
    var categories  : [Categories.Category]
    let interact    : AnyStruct?
    let description : String
    let misc        : String
    let thumbnail   : {String : {MetadataViews.File}}
    let file        : {String : MetadataViews.Media}

    prepare(agent: AuthAccount) {
        self.creator      = creator
        self.metadataGen  = getAccount(self.creator)
            .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint, ${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath).borrow()!
        self.agent        = agent.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath)!
        self.name         = name
        self.max          = max
        self.description  = description
        self.interact     = interact
        self.misc         = misc
        self.thumbnail    = {thumbnailType_path : setFile(ipfs: ipfs_thumbnail, string_cid: thumbnail_cid, type_path: fileType_path)}
        let fileData      = setFile(ipfs: ipfs_file, string_cid: file_cid, type_path: fileType_path)
        let fileType      = ipfs_file ? "ipfs" : fileType_path
        self.file         = {fileType : MetadataViews.Media(file: fileData, mediaType: fileType)}
        self.categories = []
        for cat in categories {
            self.categories.append(Categories.Category(cat))
        }
    }

    execute {
        let metadata <- self.agent.createMetadata(creator: self.creator, name: self.name, max: self.max, categories: self.categories, description: self.description, misc: self.misc,
            thumbnail: self.thumbnail, file: self.file, interact: self.interact)
        let mid = metadata.mid
        self.metadataGen.returnMetadata(metadata: <- metadata)
        log("Metadata Submitted: ".concat(mid.toString()))
    }
}
`;

export const mintAsAgent = async (mintResult, opts = {}) => {

  const {
    mintForCreator,
    title,
    description,
    series,
    categories,
    data,
    thumbnail,
    file,
    fileName
  } = mintResult;

  if (series == null)
    throw new Error("createSaleOffer(mid, price) -- series required");
  if (data == null)
    throw new Error("createSaleOffer(mid, price) -- data required");

  const { authorization } = fcl.currentUser();

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(mintForCreator, types.Address),
        fcl.arg(title, types.String),
        fcl.arg(series === 0 ? null : series, types.Optional(types.UInt64)),
        fcl.arg(categories, types.Array(types.String)),
        fcl.arg(description, types.String),

        fcl.arg(JSON.stringify({ title, description }), types.String),

        fcl.arg(false, types.Bool),
        fcl.arg(thumbnail, types.String),
        fcl.arg(fileName.split(".").pop(), types.String),

        fcl.arg(false, types.Bool),
        fcl.arg(file, types.String),
        fcl.arg(fileName.split(".").pop(), types.String),

        fcl.arg(null, types.Optional(types.Struct))
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
