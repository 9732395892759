import React from "react";
import { Row, Col, Avatar } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { DashboardRow } from "../Dashboard";
import avatarImg from "../../images/profile_placeholder.png";
import { BoxWrapper, TitleTopSeller } from "./TopSellers.styled";

const categoriesInfo = [
  { title: "B1GDD4DD1", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD2", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD3", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD4", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD5", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD6", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD7", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD8", avatar: <Avatar src={avatarImg} alt="avatar" /> },

  { title: "B1GDD4DD9", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD10", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD11", avatar: <Avatar src={avatarImg} alt="avatar" /> },
  { title: "B1GDD4DD12", avatar: <Avatar src={avatarImg} alt="avatar" /> },
];

const TopSellers = (props) => {
  return (
    <>
      <TitleTopSeller>
        Top Creators{" "}
        <CaretDownOutlined
          style={{
            // "text-align": "left",
            // font: 'normal normal normal 34px/41px "Font Awesome 5 Pro"',
            padding: "10px 0 0 10px",
            display: "flex",
            alignSelf: "center",
          }}
        />
      </TitleTopSeller>
      <DashboardRow style={{ paddingTop: 34 }}>
        <Row justify="center">
          {categoriesInfo.map((categoryInfo, index) => {
            return (
              <Col xl={4} lg={4} md={8} sm={12} xs={24} key={index}>
                <BoxWrapper>
                  {categoryInfo.avatar}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: "bold",
                      font: "normal normal 600 26px/39px Oswald",
                      color: "#1A1D1F",
                    }}
                  >
                    {categoryInfo.title}
                  </span>
                </BoxWrapper>
              </Col>
            );
          })}
        </Row>
      </DashboardRow>
    </>
  );
};

export default TopSellers;
