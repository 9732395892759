/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  FlowPrice,
  NftDesc,
  NftName,
  NftPrice,
  Price,
  SliderElement,
  SliderInfoWrapper,
  SliderWrapper,
  SubTitleSlider,
  TitleSlider,
  ViewDetailsContainer,
  ViewDetailsLeft,
  ViewDetailsRight,
  ViewPiece,
  ViewPieceButton
} from "./Slider.styled";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./horizontal.css"; // customise the default slider css

const SliderShow = (props) => {
  const [sliderData, setSliderData] = useState(props?.data[0] || null);
  return (
    <SliderWrapper>
      <Slider
        autoplay={6000}
        onSlideChange={(event) => {
          setSliderData(props?.data[event.slideIndex]);
        }}
        infinite
      >
        {props?.data
          ?.filter((x) => x.metaDataInfo !== null)
          .map(({ auctionInfo, metaDataInfo }) => {
            const { thumbnail } = metaDataInfo;
            //const { auctionID, buyNow, mid } = auctionInfo;

            return (
              <SliderElement
                key={Math.random()}
                image={thumbnail[Object.keys(thumbnail)[0]].file}
              >
                {/* <SliderInfoWrapper>
                  <TitleSlider>{edition?.name}</TitleSlider>
                  <SubTitleSlider>{item?.subTitle}</SubTitleSlider>
                  <br />

                  <Price>{parseInt(buyNow)}</Price>
                  <FlowPrice>{(parseInt(buyNow) / 12).toFixed(2)}</FlowPrice>
                  <br />
                  <ViewPieceButton type="primary" size="large">
                    View piece
                  </ViewPieceButton>
                </SliderInfoWrapper> */}
              </SliderElement>
            );
          })}
      </Slider>
      {sliderData && (
        <ViewDetailsContainer>
          <ViewDetailsLeft>
            <NftName>{sliderData?.metaDataInfo?.edition?.name}</NftName>
            <NftDesc ellipsis={{ rows: 1, expandable: true, symbol: "more"}} level={4}>
              {sliderData?.metaDataInfo?.description.replaceAll(
                "(\r\n|\n)",
                "<br>"
              )}
            </NftDesc>
            <NftPrice color="#ff4040">
              {parseInt(sliderData?.auctionInfo.buyNow)}
            </NftPrice>
            <NftPrice color="#999b9e">
              {(parseInt(sliderData?.auctionInfo.buyNow) / 12).toFixed(2)}
            </NftPrice>
          </ViewDetailsLeft>
          <ViewDetailsRight>
            <ViewPiece>View Piece</ViewPiece>
          </ViewDetailsRight>
        </ViewDetailsContainer>
      )}
    </SliderWrapper>
  );
};

export default SliderShow;
