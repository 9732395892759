import { Menu, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CardStyles = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 0.5em 0.25em;

  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
`;


// export const CardMain = styled.div`
export const CardMain = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000b;
  border: 1px solid #e6e6e6;

  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
`;
export const CardImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25vh;
  padding: 10px;
  background-color: #f7f7f7;
  /* background-image: url(${(props) => props && props.src}); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #cdd0d3;
  min-height: 175px;
`;
export const ImageSrc = styled.img`
  // width: 100%;
  min-height: 100%;
  max-width: 100%;
  // background-position: center;
  // background-size: cover;
`;
export const CardCollectionImages = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CardCollectionImage = styled.div`
  display: flex;
  width: 50%;
  height: calc(14vh);
  background-color: #ccc;
  background-image: url(${(props) => props && props});
  background-position: center;
  background-size: cover;
`;
export const CardRow = styled.h3`
  display: flex;
  align-items: center;
`;
export const CardAuthor = styled.h4`
  display: flex;

  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const AuthorMore = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  margin-left: auto;
`;
export const TitleMore = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  margin-left: auto;
`;
export const CardTitle = styled(Typography.Text)`
  display: flex;
  text-align: left;
  font: normal normal bold 15px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const CardPrices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;
export const CardPrice = styled.span`
  display: flex;
  text-align: left;
  font: normal normal bold 13px/19px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  opacity: 1;
`;
export const CardFlowPrice = styled.span`
  display: flex;

  text-align: left;
  font: normal normal bold 13px/19px Lato;
  letter-spacing: 0px;
  color: #595e62;
  opacity: 1;
`;
export const CardDetails = styled.span`
  display: flex;
  flex-direction: column;
  background-color: white;
  //   padding-top: 16px;
  //   padding-bottom: 16px;
  //   padding-left: 24px;
  //   padding-right: 24px;
  padding: 10px 12px;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  border: 1px solid #e6e6e6;
`;
export const CardStatus = styled.span`
  display: flex;
  flex-direction: column;
  background-color: white;
  font-weight: 600;
  //   padding-top: 16px;
  //   padding-bottom: 16px;
  //   padding-left: 24px;
  //   padding-right: 24px;
  // padding:10px 12px;
  border-radius: 5px;
  font-family: Lato;
  font: normal normal 600 19px/24px Lato;
  margin-top: 8px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-light);
  color: ${(props) => (props.status === "Submited" ? "orange" : "green")};
`;
export const CardPieces = styled.div`
  display: flex;
  color: #ff4040;
  font-size: 14px;
  margin-bottom: 0;
`;
export const CardPiecesCount = styled.span`
  font-weight: 600;
`;

export const MenuItem = styled(Menu.Item)`
  font: normal normal normal 12px Lato;
  color: #2f3234;
  padding: 2px 8px;
  &:hover {
    background-color: #fc4448;
    color: #fff;
  }
`;
