import { Popover } from "antd";
import styled from "styled-components";

export const ListItemWrapper = styled.div`
  width: 100%;
  // height: 69px;
  background: #ffffff;
  margin-top: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000001a;
  opacity: 1;
`;

export const CreatorListitemLeft = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-evenly;
  margin-left: 15px;
`;
export const CreatorListitemRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const UserType = styled.div`
  /* width: 77px;
  height: 26px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: var(--unnamed-color-ff4040) 0% 0% no-repeat padding-box;
  /* background: #ff4040 0% 0% no-repeat padding-box; */
  background: ${(props) => props?.color && `${props.color}`} 0% 0% no-repeat
    padding-box;
  border-radius: 98px;
  opacity: 1; //0.53
  padding: 4px 10px;
`;
export const UserTypeName = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  margin-left: 3px;
`;
export const CreatorName = styled.span`
  margin-left: 15px;
  height: 19px;
  /* UI Properties */
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const IconImage = styled.img``;
export const ContentWrapper = styled.div`
  // width: 153px;
`;
export const ContentItems = styled.span`
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0px;
  }
`;
export const Iconscontent = styled.span`
  margin-right: 5px;
`;
export const ContentName = styled.span`
  /* UI Properties */
  font: normal normal normal 16px/22px Lato;
  letter-spacing: 0px;
  color: #2f3234;
`;

export const PopOverControl = styled(Popover)`
  & .ant-popover-content {
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000019;
    border: 1px solid #e9e9e9;
  }
`;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
// export const ListItemWrapper = styled.div``;
