/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
  DashboardHeader,
  DashboardTitle,
  DashboardHeaderMore,
  ContentContainer,
  ContentItem
} from "../Dashboard";
import { HomePageCardsWrapper } from "../../containers/home/Home.styled";
import Card from "../Card";
import avatarImg from "../../images/profile_placeholder.png";
import { Avatar, Row, Col, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Fire from "../../images/Fire.png";
import Coin from "../../images/Coin.png";
import Certificate from "../../images/Certificate.png";
import Star from "../../images/Starpng.png";

import { ImageCon } from "../SliderShow/Slider.styled";
import { useAuction } from "../../context/auctions.context";
import { Collection } from "../collection";
import { useDispatch, useSelector } from "react-redux";
import { AdminActions } from "../../redux/actions";

export default function HomeLatestCards(props) {
  const [cat, setCat] = useState({ name: "Latest", image: Certificate });
  const { auctions, getAuctionsforPublicURL } = useAuction();
  const [flowPrice, setFlowPrice] = useState(1);
  const [auctionData, setAuctionData] = useState(
    auctions?.sort((a, b) => (Number(a.start) < Number(b.start) ? 1 : -1))
  );

  const dispatch = useDispatch();
  const { userList } = useSelector((obj) => obj);

  useEffect(async () => {
    dispatch(AdminActions.getUsers());
    getAuctionsforPublicURL();
    const fprice = await AdminActions.get_exchange_rate();
    setFlowPrice(fprice);
  }, []);
  const content = () => {
    return (
      <>
        <ContentContainer>
          <ContentItem
            onClick={() => setCat({ name: "Popularity", image: Fire })}
          >
            <img src={Fire} alt={"Popularity"} /> Popularity
          </ContentItem>
          <ContentItem onClick={() => setCat({ name: "Price", image: Coin })}>
            <img src={Coin} alt={"Price"} /> Price
          </ContentItem>
          <ContentItem
            onClick={() => setCat({ name: "Latest", image: Certificate })}
          >
            <img src={Certificate} alt={"Latest"} /> Latest
          </ContentItem>
          <ContentItem
            onClick={() => setCat({ name: "Featured", image: Star })}
          >
            <img src={Star} alt={"Featured"} /> Featured
          </ContentItem>
        </ContentContainer>
      </>
    );
  };

  return (
    <HomePageCardsWrapper>
      <DashboardHeader>
        <DashboardTitle>
          <ImageCon>
            <img src={cat.image} width="34px" height="34px" alt={"category"} />
          </ImageCon>
          <span>{cat.name}</span>
        </DashboardTitle>
        <DashboardHeaderMore>
          {/* <Image src={MoreCaret} /> */}
          <Popover placement="bottomRight" content={content} trigger="click">
            <EllipsisOutlined style={{ fontSize: "35px" }} />
          </Popover>
        </DashboardHeaderMore>
      </DashboardHeader>

      <Row gutter={[16, 16]}>
        {/* {latestCards.map(({ author, title, price, flowPrice, avatar }) => {
          return (
            <Col xl={6} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
              <Card
                author={author}
                title={title}
                avatar={avatar}
                price={price}
                flowPrice={flowPrice}
              />
            </Col>
          );
        })} */}

        {auctionData
          ?.filter((x) => x.metaDataInfo !== null)
          .map(({ auctionInfo, metaDataInfo }) => {
            const { creatorInfo, edition, thumbnail } = metaDataInfo;
            const { auctionID, buyNow, mid } = auctionInfo;
            const filterAdd = userList?.users?.find((x) => {
              return x.address === creatorInfo?.creator;
            });
            console.log(buyNow,flowPrice, buyNow*flowPrice)
            return (
              // <BoxWrapper key={Math.random()}>
              <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
                <Collection
                  to={{
                    pathname: `/art/${creatorInfo?.creator}/${auctionID}`,
                    state: true
                  }}
                  title={edition?.name}
                  price={parseInt(buyNow)}
                  flowPrice={(parseInt(buyNow) * flowPrice).toFixed(2)}
                  image={thumbnail[Object.keys(thumbnail)[0]].file}
                  avatarImage={filterAdd?.avtarImg}
                  authorLink={`/details/${creatorInfo?.creator}`}
                  data={{ auctionInfo, metaDataInfo }}
                />
              </Col>
              // </BoxWrapper>
            );
          })}
      </Row>
      <br />
    </HomePageCardsWrapper>
  );
}
