/* eslint-disable no-unused-vars */
import * as types from "../constTypes";
import fashion from "../../images/icons/categories/fashion1.svg";
import physicalart from "../../images/icons/categories/phart1.svg";
import video from "../../images/icons/categories/video1.svg";
import audio from "../../images/icons/categories/audio1.svg";
import digitalart from "../../images/icons/categories/dg1.svg";
import photography from "../../images/icons/categories/photography1.svg";
import sculpture from "../../images/icons/categories/sc1.svg";

const digital = [
  {
    label: "Digital Art",
    value: "Image",
    category: "Digital",
    icon: digitalart,
    url: "/categories/Image",
    extension: ["jpeg", "jpg", "png", "gif", "webp","tif","tiff","pdf"],
  },
  {
    label: "Video",
    value: "Video",
    category: "Digital",
    icon: video,
    url: "/categories/Video",
    extension: ["mp4", "mov", "wmv", "avi", "avchd", "MPEG-2", "mkv","pdf"],
  },
  {
    label: "Audio",
    value: "Audio",
    category: "Digital",
    icon: audio,
    url: "/categories/Audio",
    extension: ["m4a", "flac", "mp3", "mp4", "wav", "wma", "aac","pdf"],
  },
  {
    label: "Photography",
    value: "Photography",
    category: "Digital",
    icon: photography,
    url: "/categories/Photography",
    extension: ["jpeg", "jpg", "png", "gif", "eps", "psd", "pdf", "ai","pdf"],
  },
  // {
  //   label: "VRAR",
  //   value: "vrar",
  //   icon: headVr,
  //   url: "/categories/vrar",
  //   extension: [],
  // },
];
const physical = [
  {
    label: "Physical Art",
    value: "Physical",
    category: "Physical",
    icon: physicalart,
    url: "/categories/Physical",
    extension: ["jpeg", "jpg", "png", "gif", "webp","mp4", "mov", "wmv", "avi", "avchd", "MPEG-2", "mkv","pdf"],

    // extension: [],
  },
  {
    label: "Sculpture",
    value: "Sculpture",
    category: "Physical",
    icon: sculpture,
    url: "/categories/Sculpture",
    extension: ["jpeg", "jpg", "png","pdf"],
  },
  {
    label: "Fashion",
    value: "Fashion",
    category: "Physical",
    icon: fashion,
    url: "/categories/Fashion",
    extension: ["jpeg", "jpg", "png","pdf"],
  },
  {
    label: "Real Estate",
    value: "RealEstate",
    category: "Physical",
    icon: sculpture,
    url: "/categories/RealEstate",
    extension: ["jpeg", "jpg", "png","pdf"],
  },
];
// const genre = [
//   { label: "Rare", value: "rare", icon: gem, url: "/categories/rare" },
//   {
//     label: "Erotique",
//     value: "erotique",
//     icon: smileWink,
//     url: "/categories/erotique",
//     extension: [],
//   },
//   {
//     label: "Comedy",
//     value: "comedy",
//     icon: theaterMasks,
//     url: "/categories/comedy",
//     extension: [],
//   },
// ];

const initialState = { digital, physical };

export const filterList = (state = initialState, action) => {
  switch (action.type) {
    case types.commonTypes.FILTERS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
