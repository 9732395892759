import styled from "styled-components";
import { Card, Form } from "antd";

export const PageTitleStyles = styled.div``;

export const ModalContainer = styled.div``;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
`;
export const Title = styled.h3`
  text-align: left;
  font: normal normal 500 34px Oswald;
  letter-spacing: 0.54px;
  color: #2f3234;
  opacity: 1;
`;
export const CloseIcon = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border: 1px solid var(--unnamed-color-cdd0d3);
  border: 1px solid #cdd0d3;
  opacity: 1;
  cursor: pointer;
`;
export const ModalBody = styled.div``;
export const UploadContainerArea = styled.div`
  padding: 10px;
  margin: auto;
  width: 375px;
  height: 151px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #999b9e;
  border-radius: 5px;
  opacity: 1;
`;
export const InputContent = styled.input`
  /* margin: auto; */
  padding-left: 20px;
  width: 375px;
  height: 54px;
  border: 1px solid var(--unnamed-color-999b9e);
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #999b9e;
  border-radius: 5px;
  opacity: 1;
  ::placeholder {
    text-align: left;
    font: normal normal bold 16px/76px Lato;
    letter-spacing: 0.51px;
    color: #1a1d1f;
    opacity: 1;
  }
`;
export const CollectionNameInputContainer = styled.div`
  margin-left: 55px;
  margin-top: 20px;
`;
export const CollectionNameFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const DescriptionContent = styled.textarea`
  padding: 20px;
  width: 375px;
  height: 165px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #999b9e;
  border-radius: 5px;
  opacity: 1;
  margin-top: 20px;
  resize: none;
  ::placeholder {
    text-align: left;
    font: normal normal bold 16px/30px Lato;
    letter-spacing: 0.51px;
    color: #1a1d1f;
    opacity: 1;
  }
`;

export const CreateButton = styled.button`
  margin-left: 55px;
  margin-top: 20px;
  width: 375px;
  height: 50px;
  border: 1px solid var(--unnamed-color-1a1d1f);
  background: #1a1d1f 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  opacity: 1;
`;
export const ButtonTitle = styled.span`
  text-align: center;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;
`;

// upload collection commeted out
// <Row align="middle" justify="end">
//   <Col span={22}>
//     <PageTitle
//       m={"1em"}
//       style={{
//         font: "normal normal 600 34px Oswald",
//         letterSpacing: "0.48px",
//       }}
//     >
//       Upload Collection
//     </PageTitle>
//     {/* <Upload
//       action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//       listType="picture-card"
//     >
//       Upload
//     </Upload> */}
//     <UploadBrowseFiles
//       name="file"
//       onChange={filechange}
//       fileList={fileList}
//       files={file}
//       action={`${config.serverURL}/files/upload`}
//       onUploadSuccess={onAvtarUploadSuccess}
//     />
//     <Form
//       name="basic"
//       labelCol={{ span: 8 }}
//       wrapperCol={{ span: 16 }}
//       initialValues={{ remember: true }}
//       onFinish={onFinish}
//       //   onFinishFailed={onFinishFailed}
//       autoComplete="off"
//     >
//       <Form.Item
//         name="collectionName"
//         rules={[
//           { required: true, message: "Please input collection Name!" },
//         ]}
//       >
//         <Input placeholder="Collection Name" />
//       </Form.Item>

//       <Form.Item name="description">
//         <Input placeholder="Description" />
//       </Form.Item>

//       <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
//         <Button type="primary" htmlType="submit" disabled={disableBtn}>
//           Create
//         </Button>
//       </Form.Item>
//     </Form>
//   </Col>
//   <Col span={1}>
//     <Button order="tertiary" size="regular" ml="16px">
//       <CloseOutlined />
//     </Button>
//   </Col>
// </Row>

export const CardCreatorList = styled(Card)`
  border: none;
  cursor: pointer;
  & :hover {
    background-color: #ff4040;
    color: #fff;
    & .ant-card-meta-title,
    .ant-card-meta-description {
      color: #fff;
    }
  }
  & .ant-card-body {
    & :hover {
      background-color: #ff4040;
      color: #fff;
    }
    padding: 5px;
    & .ant-card-meta {
      display: flex;
      align-items: center;
      & .ant-card-meta-avatar {
        &. ant-avatar {
          height: 50px !important;
          width: 50px !important;
        }
      }
    }
  }
`;
