import React from "react";
import { MintingWrapper } from "../containers/minting/Minting.styled";
import SubmitStep from "../containers/minting/submitStep";
import { MintProvider } from "../context/mintContext";

const ListNFTPage = () => {
  return (
    <MintProvider>
      <MintingWrapper>
        <SubmitStep />
      </MintingWrapper>
    </MintProvider>
  );
};

export default ListNFTPage;
