


import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// answer_admin_invite.cdc
// Answer the invitation to be an Admin.

import ${keys.daamImport} from ${keys.daam}

transaction(submit: Bool) {
  let signer: AuthAccount
  let submit: Bool

  prepare(signer: AuthAccount) {
      self.signer = signer
      self.submit = submit     
  }

  execute {
      let admin <- ${keys.daamImport}.answerAdminInvite(newAdmin: self.signer, submit: self.submit)
      if admin != nil {
          let old_admin <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.adminStoragePath)
          self.signer.save<@${keys.daamImport}.Admin>(<- admin!, to: ${keys.daamImport}.adminStoragePath)
          let adminRef = self.signer.borrow<&${keys.daamImport}.Admin>(from: ${keys.daamImport}.adminStoragePath)!
          destroy old_admin

          let old_request <- self.signer.load<@AnyResource>(from: ${keys.daamImport}.requestStoragePath)
          let requestGen <-! adminRef.newRequestGenerator()
          self.signer.save<@${keys.daamImport}.RequestGenerator>(<- requestGen, to: ${keys.daamImport}.requestStoragePath)
          destroy old_request
          
          log("You are now a ${keys.daamImport}.Admin: ".concat(self.signer.address.toString()) )
      } else {
          destroy admin
          log("Thank You for your consoderation.")
      }
  }
}
`

export const answerAdminInvite = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx([
    fcl.transaction(CODE),
    fcl.args([fcl.arg(true, types.Bool)]),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts)
}