import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import {
  ButtonNFT,
  DivExplore,
  IconNFT,
  HandPeaceWrapper
} from "./Explore.styled";
import { ExploreNFTWrapper } from "../../containers/home/Home.styled";
import { Typography } from "antd";
import { DashboardRow } from "../Dashboard";
import Image from "../../components/Image";
import handPeace from "../../images/hand-peace2.png";
import vestPatches from "../../images/icons/minting/vest-patches.png";
import cameraRetro from "../../images/icons/minting/camera-retro.png";
import camcorder from "../../images/icons/minting/camcorder.png";
import image from "../../images/icons/minting/image.png";
import palette from "../../images/icons/minting/palette.png";
import turntable from "../../images/icons/minting/turntable.png";
import userAlien from "../../images/icons/minting/user-alien.png";

const { Title } = Typography;

const categoriesInfo = [
  {
    name: "DIGITAL",
    buttons: [
      // { title: "Digital Art", icon: <IconNFT src={image} alt="image" /> },
      // { title: "Audio", icon: <IconNFT src={turntable} alt="turntable" /> },
      // { title: "Video", icon: <IconNFT src={camcorder} alt="camcorder" /> },
      // {
      //   title: "Photography",
      //   icon: <IconNFT src={cameraRetro} alt="Photography" />,
      // },
      // { title: "VR/AR", icon: <IconNFT src={headVr} alt="headVr" /> },
      {
        label: "Digital Art",
        value: "digitalart",
        icon: <IconNFT src={image} alt="image" />,
        url: "/categories/digitalart",
        extension: ["jpeg", "jpg", "png", "gif", "webp", "tif"]
      },
      {
        label: "Video",
        value: "video",
        icon: <IconNFT src={camcorder} alt="camcorder" />,
        url: "/categories/video",
        extension: ["mp4", "mov", "wmv", "avi", "avchd", "MPEG-2", "mkv"]
      },
      {
        label: "Audio",
        value: "audio",
        icon: <IconNFT src={turntable} alt="turntable" />,
        url: "/categories/audio",
        extension: ["m4a", "flac", "mp3", "mp4", "wav", "wma", "aac"]
      },
      {
        label: "Photography",
        value: "photography",
        icon: <IconNFT src={cameraRetro} alt="Photography" />,
        url: "/categories/photography",
        extension: ["jpeg", "jpg", "png", "gif", "eps", "psd", "pdf", "ai"]
      }
    ]
  },
  {
    name: "PHYSICAL",
    buttons: [
      // { title: "Physical Art", icon: <IconNFT src={palette} alt="image" /> },
      // { title: "Sculpture", icon: <IconNFT src={userAlien} alt="userAlien" /> },
      // {
      //   title: "Fashion",
      //   icon: <IconNFT src={vestPatches} alt="vestPatches" />,
      // },
      // {
      //   title: "Photography",
      //   icon: <IconNFT src={cameraRetro} alt="Photography" />,
      // },
      {
        label: "Physical Art",
        value: "physicalart",
        icon: <IconNFT src={palette} alt="image" />,
        url: "/categories/physicalart",
        extension: [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "webp",
          "mp4",
          "mov",
          "wmv",
          "avi",
          "avchd",
          "MPEG-2",
          "mkv"
        ]
        // extension: [],
      },
      {
        label: "Sculpture",
        value: "sculpture",
        icon: <IconNFT src={userAlien} alt="userAlien" />,
        url: "/categories/sculpture",
        extension: []
      },
      {
        label: "Fashion",
        value: "fashion",
        icon: <IconNFT src={vestPatches} alt="vestPatches" />,
        url: "/categories/fashion",
        extension: []
      },
      {
        label: "Real Estate",
        value: "realestate",
        icon: <IconNFT src={userAlien} alt="userAlien" />,
        url: "/categories/realestate",
        extension: []
      }
    ]
  }
];

const ExploreNFTinDashboard = (props) => {
  const category = (categoryInfo) => {
    return (
      <>
        <br />
        <Title
          strong
          type="danger"
          level={5}
          style={{
            font: "normal normal 600 16px/24px Oswald",
            letterSpacing: "0.77px",
            color: "#FF4040",
            textTransform: "uppercase",
            opacity: 1
          }}
        >
          {categoryInfo?.name}
        </Title>
        <Row gutter={[8, 8]}>
          {categoryInfo?.buttons.map((buttonInfo) => {
            return (
              <Col lg={8} md={8} sm={24} xs={24} key={Math.random()}>
                <Link to={`${buttonInfo.url}`}>
                  <ButtonNFT>
                    {buttonInfo.icon}
                    {buttonInfo.label}
                  </ButtonNFT>
                </Link>
              </Col>
            );
          })}
        </Row>
        <br />
      </>
    );
  };
  return (
    <ExploreNFTWrapper>
      <DivExplore>Explore</DivExplore>
      <DashboardRow exploreNft className="">
        <HandPeaceWrapper>
          <Image w="52px" h="61px" src={handPeace} alt="handPeace" />
        </HandPeaceWrapper>
        <Row
          gutter={[64, 16]}
          style={{ marginLeft: "unset", marginRight: "unset" }}
        >
          {categoriesInfo.map((categoryInfo, index) => {
            return (
              <Col span={12} key={index}>
                {category(categoryInfo)}
              </Col>
            );
          })}
        </Row>

        {/* {categoriesInfo.map((categoryInfo, index) => category(categoryInfo))} */}
      </DashboardRow>
    </ExploreNFTWrapper>
  );
};

export default ExploreNFTinDashboard;
