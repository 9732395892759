import * as fcl from "@onflow/fcl";
import keys from "../config/keys";

export const getMetadata = async (addr) => {
  // console.log(addr)
  const result = await fcl.query({
    cadence: `
    // view_metadatas.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(creator: Address): [${keys.daamImport}.MetadataHolder] {
    let metadataRef = getAccount(creator)
        .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath)
        .borrow()!
        
    return metadataRef.viewMetadatas()
}
        `,
    args: (arg, t) => [
      arg(addr, t.Address) // addr: Address
    ]
  });
  return result;
};

export const getMetaDataofAgentCreators = async (addr) => {
  // console.log(addr)
  const result = await fcl.query({
    cadence: `
    // view_metadatas_of_agent_creators.cdc

    import ${keys.daamImport} from ${keys.daam}
    
    pub fun main(creators: [Address]): [${keys.daamImport}.MetadataHolder] {
       
        // var creatorAddresses = ${keys.daamImport}.getAgentCreators(agent: agentAddress);
        // log(creatorAddresses)
        var list: [${keys.daamImport}.MetadataHolder] =[]
        for c in creators {
          log(c)
          let metadataRef = getAccount(c)
          .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath)
          .borrow()!
  
          for ele in metadataRef.viewMetadatas() {
          list.append(ele)
          }
      }
      return list;
    }
        `,
    args: (arg, t) => [
      arg(addr, t.Array(t.Address)) // addr: Address
    ]
  });
  return result;
};
