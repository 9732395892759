/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import CryptoJS from "crypto-js";
import {
  AreaCenter,
  CenterIcon,
  UploadDropArea,
  UploadDropAreaContainer,
  UploadImageButton,
  CustomUpload,
  CollectionTitle,
} from "./styles";

export default function UploadBrowseFiles(props) {
  const { onChange, files, action, onUploadSuccess, ...rest } = props;
  const [fileList, setFileList] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  const handlePreview = async (file) => {
    setPreviewVisible(false);
  };

  const handleChange = (info) => {
    //  if (info.file.status === "uploading") {
    // console.log(info.file.status);
    setFileList(info.fileList);
    onChange(info);
    // }

    if (info.file.status === "done") {
      // console.log(info.file.response);
      const bytes = CryptoJS.AES.decrypt(info.file.response, "DAAMAgency");
      const originalBody = bytes.toString(CryptoJS.enc.Utf8);
      const response = JSON.parse(originalBody);
      // console.log(response);
      onUploadSuccess(response);
    }
  };

  return (
    <UploadDropAreaContainer>
      <UploadDropArea>
        <AreaCenter>
          <CustomUpload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            action={action}
          >
            {files?.filePreview && files?.file.status === "uploading" && (
              <span>Uploading...</span>
            )}
            {files?.filePreview && files?.file.status === "done" && (
              <CenterIcon
                src={files.filePreview}
                width={props.width}
                height={props.height}
              />
            )}
            {!files?.filePreview && (
              <>
                <CollectionTitle>{props?.title}</CollectionTitle>
                <UploadImageButton>Browse</UploadImageButton>
              </>
            )}
          </CustomUpload>
        </AreaCenter>
      </UploadDropArea>
    </UploadDropAreaContainer>
  );
}
