import { useSelector } from "react-redux";
import { Avatar } from "antd";
import Image from "../Image";
import * as config from "../../config/keys";
import AdminProfile from "../../images/Avtar/adminAvtar.png";
import AgentImage from "../../images/Avtar/agentAvtar.png";
import CollectorImage from "../../images/Avtar/collectorAvtar.png";
import CreatorImage from "../../images/Avtar/CreatorAvtar.png";

const ProfileAvatar = (props) => {
  const { auth } = useSelector((obj) => obj);

  return auth && auth?.avtarImg ? (
    <Avatar
      // style={{ height: "40px", width: "40px"  }}
      src={
        <Image
          src={`${config.serverURL}/files/images/${auth.avtarImg}`}
          h={40}
          w={40}
        />
      }
      {...props}
    />
  ) : (
    <Avatar
      size="large"
      src={
        <Image
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={
            auth?.isCreator
              ? CreatorImage
              : auth?.isAgent
              ? AgentImage
              : auth?.isAdmin
              ? AdminProfile
              : auth?.isCollector
              ? CollectorImage
              : { backgroundColor: "rgb(205, 208, 211)" }
          }
        />
        // ProfileImagePlaceholder
      }
      {...props}
    />
  );
};

export default ProfileAvatar;
