import styled from "styled-components";
import { Button, Typography } from "antd";

export const SliderElement = styled.div`
  background: url("${(props) => props.image}") no-repeat center center;
`;
export const SliderWrapper = styled.div`
  padding: 2em 2em 1em 2em;
  & .slider {
    background: #cdd0d3 0% 0% no-repeat padding-box;
    & a.nextButton,
    a.previousButton {
      padding: 20px;
      background: #e9e9e9 0% 0% no-repeat padding-box;
      opacity: 0.93;
    }
    & a.disabled {
      display: none;
    }
    & a.previousButton {
      left: 0;
    }
    & a.nextButton {
      right: 0;
    }
  }
`;
export const SliderCard = styled.div`
  padding: 20px;
  width: 200px;
  background-color: #f4f4f4;
  margin: 20px;
  border-radius: 16px;
  opacity: 0.93;
`;

export const ViewPieceButton = styled(Button)`
  background-color: #fff;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  opacity: 1;
  width: 162px;
  height: 50px;
  text-align: center;
  font: normal normal normal 20px/24px Lato;
  letter-spacing: 0.4px;
  color: #fc4448;
  opacity: 1;
  &:hover {
    color: #fff;
    background-color: #fc4448;
  }
`;
export const SliderInfoWrapper = styled.div`
  padding: 15em;
`;

export const TitleSlider = styled.span`
  display: flex;
  text-align: left;
  font: normal normal bold 48px/71px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const SubTitleSlider = styled.span`
  display: flex;
  text-align: left;
  font: normal normal 600 24px/36px Oswald;
  letter-spacing: 0.58px;
  color: #1a1d1f;
  opacity: 1;
`;

export const FlowPrice = styled.span`
  display: flex;
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #999b9e;
  opacity: 1;
`;
export const Price = styled.span`
  display: flex;
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  opacity: 1;
`;

export const ViewDetailsContainer = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;

export const ViewDetailsLeft = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
`;
export const NftName = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 500 48px/71px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const NftDesc = styled(Typography.Title)`
  text-align: left;
  font: normal normal 400 24px/36px Oswald !important;
  letter-spacing: 0.58px;
  color: #1a1d1f;
  opacity: 1;
`;
export const NftPrice = styled.span`
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: ${(props) => props.color && props.color};
  opacity: 1;
  margin-top: 5px;
`;
export const ViewDetailsRight = styled.div`
  margin-top: 20px;
  padding: 10px;
`;
export const ViewPiece = styled.button`
  text-align: center;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0.4px;
  color: #fc4448;
  opacity: 1;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  opacity: 1;
  padding: 10px;
  background-color: #fff;
`;
export const ImageCon = styled.div`
  /* margin-top: 10px; */
  margin-right: 5px;
  margin-top: -5px;
`;
// export const ViewDetailsContainer = styled.div``;
