import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// add_to_collection.cdc

import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}  from ${keys.daam}


pub fun setFile(string: String, type: String): {MetadataViews.File} {
  switch type! {
      case "http": return MetadataViews.HTTPFile(url: string)
      default: return ${keys.daamImport}.OnChain(file: string)
  }
}

transaction(name: String, description: String, externalURL: String, squareImage: String, squareImageType: String,
  bannerImage: String, bannerImageType: String, social: String?, web: String? )
{
  let collectionRef : &${keys.daamImport}.Collection
  let name          : String
  let description   : String
  let externalURL   : MetadataViews.ExternalURL
  let squareImage   : MetadataViews.Media
  let bannerImage   : MetadataViews.Media
  let socials       : {String: MetadataViews.ExternalURL}

  prepare(acct: AuthAccount) {
      // Borrow a reference from the stored collection
      self.collectionRef = acct.borrow<&${keys.daamImport}.Collection>(from: ${keys.daamImport}.collectionStoragePath)!
          //?? panic("Could not borrow a reference to the owner's collection")
      self.name              = name // Get name of collection
      self.description       = description
      self.externalURL       = MetadataViews.ExternalURL(externalURL)
      let sqi = setFile(string: squareImage, type: squareImageType)
      self.squareImage       = MetadataViews.Media(file: sqi, mediaType: squareImageType)
      let bi  = setFile(string: bannerImage, type: bannerImageType)
      self.bannerImage       = MetadataViews.Media(file: bi, mediaType: bannerImageType)
      self.socials           =  social==nil ? {} : {social! : MetadataViews.ExternalURL(web!)}
  }
  
  pre { social == nil && web == nil || social != nil && web != nil : "" }

  execute {
      self.collectionRef.addCollection(name: self.name, description: self.description, externalURL: self.externalURL,
          squareImage: self.squareImage, bannerImage: self.bannerImage, socials: self.socials) 
      log("Collection ".concat(name).concat(" Created"))
  }
}
`;

export function AddNewCollection(data, opts = {}) {
  const {
    name,
    description,
    externalURL,
    squareImage,
    squareImageType,
    bannerImage,
    bannerImageType,
    social,
    web
  } = data;
  const { authorization } = fcl.currentUser();
  // prettier-ignore
  // console.log(Number(mid))

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(name, types.String),
        fcl.arg(description, types.String),
        fcl.arg(externalURL, types.String),
        fcl.arg(squareImage, types.String),
        fcl.arg(squareImageType, types.String),
        fcl.arg(bannerImage, types.String),
        fcl.arg(bannerImageType, types.String),
        fcl.arg(social, types.String),
        fcl.arg(web, types.String)
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
}

const ADD_MID_TO_COLLECTION = fcl.cdc`
// add_mid_to_collection.cdc

import ${keys.daamImport} from ${keys.daam}

transaction(mid: UInt64, feature: Bool, name: String) {
  let collectionRef : &${keys.daamImport}.Collection
  let creatorRef    : &${keys.daamImport}.Creator
  let mid           : UInt64
  let feature       : Bool
  var name          : String

  prepare(acct: AuthAccount) {
      self.creatorRef = acct.borrow<&${keys.daamImport}.Creator>(from: ${keys.daamImport}.creatorStoragePath)!
      let metadataGen = acct.borrow<&${keys.daamImport}.MetadataGenerator>(from: ${keys.daamImport}.metadataStoragePath)!
      // Borrow a reference from the stored collection
      self.collectionRef = acct.borrow<&${keys.daamImport}.Collection>(from: ${keys.daamImport}.collectionStoragePath)
          ?? panic("Could not borrow a reference to the owner's collection")
      self.mid     = mid
      self.feature = feature
      self.name    = name
  }

  execute {
      self.collectionRef.collections[self.name]!.addMID(creator: self.creatorRef, mid: self.mid, feature: self.feature)
      log("MID: ".concat(self.mid.toString()).concat(" added to Collection."))
  }
}
`;

export const addMidToCollection = ({ mid, feature = false, name, index }, opts) => {
  const { authorization } = fcl.currentUser();
  // prettier-ignore
  // console.log(Number(mid))

  return tx([
    fcl.transaction(ADD_MID_TO_COLLECTION),
    fcl.args([
      fcl.arg(parseInt(mid), types.UInt64),
      fcl.arg(feature, types.Bool),
      fcl.arg(name, types.String),
      //  fcl.arg(parseInt(index), types.Int), 
    ]),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts);
};
