import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import keys from "../config/keys";


export const buyNowAmount = async ({ auctionAddress, auction, bidderAddress }, opts = {}) => {
  const result = await fcl.query({
    cadence: `
    // get_buy_now_amount.cdc
// Gets the amount required for a Buy It Now

import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(auction: Address, aid: UInt64, bidder: Address): UFix64 {
  let auctionHouse = getAccount(auction)
      .getCapability<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>
      (${keys.auctionHouseImport}.auctionPublicPath)
      .borrow()!

  let mRef = auctionHouse.item(aid) as &${keys.auctionHouseImport}.Auction{${keys.auctionHouseImport}.AuctionPublic}?  
  return mRef!.getBuyNowAmount(bidder: bidder)
}
`
    ,
    args: (arg, t) => [
      arg(auctionAddress, types.Address),
      arg(auction, types.UInt64),
      arg(bidderAddress, types.Address),
    ],
  });
  return result;
};
