import styled from "styled-components";
import { Typography, Col } from "antd";
import { Link } from "react-router-dom";
const { Text } = Typography;

export const CategoriesSubmenuLink = styled.a`
  font-weight: small;
  font-size: medium;
  margin: 0px;
  padding: 0px;
`;

export const SubMenuLiWrapper = styled.ul`
  list-style-type: none;
  & a {
    color: #1a1d1f;
  }
`;

export const IconNFTCategoriesMenu = styled.img`
  margin-right: 1em;
  // margin-top: 6px;
  width: 16px;
  height: 16px;
  // filter: invert(46%) sepia(46%) saturate(5170%) hue-rotate(335deg)
  //   brightness(97%) contrast(109%);
`;

export const SubMenTitle = styled(Text)`
  padding-left: 2em;
  padding-right: 3em;
  font: normal normal 600 16px/24px Oswald;
  letter-spacing: 0.77px;
  color: #ff4040;
  text-transform: uppercase;
  &:hover {
    color: #ff4040 !important;
  }
`;

export const LinkLi = styled(Link)`
  font: normal normal 400 13px Lato;
  letter-spacing: 0.26px;
  color: #1a1d1f;
`;
export const SubMenuCol = styled(Col)`
  a {
    display: flex;

    &:hover {
      color: red;
    }
  }
  ${(props) =>
    props.withrightborder === "true" &&
    `
        border-right: 1px solid rgba(128, 128, 128, .2);
        -webkit-background-clip: padding-box; /* for Safari */
        background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    `}
`;

export const SubMenuUlWrapper = styled.li`
  list-style: none;
  cursor: default;
  border-radius: 10px;
  margin-left: -10em;
  padding: 1.2em;
  width: auto;
  z-index: 99;
  position: absolute;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 12px #0000001a;
  border: 1px solid #e9e9ec;
  opacity: 1;
  ${(props) => props.location === "/" && `margin-top: 1.5em`}
`;
