/* eslint-disable no-unused-vars */
import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CREATE_PROFILE_SHORT = fcl.cdc`
// create_profile_short.cdc
// Used to create a DAAM_Profile. This is the same as create_profile.cdc but only name and email.

import MetadataViews from ${keys.metadataViews}
import DAAM_Profile  from ${keys.profile}
import ${keys.daamImport}  from ${keys.daam}

transaction(name: String, emailName: String?, emailAt:String?, emailDot: String?)
{
    let signer: AuthAccount
    let name  : String
    let emailName : String?
    let emailAt   : String?
    let emailDot  : String?

    prepare(signer: AuthAccount) {
        self.signer = signer
        self.name   = name
        self.emailName  = emailName
        self.emailAt    = emailAt 
        self.emailDot   = emailDot
    }

    execute {
        let profile <- DAAM_Profile.createProfile(
            name:self.name, about:nil, description:nil, web:nil, social:nil, avatar:nil, heroImage:nil, notes:nil)

        profile.setEmail(name: self.emailName, at: self.emailAt, dot: self.emailDot)
        
        self.signer.save<@DAAM_Profile.User>(<-profile, to: DAAM_Profile.storagePath)
        self.signer.link<&DAAM_Profile.User{DAAM_Profile.Public}>(DAAM_Profile.publicPath, target: DAAM_Profile.storagePath)
        log("${keys.daamImport} Profile Created: ".concat(self.signer.address.toString()))
    }
}
`;
export const createProfile = async (profile, opts = {}) => {
  const { authorization } = fcl.currentUser();
  const emailName = profile.email.split("@");
  const emailPortion = emailName[1].split(".");

  return tx(
    [
      fcl.transaction(CREATE_PROFILE_SHORT),
      fcl.args([
        fcl.arg(profile.name, types.String),
        fcl.arg(null, types.Optional(types.String)),
        fcl.arg(null, types.Optional(types.String)),
        fcl.arg(null, types.Optional(types.String))
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
