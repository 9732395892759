import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import { tx } from "./util/tx"

const CODE = fcl.cdc`
// This transaction configures the signer's account with an empty FUSD vault.
//
// It also links the following capabilities:
//
// - FungibleToken.Receiver: this capability allows this account to accept FUSD deposits.
// - FungibleToken.Balance: this capability allows anybody to inspect the FUSD balance of this account.

import FungibleToken from ${keys.fungibleToken}
import FUSD          from ${keys.fusd}

transaction {

  prepare(signer: AuthAccount) {

      // It's OK if the account already has a Vault, but we don't want to replace it
      if(signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) != nil) {
          return
      }
      
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
          /public/fusdReceiver,
          target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
          /public/fusdBalance,
          target: /storage/fusdVault
      )
  }
}
`

export const createFUSDWallet = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx([
    fcl.transaction(CODE),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts)
}