import * as fcl from '@onflow/fcl';
import * as keys from "../config/keys";
export const checkFUSDWallet = async (address) => {
  const result = await fcl.query({
    cadence: `
      // get_fusd_balance.cdc

      import FungibleToken from ${keys.fungibleToken}
      import FlowToken     from ${keys.flowToken}
      import FUSD          from ${keys.fusd}
      
      pub fun main(address: Address): UFix64?
{
  let vaultRef = getAccount(address)
    .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
    .borrow<>()
    //?? panic("Could not borrow Balance capability")

  return vaultRef?.balance
}
        `,
    args: (arg, t) => [
      arg(address, t.Address)
    ],
  });
  return result;
};
