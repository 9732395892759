/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { FolderAddOutlined } from "@ant-design/icons";
import { Avatar, Card, Divider, Dropdown, Menu, Tag } from "antd";
import { Button, Space, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormUnit, FormUnits, FormCollapse } from "../../../components/Form";
import UploadNFTBrowseFiles from "../../../components/UploadNFTBrowseFiles";
import { getCollection } from "../../../scripts/getCollections";
import { PanelWrapper } from "./Step1.styled";
import CollectionUpload from "./UploadCollection";
import { CardCreatorList } from "./uploadCollection.styled";
import * as config from "../../../config/keys";
import ReactSelect from "react-select";

const Collection = ({
  onCollectionSelected,
  mintResult,
  onRemoveCollection,
  onStartCollection,
  onCollectionComplete
}) => {
  const { auth } = useSelector((obj) => obj);
  const [selectedOption, setSelectedOption] = useState(null);
  const [updateDropdown, setUpdateDropdown] = useState(false);
  // useEffect(() => {
  //   getCollection(auth.address).then((re) => {return });
  // }, []);

  // const onClick = ({ key }) => {

  //   onCollectionSelected(key);
  // };

  // const menu = (
  //   <Menu onClick={onClick}>
  //     {auth?.collections?.map((el) => {
  //       return (
  //         <Menu.Item key={el.collectionName}>{el.collectionName}</Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const [visible, setVisible] = React.useState(false);

  const customOptions = ({ innerRef, innerProps, data }) => {
    return (
      <div ref={innerRef} {...innerProps}>
        {data.value !== -1 ? (
          <>
            <CardCreatorList>
              <Card.Meta
                avatar={
                  <Avatar
                    src={`${config.serverURL}/files/images/${data?.collectionImage}`}
                    alt="avatar"
                  />
                }
                title={data.collectionName}
                description={data.description || data.collectionName}
              />
            </CardCreatorList>
            <Divider style={{ margin: 0 }} />
          </>
        ) : (
          <>
            <Button
              type="primary"
              block
              size={"large"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={() => setVisible(true)}
            >
              <FolderAddOutlined /> {` ${data.label}`}
            </Button>
            <Divider style={{ margin: [3, 0] }} plain>
              OR Select{" "}
            </Divider>
          </>
        )}
      </div>
    );
  };

  const onCollectionCreated = () => {
    setUpdateDropdown(true);
  };

  useEffect(() => {
    if (updateDropdown) {
      const val = auth?.collections[auth?.collections.length - 1];
      setSelectedOption({
        ...val,
        label: val.collectionName,
        value: val.collectionName
      });
      onCollectionSelected(val.collectionName);
      setUpdateDropdown(false);
    }
  }, [updateDropdown]);

  return (
    <FormUnits fd="column">
      <FormUnit>
        <FormCollapse showArrow={true} ghost>
          <PanelWrapper showArrow={true} header="Collections" key="1">
            <ReactSelect
              value={selectedOption}
              options={[
                {
                  label: "Add New Collection",
                  value: -1
                },
                ...auth?.collections.map((x) => {
                  return {
                    ...x,
                    label: x.collectionName,
                    value: x.collectionName
                  };
                })
              ]}
              isClearable
              isSearchable
              components={{ Option: customOptions }}
              onChange={(selectedOption) => {
                setSelectedOption(selectedOption);
                onCollectionSelected(selectedOption?.value);
              }}
            />
            <ModalCom
              visible={visible}
              setVisible={setVisible}
              onCollectionCreated={onCollectionCreated}
              onStartCollection={onStartCollection}
              onCollectionComplete={onCollectionComplete}
            />
            {/* <Space direction="vertical">
              <Space style={{ alignItems: "flex-start" }}>
                <Space
                  direction="vertical"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  <Dropdown overlay={menu}>
                    <CollectionButton
                      onClick={(e) => e.preventDefault()}
                      icon={<FolderOpenOutlined />}
                    ></CollectionButton>
                  </Dropdown>
                  {"Browse"}
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  <CollectionButton
                    onClick={() => setVisible(true)}
                    icon={<PlusOutlined />}
                  ></CollectionButton>
                  {"New"}
                  <ModalCom visible={visible} setVisible={setVisible} />
                </Space>
                {mintResult?.collection && (
                  <SelectedCollectionTag
                    closable
                    onClose={onRemoveCollection}
                    color="green"
                  >
                    {mintResult?.collection}
                  </SelectedCollectionTag>
                )}
              </Space>
            </Space> */}
          </PanelWrapper>
        </FormCollapse>
      </FormUnit>
    </FormUnits>
  );
};

export default Collection;

export const ModalCom = (props) => {
  const bodyStyle = {
    width: "537px",
    height: "710px",
    margin: "auto",
    overflow: "hidden"
  };
  const maskStyle = {
    backdropFilter: "blur(5px)",
    background: "rgb(247 247 247 / 58%) 0% 0% no-repeat padding-box"
  };
  const style = {
    borderRadius: "24px",
    overflow: "hidden",
    background: "#f7f7f7 0% 0% no-repeat padding-box",
    boxShadow: "0px 8px 20px #00000029",
    top: "15%"
  };
  return (
    <Modal
      closable={false}
      visible={props.visible}
      width={537}
      height={710}
      footer={null}
      maskStyle={maskStyle}
      bodyStyle={bodyStyle}
      maskClosable={true}
      style={style}
    >
      <CollectionUpload
        width="100%"
        height="100px"
        title="Collection Image"
        setVisible={props.setVisible}
        onCollectionCreated={props?.onCollectionCreated}
        onStartCollection={props?.onStartCollection}
        onCollectionComplete={props?.onCollectionComplete}
      />
    </Modal>
  );
};
