import * as fcl from "@onflow/fcl";
import * as types from "@onflow/types";
import keys from "../config/keys";
export const AcceptNft = async (mid) => {
  //  console.log(mid)
  const { authorization } = fcl.currentUser();
  try {
    const tx = await fcl.send([
      fcl.transaction`
// change_metadata_status.cdc
// Used for Admin / Agents to Approve/Disapprove Metadata via MID. True = Approved, False = Disapproved

import ${keys.daamImport} from ${keys.daam}

transaction(creator: Address, mid: UInt64, status: Bool)
{
    let admin  : &${keys.daamImport}.Admin{${keys.daamImport}.Agent}
    let mid    : UInt64
    let status : Bool
    let creator: Address

    prepare(agent: AuthAccount) {
        self.admin   = agent.borrow<&${keys.daamImport}.Admin{${keys.daamImport}.Agent}>(from: ${keys.daamImport}.adminStoragePath)!
        self.mid     = mid
        self.status  = status
        self.creator = creator
    }

    execute {
        self.admin.changeMetadataStatus(creator: self.creator, mid: self.mid, status: self.status)
        log("Change Metadata Status")
    }
}
          `,
      fcl.args([fcl.arg(mid, types.UInt64), fcl.arg(true, types.Bool)]),
      fcl.payer(authorization),
      fcl.proposer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(100)
    ]);

    return tx;
  } catch (error) {
    console.log(error.message);
  }
};
