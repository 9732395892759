import styled from "styled-components";

export const ManageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 8vw;
`;

export const ManageContainerWrapper = styled.div`
  margin-left: 20px;
  width: 100%;
`;
