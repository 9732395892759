import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { Divider } from "antd";

export const MessagesWrapper = styled.div`
  width: 100%;
  padding: 1em;
`;
export const MessagesDottedDivider = styled.hr`
  border-top: 3px dashed gray;
`;
export const RedCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;
  color: red;
`;
export const DividerMessage = styled(Divider)`
  margin: 0;
  padding: 0;
`;
