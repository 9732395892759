/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Verify from "./Verify";

const VerifyContainer = ({ onValidateOtp, onResendEmail }) => {
  return <Verify onValidateOtp={onValidateOtp} onResendEmail={onResendEmail} />;
};

export default VerifyContainer;
