/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const SignupContext = createContext();

export const SignupProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0); // 0 = CREATENFT, OTHER = STEPS
  const history = useHistory();
  const [signupData, setSignupData] = useState({
    email: "",
    nationality: { value: "CA", label: "Canada" },
  });

  return (
    <SignupContext.Provider value={{ signupData, setSignupData }}>
      {children}
    </SignupContext.Provider>
  );
};
