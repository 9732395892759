/* eslint-disable no-unused-vars */
import {
  Dropdown,
  Menu,
  Space,
  Modal,
  Row,
  Col,
  Card,
  Button,
  Spin,
  Typography,
  Input
} from "antd";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import * as config from "../../config/keys";
import paintbrush from "../../images/icons/paint_brush.svg";
import {
  CardStyles,
  CardImage,
  CardDetails,
  CardRow,
  CardAuthor,
  CardTitle,
  CardPrices,
  CardPrice,
  CardFlowPrice,
  CardMain,
  ImageSrc,
  MenuItem
} from "./styles";
import {
  ArrowRightOutlined,
  CheckOutlined,
  EditOutlined,
  EllipsisOutlined,
  FireOutlined,
  FolderOpenOutlined,
  StarFilled,
  StarOutlined,
  DeleteOutlined,
  EyeOutlined,
  FolderOpenFilled,
  FileAddFilled
} from "@ant-design/icons";
import { VideoSrc } from "../../common/commonStyles";
import { listToMarketplace } from "../../scripts/listToMarketplace";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Routes from "../../Routes";
import PDFViewer from "../PDFViewer";
import { getCollection } from "../../scripts/getCollections";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { addMidToCollection } from "../../scripts/addNewCollection";
import { toast } from "react-toastify";
import { ToastText } from "../../containers/minting/Minting.styled";
import { useEffect } from "react";
import { getSingleMetadata,getSaleHistory } from "../../scripts";


// import "./index.css";

export const Collection = ({
  data,
  disable,
  image,
  author,
  title,
  price,
  flowPrice,
  authorMore,
  titleMore,
  status,
  avatarImage,
  to,
  authorLink,
  cannotClick,
  showFilters = false,
  isCollection = false,
  mid,
  address,
  setChangeData
}) => {
  const history = useHistory();
  const { auth } = useSelector((obj) => obj);
  const toastId = useRef(null);
  const [visible, setVisible] = useState(false);
  const [collection, setCollection] = useState([]);
  const [selectedMetadata, setSelectedMetadata] = useState(null);

  const onSuccess = async () => {
    setChangeData(true);
  };
  const onError = (err) => {
    console.log("Error", err);
  };
  const onComplete = () => {
    setVisible(false);
  };
  const handleList = () => {
    console.log(mid, price);
    getSingleMetadata(address, mid)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

      getSaleHistory(mid).then(res=>console.log(res)).catch(ex=>console.log(ex));
    // listToMarketplace(
    //   { mid: mid, price: price },
    //   { onSuccess, onError, onComplete }
    // )
    //   .then((res) => {
    //     const listAuctionID = res.events[2].data?.auctionID;
    //     history.push(`${Routes.NFT_DETAILS_PAGE}/${address}/${listAuctionID}`);
    //   })
    //   .catch((err) => {
    //     console.log("error ", err);
    //   });
  };

  const addToCollection = (selectedCollection) => {
    // console.log(auth?.collections.findIndex(x=>x.collectionName===selectedCollection.collectionName));
    addMidToCollection(
      {
        mid: parseInt(selectedMetadata?.metaDataInfo?.mid),
        feature: false,
        name: selectedCollection.collectionName,
        index: parseInt(
          auth?.collections.findIndex(
            (x) => x.collectionName === selectedCollection.collectionName
          )
        )
      },
      {
        onStart: () => {
          toastId.current = toast(
            <Space size="large">
              <Spin size="large" />
              <Typography.Text
                style={{ color: "#fff" }}
              >{`Adding ${selectedMetadata?.metaDataInfo?.edition?.name} item in ${selectedCollection.collectionName} collection `}</Typography.Text>
            </Space>,
            {
              type: toast.TYPE.INFO,
              autoClose: false,
              closeButton: false, // Remove the closeButton
              theme: "colored",
              pauseOnFocusLoss: false,
              icon: false
            }
          );
        },
        onSuccess: () => {
          toast.update(toastId.current, {
            render: (
              <Typography.Text
                style={{ color: "#fff" }}
              >{`${selectedMetadata?.metaDataInfo?.edition?.name} successfully added to ${selectedCollection.collectionName}.👌`}</Typography.Text>
            ),
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
        },
        onError: (err) => {
          toast.update(toastId.current, {
            render: (
              <Typography.Text
                style={{ color: "#fff" }}
              >{`🤯 Error : ${err}`}</Typography.Text>
            ),
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
        },

        onComplete
      }
    );
  };

  const menuClick = (e) => {
    e.domEvent.preventDefault();

    if (e.key === "addtocollection") {
      if (auth?.collections && auth?.collections.length > 0) {
        setCollection(auth?.collections);
        setVisible(true);
      }
      // getCollection(auth?.address)
      //   .then((res) => {
      //     console.log(res);
      //     setCollection(res);
      //     setVisible(true);
      //   })
      //   .catch((err) => console.log(err));
    }
  };

  const assetMenu = (selectedValue) => {
    setSelectedMetadata(selectedValue);
    return (
      <Menu onClick={menuClick}>
        <MenuItem
          key="featured"
          style={{ font: "normal normal normal 12px Lato", color: "#2F3234" }}
        >
          <Space>
            <StarOutlined />
            {`Featured Item`}{" "}
          </Space>
        </MenuItem>
        <Menu.Divider />
        {/* <MenuItem key="viewContents">
          <Space>
            <EyeOutlined />
            {`View Contents`}{" "}
          </Space>
        </MenuItem> */}
        <MenuItem key="addtocollection">
          <Space>
            <FolderOpenFilled />
            {`Add to Collection`}{" "}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="edit">
          {" "}
          <Space>
            <EditOutlined />
            {`Edit Metadata`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="listed" onClick={handleList}>
          <Space>
            <CheckOutlined />
            {`Listed`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="5">
          <Space>
            <ArrowRightOutlined />
            {`Transfer`}
          </Space>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="7">
          <Space>
            <DeleteOutlined />
            {`Burn/Remove`}
          </Space>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <CardStyles style={disable ? { opacity: ".5" } : {}}>
        <CardMain
          to={to}
          style={cannotClick ? { pointerEvents: "none" } : null}
          // onClick={(e) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          // }}
        >
          {data && (
            <CardImage>
              {
                ["Audio", "Video"].includes(
                  data?.metaDataInfo?.category[1].name
                ) ? (
                  <VideoSrc controls>
                    <source
                      src={image}
                      type={Object.keys(data?.metaDataInfo?.thumbnail)[0]}
                    />
                  </VideoSrc>
                ) : Object.keys(data?.metaDataInfo?.thumbnail)[0] === "pdf" ? (
                  <PDFViewer
                    mintResult={{ file: image }}
                    showPageControls={false}
                    showOnlyFirstPage={true}
                    height={150}
                  />
                ) : (
                  // <ImageSrc src={image && image} />
                  <ImageSrc src={image && image} />
                )
                // Object.keys(data?.metaDataInfo?.thumbnail)[0].startsWith("tif") ? (
                //   <embed
                //     src={image}
                //     type={Object.keys(data?.metaDataInfo?.thumbnail)[0]}
                //     negative="yes"
                //     style={{ height: "90%", width: "90%", padding: "50px" }}
                //   />
                // ) : (
                //   <ImageSrc src={image && image} />
                // )
              }
            </CardImage>
          )}
          <CardDetails>
            {author && (
              <CardRow>
                <CardAuthor>{author}</CardAuthor>
              </CardRow>
            )}
            <CardRow
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                justifyContent: "space-between"
              }}
            >
              {title && (
                <CardTitle ellipsis={{ tooltip: title }}>{title}</CardTitle>
              )}
              {showFilters && (
                <Dropdown
                  placement="bottomRight"
                  overlay={() => assetMenu(data)}
                  trigger={["click"]}
                >
                  <EllipsisOutlined
                    onClick={(e) => e.preventDefault()}
                    style={{
                      background: " #FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #CDD0D3",
                      borderRadius: "5px",
                      fontSize: "20px"
                    }}
                    size="small"
                  />
                </Dropdown>
              )}
            </CardRow>

            {(price || flowPrice) && (
              <Space
                style={{ paddingTop: 12, justifyContent: "space-between" }}
              >
                <Space direction="vertical">
                  {isCollection ? (
                    <CardPrices>
                      {price && <CardPrice>{`From ${price} $`}</CardPrice>}
                      {flowPrice && (
                        <CardFlowPrice>{`From ${flowPrice} F`}</CardFlowPrice>
                      )}
                    </CardPrices>
                  ) : (
                    <CardPrices>
                      {price && <CardPrice>{price} $</CardPrice>}
                      {flowPrice && (
                        <CardFlowPrice>{flowPrice} F</CardFlowPrice>
                      )}
                    </CardPrices>
                  )}
                </Space>
                {/* state: { message: 'hello, im a passed message!' }  */}
                <Link
                  to={{
                    pathname: authorLink,
                    state: true
                  }}
                >
                  <Avatar
                    // style={{ width: "48px", height: "48px" }}
                    src={
                      avatarImage
                        ? avatarImage.includes("data:")
                          ? avatarImage
                          : `${config.serverURL}/files/images/${avatarImage}`
                        : paintbrush
                    }
                    alt="avatar"
                  />
                </Link>
              </Space>
            )}
          </CardDetails>
        </CardMain>
      </CardStyles>
      <ModalCom
        visible={visible}
        setVisible={() => setVisible(!visible)}
        collection={auth?.collections}
        onCollectionSelect={(selectedCollection) =>
          addToCollection(selectedCollection)
        }
      />
    </>
  );
};

const ModalCom = (props) => {
  const bodyStyle = {
    // width: "537px",
    // height: "710px",
    margin: "auto",
    overflow: "auto"
  };
  const maskStyle = {
    backdropFilter: "blur(5px)",
    background: "rgb(247 247 247 / 58%) 0% 0% no-repeat padding-box"
  };
  const style = {
    borderRadius: "24px 24px 0px 0px",
    top: "5px"
    // overflow: "hidden",
    //  background: "#f7f7f7 0% 0% no-repeat padding-box",
    //  boxShadow: "0px 8px 20px #00000029"
    // top: "15%"
  };

  const [data, setData] = useState({
    value: "",
    sourceData: props?.collection || [],
    filterData: props?.collection || []
  });

  const filterList = (e) => {
    const regex = new RegExp(e.target.value, "gi");
    const filter = data?.sourceData.filter((x) => {
      return regex.test(x.collectionName);
    });
    if (e.target.value === "") {
      setData({ ...data, filterData: data.sourceData, value: e.target.value });
    } else {
      setData({ ...data, filterData: filter, value: e.target.value });
    }
  };

  return (
    <Modal
      closable={true}
      visible={props.visible}
      onCancel={props.setVisible}
      width={"80%"}
      // height={710}
      footer={null}
      maskStyle={maskStyle}
      bodyStyle={bodyStyle}
      maskClosable={true}
      style={style}
      title={
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Text>{`Add to collection`}</Typography.Text>
          <Input.Search
            placeholder="Search collection"
            allowClear
            onChange={filterList}
          />
        </Space>
      }
    >
      <div style={{ height: "700px", overflow: "auto" }}>
        <Row>
          {data?.filterData?.map((item) => {
            return (
              <Col xl={4} lg={4} md={6} sm={8} xs={24} key={Math.random()}>
                <Card
                  style={{ width: 150 }}
                  cover={
                    <img
                      alt="example"
                      src={`${config.serverURL}/files/images/${item.collectionImage}`}
                      style={{ width: 150, height: 150 }}
                    />
                  }
                  actions={[
                    <FileAddFilled
                      key={"add"}
                      onClick={() => props?.onCollectionSelect(item)}
                    >
                      {`Add to Collection`}
                    </FileAddFilled>
                  ]}
                >
                  <Card.Meta
                    //  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={item.collectionName}
                    // description={display.description}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Modal>
  );
};
