import styled from 'styled-components';

export const MintingLayoutStyles = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;
export const MintingView = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
export const MintingColumn = styled.div`
  display: flex;
  width: 150px;
`;
export const MintingCenter = styled.div`
  display: flex;
  flex: 1;
`;
export const MintingCenterColumn = styled.div`
  display: flex;
  width: 50%;
  &:nth-child(1) {
    padding-right: 12px;
  }
  &:nth-child(2) {
    padding-left: 12px;
  }
`;
export const MintingRow = styled.div`
  display: flex;
  text-align: left;
  width: 100%;
  margin-top:${props => props.mt ? props.mt : '0'}
`;
