import styled from "styled-components";

export const CreateNFTIconsWrapper = styled.div`
  position: relative;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
  background: var(--unnamed-color-f4f4f4) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-b5b7b9);
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #b5b7b9;
  border-radius: 18px;
  opacity: 1;
  background: linear-gradient(90deg, #f4f4f4 50%, #e9e9e9 50%);
`;

export const CreateNFTWrapper = styled.div`
  // width: max-content;
  // max-width: 960px;
  // margin: 8em;
  // margin-left: auto;
  // margin-right: auto;
  padding: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
`;
export const ButtonNFT = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 1em 0 1em 0.5em;
  font-size: small;
  font-weight: bold;
  text-align: center;
  border: 1px solid var(--unnamed-color-cdd0d3);
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  border: 1px solid #cdd0d3;
  border-radius: 43px;
  &:first-of-type {
    color: black;
  }
  &:hover {
    color: black;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconNFT = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 2px;
`;

export const HandPeaceWrapper = styled.div` 
  position: absolute;
  top: -5.4em;
  right: 3em;
`;


