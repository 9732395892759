import React from "react";
import { useSelector } from "react-redux";
import DashboardContainer from "../containers/dashboard";

const Dashboard = () => {
  const { user } = useSelector((obj) => obj);

  return <DashboardContainer userType={user?.userType || ""} />;
};

export default Dashboard;
