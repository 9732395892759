import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// create_auction_wallet.cdc
// Create an auction wallet. Used to store auctions.
import ${keys.auctionHouseImport} from ${keys.auctionHouse}

transaction() {
  let signer: AuthAccount

  prepare(signer: AuthAccount) {
      self.signer = signer
  }

  execute {
      if self.signer.borrow<&${keys.auctionHouseImport}.AuctionWallet>(from: ${keys.auctionHouseImport}.auctionStoragePath) == nil {
          let old <- self.signer.load<@AnyResource>(from: ${keys.auctionHouseImport}.auctionStoragePath)
          let auctionWallet <- ${keys.auctionHouseImport}.createAuctionWallet()
          self.signer.save<@${keys.auctionHouseImport}.AuctionWallet> (<- auctionWallet, to: ${keys.auctionHouseImport}.auctionStoragePath)
          self.signer.link<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>
              (${keys.auctionHouseImport}.auctionPublicPath, target: ${keys.auctionHouseImport}.auctionStoragePath)
          destroy old
          log("Auction House Created, you can now have Auctions.")
      }
      else {
          log("You already have an Auction House.")
      }
  }    
}
`

export const createAuctionWallet = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx([
    fcl.transaction(CODE),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts)
}