import { Button, Col } from "antd";
import styled from "styled-components";

export const FrameWrapper = styled.div`
  // max-width: 80%;
  // padding-top: 10em;
  // padding-left: 25em;
  padding-top: 10%;
`;

export const ImageWrapper = styled.div`
  max-width: 80%;
  max-height: 50%;
`;

export const Paragraph = styled.h3`
  display: flex;
  justify: center;
  justify-content: center;

  text-align: center;
  font: normal normal 500 34px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
`;

export const RegisterText = styled.p`
  text-align: center;
  font: normal normal 500 16px Lato;

  rtext {
    justify: center;
    text-align: center;
    font: normal normal 500 16px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
  cardlabel {
    text-align: left;
    font: normal normal bold 24px/48px Oswald;
    letter-spacing: 0.72px;
    color: #2f3234;
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const FormItem = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #707070;
  border: 1px solid red;
  & .ant-card-body {
    // min-width: 100px;
    min-height: 120px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  & .selected {
    border-color: #2bce88;
    color: #2bce88;
    & .ant-card {
      color: #2bce88;
      & p {
        color: #2bce88;
      }
    }
  }
`;

export const ClickButton = styled(Button)`
  width: 100%;
  // padding:100;

  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  height: 45px;
  text-align: center;
  // font: normal normal bold 20px/24px "Lato";
  font-family: "Lato";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 20px/24px;
  letter-spacing: 0.4px;
  color: #1a1d1f;
  opacity: 1;
  background-color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
  &:hover {
    background-color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
    color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  }
`;

export const Column = styled(Col)`
  display: flex;
  justify-content: center;
  align-item: end;
  & p {
    padding-top: 3px;
    font: normal normal 500 20px/0px Oswald;
    letter-spacing: 0.72px;
    color: #2f3234;
    text-transform: uppercase;
  }
`;

// select role screen two

export const SelectRoleMainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TopBar = styled.div`
  padding: 1rem 0rem 1rem 1rem;
  width: 200px;
`;

export const CenterItem = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;
export const CenterItemDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  width: 550px;
  height: 730px;
  background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #00000029;
  border-radius: 77px;
  opacity: 1;
  padding-top: 20px;

  @media (max-width: 1710px) {
    width: 500px;
    height: 620px;
  }
  @media (max-width: 1536px) {
    width: 420px;
    height: 550px;
  }
  @media (max-width: 1440px) {
    width: 400px;
    height: 500px;
  }
  @media (max-width: 1400px) {
    width: 400px;
    height: 550px;
    /* border-radius: 50px; */
  }
  @media (max-width: 1240px) {
    width: 330px;
    height: 450px;
    border-radius: 50px;
  }
  @media (max-width: 1024px) {
    width: 320px;
    height: 420px;
    border-radius: 50px;
  }
`;
export const CenterTitle = styled.span`
  color: var(--unnamed-color-2f3234);
  text-align: left;
  font: normal normal 500 34px/106px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;

  @media (max-width: 1240px) {
    font: normal normal 500 24px/70px Oswald;
  }
  @media (max-width: 1024px) {
    font: normal normal 500 18px/50px Oswald;
  }
`;
export const CenterSubTitle = styled.span`
  color: var(--unnamed-color-1a1d1f);
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  @media (max-width: 1240px) {
    font: normal normal 500 12px/18px Lato;
  }
  @media (max-width: 1024px) {
    font: normal normal 500 10px/16px Lato;
  }
`;
export const SelectRoleDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 1.5rem;
  @media (max-width: 1400px) {
    margin-top: 2rem;
  }
  @media (max-width: 1240px) {
    margin-top: 1.5rem;
  }
  @media (max-width: 1024px) {
    margin-top: 1.5rem;
  }
`;
export const SelectRoleItemAgent = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 11rem;
  width: 200px;
  height: 183px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  z-index: ${(props) => props.role === "agent" && `999999`};
  @media (max-width: 1710px) {
    width: 170px;
    height: 132px;
    top: 1.5rem;
    left: 10.7rem;
  }
  @media (max-width: 1536px) {
    width: 130px;
    height: 102px;
    top: 2rem;
    left: 9.2rem;
  }
  @media (max-width: 1440px) {
    width: 130px;
    height: 102px;
    top: 2rem;
  }
  @media (max-width: 1400px) {
    width: 130px;
    height: 102px;
    top: 2rem;
    left: 8.6rem;
  }
  @media (max-width: 1240px) {
    width: 110px;
    height: 102px;
    /* top: 2rem; */
    left: 6.8rem;
  }
  @media (max-width: 1024px) {
    width: 110px;
    height: 102px;
    /* top: 2rem; */
    left: 6.8rem;
  }
`;
export const SelectRoleItemCreator = styled.div`
  position: absolute;
  top: 13rem;
  left: 5rem;
  width: 200px;
  height: 181px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  @media (max-width: 1710px) {
    width: 170px;
    height: 132px;
    top: 9rem;
    left: 5.5rem;
  }
  @media (max-width: 1536px) {
    width: 130px;
    height: 100px;
    top: 8rem;
    left: 5rem;
  }
  @media (max-width: 1440px) {
    width: 130px;
    height: 100px;
    top: 8rem;
    left: 7rem;
  }
  @media (max-width: 1400px) {
    width: 130px;
    height: 102px;
    top: 8rem;
    left: 5rem;
  }

  @media (max-width: 1240px) {
    width: 110px;
    height: 100px;
    top: 8rem;
    left: 3.5rem;
  }
  @media (max-width: 1024px) {
    width: 110px;
    height: 100px;
    top: 8rem;
    left: 3.5rem;
  }
`;
export const SelectRoleItemCollector = styled.div`
  position: absolute;
  top: 13rem;
  left: 17rem;
  width: 200px;
  height: 181px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  @media (max-width: 1710px) {
    width: 170px;
    height: 132px;
    top: 9rem;
    left: 16rem;
  }
  @media (max-width: 1536px) {
    width: 130px;
    height: 100px;
    top: 8rem;
    left: 13rem;
  }
  @media (max-width: 1440px) {
    width: 130px;
    height: 100px;
    top: 8rem;
    left: 14rem;
  }
  @media (max-width: 1400px) {
    width: 130px;
    height: 102px;
    top: 8rem;
    left: 12.5rem;
  }
  @media (max-width: 1240px) {
    width: 110px;
    height: 100px;
    top: 8rem;
    left: 10rem;
  }
  @media (max-width: 1024px) {
    width: 110px;
    height: 100px;
    top: 8rem;
    left: 10rem;
  }
`;
export const ButtonDiv = styled.div`
  position: absolute;
  top: 25rem;
  left: 4rem;
  width: 80%;
  @media (max-width: 1710px) {
    top: 19rem;
    left: 3rem;
    width: 80%;
  }
  @media (max-width: 1536px) {
    top: 15rem;
    left: 4rem;
    width: 70%;
  }
  @media (max-width: 1440px) {
    top: 15rem;
    left: 4rem;
    width: 80%;
  }
  @media (max-width: 1400px) {
    top: 15rem;
    left: 4.5rem;
    width: 66%;
  }
  @media (max-width: 1240px) {
    top: 14rem;
    left: 4rem;
    width: 63%;
  }
  @media (max-width: 1024px) {
    top: 14rem;
    left: 4rem;
    width: 63%;
  }
`;

// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
// export const TopBar = styled.div``;
