/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  Menu,
  Avatar,
  Typography,
  Badge,
  Spin,
  Space,
  Divider,
  Button,
  Modal,
  Steps,
  Popover
} from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  ClockCircleOutlined,
  ContactsOutlined,
  ContactsTwoTone,
  DollarCircleOutlined,
  PoweroffOutlined,
  SettingFilled,
  SettingOutlined,
  ShareAltOutlined,
  WalletFilled,
  GlobalOutlined,
  NotificationFilled,
  BellOutlined
} from "@ant-design/icons";

import WordedLogo from "../../images/worded_logo.svg";
import MLogo from "../../images/daam_wordmark.svg";
import Pencil from "../../images/icons/pencil.png";
import ProfileImagePlaceholder from "../../images/profile_placeholder.png";
import { ProfileImage, Image } from "..";
import { AuthContext } from "../../context/authContext";
import CategoriesSubmenu from "../../containers/categories/submenu/CategoriesSubmenu";
import AdminMenu from "../AdminMenu";
import {
  CenterStyles,
  Container,
  RightStyles,
  TopNavigationStyles,
  SiderStyles,
  LoginButton,
  MenuLink,
  MenuItem,
  StartButton,
  RightMenu,
  LogoutButton,
  AccountSetupModal,
  CreateButton,
  CopyableTypo
} from "./styles";
import * as actions from "../../redux/actions";
import * as ROUTES from "../../Routes";
import ProfileAvatar from "../Avatar";
import { NavLink } from "react-router-dom";
import { createProfile } from "../../scripts/createProfile";
import Notification from "../Notification";
import socket from "../../common/socketClient";

export default function AuthNavigation() {
  const { auth } = useSelector((obj) => obj);
  const {
    user,
    setUser,
    signOut,
    signIn,
    isCreator,
    isAdmin,
    isAgent,
    isCollector,
    hasProfile,
    hasAuctionWallet,
    hasDaamAccount,
    hasFUSDWallet,
    setupAuctionWallet,
    setupDaamAccount,
    setupFUSDWallet,
    setupProfile,
    acceptCreatorInvite,
    acceptAdminInvite,
    acceptAgentInvite,
    setMintState,
    verifyAll
  } = useContext(AuthContext);

  const [notification, setNotification] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const acceptInvitationLabel = () => {
    let label = "";
    if (user?.userType === "admin" && !user?.approved) {
      label = "Admin";
    } else if (user?.userType === "creator" && !user?.approved) {
      label = "Creator";
    } else if (user?.userType === "agent" && !user?.approved) {
      label = "Agent";
    } else return null;
    return `Accept ${label} Invitation`;
  };

  const acceptInvite = () => {
    setSettingAccount(true);
    if (user?.userType === "admin" && !user?.approved) {
      acceptAdminInvite({ onSuccess, onAcceptError });
    } else if (user?.userType === "creator" && !user?.approved) {
      acceptCreatorInvite({ onSuccess, onAcceptError });
    } else if (user?.userType === "agent" && !user?.approved) {
      acceptAgentInvite({ onSuccess, onError });
    }
    // setAlertCount((prev) => prev.alertCount - 1);
  };
  const onSuccess = (val) => {
    setInviteSuccess(true);
    const update = {
      where: { _id: user._id },
      update: {
        approved: true,
        invitation: "accepted"
      }
    };
    dispatch(
      actions.loginActions.updateUser(update, (res) => {
        dispatch(actions.loginActions._auth({ ...auth, approved: true }));
        setUser({ ...user, approved: true });
      })
    );
    setStepText(`Account Setup Completed!!!! Hurray!!!`);
    setSettingAccount(false);
    setRefresh(!refresh);
    // setCurrentStep((prev) => prev + 1);
    // setStepText(`Finalizing Account Setup`);
    // setupDaamAccount({ onSuccess: onDaamAccountSuccess, onError });
  };

  const onDaamAccountSuccess = (val) => {
    setCurrentStep((prev) => prev + 1);
    setStepText(`Account Setup Completed!!!! Hurray!!!`);
    setSettingAccount(false);
  };

  const onAcceptError = (val) => {
    setSettingAccount(false);
  };

  const onError = (val) => {
    setSettingAccount(false);
  };

  const menu = (
    <RightMenu style={{ flexDirection: "column" }}>
      <MenuItem key={Math.random()}>
        <Typography.Text>{user?.email}</Typography.Text>
      </MenuItem>
      <Divider />

      {user?.blockChainAddress?.length > 0 && (
        <MenuItem disable key={Math.random()} icon={<WalletFilled />}>
          <CopyableTypo copyable>
            {user?.blockChainAddress[0].addr}
          </CopyableTypo>
        </MenuItem>
      )}
      {/*
      // notification
      <MenuItem
        key={Math.random()}
        onClick={() => setNotification(true)}
        icon={<BellOutlined />}
      >
        Notifications <Badge count={2} />
      </MenuItem> */}
      {
        <>
          <MenuItem
            key={Math.random()}
            onClick={() =>
              window.open(
                "https://testnet-faucet.onflow.org/fund-account",
                "_blank",
                "noopener,noreferrer"
              )
            }
            icon={<DollarCircleOutlined />}
          >
            <strong>{` ${
              hasFUSDWallet && Number(auth?.hasFUSDWallet).toFixed(2)
            } FUSD`}</strong>
          </MenuItem>
          <MenuItem
            onClick={() => history.push(ROUTES.DASHBOARD)}
            key={Math.random()}
          >
            <div>Dashboard</div>
          </MenuItem>
          <MenuItem
            onClick={() => history.push(ROUTES.PROFILE)}
            key={Math.random()}
          >
            <div>Edit Profile</div>
          </MenuItem>
        </>
      }

      {user?.invitation === "sent" &&
        ((user?.userType === "creator" && !user?.approved) ||
          (user?.userType === "agent" && !user?.approved) ||
          (user?.userType === "admin" && !user?.approved)) && (
          <MenuItem
            key={Math.random()}
            danger
            onClick={() => {
              // acceptInvite();
              setStepText("Accepting Invitation......");
              setShowInvitaionModal(true);
              acceptInvite();
            }}
          >
            {!inviteSuccess && acceptInvitationLabel()}
          </MenuItem>
        )}

      {user?.blockChainAddress?.length > 0 &&
        user?.invitation === "pending" &&
        !user?.isCollector && (
          <MenuItem key={Math.random()} danger onClick={acceptInvite}>
            <Space size="middle">
              <Spin size="small" />
              <MenuItem>{`Wait for Invitation`}</MenuItem>
            </Space>
          </MenuItem>
        )}

      {!hasProfile && (
        <MenuItem key={Math.random()} danger onClick={setupProfile}>
          {`Create Profile`}
        </MenuItem>
      )}
      {/* {hasProfile && !hasAuctionWallet &&  */}
      {hasProfile && !hasAuctionWallet && (
        <MenuItem key={Math.random()} success onClick={setupAuctionWallet}>
          {`Create Auction Wallet`}
        </MenuItem>
      )}
      {!hasFUSDWallet && (
        <MenuItem key={Math.random()} success onClick={setupFUSDWallet}>
          {`Setup FUSD Wallet`}
        </MenuItem>
      )}
      {(isCreator || isAgent || isCollector) && (
        <>
          {!hasDaamAccount && (
            <MenuItem key={Math.random()} success onClick={setupDaamAccount}>
              {`Setup DAAM account`}
            </MenuItem>
          )}
        </>
      )}
      <Divider />

      {user && user?.email && (
        <>
          <MenuItem
            key={Math.random()}
            icon={<ContactsTwoTone />}
          >{`My Portfolio`}</MenuItem>
          <MenuItem
            key={Math.random()}
            icon={<ClockCircleOutlined />}
          >{`Trade History  `}</MenuItem>
          <MenuItem
            key={Math.random()}
            icon={<ShareAltOutlined />}
          >{`Invite Friends`}</MenuItem>
          <MenuItem
            key={Math.random()}
            icon={<SettingOutlined />}
          >{`Settings`}</MenuItem>
          <Divider />
        </>
      )}

      <LogoutButton
        type="primary"
        onClick={signOut}
        icon={<PoweroffOutlined />}
      >
        Log out
      </LogoutButton>
    </RightMenu>
  );

  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showInvitaionModal, setShowInvitaionModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [settingAccount, setSettingAccount] = useState(false);
  const [stepText, setStepText] = useState("Connect & setup account(s)");

  useEffect(() => {
    if (!auth?.address) {
      setShowConnectModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (user.address) {
      setShowConnectModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    socket.on("invitation_received", (data) => {
      dispatch(actions.loginActions._auth({ ...data }));
      setUser({ ...user, ...data, addr: data.address });
      if (data.invitation === "sent") {
        setStepText("Accept Invitation");
        setShowInvitaionModal(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignInSuccess = () => {
    setCurrentStep((prev) => prev + 1);
    setStepText("Creating Profile....");
    setupProfile({
      onSuccess: onProfileSuccess,
      onError: onProfileSetupError
    });
    setRefresh(!refresh);
  };

  const onProfileSuccess = () => {
    setCurrentStep((prev) => prev + 1);
    setStepText("Setting up Auction Wallet...");
    setupAuctionWallet({
      onSuccess: onAuctionWalletSuccess,
      onError: onProfileSetupError
    });
    setRefresh(!refresh);
  };

  const onAuctionWalletSuccess = () => {
    setCurrentStep((prev) => prev + 1);
    setStepText("Setting up FUSD Wallet...");
    setupFUSDWallet({
      onSuccess: onFUSDWalletSuccess,
      onError: onProfileSetupError
    });
    setRefresh(!refresh);
  };

  const onFUSDWalletSuccess = () => {
    setCurrentStep((prev) => prev + 1);
    setStepText("Setting up DAAM Account...");
    setupDaamAccount({
      onSuccess: () => {
        setCurrentStep((prev) => prev + 1);
        setStepText(`Setup Completed`);
        setSettingAccount(false);

        verifyAll();
        setRefresh(!refresh);

        //window.location.reload();
      },
      onError
    });
    // setStepText("Setup Completed");
    // setSettingAccount(false);
  };

  const onProfileSetupError = (err) => {
    setSettingAccount(false);
    //setCurrentStep((prev) => prev + 1);
  };

  const showBlockChainProfileCreationSteps = () => {
    const redirectToEditProfile = () => {
      if (stepText.includes("Setup Completed")) {
        history.push(ROUTES.PROFILE);
      }
    };
    return (
      <AccountSetupModal
        visible={showConnectModal}
        onCancel={() => setShowConnectModal(false)}
        footer={[
          <div className="d-flex justify-content-center">
            <LoginButton
              // onClick={() => setCurrentStep((prev) => prev + 1)}
              onClick={() => {
                if (stepText.includes("Setup Completed")) {
                  setShowConnectModal(false);
                } else {
                  setSettingAccount(true);
                  if (stepText.includes("Connect & setup account(s)")) {
                    signIn(onSignInSuccess, onProfileSetupError);
                  } else if (stepText.includes("Creating Profile")) {
                    setupProfile({
                      onSuccess: onProfileSuccess,
                      onError: onProfileSetupError
                    });
                  } else if (stepText.includes("Setting up Auction Wallet")) {
                    setupAuctionWallet({
                      onSuccess: onAuctionWalletSuccess,
                      onError: onProfileSetupError
                    });
                  } else if (stepText.includes("Setting up FUSD Wallet")) {
                    setupFUSDWallet({
                      onSuccess: onFUSDWalletSuccess,
                      onError: onProfileSetupError
                    });
                  } else if (stepText.includes("Setting up DAAM Account")) {
                    setupDaamAccount({
                      onSuccess: () => {
                        setCurrentStep((prev) => prev + 1);
                        setStepText(`Setup Completed`);
                        setSettingAccount(false);

                        verifyAll();
                        setRefresh(!refresh);

                        //window.location.reload();
                      },
                      onError
                    });
                  }
                }
              }}
              disabled={settingAccount}
              style={{ width: "unset" }}
            >
              {stepText}
            </LoginButton>
          </div>
        ]}
        closable={settingAccount ? false : true}
        afterClose={() => redirectToEditProfile()}
      >
        <Spin spinning={settingAccount} tip="">
          <Steps direction="vertical" current={currentStep}>
            <Steps.Step title={`Connect To Wallet 🤡`} />
            <Steps.Step title={`Create Profile 💼`} />
            <Steps.Step title={`Setup Auction Wallet 🧢`} />
            <Steps.Step title={`Setup FUSD Wallet 💸💰`} />
            <Steps.Step title={`Setup DAAM Account 💸💰`} />
          </Steps>
        </Spin>
      </AccountSetupModal>
    );
    // Modal.info({
    //   title: "To get started please connect to Wallet first!!!!!",
    //   icon: <GlobalOutlined />,
    //   footer: null,
    //   onOk: () => signIn(),
    //   // content: <LoginButton onClick={signIn}>Connect To Wallet</LoginButton>,
    //   okText: "Connect To Wallet",
    //   //okText: 'Connect To Wallet',
    // });
  };

  const showAcceptInvitaionModal = () => {
    return (
      <AccountSetupModal
        visible={showInvitaionModal}
        onCancel={() => setShowInvitaionModal(false)}
        footer={[
          <div className="d-flex justify-content-center">
            <LoginButton
              // onClick={() => setCurrentStep((prev) => prev + 1)}
              onClick={() => {
                stepText === "Accept Invitation"
                  ? acceptInvite()
                  : setShowInvitaionModal(false);
              }}
              disabled={settingAccount}
              style={{ width: "unset" }}
            >
              {stepText}
            </LoginButton>
          </div>
        ]}
        closable={true}
      >
        <Spin spinning={settingAccount} tip="">
          <Steps direction="vertical" current={currentStep}>
            <Steps.Step title={`Accept Invitation 🤡`} />
            {/* <Steps.Step title={`Finalizing Account Setup 💼`} /> */}
          </Steps>
        </Spin>
      </AccountSetupModal>
    );
  };

  return (
    <TopNavigationStyles>
      <Container>
        <Link to={ROUTES.DASHBOARD} className="d-none d-sm-none d-md-block">
          <Image src={WordedLogo} w="130px" />
        </Link>
        <Link to={ROUTES.DASHBOARD} className="d-md-none">
          <Image src={MLogo} w="30px" />
        </Link>
        <CenterStyles>
          {(auth.userType === "agent" || auth.userType === "admin") && (
            <MenuLink>
              <NavLink to={ROUTES.MANAGE_CREATOR_AGENT}>EDIT USERS</NavLink>
            </MenuLink>
          )}

          <MenuLink>
            <Link
              to={{
                pathname: `/app/details/${user?.addr}`,
                state: true
              }}
            >
              MY COLLECTION
            </Link>
          </MenuLink>
          <MenuLink to={ROUTES.AUTH_CATEGORIES}>
            <CategoriesSubmenu isAuth={true} />
          </MenuLink>
          {/* <MenuLink>
            <NavLink to="/about">ABOUT</NavLink>
          </MenuLink> */}
        </CenterStyles>
        <RightStyles>
          {user?.addr ? (
            <>
              {(isCreator || isAgent) && (
                <>
                  {hasFUSDWallet && hasDaamAccount && hasAuctionWallet && (
                    <>
                      <CreateButton
                        onClick={() => {
                          setMintState(0);
                          history.push(ROUTES.MINTING);
                        }}
                        disabled={!user?.approved}
                      >
                        Create
                      </CreateButton>
                    </>
                  )}
                </>
              )}
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                arrow
                placement="bottomRight"
              >
                <Badge count={2}>
                  <ProfileAvatar />
                </Badge>
              </Dropdown>
            </>
          ) : (
            <>
              <LoginButton onClick={signIn}>Connect</LoginButton>

              <Dropdown
                overlay={menu}
                trigger={["click"]}
                arrow
                placement="bottomRight"
              >
                <Badge count={2}>
                  <ProfileAvatar />
                </Badge>
              </Dropdown>
            </>
          )}
        </RightStyles>
      </Container>
      {showBlockChainProfileCreationSteps()}
      {showInvitaionModal && showAcceptInvitaionModal()}
      {notification && <Notification setNotification={setNotification} />}
    </TopNavigationStyles>
    // </SiderStyles>
  );
}
