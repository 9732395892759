import styled from "styled-components";
import { Typography, Card, Col, Button, Menu } from "antd";

const { Title, Text, Paragraph } = Typography;

export const TitleNFT = styled(Title)`
  text-align: center;
  font-size: 2em;
  font: normal normal 600 34px/118px Oswald;
  letter-spacing: 0.48px;
  color: #2f3234;
`;
export const CardStep3 = styled(Card)`
  width: 240;
  margin: 0em 4em;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0d3;
  border-radius: 6px 6px 22px 22px;
  opacity: 1;
  & .ant-card-cover {
    border-bottom: 1px solid #cdd0d3;
  }
  & .ant-card-body {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000b;
    border-radius: 0px 0px 22px 22px;
  }
`;

export const ColStep3 = styled(Col)`
  text-align: left;
  padding: 0 3em 3em 3em;
`;

export const TextElement = styled(Text)`
  color: white;
`;

export const ParagraphElement = styled(Paragraph)`
  text-align: left;
  font: normal normal 600 16px/30px Lato;
  letter-spacing: 0.38px;
  color: #1a1d1f;
`;

export const ViewNFT = styled(Button)`
  height: 50px;
  // width: 150px;
  font: normal normal bold 16/24px Lato;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;

  background: #1a1d1f 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  opacity: 1;
  &:hover {
    background: #fc4448 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
  }

  // background: #fc4448 0% 0% no-repeat padding-box;
  // box-shadow: 0px 1px 1px #00000029;
  // border: 1px solid #fc4448;
  // &:hover {
  //   background: #1a1d1f 0% 0% no-repeat padding-box;
  //   border: 1px solid #1a1d1f;
  // }
`;

export const ShareNFT = styled(Button)`
  height: 50px;
  // width: 150px;
  font: normal normal bold 18px/24px Lato;
  letter-spacing: 0.4px;
  color: #fc4448;
  opacity: 1;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;

  &:hover {
    background: #fc4448 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border: 1px solid #fc4448;
    color: #ffffff;
  }
`;

export const CardMeta = styled(Card.Meta)`
  background: #ffffff;
  border-radius: 0px 0px 22px 22px;
`;

export const ShareMenu = styled(Menu)`
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .ant-dropdown-menu-title-content {
    text-align: center;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0.32px;
    color: #1a1d1f;
  }
`;

export const ShareText = styled(Typography.Text)`
  font: normal normal normal 16px/30px Lato;
  letter-spacing: 0.38px;
  color: #1a1d1f;
`;

export const ViewOnFlowScan = styled(Typography.Paragraph)`
  font: normal normal normal 16px/30px Lato;
  letter-spacing: 0.38px;
  color: #1a1d1f;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 0em;
`;
