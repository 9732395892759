/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BankOutlined } from "@ant-design/icons";
import ProfileAvatar from "../../components/Avatar";
import {
  DashboardBanner,
  TitleBanner,
  BannerItem,
  BannerItemWrapper,
  CercleItem,
  BlockchainAddress,
  UserType,
  UserTypeName,
  SalesDetails,
  SalesItemName,
  UserTypeWrapper
} from "./dashboard.styled";
import * as config from "../../config/keys";
import { Avatar, Image, Space, Typography } from "antd";

import cercleImg1 from "../../images/icons/calendar-star.png";
import cercleImg2 from "../../images/icons/calendar-week.png";
import cercleImg3 from "../../images/icons/Coins.png";
import paintbrush from "../../images/icons/paint_brush.svg";
import cercleImg4 from "../../images/icons/crown.png";
import { AuthContext } from "../../context/authContext";
import CreatorImage from "../../images/Avtar/CreatorAvtar.png";
import AdminProfile from "../../images/Avtar/adminAvtar.png";
import AgentImage from "../../images/Avtar/agentAvtar.png";
import CollectorImage from "../../images/Avtar/collectorAvtar.png";
import { userActions } from "../../redux/actions";

const { Title, Text } = Typography;

const DashboardCommon = (props) => {
  const { user } = useContext(AuthContext);
  const { auth } = useSelector((obj) => obj);

  const [dataImg, setDataImg] = useState(null);
  

  const getColors = () => {
    if (auth?.userType === "agent") return "#AABBD4";
    else if (auth?.userType === "creator") return "#81D8B2";
    else if (auth?.userType === "admin") return "#E8A7A8";
    else if (auth?.userType === "collector") return "#EBD694";
    else return "#EBD694";
  };

  return (
    <>
      <UserTypeWrapper>
        {auth?.userType && (
          <div
            className="d-flex justify-content-center"
            style={{ position: "absolute", margin: "20px" }}
          >
            <UserType color={getColors()}>
              {/* <BankOutlined /> */}
              <Avatar
                size="small"
                src={
                  <Image
                    preview={false}
                    style={{
                      width: "95%",
                      height: "95%",
                      objectFit: "contain"
                    }}
                    src={
                      auth?.isCreator
                        ? CreatorImage
                        : auth?.isAgent
                        ? AgentImage
                        : auth?.isAdmin
                        ? AdminProfile
                        : auth?.isCollector
                        ? CollectorImage
                        : { backgroundColor: "rgb(205, 208, 211)" }
                    }
                  />
                }
              />
              <UserTypeName>{auth?.userType?.toString()}</UserTypeName>
            </UserType>
          </div>
        )}
      </UserTypeWrapper>
      <DashboardBanner
        style={
          auth?.heroImg
            ? {
                background:
                  "url(" +
                  `${config.serverURL}/files/images/${auth?.heroImg}` +
                  ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                // background : `url(${avatarImg})`
                objectFit: "contain"
              }
            : { backgroundColor: "rgb(205, 208, 211)" }
        }
      >
        {/* <BannerChild>
        <BannerChildButton>
          {"Total Sales"}
          <Title level={4}>{"1000 USD"}</Title>
          {"0 Flow Pending"}
        </BannerChildButton>

        <BannerChildButton Bottom>
          {"Net sales revenue"}
          <Title level={4}>{"4000 USD"}</Title>
          {"0 Flow Pending"}
        </BannerChildButton>
      </BannerChild> */}

        <TitleBanner>
          {/* In other case of daam dashboard, we show daam logo 
        In other cases like creator and agent, we show the avatar */}
          {/* {props?.isDAAMAgency ? (
          <Image src={daamLogo} alt="daamLogo" h="40px" />
        ) : (
          <AvatarBanner src={props?.userInfo?.userAvatar} alt="avatar" />
        )} */}
          <Space
            direction="vertical"
            size="small"
            className="mt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Title className="title">
              <Space>
                <ProfileAvatar />
                <Space direction="vertical" className="userTitle">
                  {auth?.name}
                </Space>
              </Space>
            </Title>
            {/* {auth?.blockChainAddress?.length > 0 && (
            <BlockchainAddress code level={2}>
              {auth?.blockChainAddress[0].addr}
            </BlockchainAddress>
          )} */}
          </Space>
          <BannerItemWrapper>
            <Space size={"large"}>
              <BannerItem>
                <Space direction="vertical">
                  <CercleItem>
                    {/* <Avatar src={CalendarOutlined} alt="avatar" /> */}
                    {/* <Icon component={CalendarOutlined} style={{ fontSize: "16px", color: "#08c" }}/> */}
                    <img src={cercleImg1} alt="avatar" />
                    {/* <CalendarOutlined style={{ fontSize: "16px" }} /> */}
                  </CercleItem>
                  {/* <DashboardAvtar size="large" icon={<CalendarOutlined s />} /> */}
                  <SalesDetails>{props?.bannerInfo?.statsToDate}</SalesDetails>
                  To Date
                </Space>
              </BannerItem>

              <BannerItem>
                <Space direction="vertical">
                  <CercleItem>
                    <img src={cercleImg2} alt="avatar" />
                  </CercleItem>
                  <SalesDetails>{props?.bannerInfo?.statsWeekly}</SalesDetails>
                  Weekly
                </Space>
              </BannerItem>
              <BannerItem>
                <Space direction="vertical">
                  <CercleItem>
                    <img src={cercleImg3} alt="avatar" />
                  </CercleItem>
                  <SalesDetails>{props?.bannerInfo?.statsForSale}</SalesDetails>
                  For Sale
                </Space>
              </BannerItem>
              <BannerItem>
                <Space direction="vertical">
                  <CercleItem>
                    <img src={cercleImg4} alt="avatar" />
                  </CercleItem>
                  <SalesDetails>{props?.bannerInfo?.statsFutured}</SalesDetails>
                  <SalesItemName>Featured</SalesItemName>
                </Space>
              </BannerItem>
            </Space>
          </BannerItemWrapper>
        </TitleBanner>
      </DashboardBanner>
    </>
  );
};
export default DashboardCommon;
