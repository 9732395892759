/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, Spin } from "antd";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import Logo from "../../../images/worded_logo.svg";
import loginImage from "../../../images/login/Signup.png";
import {
  FrameWrapper,
  Paragraph,
  FormItem,
  ClickButton,
  RegisterText,
  InputText,
  InputPassword,
} from "../CreateAccount.styled";
import { SignupContext } from "../../../context/signupContext";
import * as Routes from "../../../Routes";

const SignupContainer = ({ createAccount }) => {
  const { signupData, setSignupData } = useContext(SignupContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [termsAgreed, setIsTermsAgreed] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ ...signupData });
  }, [signupData]);

  return (
    <Spin spinning={isDisabled}>
      <Row align="top" justify="center">
        <Col
          lg={8}
          xl={8}
          md={8}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Row align="top" justify="center" className="pt-5">
            <Col
              span={20}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "-18px",
              }}
            >
              <Image src={Logo} w="230px" />
            </Col>
            <Col span={20}>
              <FrameWrapper>
                <Col>
                  <Row style={{ align: "center" }} justify="center">
                    <Col className="pt-">
                      <Paragraph style={{ align: "center" }}>
                        Start your physical to <br /> digital future.
                      </Paragraph>
                    </Col>
                  </Row>
                  <Row
                    style={{ align: "center" }}
                    justify="center"
                    gutter={[16, 16]}
                  >
                    <Col>
                      <RegisterText
                        style={{
                          align: "center",
                          justify: "center",
                          marginTop: "20px",
                        }}
                      >
                        <span className="rtext">Have an account?</span>{" "}
                        <Link
                          to={Routes.LOGIN}
                          className="rlink"
                          // onClick={() => {
                          //   window.location.href = Routes.LOGIN;
                          // }}
                        >
                          Login
                        </Link>
                      </RegisterText>
                    </Col>
                  </Row>
                </Col>

                <Form
                  form={form}
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="text-center"
                  onFinish={() => {
                    setIsDisabled(true);
                    createAccount(signupData);
                  }}
                >
                  <FormItem
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      { type: "email", message: "Email is not valid !" },
                    ]}
                  >
                    <InputText
                      style={{
                        border: "1px solid #999B9E",
                        borderRadius: "4px",
                        opacity: 1,
                        height: "45px",
                      }}
                      disabled={signupData?.query ? true : false}
                      placeholder={"Email"}
                      onChange={(e) =>
                        setSignupData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </FormItem>
                  <FormItem
                    name="password"
                    rules={[
                      { required: true, message: "Please enter your password" },
                    ]}
                  >
                    <InputPassword
                      style={{
                        border: "1px solid #999B9E",
                        borderRadius: "4px",
                        opacity: 1,
                        height: "45px",
                        fontWeight: "bold",
                      }}
                      placeholder="Password"
                      onChange={(e) =>
                        setSignupData((prev) => ({
                          ...prev,
                          password: e.target.value,
                          // password: cryptoJS.SHA256(
                          //   e.target.value,
                          //   "DAAMAgency"
                          // ).toString(),
                        }))
                      }
                    />
                  </FormItem>
                  {/* <FormItem
                    name="referralcode"
                    // rules={[{ required: true }]}
                  >
                    <InputText
                      placeholder={"Referral Code"}
                      onChange={(e) =>
                        setSignupData((prev) => ({
                          ...prev,
                          referralcode: e.target.value,
                        }))
                      }
                    />
                  </FormItem> */}
                  <FormItem
                    name="confiramation"
                    // rules={[{ required: true }]}
                  >
                    <Checkbox
                      onChange={(e) => setIsTermsAgreed(e.target.checked)}
                    >
                      <label>
                        {`  I agree to `}
                        <a
                          href="https://www.daam.agency/terms-of-service"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.daam.agency/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </label>
                    </Checkbox>
                  </FormItem>
                  <Form.Item>
                    <ClickButton
                      htmlType="submit"
                      inverse={"true"}
                      disabled={!termsAgreed}
                    >
                      Create Account
                    </ClickButton>
                  </Form.Item>
                </Form>
              </FrameWrapper>
            </Col>

            <Col
              lg={14}
              xl={14}
              md={14}
              sm={24}
              xs={24}
              style={{ textAlign: "center", marginTop: "100px" }}
            >
              {/*Homepage redirect style added*/}
              <Link
                to={"/"}
                style={{
                  textAlign: "center",
                  font: "normal normal 500 16px/106px Lato",
                  letterSpacing: 0,
                  color: "#FF4040",
                }}
                // onClick={() => history.push("/")}
              >
                🡰 Back to Homepage
              </Link>
            </Col>
          </Row>
        </Col>

        <Col lg={14} xl={14} md={14} sm={24} xs={24}>
          {/* <ImageWrapper> */}
          <img
            src={loginImage}
            alt="signup"
            style={{ height: "100vh", width: "100%" }}
          />
          {/* </ImageWrapper> */}
        </Col>
      </Row>
    </Spin>
  );
};

export default SignupContainer;
