import styled from 'styled-components';

export const SearchBarStyles = styled.div`
  display: flex;
  position: relative;
  width: 442px;
  margin-left: auto;
  margin-right: auto;
  &:focus {
    outline: none;
  }
`;
export const SearchBarIcon = styled.div`
  position: absolute;
  top: 11px;
  left: 12px;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  background-size: cover;
  background-image: url(${(props) => props});
  background-position: center;
`;
export const SearchBarField = styled.input`
  display: flex;
  width: 100%;
  height: 45px;
  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 20px;
  padding-left: 48px;
  box-shadow: 0 1px 1px #000029;
  appearance: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
`;
