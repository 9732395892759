/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Steps } from "antd";
import { toast } from "react-toastify";
import ChangePassword from "./Changepassword";
// import SignupContainer from "./signup";
import VerifyContainer from "../CreateAccount/verify";
// import PersonalContainer from "./personaldetails";
// import SelectRoleContainer from "./selectrole";
import { loginActions } from "../../redux/actions";
import {
  ForgotPWProvider,
  ForgotPWContext,
} from "../../context/forgotPasswordContext";
import * as Routes from "../../Routes";

const ForgetPassword = ({ filterParam }) => {
  const [current, setCurrent] = React.useState(0);
  const { user } = useSelector((obj) => obj);
  const { redirect } = useSelector((obj) => obj);

  const { forgotPWData, setforgotPWData } = useContext(ForgotPWContext);

  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");

  const descriptionRef = useRef();
  const titleRef = useRef();
  const history = useHistory();

  const next = (event) => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // useEffect(() => {
  //   if (user?.data?.acknowledged && user?.data?.insertedId) {
  //     history.push(Routes.LOGIN);
  //   }
  // }, [history, user]);

  // useEffect(()=>{
  //   if(user && user.moveToNext){
  //     next();
  //   }
  // }, [user?.moveToNext])

  // useEffect(()=>{
  //   console.log(redirect)
  //   history.push(Routes[redirect.RedirectTo])
  // },[redirect])

  const createUserAccount = () => {
    setTimeout(() => {
      user?.success ? next() : history.push(Routes.LOGIN);
    }, 500);
  };

  const cancel = () => {
    history.push("/create");
  };

  const createAccount = () => {
    // dispatch(loginActions.sendVerificationMail(signupData));
    next();
  };

  const validateOtp = (value) => {
    if (value.toString() === user?.otp.toString()) {
      toast("Code Verification successful", {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: true,
      });
      // dispatch(loginActions.ForgotPassword(forgotPWData));
      setTimeout(() => {
        next();
      }, 1000);
    } else {
      toast("Invalid Verification Code", {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        icon: true,
      });
    }
  };

  const forgotPWContent = () => {
    // dispatch(loginActions.sendVerificationMail(signupData));
    dispatch(
      loginActions.sendVerificationMail(
        { ...forgotPWData, isResetingPassword: "resetPassword" },
        () => {
          next();
        }
      )
    );
    // next();
  };

  const Resetpassword = () => {
    dispatch(loginActions.ForgotPassword(forgotPWData));
  };

  const steps = [
    {
      content: <ChangePassword forgotPWContent={forgotPWContent} />,
    },
    {
      content: (
        <VerifyContainer
          onValidateOtp={validateOtp}
          onResendEmail={() => console.log(forgotPWData)}
        />
      ),
    },
    {
      content: <ChangePassword pwd Resetpassword={Resetpassword} />,
    },
  ];

  return (
    <>
      <Steps current={current}></Steps>
      {steps[current].content}
    </>
  );
};

const ForgotPWContainer = () => {
  return (
    <ForgotPWProvider>
      <ForgetPassword />
    </ForgotPWProvider>
  );
};

export default ForgotPWContainer;
