import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Space, Typography, DatePicker, Tabs, Spin } from "antd";
import moment from "moment";
import PageTitle from "../../../components/PageTitle";
import UploadNFTBrowseFiles from "../../../components/UploadNFTBrowseFiles";
import Image from "../../../components/Image";
import sellImg from "../../../images/icons/minting/Coins_black.png";
import auctionImg from "../../../images/icons/minting/Gavel.png";

import {
  FormCollapse,
  FormField,
  FormTextArea,
  FormUnit
} from "../../../components/Form";
import { MintContext } from "../../../context/mintContext";
import PhysicalObjectDetails from "./PhysicalObjectDetails";
import {
  PanelWrapper,
  InputForNumber,
  CheckboxMint,
  FormCollapseNoHeader,
  Tabpanel,
  TabLabel
} from "./Step1.styled";
import Button from "../../../components/Button";
import Cancel from "../../../images/times2x.png";
import {
  DAMMServiceFees,
  RoyalitySlider,
  SetPriceInputNumber
} from "../setPrice/setPrice.styled";
import { MintingButton, MintingText } from "../Minting.styled";
import MoneyEdit from "../../../images/money-check-edit-alt.png";
import Collection from "./Collection";
import CreatorListforagent from "./CreatorList";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const DEFAULT_ADDITIONS_VALUE = 1;
const MIN_ADDITIONS_VALUE = DEFAULT_ADDITIONS_VALUE;
const MAX_ADDITIONS_VALUE = 999;
const UNLIMITED_ADDITIONS_VALUE = 0;
const DEFAULT_PHYSICAL_OBJECT = true;

const royalityMarks = {
  10: "10%",
  20: "20%",
  30: {
    style: {
      // color: "#f50",
    },
    label: <strong>30%</strong>
  }
};

const UploadStep = ({
  descriptionRef,
  titleRef,
  errorInputMessage,
  filterParam,
  onCancelClick,
  onConfirmDetails,
  buyPrice,
  errorMsgPrice,
  setErrorMsgPrice,
  setErrorMsgFile,
  errorMsgFile
}) => {
  const { setMintResult, mintResult } = useContext(MintContext);
  // eslint-disable-next-line no-unused-vars
  const { auth } = useSelector((obj) => obj);

  const [nbAdditions, setNbAdditions] = useState(1);
  const [showSpinning, setShowSpinning] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [physicalObject, setIsPhysicalObject] = useState(
    DEFAULT_PHYSICAL_OBJECT
  );

  useEffect(() => {
    setMintResult((prev) => ({
      ...prev,
      isUnlimited,
      isPhysical: physicalObject,
      series: nbAdditions,
      // price: mintResult.price || 10000,
      royaltyPer: prev.royaltyPer || 15,
      creationDate: moment(),
      saleType: "sell",
      mintedDate: moment(),
      creationDateString: moment().format("YYYY-MM-DD"),
      creationDateTimemtamp: new Date(moment().format("YYYY-MM-DD")).getTime(),
    
      // creationDate: new Date().toLocaleDateString("en-US"),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnlimitedChange = (checked) => {
    const isChecked = checked;
    setIsUnlimited(!isUnlimited);
    isChecked
      ? setNbAdditions(UNLIMITED_ADDITIONS_VALUE)
      : setNbAdditions(DEFAULT_ADDITIONS_VALUE);
    setMintResult((prev) => ({ ...prev, isUnlimited: !prev.isUnlimited }));
  };
  // eslint-disable-next-line no-unused-vars
  const onPhysicalObjectChange = (checked) => {
    setIsPhysicalObject(!physicalObject);
    setMintResult((prev) => ({ ...prev, isPhysical: !prev.isPhysical }));
  };

  const onAdditionsChange = (val) => {
    // setIsUnlimited(!isUnlimited);
    setMintResult((prev) => ({ ...prev, series: val }));
  };

  const onPhysicalObjectDetailsChange = (e) => {
    setMintResult((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const onDateChange = (date, datestring) => {
    setMintResult((prev) => ({
      ...prev,
      mintedDate: date,
      creationDate: date,
      creationDateString: datestring,
      creationDateTimemtamp: new Date(datestring).getTime()
    }));
  };

  const onAuctionStartChange = (date, datestring) => {
    setMintResult((prev) => ({
      ...prev,
      auctionStartDatetime: date,
      auctionStartDatetimeString: datestring,
      auctionStartDatetimeTimestamp: new Date(datestring).getTime()
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const priceChange = (val) => {
    setMintResult((prev) => ({ ...prev, price: val }));
  };

  const sliderChange = (val) => {
    setMintResult((prev) => ({ ...prev, royaltyPer: val }));
  };
  const collectionSelected = (val) => {
    setMintResult((prev) => ({ ...prev, collection: val || null }));
  };
  const collectionRemoved = (val) => {
    setMintResult((prev) => ({ ...prev, collection: null }));
  };
  const creatorSelected = (val) => {
    setMintResult((prev) => ({ ...prev, mintForCreator: val }));
  };
  const creatorRemoved = (val) => {
    setMintResult((prev) => ({ ...prev, mintForCreator: "" }));
  };

  const onStartCollection = () => setShowSpinning(true);

  const onCollectionComplete = () => setShowSpinning(false);

  return (
    <Spin spinning={showSpinning}>
      <Row align="middle" justify="end">
        {/* <Col span={1} className="arrow-left">
          <ButtonCancel order="tertiary" size="regular" ml="16px">
            <Image src={ArrowLeft} w="16px" />
          </ButtonCancel>
        </Col> */}
        <Col span={22}>
          <PageTitle
            m={"1em"}
            style={{
              font: "normal normal 600 34px Oswald",
              letterSpacing: "0.48px"
            }}
          >
            Upload {mintResult.mintLabel}
          </PageTitle>
        </Col>
        <Col span={2} justify="end">
          <Button
            order="tertiary"
            size="regular"
            ml="16px"
            onClick={onCancelClick}
          >
            <Image src={Cancel} w="16px" />
          </Button>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{ marginLeft: "unset", marginRight: "unset" }}
      >
        <Col lg={12} sm={24} xl={12} md={12} xs={24}>
          <Row>
            <Col span={24}>
              <UploadNFTBrowseFiles setErrorMsgFile={setErrorMsgFile} />
            </Col>
            {errorMsgFile && <p style={{ color: "#FC4448" }}>{errorMsgFile}</p>}
          </Row>
        </Col>
        <Col lg={12} sm={24} xl={12} md={12} xs={24}>
          <Row
            gutter={[8, 8]}
            style={{ marginLeft: "unset", marginRight: "unset" }}
          >
            <FormUnit>
              {errorInputMessage && (
                <p style={{ color: "#FC4448" }}>{errorInputMessage}</p>
              )}
              <FormField
                name="title"
                placeholder="Title"
                value={mintResult.title}
                onChange={(e) =>
                  setMintResult((prev) => ({ ...prev, title: e.target.value }))
                }
                ref={titleRef}
              />
            </FormUnit>
            <FormUnit>
              <FormTextArea
                name="description"
                placeholder="Description"
                value={mintResult.description}
                onChange={(e) =>
                  setMintResult((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                rows={4}
                ref={descriptionRef}
              ></FormTextArea>
            </FormUnit>
            <FormUnit>
              <FormCollapseNoHeader
                defaultActiveKey={["1"]}
                showArrow={false}
                ghost
              >
                <PanelWrapper showArrow={false} collapsible="disabled" key="1">
                  <Row>
                    {/* <Col span={12}>{"Details"}</Col> */}
                    <Col span={24}>{"Additions"}</Col>
                  </Row>
                  <Row>
                    {/* <Col span={12}>
                      <CheckboxMint
                        onChange={onPhysicalObjectChange}
                        defaultChecked={DEFAULT_PHYSICAL_OBJECT}
                      >
                        {"Physical"}
                      </CheckboxMint>

                    </Col> */}
                    <Col span={12}>
                      {!isUnlimited && (
                        <>
                          {"1 of "}
                          <InputForNumber
                            value={
                              mintResult.series === 0
                                ? mintResult.nbAdditions
                                : mintResult.series
                            }
                            disabled={isUnlimited}
                            size="small"
                            min={MIN_ADDITIONS_VALUE}
                            max={MAX_ADDITIONS_VALUE}
                            w="56px"
                            defaultValue={DEFAULT_ADDITIONS_VALUE}
                            onChange={onAdditionsChange}
                          />
                        </>
                      )}
                    </Col>
                    <Col span={12}>
                      <CheckboxMint onChange={onUnlimitedChange}>
                        {"Unlimited"}
                      </CheckboxMint>
                    </Col>
                  </Row>
                </PanelWrapper>
              </FormCollapseNoHeader>
            </FormUnit>
            <FormUnit>
              {mintResult?.category === "Physical" && physicalObject && (
                <PhysicalObjectDetails
                  onChange={onPhysicalObjectDetailsChange}
                  onDateChange={onDateChange}
                  mintResult={mintResult}
                />
              )}
            </FormUnit>
            {auth.isAgent && (
              <FormUnit>
                <CreatorListforagent
                  onCreatorSelected={creatorSelected}
                  onCreatorRemoved={creatorRemoved}
                  mintResult={mintResult}
                />
              </FormUnit>
            )}
            <FormUnit>
              {
                <Collection
                  onCollectionSelected={collectionSelected}
                  onRemoveCollection={collectionRemoved}
                  mintResult={mintResult}
                  onStartCollection={onStartCollection}
                  onCollectionComplete={onCollectionComplete}
                />
              }
            </FormUnit>
          </Row>
        </Col>

        <Col span={24}>
          <Tabpanel
            type="card"
            onChange={(key) =>
              setMintResult((prev) => ({
                ...prev,
                saleType: key
              }))
            }
          >
            <Tabs.TabPane
              tab={
                <Space>
                  <Image src={sellImg} />
                  <Typography.Text>{`Sell`} </Typography.Text>
                </Space>
              }
              key="sell"
            >
              <Row
                gutter={[4, 4]}
                style={{ marginLeft: "unset", marginRight: "unset" }}
              >
                <Col span={24}>
                  <Typography.Title level={5}>{`Set Price`}</Typography.Title>
                </Col>
                <Col xl={6} md={6} l={6} sm={12} xs={24}>
                  <Space direction="vertical">
                    <TabLabel>Buy it now</TabLabel>
                    <SetPriceInputNumber
                      controls={false}
                      small={"true"}
                      addonAfter="USD $"
                      value={mintResult.price}
                      onChange={(val) => {
                        setErrorMsgPrice("");
                        setMintResult((prev) => ({
                          ...prev,
                          buyNowPrice: val,
                          price: val
                        }));
                      }}
                      ref={buyPrice}
                    />
                    {errorMsgPrice && (
                      <p style={{ color: "#FC4448" }}>{errorMsgPrice}</p>
                    )}
                  </Space>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <Space>
                  <Image src={auctionImg} />
                  <Typography.Text>{`Auction`}</Typography.Text>
                </Space>
              }
              key="auction"
            >
              <Row
                gutter={[4, 4]}
                style={{ marginLeft: "unset", marginRight: "unset" }}
              >
                <Col span={24}>
                  <Typography.Title level={5}>{`Set Price`}</Typography.Title>
                </Col>

                <Col span={24}>
                  <Row justify="space-between">
                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical" size={"small"}>
                        <TabLabel>{`Minimum Price`}</TabLabel>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.minimumPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              minimumPrice: val
                            }))
                          }
                        />
                      </Space>
                    </Col>
                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical">
                        <TabLabel>{`Reserve Price`}</TabLabel>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.maximumPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              maximumPrice: val
                            }))
                          }
                        />
                      </Space>
                    </Col>

                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical">
                        <TabLabel>Buy it now</TabLabel>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.buyNowPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              buyNowPrice: val,
                              price: val
                            }))
                          }
                          ref={buyPrice}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="pt-3">
                  <Typography.Title level={5}>{`Timeline`}</Typography.Title>
                </Col>
                <Col xl={6} md={6} l={6} sm={12} xs={24}>
                  <Space direction="vertical">
                    <TabLabel>Auction starts</TabLabel>
                    <DatePicker
                      showTime
                      onChange={onAuctionStartChange}
                      id="auctionStart"
                      value={mintResult.auctionStartDatetime}
                    />
                  </Space>
                </Col>
                <Col xl={6} md={6} l={6} sm={12} xs={24}>
                  <Space direction="vertical">
                    <TabLabel>Auction expires</TabLabel>
                    <DatePicker
                      showTime
                      onChange={(date, datestring) =>
                        setMintResult((prev) => ({
                          ...prev,
                          auctionExpireDatetime: date,
                          auctionExpireDatetimeString: datestring,
                          auctionExpireDatetimeTimestamp: new Date(
                            datestring
                          ).getTime()
                        }))
                      }
                      id="createdDate"
                      value={mintResult.auctionExpireDatetime}
                    />
                  </Space>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabpanel>
        </Col>
        {/* <Col span={24}>
          <FormCollapse
            showArrow={true}
            accordion
            collapsible="header"
            ghost
            defaultActiveKey={["1"]}
            style={{ flexDirection: "row" }}
          >
            <PanelWrapper
              hideArrow
              showArrow={false}
              header={
                <Space>
                  <NodeCollapseOutlined />
                  {`Auction`}
                </Space>
              }
              key="1"
              style={{
                flexDirection: "row",
              }}
            >
              <Row
                gutter={[4, 4]}
                style={{ marginLeft: "unset", marginRight: "unset" }}
              >
                <Col span={24}>
                  <Typography.Title level={5}>{`Set Price`}</Typography.Title>
                </Col>

                <Col span={24}>
                  <Row justify="space-between">
                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical">
                        <Typography.Text>{`Minimum Price`}</Typography.Text>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.minimumPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              minimumPrice: val,
                            }))
                          }
                        />
                      </Space>
                    </Col>
                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical">
                        <Typography.Text>{`Reserve Price`}</Typography.Text>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.maximumPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              maximumPrice: val,
                            }))
                          }
                        />
                      </Space>
                    </Col>

                    <Col xl={7} md={7} l={7} sm={12} xs={24}>
                      <Space direction="vertical">
                        <Typography.Text>Buy it now</Typography.Text>
                        <SetPriceInputNumber
                          small={"true"}
                          addonAfter="USD $"
                          value={mintResult.buyNowPrice}
                          onChange={(val) =>
                            setMintResult((prev) => ({
                              ...prev,
                              buyNowPrice: val,
                              price: val,
                            }))
                          }
                        />
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="pt-3">
                  <Typography.Title level={5}>{`Timeline`}</Typography.Title>
                </Col>
                <Col xl={6} md={6} l={6} sm={12} xs={24}>
                  <Space direction="vertical">
                    <Typography.Text>Auction starts</Typography.Text>
                    <DatePicker
                      showTime
                      onChange={onAuctionStartChange}
                      id="auctionStart"
                      value={mintResult.auctionStartDatetime}
                    />
                  </Space>
                </Col>
                <Col xl={6} md={6} l={6} sm={12} xs={24}>
                  <Space direction="vertical">
                    <Typography.Text>Auction expires</Typography.Text>
                    <DatePicker
                      showTime
                      onChange={(date, datestring) =>
                        setMintResult((prev) => ({
                          ...prev,
                          auctionExpireDatetime: date,
                          auctionExpireDatetimeString: datestring,
                          auctionExpireDatetimeTimestamp: new Date(
                            datestring
                          ).getTime(),
                        }))
                      }
                      id="createdDate"
                      value={mintResult.auctionExpireDatetime}
                    />
                  </Space>
                </Col>
              </Row>
            </PanelWrapper>
          </FormCollapse>
        </Col> */}
        <Col span={24}>
          {/* <FormUnits fd="column">
            <FormUnit> */}
          <FormCollapse
            showArrow={true}
            accordion
            collapsible="header"
            ghost
            defaultActiveKey={["1"]}
            style={{ flexDirection: "row" }}
          >
            <PanelWrapper
              hideArrow
              showArrow={false}
              header="Royalties"
              key="1"
              style={{
                flexDirection: "row"
              }}
            >
              <Row justify="space-between">
                <Col xl={12} l={12} m={12} sm={12} xs={24}>
                  <Space direction="vertical">
                    <Row justify="space-between">
                      <Col span={20}>
                        <RoyalitySlider
                          marks={royalityMarks}
                          max={30}
                          min={10}
                          onChange={sliderChange}
                          value={mintResult.royaltyPer}
                        />
                      </Col>
                      <Col>
                        <InfoCircleOutlined style={{ color: "red" }} />
                      </Col>
                    </Row>

                    <Space>
                      <Image src={MoneyEdit} w="16px" />
                      <DAMMServiceFees>
                        {"DAAM Service fees 2.5%"}
                      </DAMMServiceFees>
                    </Space>
                  </Space>
                </Col>
                <Col xl={12} l={12} m={12} sm={12} xs={24}>
                  <MintingButton
                    // onClick={minting}
                    key="1"
                    onClick={onConfirmDetails}
                    type="primary"
                    size="large"
                    className="mintingNextButton"
                    style={{
                      width: "100%",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <MintingText>Confirm Details</MintingText>
                  </MintingButton>
                </Col>
              </Row>
            </PanelWrapper>
          </FormCollapse>
          {/* </FormUnit>
          </FormUnits> */}
        </Col>
      </Row>
    </Spin>
  );
};

export default UploadStep;
