import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// submit_and_auction.cdc
// Creator uses to submit Metadata & Approve Rpyalty
// Used to create an auction for a first-time sale.

import FungibleToken        from ${keys.fungibleToken} 
import Categories           from ${keys.categories}
import MetadataViews        from ${keys.metadataViews}
import ${keys.daamImport}         from ${keys.daam}
import ${keys.auctionHouseImport} from ${keys.auctionHouse}
import FUSD                 from ${keys.fusd}

// argument have two modes:
// when ipfs = true; first arument is cid, second argument is path 
// when ipfs = false; first arument thumbnail String, second argument is thumbnailType and can not be nil
pub fun setFile(ipfs: Bool, string_cid: String, type_path: String?): {MetadataViews.File} {
    pre { ipfs || !ipfs && type_path != nil }
    if ipfs { return MetadataViews.IPFSFile(cid: string_cid, path: type_path) }
    switch type_path! {
        case "text": return ${keys.daamImport}.OnChain(file: string_cid)
        case "jpg": return ${keys.daamImport}.OnChain(file: string_cid)
        case "jpeg": return ${keys.daamImport}.OnChain(file: string_cid)
        case "png": return ${keys.daamImport}.OnChain(file: string_cid)
        case "bmp": return ${keys.daamImport}.OnChain(file: string_cid)
        case "gif": return ${keys.daamImport}.OnChain(file: string_cid)
        case "http": return MetadataViews.HTTPFile(url: string_cid)
    }
    panic("Type is invalid")
}

transaction(
    name: String, max: UInt64?, categories: [String], description: String, misc: String,  // Metadata information
    ipfs_thumbnail: Bool, thumbnail_cid: String, thumbnailType_path: String, // Thumbnail setting: IPFS, HTTP(S), FILE(OnChain)
    ipfs_file: Bool, file_cid: String, fileType_path: String,                // File setting: IPFS, HTTP(S), FILE(OnChain)
    interact: AnyStruct?, percentage: UFix64,
    
    start: UFix64, length: UFix64, isExtended: Bool, extendedTime: UFix64, /*requiredCurrency: Type,*/
    incrementByPrice: Bool, incrementAmount: UFix64, startingBid: UFix64, reserve: UFix64, buyNow: UFix64, reprint: UInt64?
    )
{    
    let requestGen  : &${keys.daamImport}.RequestGenerator
    let metadataGen : &${keys.daamImport}.MetadataGenerator
    let metadataCap : Capability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint}>
    let auctionHouse: &${keys.auctionHouseImport}.AuctionWallet

    let name        : String
    let max         : UInt64?
    var categories  : [Categories.Category]
    let interact    : AnyStruct?
    let description : String
    let misc        : String
    let thumbnail   : {String : {MetadataViews.File}}
    let file        : {String : MetadataViews.Media}
    let royalties   : MetadataViews.Royalties

    // Auction
    let start       : UFix64
    let length      : UFix64
    let isExtended  : Bool
    let extendedTime: UFix64
    let incrementByPrice: Bool
    let incrementAmount : UFix64
    let startingBid : UFix64?
    let reserve     : UFix64
    let buyNow      : UFix64
    let reprint     : UInt64?

    prepare(creator: AuthAccount) {
        self.metadataGen  = creator.borrow<&${keys.daamImport}.MetadataGenerator>(from: ${keys.daamImport}.metadataStoragePath)!
        self.requestGen   = creator.borrow<&${keys.daamImport}.RequestGenerator>( from: ${keys.daamImport}.requestStoragePath)!
        self.auctionHouse = creator.borrow<&${keys.auctionHouseImport}.AuctionWallet>(from: ${keys.auctionHouseImport}.auctionStoragePath)!
        self.metadataCap  = creator.getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint}>(${keys.daamImport}.metadataPublicPath)!
        
        self.name         = name
        self.max          = max
        self.description  = description
        self.interact     = interact
        self.misc         = misc
        self.thumbnail    = {thumbnailType_path : setFile(ipfs: ipfs_thumbnail, string_cid: thumbnail_cid, type_path: fileType_path)}
        let fileData      = setFile(ipfs: ipfs_file, string_cid: file_cid, type_path: fileType_path)
        let fileType      = ipfs_file ? "ipfs" : fileType_path
        self.file         = {fileType : MetadataViews.Media(file: fileData, mediaType: fileType)}

        let royalties    = [ MetadataViews.Royalty(
            receiver: creator.getCapability<&AnyResource{FungibleToken.Receiver}>(MetadataViews.getRoyaltyReceiverPublicPath()),
            cut: percentage,
            description: "Creator Royalty" )
        ]
        self.royalties = MetadataViews.Royalties(royalties)

        self.categories = []
        for cat in categories {
            self.categories.append(Categories.Category(cat))
        }

        self.start            = start
        self.length           = length
        self.isExtended       = isExtended
        self.extendedTime     = extendedTime
        self.incrementByPrice = incrementByPrice
        self.incrementAmount  = incrementAmount
        self.startingBid      = startingBid
        self.reserve          = reserve
        self.buyNow           = buyNow
        self.reprint          = reprint 
    }

    pre { percentage >= 0.01 || percentage <= 0.3 : "Percentage must be between 10% to 30%." }

    execute {
        let mid = self.metadataGen.addMetadata(name: self.name, max: self.max, categories: self.categories,
        description: self.description, misc: self.misc, thumbnail: self.thumbnail, file: self.file, interact: self.interact)

        self.requestGen.acceptDefault(mid: mid, metadataGen: self.metadataGen, royalties: self.royalties)
        let vault <- FUSD.createEmptyVault()

        self.auctionHouse.createAuction(
            metadataGenerator: self.metadataCap, nft: nil, id: mid, start: self.start, length: self.length, isExtended: self.isExtended,
            extendedTime: self.extendedTime, vault: <-vault ,incrementByPrice: self.incrementByPrice, incrementAmount: self.incrementAmount,
            startingBid: self.startingBid, reserve: self.reserve, buyNow: self.buyNow, reprintSeries: self.reprint
        )!

        log("New Auction has been created.")
        log("Metadata Submitted: ".concat(mid.toString()))
    }
}
`;

export const mint_and_list_to_marketplace = (mintResult, opts = {}) => {
    const {
        categories,
        thumbnail,
        fileName,
        file,
        percentage,
        auctionStartDatetimeTimestamp,
        auctionExpireDatetimeTimestamp,
        minimumPrice,
        buyNowPrice,
        title,
        description,
        series,
    } = mintResult;

    const { authorization } = fcl.currentUser();

    // console.log("mint_and_list_to_marketplace", mintResult);

    // prettier-ignore
    return tx([
        fcl.transaction(CODE),
        fcl.args([
            fcl.arg(title, types.String), //name
            // fcl.arg(series, types.UInt64),
            fcl.arg(series === 0 ? null : series, types.Optional(types.UInt64)), //max
            // fcl.arg(false,types.Bool), //feature
            fcl.arg(categories, types.Array(types.String)), //categories
            // fcl.arg(null, types.Optional(types.Dictionary)), //inCollection
            fcl.arg(description, types.String), //description
            fcl.arg("", types.String),        //misc
            fcl.arg(false, types.Bool), //ipfs_thumbnail
            fcl.arg(thumbnail, types.String),  // thumbnail_cid
            fcl.arg(fileName.split(".").pop(), types.String),//thumbnailType_path

            fcl.arg(false, types.Bool), // ipfs_file
            fcl.arg(file, types.String), // file_cid
            fcl.arg(fileName.split(".").pop(), types.String), //fileType_path
            // fcl.arg(data, types.String),
            // fcl.arg(thumbnail, types.String),
            // fcl.arg(file, types.String),
            fcl.arg(null, types.Optional(types.Struct)), //interact
            fcl.arg(percentage, types.UFix64), //percentage
            // fcl.arg(Number(mid), types.UInt64), //AuctionID
            // fcl.arg(String(auctionStartDatetimeTimestamp), types.UFix64), //start
            fcl.arg(String(Math.round((auctionStartDatetimeTimestamp / 1000) + 200).toFixed(1)), types.UFix64), //start 
            fcl.arg(String(Math.round((auctionExpireDatetimeTimestamp / 1000) + 200).toFixed(1)), types.UFix64), //end
            // fcl.arg("999999.0", types.UFix64), //length
            fcl.arg(false, types.Bool), // isExtended
            fcl.arg("0.0", types.UFix64), //extendedTime
            fcl.arg(false, types.Bool), //incrementByPrice
            fcl.arg("0.01", types.UFix64), //incrementAmount
            // Added by Nikhil- Just to check 
            // This was giving error when NTF is $2 and starting bid becomes 0
            // so comment below and changed to - 0.9
            // =================== 
            // fcl.arg(String(Number(price - 2).toFixed(1)) , types.UFix64), //startingBid
            fcl.arg(String(Number(minimumPrice - 0.9).toFixed(1)), types.UFix64), //startingBid
            //-------------------------
            fcl.arg(String(Number(minimumPrice - 0.1).toFixed(1)), types.UFix64), //reserve,
            fcl.arg(String(Number(buyNowPrice).toFixed(1)), types.UFix64), //BuyNow
            fcl.arg(series === 0 ? null : series, types.Optional(types.UInt64)), //reprint series
        ]),
        fcl.proposer(authorization),
        fcl.payer(authorization),
        fcl.authorizations([
            authorization
        ]),
        fcl.limit(keys.fclLimit)
    ], opts)
};
