/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FolderOpenOutlined,
  PlusOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  Upload
} from "antd";
import PageTitle from "../../../components/PageTitle";
import { AuthContext } from "../../../context/authContext";

import * as actions from "../../../redux/actions";
import UploadBrowseFiles from "./../../profile/UploadBrowseFiles";
import { AddNewCollection } from "../../../scripts/addNewCollection";

import * as config from "../../../config/keys";
import {
  ButtonTitle,
  CloseIcon,
  CollectionNameInputContainer,
  CreateButton,
  DescriptionContent,
  InputContent,
  ModalBody,
  ModalContainer,
  Title,
  TitleContainer,
  UploadContainerArea,
  CollectionNameFormItem
} from "./uploadCollection.styled";
import { toast } from "react-toastify";
import { ToastText } from "../Minting.styled";

const CollectionUpload = (props) => {
  const { auth } = useSelector((obj) => obj);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState(null);
  const [file, setfile] = useState(null);
  const [fileVal, setfileVal] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const toastId = useRef(null);
  const onAvtarUploadSuccess = async (info) => {
    setfileVal(info.filename);
  };
  const onFinish = (value) => {
    toastId.current = toast(
      <Space size="large">
        <Spin size="large" />
        <ToastText>Creating Collection</ToastText>
      </Space>,
      {
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false, // Remove the closeButton
        theme: "colored",
        pauseOnFocusLoss: false,
        icon: false
      }
    );
    var filetype = file.fileType.split("/");
    AddNewCollection(
      {
        name: value.collectionName,
        description: value.description || "",
        externalURL: file.filePreview,
        squareImage: file.filePreview,
        squareImageType: filetype[1],
        bannerImage: file.filePreview,
        bannerImageType: filetype[1],
        social: "",
        web: ""
      },
      {
        onStart: () => {
          props?.onStartCollection();
        },
        onSuccess: () => {
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Collection Created Successfully 👌`}</ToastText>
            ),
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          });
          toast.dismiss(toastId);
          Object.keys(value).forEach(
            (key) => value[key] === undefined && delete value[key]
          );
          Object.assign(value, { collectionImage: fileVal });
          // console.log([value, ...(auth.collections ? auth.collections : [])]);
          const update = {
            where: { _id: auth._id },
            update: {
              collections: [
                ...(auth.collections ? auth.collections : []),
                value
              ]
            }
          };
          dispatch(
            actions.loginActions.updateUserCollection(update, () => {
              if (props?.onCollectionCreated) {
                props?.onCollectionCreated();
              }
            })
          );
        },
        onError: (err) =>
          toast.update(toastId.current, {
            render: (
              <ToastText>{`Cannot create Collection 🤯, Try Again..: ${err} `}</ToastText>
            ),
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            theme: "colored",
            pauseOnFocusLoss: false,
            icon: true
          }),
        onComplete: () => {
          props?.onCollectionComplete();
        }
      }
    );

    props?.setVisible(false);

    // console.log(auth);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const filechange = async (info) => {
    const { fileList, file } = info;
    setFileList(fileList);
    if (file) {
      file.preview = await getBase64(file.originFileObj);
    }
    setfile((prev) => ({
      ...prev,
      file: file,
      filePreview: file.preview,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      fileLastModified: file.lastModified
    }));

    if (info.file.status === "uploading") {
      setDisableBtn(true);
    }
    if (info.file.status === "done") {
      setDisableBtn(false);
    }
  };

  return (
    <ModalContainer>
      <TitleContainer>
        <Title>Create a Collection</Title>
        <CloseIcon
          onClick={() => {
            props?.setVisible(false);
          }}
        >
          <CloseOutlined style={{ fontSize: "24px", color: "red" }} />
        </CloseIcon>
      </TitleContainer>
      <ModalBody>
        <UploadContainerArea>
          <UploadBrowseFiles
            name="file"
            onChange={filechange}
            fileList={fileList}
            files={file}
            action={`${config.serverURL}/files/upload`}
            onUploadSuccess={onAvtarUploadSuccess}
            width={props?.width}
            height={props?.height}
            title={props?.title}
          />
        </UploadContainerArea>
        <Form
          name="basic"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <CollectionNameInputContainer>
            <CollectionNameFormItem
              name="collectionName"
              rules={[
                { required: true, message: "Please input collection Name!" }
              ]}
            >
              <InputContent placeholder="Collection Name" />
            </CollectionNameFormItem>
            <Form.Item name="description">
              <DescriptionContent placeholder="Description" />
            </Form.Item>
          </CollectionNameInputContainer>
          <Form.Item>
            <CreateButton htmlType="submit" disabled={disableBtn}>
              <ButtonTitle>Create</ButtonTitle>
            </CreateButton>
          </Form.Item>
        </Form>
      </ModalBody>
    </ModalContainer>
  );
};

export default CollectionUpload;
