/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button } from "antd";
import {
  CreateNFTIconsWrapper,
  CreateNFTWrapper,
  ButtonNFT,
  IconNFT,
  HandPeaceWrapper,
} from "../containers/minting/createNFT/CreateNFT.styled";
import { Link } from "react-router-dom";
import Image from "../components/Image";
import handPeace from "../images/hand-peace.png";
import { Typography } from "antd";
import vestPatches from "../images/icons/minting/vest-patches.png";
import cameraRetro from "../images/icons/minting/camera-retro.png";
import camcorder from "../images/icons/minting/camcorder.png";
import image from "../images/icons/minting/image.png";
import headVr from "../images/icons/minting/head-vr.png";
import palette from "../images/icons/minting/palette.png";
import turntable from "../images/icons/minting/turntable.png";
import userAlien from "../images/icons/minting/user-alien.png";
import UploadStep from "../containers/minting/uploadStep";
import { MintContext, MintProvider } from "../context/mintContext";
import {
  MintingCenter,
  MintingWrapper,
} from "../containers/minting/Minting.styled";
import Text from "antd/lib/typography/Text";
import { CaretRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import * as Routes from "../Routes";

const { Title } = Typography;

const categoriesInfo = [
  {
    name: "DIGITAL",
    buttons: [
      {
        title: "Digital Art",
        icon: <IconNFT src={image} alt="image" />,
        extension: ["jpeg", "jpg", "png", "gif","tif"],
      },
      {
        title: "Audio",
        icon: <IconNFT src={turntable} alt="turntable" />,
        extension: ["m4a", "flac", "mp3", "mp4", "wav", "wma", "aac"],
      },
      {
        title: "Video",
        icon: <IconNFT src={camcorder} alt="camcorder" />,
        extension: ["mp4", "mov", "wmv", "avi", "avchd", "MPEG-2", "mkv"],
      },
      {
        title: "Photography",
        icon: <IconNFT src={cameraRetro} alt="Photography" />,
        extension: ["jpeg", "jpg", "png", "gif", "eps", "psd", "pdf", "ai"],
      },
      {
        title: "VR/AR",
        icon: <IconNFT src={headVr} alt="headVr" />,
        extension: [],
      },
    ],
  },
  {
    name: "PHYSICAL",
    buttons: [
      {
        title: "Physical Art",
        icon: <IconNFT src={palette} alt="image" />,
        // extension: [],
        extension: ["jpeg", "jpg", "png", "gif", "webp","mp4", "mov", "wmv", "avi", "avchd", "MPEG-2", "mkv"],

      },
      {
        title: "Sculpture",
        icon: <IconNFT src={userAlien} alt="userAlien" />,
        extension: [],
      },
      {
        title: "Fashion",
        icon: <IconNFT src={vestPatches} alt="vestPatches" />,
        extension: [],
      },
      {
        title: "Photography",
        icon: <IconNFT src={cameraRetro} alt="Photography" />,
        extension: [],
      },
    ],
  },
];
const Mint = () => {
  const history = useHistory();
  const [category, setCategory] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const { minting, setMintResult } = useContext(MintContext);

  const descriptionRef = useRef();
  const titleRef = useRef();

  const categoryItem = (categoryInfo) => {
    return (
      <>
        <br />
        <Title
          strong
          type="danger"
          level={5}
          style={{
            font: "normal normal 600 16px/24px Oswald",
            letterSpacing: "0.77px",
            color: "#FF4040",
            "text-transform": "uppercase",
            margin: "0px 15px 18px 12px",
          }}
        >
          {categoryInfo?.name}
        </Title>
        <Row gutter={[8, 8]}>
          {categoryInfo?.buttons.map((buttonInfo, index) => (
            <Col span={8} key={Math.random()}>
              <Link
                to={{
                  pathname: `/app/mint/${buttonInfo.title}`,
                  state: { extensions: buttonInfo.extension.toString() },
                }}
              >
                <ButtonNFT
                // onClick={() => {
                //   console.log(buttonInfo.extension)
                //   setMintResult((prev) => ({
                //     ...prev,
                //     mintType: buttonInfo.title,
                //     allowedExtensions: buttonInfo.extension,
                //   }));
                //   history.push(`/app/mint/${buttonInfo.title}`);
                // }}
                >
                  {buttonInfo.icon}
                  {" " + buttonInfo.title}
                </ButtonNFT>
              </Link>
            </Col>
          ))}
        </Row>
        <br />
      </>
    );
  };
  return (
    <CreateNFTWrapper>
      <Title
        level={3}
        style={{
          font: "normal normal 600 34px/51px Oswald",
          letterSpacing: "1.02px",
          color: "#1A1D1F",
        }}
      >
        What are you minting today?
      </Title>
      <CreateNFTIconsWrapper>
        <HandPeaceWrapper>
          <Image src={handPeace} alt="handPeace" />
        </HandPeaceWrapper>
        <Row gutter={[64, 16]}>
          {categoriesInfo.map((categoryInfo, index) => {
            return (
              <Col span={12} key={Math.random()}>
                {categoryItem(categoryInfo)}
              </Col>
            );
          })}
        </Row>
      </CreateNFTIconsWrapper>
    </CreateNFTWrapper>
  );
};

const MintPage = () => {
  return (
    <MintProvider>
      <Mint />
    </MintProvider>
  );
};

export default MintPage;
