import styled from "styled-components";

function setOrderStyles(order) {
    let styles = {}
    switch(order) {
        case 'primary':
            styles = `
                background-color:#3DCB8C;
                color:white;
            `
        break;
        case 'full-red':
            styles = `
                background-color:#FF4040;
                color:white;
            `
        break;
        case 'tertiary':
            styles = `
                background-color:transparent;
                border:1px solid #CDD0D3;
            `
        break;
        case 'tertiary-red':
            styles = `
                background-color:transparent;
                color:red;
                border:1px solid #FC4448;
            `
        break;
        case 'tertiary-black':
            styles = `
                background-color:transparent;
                color:black;
                border:1px solid black;
            `
        break;
        default:
          
    }
    return styles
}
function setSizeStyles(size) {
    let styles = {}
    switch(size) {
        case 'small':
            styles = `
                width:32px;
                height:32px;
                padding-left:8px;
                padding-right:8px;
            `
        break;
        case 'regular':
            styles = `
                min-width:48px;
                font-size:14px;
                height:40px;
                padding-left:12px;
                padding-right:12px;
            `
        break;
        default:
          
    }
    return styles
}
function setCornersStyles(size) {
    let styles = {}
    switch(size) {
        case 'full-radius':
            styles = `
              border-radius:30px;
            `
        break;
        default:
          
    }
    return styles
}


export const ButtonStyles = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:${props => props.br ? props.br:'5px'};
    ${props => props.ml && `margin-left:${props.ml};`}
    ${props => props.mr && `margin-right:${props.mr};`}
    ${props => props.order && setOrderStyles(props.order)}
    ${props => props.size && setSizeStyles(props.size)}
    ${props => props.corners && setCornersStyles(props.corners)}
    &:hover {
        cursor:pointer;
    }
`