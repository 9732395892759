import React from "react";
import { MintingWrapper } from "./Minting.styled";
import Steps from "./stepsContainer";

const MintingContainer = ({ filterParam }) => {
  return (
    <MintingWrapper>
      <Steps filterParam={filterParam}></Steps>
    </MintingWrapper>
  );
};

export default MintingContainer;
