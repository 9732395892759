import styled from 'styled-components';

export const NFTExplorerHeaderStyles = styled.header`
  display: flex;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 35px;
`;

export const NFtExplorerTitle = styled.h3`
  color: black;
  font-size: 25px;
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font-family: normal normal normal 34px/51px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
