import { Button, Form } from "antd";
import ReactVerificationInput from "react-input-verification-code";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const FrameWrapper = styled.div`
  // max-width: 80%;
  // padding-top: 10em;
  // padding-left: 25em;
  padding-top: 10%;
`;

export const ImageWrapper = styled.div`
  max-width: 80%;
  max-height: 50%;
`;

export const Paragraph = styled.h3`
  display: flex;
  justify: center;
  justify-content: center;

  text-align: center;
  font: normal normal 500 34px/106px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
`;

export const RegisterText = styled.p`
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  // justify:"center"
  rlink {
    justify: center;
    text-align: center;
    font: normal normal bold 16px Lato;
    letter-spacing: 0px;
    color: #ff4040;
  }
  rtext {
    justify: center;
    text-align: center;
    font: normal normal 500 16px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
  cardlabel {
    text-align: left;
    font: normal normal bold 16px/48px Oswald;
    letter-spacing: 0.72px;
    color: #2f3234;
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
    justify: center;
  }
  label {
    text-align: center;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    text-transform: capitalize;
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
`;

export const ClickButton = styled(Button)`
  width: 100%;

  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  height: 45px;
  text-align: center;
  // font: normal normal bold 20px/24px "Lato";
  font-family: "Lato";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 20px/24px;
  letter-spacing: 0.4px;
  color: #1a1d1f;
  opacity: 1;
  background-color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
  &:hover {
    background-color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
    color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  }
`;

export const OTPVerificationInput = styled(ReactVerificationInput)`
  width: 100% !important;
  div {
    background-color: red;
  }
  & .ReactInputVerificationCode__item {
    background-color: red;
  }
  &.ReactInputVerificationCode__item {
    background-color: red !important;
  }
  & div {
    width: 40px;
    height: 50px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
    border-radius: 10px;
    opacity: 1;
  }
`;

// verify main div
export const VerifyMainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export const VerifyCenterDiv = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;
export const VerifyCenterDivItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  width: 550px;
  height: 600px;
  background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #00000029;
  border-radius: 77px;
  opacity: 1;
  padding-top: 80px;

  @media (max-width: 1720px) {
    width: 500px;
    height: 600px;
  }

  @media (max-width: 1536px) {
    width: 500px;
    height: 600px;
  }
  @media (max-width: 1440px) {
    width: 450px;
    height: 550px;
  }
  @media (max-width: 1240px) {
    width: 400px;
    height: 450px;
    padding-top: 40px;
  }
  @media (max-width: 1024px) {
    width: 400px;
    padding-top: 40px;
    height: 450px;
  }
`;
export const VerifyTitle = styled.span`
  color: var(--unnamed-color-2f3234);
  text-align: left;
  font: normal normal 500 34px/48px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
  @media (max-width: 1400px) {
    font: normal normal 500 24px/28px Oswald;
  }
  @media (max-width: 1240px) {
    font: normal normal 500 24px/28px Oswald;
  }
  @media (max-width: 1024px) {
    font: normal normal 500 24px/28px Oswald;
  }
`;
export const VerifySubTitle = styled.span`
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  width: 348px;
  color: var(--unnamed-color-1a1d1f);
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  padding-top: 20px;
  @media (max-width: 1400px) {
    width: 328px;
    font: normal normal 500 12px/18px Lato;
  }
  @media (max-width: 1240px) {
    width: 328px;
    font: normal normal 500 12px/18px Lato;
  }
  @media (max-width: 1024px) {
    width: 328px;
    font: normal normal 500 12px/18px Lato;
  }
`;
export const VerifyEmailTitle = styled(Link)`
  color: var(--unnamed-color-ff4040);
  text-align: center;
  font: normal normal bold 16px/24px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  @media (max-width: 1400px) {
    font: normal normal bold 12px/18px Lato;
  }
  @media (max-width: 1240px) {
    font: normal normal bold 12px/18px Lato;
  }
  @media (max-width: 1024px) {
    font: normal normal bold 12px/18px Lato;
  }
`;
export const VerifyCodeValidTitle = styled.span`
  /* UI Properties */
  color: var(--unnamed-color-1a1d1f);
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  margin-top: 10px;
  @media (max-width: 1400px) {
    font: normal normal bold 12px/18px Lato;
  }
  @media (max-width: 1240px) {
    font: normal normal bold 12px/18px Lato;
  }
  @media (max-width: 1024px) {
    font: normal normal bold 12px/18px Lato;
  }
`;
export const VerifyFormItem = styled.div`
  width: 100%;
  position: relative;
  margin-top: 3rem;
  @media (max-width: 1240px) {
    margin-top: 2rem;
  }
  @media (max-width: 1024px) {
    margin-top: 2rem;
  }
`;
export const ButtonDiv = styled.div`
  position: absolute;
  top: 14rem;
  left: 4rem;
  width: 80%;

  @media (max-width: 1536px) {
    top: 15rem;
    left: 3.5rem;
    width: 80%;
  }
  @media (max-width: 1440px) {
    top: 13rem;
    left: 3rem;
    width: 78%;
  }
  @media (max-width: 1240px) {
    top: 12rem;
    left: 3rem;
    width: 75%;
  }
  @media (max-width: 1024px) {
    top: 12rem;
    left: 3rem;
    width: 75%;
  }
`;

export const OTPVerificationInputVerify = styled(ReactVerificationInput)`
  width: 70%;
  div {
    background-color: red;
  }
  & .ReactInputVerificationCode__item {
    background-color: red;
  }
  &.ReactInputVerificationCode__item {
    background-color: red !important;
  }
  & div {
    width: 40px;
    height: 50px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
    border-radius: 10px;
    opacity: 1;
  }

  @media (max-width: 1240px) {
    width: 60%;
    & div {
      width: 30px !important;
      height: 40px !important;
    }
  }
  @media (max-width: 1024px) {
    width: 60%;
    & div {
      width: 30px !important;
      height: 40px !important;
    }
  }
`;

export const VerifySubTitleBottom = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--unnamed-color-1a1d1f);
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  width: 400px;

  @media (max-width: 1400px) {
    width: 300px;
    font: normal normal 500 12px/18px Lato;
  }
  @media (max-width: 1240px) {
    width: 300px;
    font: normal normal 500 12px/18px Lato;
  }
  @media (max-width: 1024px) {
    width: 300px;
    font: normal normal 500 12px/18px Lato;
  }
`;
export const FormItemVerify = styled(Form.Item)`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 70%;
  .ant-form-item-label {
    padding: 0;
    justify: center;
  }
  label {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    ${"" /* text-transform: capitalize; */}
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
  placeholder {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    text-transform: capitalize;
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
`;

// export const VerifyMainDiv=styled.div``
// export const VerifyMainDiv=styled.div``
// export const VerifyMainDiv=styled.div``
// export const VerifyMainDiv=styled.div``
// export const VerifyMainDiv=styled.div``
