

import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
// import * as types from "@onflow/types"
import { tx } from "./util/tx"

const CODE = fcl.cdc`
// clear_old_collection.cdc
// Debugging Tool
import DAAM_Mainnet_V8 from ${keys.daam}

transaction() {
  prepare(signer: AuthAccount) {
      let collection = signer.borrow<&DAAM_Mainnet_V8> (from: DAAM_Mainnet_V8.collectionStoragePath)
      let nfts = collection?.getIDs()
      if nfts != nil {
          for token in nfts! {
              let nft <- collection?.withdraw(withdrawID: token)
              destroy nft
          }
          log("NFTs' cleared.")
      }        
      destroy collection
      signer.unlink(DAAM_Mainnet_V8.collectionPublicPath)
      log("Wallet cleared.")
  } 
}
`

export const Nuke = async (opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx([
    fcl.transaction(CODE),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts)
}