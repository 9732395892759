/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CategoriesWrapper,
  CategoriesMenuWrapper,
  CategoriesHeaderWrapper,
  CheckboxRow,
  CustomizedTag,
  CustomizedDivider,
  IconNFTCategories,
  FilterCheckbox,
  FlexBox,
  BoxWrapper
} from "./Categories.styled";
import RelevanceSelect from "./RelevanceSelect";
import { Typography, Avatar, Row, Col, Spin, Space } from "antd";
import avatarImg from "../../images/profile_placeholder.png";
import { useAuction } from "../../context/auctions.context";
import { safeParse } from "../../scripts/util/safeParse";
import { Collection } from "../../components/collection";
import { useDispatch, useSelector } from "react-redux";
import { AdminActions } from "../../redux/actions";
import Nftfilter from "./NftFilter";

const { Title, Text } = Typography;

const CategoriesContainer = (props) => {
  const [visibleTagVideo, setVisibleTagVideo] = useState(true);
  const [visibleTagDigitalArt, setVisibleTagDigitalArt] = useState(true);
  const { filterList } = useSelector((obj) => obj);
  const { filterParam } = useParams();
  const [checkedFilter, setCheckedFilter] = useState([]);
  const [relevanceFilter, setrelevanceFilter] = useState("");
  const { auctions, getAuctions, getAuctionsforPublicURL } = useAuction();
  const [auctionData, setAuctionData] = useState(
    auctions?.sort((a, b) => (Number(a.start) < Number(b.start) ? 1 : -1))
  );
  const dispatch = useDispatch();
  const { userList, auth } = useSelector((obj) => obj);

  useEffect(() => {
    dispatch(AdminActions.getUsers());
  }, [dispatch]);

  // useEffect(() => {
  //   if (filterParam) {
  //     setCheckedFilter([filterParam]);
  //   }
  // }, []);

  useEffect(() => {
    if (filterParam) {
      setCheckedFilter([filterParam]);
    }
  }, [filterParam]);

  useEffect(() => {
    if (auth.address) {
      getAuctions();
    } else {
      getAuctionsforPublicURL();
    }
  }, [auth]);

  useEffect(() => {
    if (auctions && auctions.length > 0) {
      if (relevanceFilter) {
        filterAry(relevanceFilter);
      } else {
        setAuctionData(auctions);
      }
    }
  }, [auctions]);

  const soldPieces = [
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      title: "You Are The Technology",
      avatar: <Avatar src={avatarImg} alt="avatar" />
    }
  ];
  // console.log("user list", filterList);
  const onCheckChange = (e) => {
    let tmp = checkedFilter;
    if (e.target.checked) {
      tmp.push(e.target.value);
    } else {
      tmp = tmp.filter((x) => x !== e.target.value);
    }

    tmp = [...new Set(tmp)];
    setCheckedFilter([...tmp]);
  };

  useEffect(() => {
    if (auctions && auctions.length > 0) {
      filterAry(relevanceFilter);
    }
  }, [checkedFilter]);

  const FilteringCheckbox = (item) => {
    return (
      <CheckboxRow
        key={Math.random()}
        style={{ justifyContent: "space-between" }}
      >
        <Col className={`d-flex justify-content-center align-items-center`}>
          <IconNFTCategories src={item.icon} alt="text" />
          <Text
            style={{
              color: checkedFilter.includes(item.value) ? "#FF4040" : "#1A1D1F"
            }}
          >
            {item.label}
          </Text>
        </Col>
        <Col flex="5px">
          <FilterCheckbox
            onChange={onCheckChange}
            value={item.value}
            checked={checkedFilter.includes(item.value)}
          />
        </Col>
      </CheckboxRow>
    );
  };
  const CreationPieces = (creationPieces) => {
    return (
      // <Row>
      <Spin spinning={auctions === null} tip="Fetching NFT's.....">
        {/* <FlexBox> */}
        <Row style={{ marginTop: "0.5em" }}>
          {auctions !== null &&
            auctionData
              ?.filter(
                (x) =>
                  x.metaDataInfo !== null &&
                  (parseInt(x.auctionInfo?.length) === 999999 ||
                    new Date((x.auctionInfo?.length - 200) * 1000).getTime() >
                      new Date().getTime())
              )
              .map(({ auctionInfo, metaDataInfo }) => {
                const { creatorInfo, edition, thumbnail } = metaDataInfo;
                const { auctionID, buyNow, mid } = auctionInfo;
                const filterAdd = userList?.users?.find((x) => {
                  return x.address === creatorInfo?.creator;
                });

                // const isDisable =
                //   parseInt(auctionInfo?.length) === 999999
                //     ? false
                //     : new Date((auctionInfo?.length - 200) * 1000).getTime() <
                //       new Date().getTime();

                return (
                  // <BoxWrapper key={Math.random()}>
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
                    <Collection
                      // to={
                      //   !isDisable
                      //     ? {
                      //         pathname: auth.address
                      //           ? `/app/art/${creatorInfo?.creator}/${auctionID}`
                      //           : `/art/${creatorInfo?.creator}/${auctionID}`,
                      //         state: true
                      //       }
                      //     : null
                      // }
                      to={{
                        pathname: auth.address
                          ? `/app/art/${creatorInfo?.creator}/${auctionID}`
                          : `/art/${creatorInfo?.creator}/${auctionID}`,
                        state: true
                      }}
                      //title={`${edition?.name} ${isDisable ? "(Expired)" : ``}`}
                      title={`${edition?.name}`}
                      price={parseFloat(buyNow)}
                      flowPrice={(parseFloat(buyNow) / 12).toFixed(2)}
                      image={thumbnail[Object.keys(thumbnail)[0]].file}
                      avatarImage={filterAdd?.avtarImg}
                      authorLink={
                        auth.address
                          ? `/app/details/${creatorInfo?.creator}`
                          : `/details/${creatorInfo?.creator}`
                      }
                      data={{ auctionInfo, metaDataInfo }}
                      disable={false}
                    />
                  </Col>
                  // </BoxWrapper>
                );
              })}
        </Row>
        {/* </FlexBox> */}
      </Spin>
      // </Row>
    );
  };

  const filterAry = (filter) => {
    let filterdData = [...auctions];
    if (auctions.length > 0 && checkedFilter.length > 0) {
      filterdData = auctions?.filter(
        (x) =>
          x.metaDataInfo !== null &&
          (checkedFilter.includes(x.metaDataInfo?.category[0]?.name) ||
            checkedFilter.includes(x.metaDataInfo?.category[1]?.name))
      );
    }

    switch (filter) {
      case "lowprice":
        setAuctionData([
          ...filterdData?.sort((a, b) =>
            Number(a.auctionInfo.buyNow) > Number(b.auctionInfo.buyNow) ? 1 : -1
          )
        ]);
        break;
      case "highprice":
        setAuctionData([
          ...filterdData?.sort((a, b) =>
            Number(a.auctionInfo.buyNow) < Number(b.auctionInfo.buyNow) ? 1 : -1
          )
        ]);

        break;
      case "nameAsc":
        setAuctionData([
          ...filterdData?.sort((a, b) => {
            a = a.metaDataInfo?.edition?.name || "";
            b = b.metaDataInfo?.edition?.name || "";
            return a.localeCompare(b);
          })
        ]);
        break;
      case "nameDsc":
        setAuctionData([
          ...filterdData?.sort((a, b) => {
            a = a.metaDataInfo?.edition?.name || "";
            b = b.metaDataInfo?.edition?.name || "";

            return b.localeCompare(a);
          })
        ]);
        break;

      default:
        setAuctionData([
          ...filterdData?.sort((a, b) =>
            Number(a.auctionInfo.start) < Number(b.auctionInfo.start) ? 1 : -1
          )
        ]);
        break;
    }
  };

  return (
    <CategoriesWrapper>
      <CategoriesHeaderWrapper>
        <Row style={{ alignItems: "center" }}>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <Title style={{ font: "normal normal normal 30px Oswald" }}>
              {`${
                auctionData?.filter((x) => x.metaDataInfo !== null).length || 0
              } Pieces`}
            </Title>
          </Col>
          <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            {checkedFilter.map((x) => (
              <Space key={Math.random()}>
                <CustomizedTag
                  closable
                  key={Math.random()}
                  onClose={() =>
                    setCheckedFilter([...checkedFilter.filter((y) => y !== x)])
                  }
                >
                  {` ${x} `}
                </CustomizedTag>
              </Space>
            ))}
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            {/* <Nftfilter /> */}
          </Col>
          <Col xs={24} s={24} md={3} lg={3} xl={3}>
            <RelevanceSelect
              setFilterVal={(value) => {
                filterAry(value);
                setrelevanceFilter(value);
              }}
            />
          </Col>
        </Row>
      </CategoriesHeaderWrapper>
      <Row gutter={[0, 0]} style={{ margin: "0 1em" }}>
        <Col xl={4} lg={4} md={6} sm={24} xs={24}>
          <CategoriesMenuWrapper>
            <Space direction="vertical" className="w-100">
              <Title style={{ font: " normal normal bold 14px/19px Lato" }}>
                FILTER BY DIGITAL
              </Title>
              <div>{filterList.digital.map((x) => FilteringCheckbox(x))}</div>
              <CustomizedDivider />
              <Title style={{ font: " normal normal bold 14px/19px Lato" }}>
                FILTER BY PHYSICAL
              </Title>
              {filterList.physical.map((x) => FilteringCheckbox(x))}

              {/* <Title level={5}>PRICE</Title>
              <Slider range={{ draggableTrack: true }} defaultValue={[0, 75]} /> */}
            </Space>
          </CategoriesMenuWrapper>
        </Col>
        <Col xl={20} lg={20} md={18} sm={24} sx={24}>
          <CreationPieces />
        </Col>
      </Row>
    </CategoriesWrapper>
  );
};

export default CategoriesContainer;
