import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// remove_agent_minter.cdc
// Admin can remove an Agent.
// Two Admins can remove another Admin. Must be run by two Admins.

import ${keys.daamImport} from ${keys.daam}

transaction(exAgent: Address) {
  let admin   : &${keys.daamImport}.Admin
  let exAgent : Address

  prepare(admin: AuthAccount) {
      self.admin = admin.borrow<&${keys.daamImport}.Admin>(from: ${keys.daamImport}.adminStoragePath) ?? panic(exAgent.toString().concat(" is not an Agent."))
    self.exAgent = exAgent
  }

  // Verify exAgent is an Agent
  pre { ${keys.daamImport}.isAgent(exAgent) != nil : exAgent.toString().concat(" is not an Agent.") }
  
  execute {
      self.admin.removeAgent(agent: self.exAgent)
      log("Removed Agent")

      if ${keys.daamImport}.isMinter(self.exAgent) != nil {
          self.admin.removeMinter(minter: self.exAgent)
          log("Removed Minter")
      }
  }

  // Verify is not an Agent
  post { ${keys.daamImport}.isAgent(self.exAgent) == nil : self.exAgent.toString().concat(" is still an Agent.") }
}
`;

export const removeAgent = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(address, types.Address)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit),
    ],
    opts
  );
};
