import { send, decode, script, args, arg, cdc } from "@onflow/fcl";
import { Address } from "@onflow/types";
import keys from "../config/keys";

const CODE = cdc`
// is_agent.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(agent: Address): Bool? {
  return ${keys.daamImport}.isAgent(agent)
}
// nil = not an agent, false = invited to be an agent, true = is an agent
`;

async function isAgent(address) {
  if (address == null) return Promise.resolve(false);

  // prettier-ignore
  // console.log(address)
  return send([script(CODE), args([arg(address, Address)])]).then(decode);
}

export default isAgent;
