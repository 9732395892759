import styled from "styled-components";
import { InputNumber, Slider, Typography } from "antd";
const { Paragraph } = Typography;

export const SetPriceInputNumber = styled(InputNumber)`
  width: 100%;
  border: 1px solid #707070;
  border-radius: 11px;
  & .ant-input-number {
    // height: 50px;
    margin:${(props) => (props.small ? "5px" : "10px")};
    border: 1px solid #999b9e;
    border-radius: 0px !important;
    background: #fafafa 0% 0% no-repeat padding-box;
    width: 96%;
    // font: normal normal bold 20px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
    outline: 0;
    & .ant-input-number-input-wrap {
      & input {
        outline: none;
        height: ${(props) => (props.small ? "30px" : "50px")};
        font:${(props) => (props.small ? "normal normal bold 14px Lato" : "normal normal bold 20px Lato")} ;
        &:focus {
          outline: none;
        }
      }
    }
  }
  & .ant-input-number-group-addon {
    background-color: transparent;
    border: none;
    width: 20%;
    text-align: left;
    font: ${(props) =>
      props.small
        ? "normal normal bold 20px Oswald"
        : "normal normal bold 34px Oswald"};
    letter-spacing: 1.16px;
    color: #1a1d1f;
  }
`;

export const SetPriceParagraph = styled(Paragraph)`
  text-align: left;
  font: normal normal normal 12px/18px Lato;
  letter-spacing: 0.34px;
  color: #1a1d1f;
  opacity: 1;
  padding: 12px;
`;

export const Royality = styled(Typography.Text)`
  text-align: left;
  font: normal normal normal 15px/24px Lato;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
`;

export const RoyalitySlider = styled(Slider)`
  font: normal normal bold 9px/12px Lato;
  & .ant-slider-mark {
    top: -18px;
    & span {
      font: normal normal bold 9px/12px Lato;
    }
  }
`;

export const DAMMServiceFees = styled(Typography.Text)`
  font: normal normal normal 12px/30px Lato;
  letter-spacing: 0.34px;
  color: #1a1d1f;
`;
