import styled from "styled-components";

export const PageTitleStyles = styled.div`
  display: block;
  color: #2f3234;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  font-family: Oswald;
  letter-spacing: 0.48px;
  ${(props) => props.ta && `text-align:${props.ta};`}
  ${(props) => props.m && `margin:${props.m};`}
`;
