/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { DashboardWrapper } from "./dashboard.styled";
import CreatorDashboardContainer from "./creator";
import AgentDashboardContainer from "./agent";
import DAAMDashboardContainer from "./daaam-agency";

import DashboarCommon from "./DashboardCommon";
import avatarImg from "../../images/profile_placeholder.png";
import DB from "../CreationDashNewB";
import { AuthContext } from "../../context/authContext";

const DashboardContainer = (props) => {
  /*Input props.agent => Rendering AGENT Dashboard
  Input props.creator => Rendering CREATOR Dashboard
  Either fill data with userInfo1 (if dashboard is creator) or userInfo2 (agent)*/
  const { auth } = useSelector((obj) => obj);
  const { user } = useContext(AuthContext);

  const userInfo1 = {
    userName: "Creator name",
    userAvatar: avatarImg,
  };

  const userInfo2 = {
    userName: "Agent Xycia",
    userAvatar: avatarImg,
  };

  const bannerInfo = {
    statsToDate: "0",
    statsWeekly: "0",
    statsForSale: "0",
    statsFutured: "0",
  };

  return (
    <DashboardWrapper>
      {auth?.isCreator ? (
        <>
          <DashboarCommon
            userInfo={userInfo2}
            bannerInfo={bannerInfo}
            userDetails={auth}
          />
          <CreatorDashboardContainer />
        </>
      ) : auth?.isCollector ? (
        <>
          <DashboarCommon
            userInfo={userInfo2}
            bannerInfo={bannerInfo}
            userDetails={auth}
          />
          <CreatorDashboardContainer />
        </>
      )
       : auth?.isAgent ? (
        <>
          <DashboarCommon userInfo={userInfo2} bannerInfo={bannerInfo} />
          {/* <AgentDashboardContainer /> */}
          <CreatorDashboardContainer />
        </>
      ) : (
        <>
          <DashboarCommon isDAAMAgency bannerInfo={bannerInfo} />
          <DAAMDashboardContainer />
        </>
      )}
    </DashboardWrapper>
  );
};

export default DashboardContainer;
