import styled from "styled-components";

export const NotificationContainer = styled.div`
  width: 350px;
  position: fixed;
  top: 8%;
  right: 10%;
  height: 373px;
  overflow-y: scroll;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000029;
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
  z-index: 99999999999999999999;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  background: var(--unnamed-color-f4f4f4) 0% 0% no-repeat padding-box;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
`;
export const NotificationTitle = styled.span`
  color: var(--unnamed-color-1a1d1f);
  font: normal normal bold 20px/30px Oswald;
  letter-spacing: 0.48px;
  color: #1a1d1f;
  text-transform: capitalize;
  opacity: 1;
`;
export const NotificationTableWrapper = styled.div``;
export const NotificationTable = styled.table`
  width: 100%;
`;
export const NotificationTableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px dashed var(--unnamed-color-cdd0d3);
  border-bottom: 2px dashed #cdd0d3;
  opacity: 1;
  &:first-of-type {
    border-bottom: none;
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
`;
export const NotificationTableHead = styled.th`
  padding: 15px;
  ${(props) =>
    props.colorAndUnderLine &&
    `
text-decoration: underline;
font: normal normal bold 16px/19px Lato;
letter-spacing: 0.26px;
color: #FC4448;
opacity: 1;
text-underline-offset: 2px;
s
  `}
  ${(props) =>
    props.sym &&
    `
color: var(--unnamed-color-1a1d1f);
font: normal normal bold 16px/19px Lato;
letter-spacing: 0.26px;
color: #1A1D1F;
opacity: 1;
  `}
`;
export const NotificationTableData = styled.td`
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 500 14px/17px Lato;
  letter-spacing: 0.2px;
  color: ${(props) => (props.color ? `red` : `#1a1d1f`)};
  opacity: 1;
  padding: 10px;
`;
export const CloseIcon = styled.div`
  margin-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
`;
