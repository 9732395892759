import React from "react";
import { Button } from "antd";
import {
  AgentRequestsInfoSelectWrapper,
  CircledMoreOutlined,
  CustomizedPopover,
  CustomizedDivider,
} from "./AgentRequestsInfoSelect.style";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  HourglassTwoTone,
} from "@ant-design/icons";

const content = (
  <>
    <CheckCircleTwoTone twoToneColor="red" />
    {" Accept"}
    <CustomizedDivider />
    <HourglassTwoTone twoToneColor="red" />
    {" Negociate"}
    <CustomizedDivider />
    <CloseCircleTwoTone twoToneColor="red" />
    {" Decline"}
  </>
);

const AgentRequestsInfoSelect = (props) => {
  return (
    <AgentRequestsInfoSelectWrapper>
      <CustomizedPopover
        placement="bottomRight"
        content={content}
        trigger="click"
      >
        <Button>
          <CircledMoreOutlined />
        </Button>
      </CustomizedPopover>
    </AgentRequestsInfoSelectWrapper>
  );
};

export default AgentRequestsInfoSelect;
