import React from "react";
import { Select } from "antd";
import {
  RelevanceSelectWrapper,
  RedSortAscendingOutlined,
  RedSortDescendingOutlined,
  RedFallOutlined,
  RedRiseOutlined,
} from "./RelevanceSelect.style";

const { Option } = Select;

const RelevanceSelect = (props) => {
  return (
    <RelevanceSelectWrapper>
      <Select defaultValue={"Relevance"} style={{ width: 200 }}>
        <Option value={1}>
          <RedSortAscendingOutlined />
          {"  Name A-Z"}
        </Option>
        <Option value={2}>
          <RedSortDescendingOutlined />
          {"  Name Z-A"}
        </Option>
        <Option value={3}>
          <RedFallOutlined />
          {"  Lowest price"}
        </Option>
        <Option value={4}>
          <RedRiseOutlined />
          {"  Highest price"}
        </Option>
      </Select>
    </RelevanceSelectWrapper>
  );
};

export default RelevanceSelect;
