/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
import {
  ManageCreatorDiv,
  TitleBlock,
  Title,
  InviteCreator,
  SearchInput,
  AddAgency,
  InActiveCreator,
  InActiveTitle,
  InActiveName,
  ComponentContainer,
  ContainerLeft,
  ContainerRight,
  IconContainer,
  ErrorEmail
} from "./manageCreator.styled";
import CreatorList from "../CreatorList";
import { Form } from "antd";

const CreatorManage = ({ users, type, onInvite }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    value: "",
    sourceData: users || [],
    filterData: users || []
  });

  const [validate, setValidate] = useState(null);
  const [correctEmail, setCorrectEmail] = useState(null);

  useEffect(() => {
    setData((prev) => ({ ...prev, sourceData: users, filterData: users }));
  }, [users]);

  const search = (array, keyword) => {
    const regExp = new RegExp(keyword, "gi");
    const check = (obj) => {
      if (obj !== null && typeof obj === "object") {
        return Object.values(obj).some(check);
      }
      if (Array.isArray(obj)) {
        return obj.some(check);
      }
      return (
        (typeof obj === "string" || typeof obj === "number") && regExp.test(obj)
      );
    };
    return array.filter(check);
  };

  const recursiveSearch = (obj, searchKey, results = []) => {
    const r = results;
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (key === searchKey && typeof value !== "object") {
        r.push(value);
      } else if (typeof value === "object") {
        recursiveSearch(value, searchKey, r);
      }
    });
    return r;
  };

  const filterList = (e) => {
    setValidate(true);
    setCorrectEmail(true);
    const regex = new RegExp(e.target.value, "gi");
    const filter = data?.sourceData.filter((x) => {
      return regex.test(x.name) || regex.test(x.address) || regex.test(x.email);
    });
    if (e.target.value === "") {
      setData({ ...data, filterData: data.sourceData, value: e.target.value });
    } else {
      setData({ ...data, filterData: filter, value: e.target.value });
    }
  };

  const ValidateInput = () => {
    if (data.value === "") {
      setValidate(false);
    }
    if (data.value) {
      const emailID = data.value;
      const atpos = emailID.indexOf("@");
      const dotpos = emailID.lastIndexOf(".");
      if (atpos < 1 || dotpos - atpos < 2) {
        setCorrectEmail(false);
        return false;
      } else {
        setCorrectEmail(true);
        onInvite({ ...data, isAgent: type === "Agents" ? true : false });
        return true;
      }
    }
  };

  return (
    <ManageCreatorDiv>
      <TitleBlock>
        <Title>
          Manage {type}
          {/* <Space size={"middle"}>
            <FontAwesomeIcon icon={faPencilPaintbrush} />
            Manage {type}
          </Space> */}
        </Title>
        <InviteCreator
          onClick={() => {
            ValidateInput();
          }}
        >
          Invite {type.slice(0, -1)}
        </InviteCreator>
      </TitleBlock>
      {correctEmail === false && (
        <ErrorEmail>Please enter valid email address</ErrorEmail>
      )}
      {validate === false && (
        <ErrorEmail>Please enter email address</ErrorEmail>
      )}
      <SearchInput
        prefix={<SearchOutlined />}
        size="large"
        type="text"
        name={`Search${type}`}
        placeholder={`Search ${type}`}
        onChange={filterList}
      />
      {data &&
        data?.filterData &&
        data?.filterData.map((el) => (
          <CreatorList key={Math.random()} data={el} type={type} />
        ))}

      {/* <AddAgency>
        <ComponentContainer>
          <ContainerLeft cursor>
            <IconContainer>
              <PlusOutlined style={FontAwesomeIconStyle.plusIcon} />
            </IconContainer>
            <InActiveName>{type.slice(0, -1)}</InActiveName>
          </ContainerLeft>
          <ContainerRight>
            <PopOverCom
              data={
                type.toLowerCase() === "agents"
                  ? contentDummyDataTest
                  : dataCreator
              }
            />
          </ContainerRight>
        </ComponentContainer>
      </AddAgency> */}
      {/* in active */}
      {/* <InActiveCreator>
        <InActiveTitle>Inactive</InActiveTitle>
        {list.map((item) => {
          return (
            <ComponentContainer>
              <ContainerLeft>
                <Avatar src={Image} />
                <InActiveName>{item.name}</InActiveName>
              </ContainerLeft>
              <ContainerRight>
                <PopOverCom data={dataCreator} />
              </ContainerRight>
            </ComponentContainer>
          );
        })}
      </InActiveCreator> */}
    </ManageCreatorDiv>
  );
};

export default CreatorManage;
