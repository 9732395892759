import { Modal, Space, Typography } from "antd";
import styled from "styled-components";

export const ArtWrapper = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  // max-width: 960px;
  padding: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const ArtCenter = styled.div`
  display: block;
  text-align: center;
`;

export const TransactionHeader = styled.span`

  text-align: left;
  font: normal normal bold 12px/30px Lato;
  letter-spacing: 0.34px;
  color: #2f3234;
  text-transform: uppercase;
  opacity: 1;
`;

export const TransactionData = styled.span`
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal 700 15px/19px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
  display: flex;
`;

export const TransactionDataName = styled.span`
  margin-left: 3px;
  
  text-align: left;
  font: normal normal bold 14px/19px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const ArtDivider = styled.div`
  
  border: 1px solid #2f3234;
  opacity: 1;
`;

export const SidesWrapper = styled.div`
  padding: "1em";
  padding-left: ${(props) => (props.rightSide ? "4em" : "1em")};
`;

// bipul added
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NameTitle = styled.h3`
  text-align: left;
  font: normal normal 500 55px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  margin-bottom: 0px;
`;
export const PriceTitle = styled.span`
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  /* UI Properties */
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal 500 18px/24px Lato;
  letter-spacing: 0px;
  /* color: #ff4040; */
  color: ${(props) => (props.color ? props.color : "#ff4040")};
  opacity: 1;
`;
export const PieceNo = styled.span`
  margin-top: 8px;
  /* UI Properties */
  color: var(--unnamed-color-717579);
  text-align: left;
  font: normal normal 500 20px/24px Lato;
  letter-spacing: 0px;
  color: #717579;
  opacity: 1;
`;
export const OwnedContainer = styled.span`
  // margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const OwenedBy = styled.span`

  text-align: left;
  font: normal normal normal 14px/19px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const CreatorNameText = styled.span`
  margin-left: 5px;
  /* UI Properties */

  text-align: left;
  font: normal normal bold 14px/19px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  opacity: 1;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ButtonDivContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  // margin-top: 20px;
`;

export const ButtonDiv = styled.button`
  margin-left: 10px;
  position: relative;

  /* UI Properties */
  width: 120px;
  height: 40px;
  ${(props) =>
    props.bgColor &&
    `background: ${props.bgColor} 0% 0% no-repeat padding-box;`}
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  opacity: 1;
  &:hover {
    background: ${(props) => props?.onHover && `${props.onHover}`};
    border: ${(props) => props?.onHoverBorder && `1px solid #FC4448`};
  }
  &:focus {
    border-bottom: ${(props) => props.toggle && `none`};
    border: ${(props) => props.toggle && `1px solid black`};
  }
`;

export const ButtonText = styled.span`
  position: relative;

  text-align: center;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0.4px;
  color: ${(props) => (props?.color ? `${props.color}` : `#ffffff`)};
  opacity: 1;
  &:hover {
    color: ${(props) => props?.onHoverColor && `${props.onHoverColor}`};
  }
`;

export const ShareNftToggleWrapper = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  width: 144px;
`;
export const ShareNftToggleContent = styled.div`
  position: absolute;
  /* left: -90px; */
  padding-bottom: 15px;
  top: 23px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f7f7f7;
  z-index: 9999;
  border: 1px solid #1a1d1f;
  // border-right: 1px solid #1a1d1f;
  // border-left: 1px solid #1a1d1f;
  // border-bottom: 1px solid #1a1d1f;
  cursor: pointer;
`;
export const ShareNftToggleList = styled.span`
  width: 100px;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  // &:first-child {
  //   border-top: 1px solid #1a1d1f;
  // }
  padding-top: 8px;
  text-align: center;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0.32px;
  color: #1a1d1f;
  opacity: 1;
`;

export const ShareNFTIcon = styled.span`
  margin-right: 3px;
`;
export const CopyToClipboard = styled(Typography.Paragraph)`
  margin-bottom: 0em !important;
  margin-left: -6px;
`;
export const ShareTitle = styled.span`
  ${(props) => props.ml && `margin-left: 8px`}
`;
export const CommonTitle = styled.span`
  text-align: left;
  font: normal normal 500 20px/29px Oswald;
  letter-spacing: 0.8px;
  color: ${(props) => (props.color ? `${props.color}` : "#1a1d1f")};
  opacity: 1;
`;
export const DescriptionContainer = styled.div`
  // margin-top: 53px;
`;
export const DescriptionText = styled.span`
  text-align: left;
  white-space: pre-line;
  font: normal normal normal 16px/28px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const AuctionEnd = styled.span`
  text-align: left;
  white-space: pre-line;
  font: normal normal normal 12px/28px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

// bipul added image container
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  // height: 642px;
  height: 500px;
  background: #e6e6e6;
  margin: auto;
  // margin-bottom: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Images = styled.img`
  padding: 10px;
  width: 100%;
  max-height: 642px;
  background-size: cover;
  object-fit: contain;
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  top: 590px;
  left: 60px;
  width: 163px;
  height: 40px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000021;
  border-radius: 6px;
  opacity: 1;
`;
export const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const ButtonItem = styled.button`
  /* UI Properties */
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal 300 17px/32px Oswald;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
  background: transparent;
  border: none;
`;

// technical meta data style

export const MetaDataHeader = styled.span`
  text-align: left;
  font: normal normal 700 14px/30px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  text-transform: capitalize;
  opacity: 1;
`;
export const MetaDataHeaderText = styled.span`

  text-align: right;
  font: normal normal normal 14px/30px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

// blockchain info style
export const BlockchainInfoItemTitle = styled.span`
  color: var(--unnamed-color-2f3234);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;

export const BlockchainInfoItemData = styled.span`
  color: var(--unnamed-color-ff4040);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: #ff4040;
  opacity: 1;
`;
// artist royality style

export const ArtistRoyalistItemTitle = styled.span`
  text-align: left;
  font: normal normal bold 12px/17px Lato;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
`;
export const ArtistRoyalistItemContent = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
`;
export const ArtistItemData = styled.span`
  
  text-align: left;
  font: normal normal bold 14px/26px Lato;
  letter-spacing: 0.54px;
  color: #1a1d1f;
  opacity: 1;
`;
// export const ImageContainer=styled.div``
// export const ImageContainer=styled.div``
// export const ImageContainer=styled.div``
// export const ImageContainer=styled.div``
// export const ImageContainer=styled.div``
// export const ImageContainer=styled.div``

export const BuyModal = styled(Modal)`
  & .ant-modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    width: 75%;
  }
`;

export const UpdateConfirmation = styled(Space)`
  & .ant-space-item {
    display: flex;
    justify-content: center;
  }
`;
