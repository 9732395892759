import { Typography } from "antd";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PageControls } from "../UploadNFTBrowseFiles/styles";
import { PDFViewerContainer } from "./PDF.styled";

const PDFViewer = ({
  mintResult,
  showPageControls = true,
  showOnlyFirstPage = false,
  height=350
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <PDFViewerContainer height={height}>
      <Document file={mintResult.file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={showOnlyFirstPage ? 1 : pageNumber} />
      </Document>
      {showPageControls && (
        <PageControls>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            {`<`}
          </button>
          <Typography.Text>{`${pageNumber} of ${numPages}`}</Typography.Text>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            {`>`}
          </button>
        </PageControls>
      )}
    </PDFViewerContainer>
  );
};
export default PDFViewer;
