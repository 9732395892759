import { send, decode, script, args, arg, cdc } from '@onflow/fcl';
import { Address } from '@onflow/types';
import keys from '../config/keys';

const CODE = cdc`
// is_admin.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(admin: Address): Bool? {
  return ${keys.daamImport}.isAdmin(admin)
}
// nil = not an admin, false = invited to be an admin, true = is an admin
`;

async function isAdmin(address) {
  if (address == null) return Promise.resolve(false);

  // prettier-ignore
  //   console.log(address)
  return send([script(CODE), args([arg(address, Address)])]).then(decode);
}

export default isAdmin;
