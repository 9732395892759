import styled from "styled-components";

export const EmptyDashboardContainer = styled.div`
  // width: 80%;
  margin: 1em 0.5em;
  // margin-top: 13px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000b;
  border: 1px solid #e6e6e6;
  border-radius: 22px;
  opacity: 1;
  @media (min-width: 2500px) {
    width: 100%;
  }
`;
export const EmptyDashboardTop = styled.div`
  width: 100%;
  // height: 250px;
  height: 25vh;
  padding: 20px;
  border: 1px solid var(--unnamed-color-cdd0d3);
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0d3;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  @media (min-width: 2500px) {
    width: 100%;
  }
  @media (min-height: 1980px) {
    height: 15vh;
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const IconDiv = styled.div`
  margin-bottom: 10px;
`;
export const ButtonDiv = styled.button`
  width: 100px;
  height: 55px;
  color: #ffffff;
  background: var(--unnamed-color-1a1d1f) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-1a1d1f);
  background: #1a1d1f 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  opacity: 1;
`;
export const BtnTitle = styled.span`
  color: var(--unnamed-color-f4f4f4);
  text-align: center;
  font: normal normal 500 20px/21px Oswald;
  letter-spacing: 1.34px;
  color: #f4f4f4;
  text-transform: uppercase;
  opacity: 1;
`;
// export const Content=styled.div``

export const EmptyDashboardBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10px;
  @media (min-height: 1980px) {
    height: 150px;
  }
  @media (min-width: 2500px) {
    width: 100%;
  }
`;
export const TitleOfEmptyDashboard = styled.div`
  /* margin: 10px 0px 0px 30px; */
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
