import { Button, Form, Upload } from "antd";
import styled from "styled-components";
import ReactPhoneInput from "react-phone-input-2";

export const FrameWrapper = styled.div`
  // max-width: 80%;
  // padding-top: 10em;
  // padding-left: 25em;
  padding-top: 10%;
`;

export const ImageWrapper = styled.div`
  max-width: 80%;
  max-height: 50%;
`;

export const Paragraph = styled.h3`
  display: flex;
  justify: center;
  justify-content: center;

  text-align: center;
  font: normal normal 500 34px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
`;

export const RegisterText = styled.p`
  text-align: center;
  font: normal normal 500 16px/24px Lato;
  // justify:"center"
  rlink {
    justify: center;
    text-align: center;
    font: normal normal bold 16px Lato;
    letter-spacing: 0px;
    color: #ff4040;
  }
  rtext {
    justify: center;
    text-align: center;
    font: normal normal 500 16px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
    justify: center;
  }
  label {
    text-align: center;
    padding: 0;

    font: normal normal bold 16px/76px Lato;
    letter-spacing: 0.51px;
    color: #1a1d1f;
    text-transform: capitalize;
    opacity: 1;
  }
`;

export const ClickButton = styled(Button)`
  width: 100%;

  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  height: 45px;
  text-align: center;
  // font: normal normal bold 20px/24px "Lato";
  font-family: "Lato";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 20px/24px;
  letter-spacing: 0.4px;
  color: #1a1d1f;
  opacity: 1;
  background-color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
  &:hover {
    background-color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
    color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  }
`;

export const PhoneInput = styled(ReactPhoneInput)`
  & .form-control {
    width:100%;
    height:40px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &input {
    width: 100% !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
export const CustomUpload = styled(Upload)`
  display: flex;
  justify-content: center;
  & div {
    width: 100%;
    & .ant-upload.ant-upload-select-picture-card {
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      & .ant-upload {
        flex-direction: column;
        height: auto !important;
        margin: 25px 0;
      }
    }
  }
`;

export const CenterIcon = styled.img`
  display: flex;
  width: 40px;
`;
export const CenterLabel = styled.span`
  display: flex;
  color: #2f3234;
  font: normal normal bold 20px/52px Lato;
`;
export const UploadImageButton = styled(Button)`
  
  width: 98px;
  font: normal normal bold 12px/15px Lato;
  letter-spacing: 0.29px;
  color: #fafafa;
  text-transform: uppercase;
  background: #fc4448 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  border-radius: 15px;
  opacity: 1;

  &:hover {
    background-color: #1a1d1f;
    color: #fff;
  }
`;