import * as fcl from "@onflow/fcl";
import keys from "../config/keys";

export const getHistory = async (mid) => {
  const result = await fcl.query({
    cadence: `
    // get_history.cdc
// Return all (nil) or spcific history

//import DAAM_Mainnet from ${keys.daam}
import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(mid: UInt64?): {UInt64 : {UInt64: ${keys.auctionHouseImport}.SaleHistory}}? { // {Creator { MID : {TokenID:SaleHistory} } }
    return ${keys.auctionHouseImport}.getHistory(mid: mid) // Get SaleHostory {TokenID : SaleHstory}
}
    `,
    args: (arg, types) => [arg(mid, types.UInt64)]
  });
  return result;
};
