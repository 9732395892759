/* eslint-disable no-unused-vars */
import React, { createContext, useState, useContext } from "react";
import { send as transportGRPC } from "@onflow/transport-grpc";
import { useSelector, useDispatch } from "react-redux";
import {
  listAuctions,
  listAuctionsMetaDataInfo
} from "../scripts/listAuctions";
import * as types from "../redux/constTypes";
import { listAllMetadata } from "../scripts/listAllMetadata";
import * as fcl from "@onflow/fcl";
import keys from "../config/keys";
import "../config";
const AuctionContext = createContext();

export const AuctionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [auctions, setAuctions] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((obj) => obj);

  const getAuctions = async () => {
    if (
      auth &&
      auth?.blockChainAddress?.length > 0 &&
      auth?.blockChainAddress[0].addr !== null
    ) {
      setLoading(true);
      let data = [];
      let auctionsObject = await listAuctions();
      getAllAddressandIds(auctionsObject)
        .then((res) =>
          getAuctionData(res)
            .then((res1) => {
              setAuctions(res1);
              dispatch({
                type: types.flowTypes.AUCTIONS,
                payload: res1
              });
              setLoading(false);
              return res1;
            })
            .catch((err) => setLoading(false))
        )
        .catch((err) => setLoading(false));

      // await Object.keys(auctionsObject).map(async (items) => {
      //   const values = auctionsObject[items];
      //   await values.map(async (auctionId) => {
      //     await listAuctionsMetaDataInfo(items, auctionId).then((res) =>
      //       data.push({ auctionInfo: res[0], metaDataInfo: res[1] })
      //     );
      //     setAuctions(data);
      //     dispatch({
      //       type: types.flowTypes.AUCTIONS,
      //       payload: data
      //     });
      //   });
      // });

      //  }
    }
  };

  const getAuctionsforPublicURL = async () => {
    // fcl
    //   .config()
    //   .put("env", "testnet")
    //   .put("accessNode.api", "https://access-testnet.onflow.org")
    //   // .put("accessNode.api", "https://rest-testnet.onflow.org")
    //   .put("discovery.wallet", "https://fcl-discovery.onflow.org/testnet")

    //   .put("app.detail.title", "DAAM Agency")
    //   .put(
    //     "app.detail.icon",
    //     `${keys.clientURL}/static/media/worded_logo.79555dfd.svg`
    //   )
    //   .put("0xFlowToken", "0x7e60df042a9c0868")
    //   .put("sdk.transport", transportGRPC);
    
      // fcl.config({ ...keys.fclConfig });

    setLoading(true);
    let data = [];
    let auctionsObject = await listAuctions();
    getAllAddressandIds(auctionsObject)
      .then((res) =>
        getAuctionData(res)
          .then((res1) => {
            setAuctions(res1);
            dispatch({
              type: types.flowTypes.AUCTIONS,
              payload: res1
            });
            setLoading(false);
            return res1;
          })
          .catch((err) => setLoading(false))
      )
      .catch((err) => setLoading(false));

    // await Object.keys(auctionsObject).map(async (items) => {
    //   const values = auctionsObject[items];
    //   await values.map(async (auctionId) => {
    //     await listAuctionsMetaDataInfo(items, auctionId).then((res) =>
    //       data.push({ auctionInfo: res[0], metaDataInfo: res[1] })
    //     );
    //     // setAuctions(data);
    //     // dispatch({
    //     //   type: types.flowTypes.AUCTIONS,
    //     //   payload: data
    //     // });
    //   });
    // });
  };

  const getAllAddressandIds = async (auctionsObject) => {
    const AddressAndIds = [];
    Object.keys(auctionsObject).map(async (items) => {
      const values = auctionsObject[items];
      values.map((x) => {
        return AddressAndIds.push({ address: items, id: x });
      });
    });

    return AddressAndIds;
  };

  const getAuctionData = async (AddressAndIds) => {
    var promises = AddressAndIds.map(
      async (x) => await getActualData(x.address, x.id)
    );
    return Promise.all(promises);
  };

  const getActualData = async (address, id) => {
    return listAuctionsMetaDataInfo(address, id).then((res) => {
      return {
        auctionInfo: res[0],
        metaDataInfo: res[1]
      };
    });
  };

  return (
    <AuctionContext.Provider
      value={{
        auctions,
        setAuctions,
        getAuctions,
        getAuctionsforPublicURL,
        loading
      }}
    >
      {children}
    </AuctionContext.Provider>
  );
};

export const useAuction = () => useContext(AuctionContext);
