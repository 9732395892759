import * as fcl from "@onflow/fcl";
import * as keys from "../../config/keys";
import * as types from "@onflow/types";
import { tx } from "../util/tx";

const CODE = fcl.cdc`
// invite_admin.cdc
// Used for Admin to invite another Admin.
// The invitee Must have a DAAM_Profile before receiving or accepting this Invitation

import ${keys.daamImport} from ${keys.daam}

transaction(newAdmin: Address)
{
    let admin    : &${keys.daamImport}.Admin
    let newAdmin : Address 

    prepare(admin: AuthAccount) {
        self.admin    = admin.borrow<&${keys.daamImport}.Admin>(from: ${keys.daamImport}.adminStoragePath)!
        self.newAdmin = newAdmin
    }

    pre {
        ${keys.daamImport}.isAdmin(newAdmin) == nil   : newAdmin.toString().concat(" is already an Admin.")
        ${keys.daamImport}.isAgent(newAdmin) == nil   : newAdmin.toString().concat(" is already an Agent.")
        ${keys.daamImport}.isCreator(newAdmin) == nil : newAdmin.toString().concat(" is already an Creator.")
    }
    
    execute {
        self.admin.inviteAdmin(self.newAdmin)
        log("Admin Invited")
    }
}
`;

export const inviteAdmin = async ({ address }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([fcl.arg(address, types.Address)]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([authorization]),
      fcl.limit(keys.fclLimit)
    ],
    opts
  );
};
