import styled from "styled-components";

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;

  cursor: pointer;
  margin: 1em;
  padding: 0.5em 0;
  font-size: small;
  font-weight: bold;
  text-align: center;
  border: 1px solid var(--unnamed-color-cdd0d3);
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  border: 1px solid #cdd0d3;
  border-radius: 43px;
  & .ant-avatar {
    height: 44px;
    width: 44px;
  }
`;

export const TitleTopSeller = styled.h3`
  display: flex;
  // color: black;
  // font-size: 24px;
  // line-height: 24px;
  align-self: center;
  // margin: 0 0 1em 0;

  text-align: left;
  font: normal normal 600 34px/51px Oswald;
  letter-spacing: 1.02px;
  color: #1a1d1f;
  opacity: 1;
`;
