import * as fcl from "@onflow/fcl"
import keys from "../config/keys";


export const getFees = async ({ mid }, opts = {}) => {
  const result = await fcl.query({
    cadence: `
    // get_fee.cdc
// Gets the fee for a Metadata ID
import ${keys.auctionHouseImport} from ${keys.auctionHouse}

pub fun main(mid: UInt64): UFix64? {
    return ${keys.auctionHouseImport}.getFee(mid: mid) // 1.0 represents 100%
}
// nil = MID does not exist
    `
    ,
    args: (arg, types) => [
      arg(mid, types.UInt64)

    ],
  });
  return result;
};




