/* eslint-disable no-unused-vars */
import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { toast } from "react-toastify";
import { tx } from "./util/tx";
import { optionalArgs } from './util/optionalArgs';

const CODE_CHECK = fcl.cdc`
// accept_default.cdc
// Creator selects Royalty between 10% to 30%

import FungibleToken from ${keys.fungibleToken} 
import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}  from ${keys.daam}

transaction(mid: UInt64, percentage: UFix64) {
  let mid         : UInt64
  let percentage  : UFix64
  let requestGen  : &${keys.daamImport}.RequestGenerator
  let metadataGen : &${keys.daamImport}.MetadataGenerator
  let royalties   : MetadataViews.Royalties

  prepare(creator: AuthAccount) {
      self.mid     = mid
      self.percentage  = percentage
      self.requestGen  = creator.borrow<&${keys.daamImport}.RequestGenerator>( from: ${keys.daamImport}.requestStoragePath)!
      self.metadataGen = creator.borrow<&${keys.daamImport}.MetadataGenerator>(from: ${keys.daamImport}.metadataStoragePath)!

      let royalties    = [ MetadataViews.Royalty(
          receiver: creator.getCapability<&AnyResource{FungibleToken.Receiver}>(MetadataViews.getRoyaltyReceiverPublicPath()),
          cut: percentage,
          description: "Creator Royalty" )
      ]
      self.royalties = MetadataViews.Royalties(royalties)
  }

  pre { percentage >= 0.01 || percentage <= 0.3 }

  execute {
      self.requestGen.acceptDefault(mid: self.mid, metadataGen: self.metadataGen, royalties: self.royalties)
      log("Request Made")
  }
}
`;

const CODE = fcl.cdc`
// create_auction.cdc
// Used to create an auction for an NFT
import ${keys.auctionHouseImport} from ${keys.auctionHouse}
import NonFungibleToken     from ${keys.nonFungibleToken}
import ${keys.daamImport}         from ${keys.daam}
import FUSD                 from ${keys.fusd}

transaction(isMetadata: Bool, id: UInt64, start: UFix64, length: UFix64, isExtended: Bool, extendedTime: UFix64,
  /*requiredCurrency: Type,*/ incrementByPrice: Bool, incrementAmount: UFix64, startingBid: UFix64,
  reserve: UFix64, buyNow: UFix64, reprint: UInt64?)
{

  let auctionHouse : &${keys.auctionHouseImport}.AuctionWallet
  let nftCollection: &${keys.daamImport}.Collection
  let metadataCap  : Capability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint}>?

  let id          : UInt64
  let start       : UFix64
  let length      : UFix64
  let isExtended  : Bool
  let extendedTime: UFix64
  let incrementByPrice: Bool
  let incrementAmount : UFix64
  let startingBid : UFix64?
  let reserve     : UFix64
  let buyNow      : UFix64
  let isMetadata  : Bool
  let reprint     : UInt64?

  prepare(auctioneer: AuthAccount) {
    self.auctionHouse  = auctioneer.borrow<&${keys.auctionHouseImport}.AuctionWallet>(from: ${keys.auctionHouseImport}.auctionStoragePath)!
    self.nftCollection = auctioneer.borrow<&${keys.daamImport}.Collection>(from: ${keys.daamImport}.collectionStoragePath)!
    self.metadataCap  = (isMetadata) ? auctioneer.getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorMint}>(${keys.daamImport}.metadataPublicPath) : nil

    self.id               = id
    self.start            = start
    self.length           = length
    self.isExtended       = isExtended
    self.extendedTime     = extendedTime
    self.incrementByPrice = incrementByPrice
    self.incrementAmount  = incrementAmount
    self.startingBid      = startingBid
    self.reserve          = reserve
    self.buyNow           = buyNow
    self.isMetadata       = isMetadata
    self.reprint          = reprint
  }

  execute {
      let vault <- FUSD.createEmptyVault()
      log(vault.getType())

      var nft: @${keys.daamImport}.NFT? <- nil
      if !self.isMetadata {
        let old <- nft <- self.nftCollection.withdraw(withdrawID: self.id) as! @${keys.daamImport}.NFT
        destroy old
      }

      let aid = self.auctionHouse.createAuction(metadataGenerator: self.metadataCap, nft: <-nft, id: self.id,
        start: self.start, length: self.length, isExtended: self.isExtended, extendedTime: self.extendedTime,
        vault: <-vault, incrementByPrice: self.incrementByPrice, incrementAmount: self.incrementAmount,
        startingBid: self.startingBid, reserve: self.reserve, buyNow: self.buyNow, reprintSeries: self.reprint)

      log("New Auction has been created. AID: ".concat(aid.toString() ))
  }
}
`;

export function listToMarketplace(
  { mid, price, series = 0, ...rest },
  opts = {}
) {
  if (mid == null)
    throw new Error("createSaleOffer(mid, price) -- mid required");
  if (price == null)
    throw new Error("createSaleOffer(mid, price) -- price required");

  const { authorization } = fcl.currentUser();
  const reprintsSeries = optionalArgs(series === 0 ? null : series, types.UInt64);

  // prettier-ignore
  return tx([
    fcl.transaction(CODE),
    fcl.args([
      fcl.arg(true, types.Bool),
      fcl.arg(Number(mid), types.UInt64), //TOkenid
      fcl.arg(String(Math.round(Date.now() / 1000 + 200).toFixed(1)), types.UFix64), //start
      fcl.arg("999999.0", types.UFix64), //length
      fcl.arg(false, types.Bool), // isExtended
      fcl.arg("0.0", types.UFix64), //extendedTime
      fcl.arg(false, types.Bool), //incrementByPrice
      fcl.arg("0.01", types.UFix64), //incrementAmount
      // Added by Nikhil- Just to check 
      // This was giving error when NTF is $2 and starting bid becomes 0
      // so comment below and changed to - 0.9
      // =================== 
      fcl.arg(String(Number(price).toFixed(2)), types.UFix64), //startingBid
      // fcl.arg(null, types.Optional(types.UFix64)), //startingBid

      //-------------------------
      fcl.arg(String(Number(price + 0.1).toFixed(2)), types.UFix64), //reserve,
      fcl.arg(String(Number(price + 0.2).toFixed(1)), types.UFix64), //BuyNow
      fcl.arg(...reprintsSeries), //reprint series
    ]),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts
  )
}

export function accept_default({ mid, percentage = 0.2 }, opts = {}) {
  if (mid == null)
    throw new Error("createSaleOffer(mid, price) -- mid required");
  if (percentage == null)
    throw new Error("createSaleOffer(mid, price) -- price required");

  const { authorization } = fcl.currentUser();

  // prettier-ignore
  return toast.promise(
    tx([
      fcl.transaction(CODE_CHECK),
      fcl.args([
        fcl.arg(Number(mid), types.UInt64), //AuctionID
        fcl.arg("0.2", types.UFix64), //length
      ]),
      fcl.proposer(authorization),
      fcl.payer(authorization),
      fcl.authorizations([
        authorization
      ]),
      fcl.limit(keys.fclLimit)
    ], opts),
    {
      pending: 'Listing your NFT',
      success: 'Listing success, you can check the marketplace 👌',
      error: 'Promise rejected 🤯'
    }
  )
}
