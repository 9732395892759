import React from "react";
import { useParams } from "react-router-dom";
import MintingContainer from "../containers/minting";
import { MintProvider } from "../context/mintContext";

const Minting = () => {
  const { filterParam } = useParams();
  return (
    <MintProvider>
      <MintingContainer filterParam={filterParam} />
    </MintProvider>
  );
};

export default Minting;
