import styled from "styled-components";
import * as antd from "antd";

export const Card = styled(antd.Card)`
  width: 175px;
  box-shadow: 4px 7px 25px 1px #ccc;
  & img {
    padding: 20px;
  }
  & .ant-card-body{
      padding: 7px 12px;
  }
`;
