import styled from 'styled-components';

const ImageStyles = styled.img`
  display: flex;
  ${(props) => props.w && `width:${props.w};`}
  ${(props) => props.h && `height:${props.h};`}
    ${(props) => props.br && `width:${props.w};`}
    ${(props) => props.mt && `margin-top:${props.mt};`}
    ${(props) => props.mb && `margin-bottom:${props.mb};`}
`;

export default ImageStyles;
