import React from "react"

import AgencyListItem from "../AgencyListItem";
import Button from "../Button";
import { AgencyManagementList, AgencyManagementListsStyles, HeaderOptions, HeaderTitle, ListHeader, ListItems } from "./styles";
import PlaceholderProfileImage from '../../images/profile_placeholder.png'
import PlaceholderAgencyImage from '../../images/circular_logo.svg'
import CreatorListItem from "../CreatorListItem";

export default function AgencyManagementLists() {
    const agencies = [
        {
            image: PlaceholderAgencyImage,
            title: 'Agency title'
        },
        {
            image: PlaceholderAgencyImage,
            title: 'Agency title'
        },
        {
            image: PlaceholderAgencyImage,
            title: 'Agency title'
        },
    ]
    const creators = [
        {
            image: PlaceholderProfileImage,
            title: 'Creator name'
        },
        {
            image: PlaceholderProfileImage,
            title: 'Creator name'
        },
        {
            image: PlaceholderProfileImage,
            title: 'Creator name'
        },
    ]
    return (
        <AgencyManagementListsStyles>
            <AgencyManagementList>
                <ListHeader>
                    <HeaderTitle>Manage Agent</HeaderTitle>
                    <HeaderOptions>
                        <Button order='tertiary-black' size='regular'>Invite Agency</Button>
                    </HeaderOptions>
                </ListHeader>
                <ListItems>
                    {
                        agencies.map(({image, title}) => {
                            return <AgencyListItem image={image} title={title} key={Math.random()} />
                        })
                    }
                </ListItems>
            </AgencyManagementList>
            <AgencyManagementList>
                <ListHeader>
                    <HeaderTitle>Manage Creators</HeaderTitle>
                    <HeaderOptions>
                        <Button order='tertiary-black' size='regular'>Invite Creator</Button>
                    </HeaderOptions>
                </ListHeader>               
                <ListItems>
                    {
                        creators.map(({image, title}) => {
                            return <CreatorListItem image={image} title={title} key={Math.random()}/>
                        })
                    }
                </ListItems>
            </AgencyManagementList>
        </AgencyManagementListsStyles>
    )
}