import styled from "styled-components";
import { Row, Divider, Tag, Checkbox } from "antd";
import { Link } from "react-router-dom";

export const CategoriesWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

export const CustomizedDivider = styled(Divider)`
  margin: 0.5em 0em;
`;
export const IconNFTCategories = styled.img`
  margin-right: 1em;
  width: 16px;
  height: 16px;
  filter: invert(46%) sepia(46%) saturate(5170%) hue-rotate(335deg)
    brightness(97%) contrast(109%);
`;
export const CustomizedTag = styled(Tag)`
  // padding: 1em;
  border-radius: 17px;
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  font: normal normal 400 14px/16px Lato;
`;
export const CheckboxRow = styled(Row)`
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
`;
export const CategoriesHeaderWrapper = styled.div`
  padding: 1em;
  // padding-top: 1em;
`;
export const CategoriesMenuWrapper = styled.div`
  float: left;
  display: block;
  // width: 16em;
  margin-top: 1em;
  padding-top: 1em;
  // overflow-y: auto;
  padding-left: 1em;
  padding-right: 1em;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 98%;
`;

// bipul added
export const CommonCard = styled(Link)`
  width: 327px;
  height: 424px;
  /* background-color: white; */
  margin-left: 7px;
  margin-top: 20px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000b;
  border: 1px solid #e6e6e6;
  border-radius: 6px 6px 22px 22px;
  opacity: 1;
`;

export const CardContainer = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
`;
export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 327px;
  height: 286px;
  border: 1px solid var(--unnamed-color-cdd0d3);
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0d3;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
`;
export const CardTopImage = styled.div`
  width: 280px;
  height: 207px;
  background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: auto;
`;

export const CardBottom = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
`;
export const CardBottomTop = styled.span`
  /* width: 200px; */
  height: 50px;
  color: var(--unnamed-color-1a1d1f);
  text-align: left;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0px;
  color: #1a1d1f;
  opacity: 1;
`;
export const CardBottomBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CardBottomLeft = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: column;
`;
export const PriceContent = styled.span`
  /* width: 43px; */
  /* height: 19px; */
  color: var(--unnamed-color-ff4040);
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  /* letter-spacing: 2px; */
  color: ${(props) => props.color && props.color};
  opacity: 1;
  /* word-spacing: 4px; */

  margin-bottom: 10px;
`;

export const CardBottomRight = styled(Link)``;

export const FilterCheckbox = styled(Checkbox)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: #ff4040;
  }
  & .ant-checkbox-inner {
    &:after {
      border: 2px solid #ff4040;
      content: " ";
      position: absolute;
      display: table;
      border-top: 0;
      border-left: 0;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 5px;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  margin: 0px 5px;
`;
