/* eslint-disable no-unused-vars */

import * as fcl from "@onflow/fcl";
import * as types from "@onflow/types";
import keys from "../config/keys";

export const listAllMetadata = async () => {

  const result = await fcl.query({
    cadence: `
    // view_all_metadatas.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(): {Address: [${keys.daamImport}.MetadataHolder]}
{
    let creators = ${keys.daamImport}.getCreators()
    var list: {Address: [${keys.daamImport}.MetadataHolder]} = {}

    for creator in creators.keys {
        let metadataRef = getAccount(creator)
        .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath)
        .borrow()!

        list.insert(key: creator, metadataRef.viewMetadatas())
    }
    return list
}
        `
  });
  // console.log(result)
  return result;
};

// const CODE = fcl.cdc`// view_all_metadatas.cdc

// import DAAM_V23 from ${keys.daam}

// pub fun main(): {Address: [DAAM_V23.MetadataHolder]}
// {
//     let creators = DAAM_V23.getCreators()
//     var list: {Address: [DAAM_V23.MetadataHolder]} = {}

//     for c in creators {
//         let metadataRef = getAccount(c)
//         .getCapability<&DAAM_V23.MetadataGenerator{DAAM_V23.MetadataGeneratorPublic}>(DAAM_V23.metadataPublicPath)
//         .borrow()!

//         list.insert(key: c, metadataRef.viewMetadatas())
//     }
//     return list
// }`
// export async function listAllMetadata(address) {
//   if (address === null) return Promise.resolve(false);

//   return fcl.send([
//     fcl.script(CODE),
//     fcl.args([fcl.arg(address, types.Address)])
//   ]).then(fcl.decode);
// }
