import { Checkbox, Select } from "antd";
import styled from "styled-components";

export const NftContainer = styled.div`
  width: 95%;
  /* height: 35px; */
  position: relative;
  /* margin-right: 40px; */
`;

export const CheckBoxNameWrapper = styled.div`
  // width: 200px;
  /* height: 100%; */
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding: 7px;
  border: 1px solid #e9e9e9;
  background-color: white;
  cursor: pointer;
  /* background-color: transparent; */
`;

export const Title = styled.span`
  margin-top: -4px;
  margin-left: 10px;
`;

export const NftSelect = styled(Select)`
  // width: 200px;
`;

export const CheckBoxItem = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: absolute;
  width: 100%;

  top: 36px;
  /* background-color: white; */
  z-index: 999999999;
`;
export const CheckBoxItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000019;
  border-bottom: 1px solid #e9e9e9;
  opacity: 1;
`;

export const CheckBoxContent = styled(Checkbox)`
  border-bottom: 1px solid #e9e9e9;
  padding: 5px;
  width: 100%;
  text-align: left;
  font: normal normal normal 14px/22px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
  :first-child {
    margin-left: 8px !important;
  }
  :last-child {
    border: none;
    padding-bottom: 12px;
  }
`;
