import styled from "styled-components";

export const ButtonNFT = styled.div`
  cursor: pointer;

  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  border: 1px solid #cdd0d3;
  border-radius: 43px;

  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0.22px;
  color: #1a1d1f;
  opacity: 1;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;

  &:first-of-type {
    color: black;
  }
  &:hover {
    color: black;
  }
`;

export const TitleTopSeller = styled.h3`
  display: flex;
  color: black;
  font-size: 24px;
  line-height: 24px;
  align-self: center;
  margin: 0 0 1em 0;
`;
export const DivExplore = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -0.5em;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 6px;
  width: 154px;
  height: 61px;
  text-align: center;
  font: normal normal 600 34px/51px Oswald;
  letter-spacing: 1.02px;
  color: #1a1d1f;
  opacity: 1;
`;

export const IconNFT = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export const HandPeaceWrapper = styled.div`
  position: absolute;
  top: -4em;
  z-index: 10;
  right: 3em;
`;
