import { ListItem, Options, Title, OptionContainer, OptionTrigger } from '../ListItem'
import Image from '../Image'
import { useState } from 'react'
import AgencyManagementPanel from '../AgencyManagementPanel'

export default function AgencyListItem({image, title}) {
    const [agencyManagementPanelVisibility, setAgencyManagementPanelVisibility] = useState(false)
    return (
        <ListItem>
            <Image src={image} w='56px' />
            <Title>{title}</Title>
            <Options>
                <OptionContainer>
                    <OptionTrigger onClick={() => setAgencyManagementPanelVisibility(!agencyManagementPanelVisibility)} />
                    {
                        agencyManagementPanelVisibility && <AgencyManagementPanel/>
                    }
                </OptionContainer>
            </Options>
        </ListItem>
    )
}