import { AgencyManagementHeaderOption, AgencyManagementHeaderOptions, AgencyManagementHeaderStyles, Container } from "./styles";
import SearchBar from '../SearchBar'

export default function AgencyManagementHeader() {
    return (
        <AgencyManagementHeaderStyles>
            <Container>
                <SearchBar/>
                <AgencyManagementHeaderOptions>
                    <AgencyManagementHeaderOption></AgencyManagementHeaderOption>
                    <AgencyManagementHeaderOption></AgencyManagementHeaderOption>
                </AgencyManagementHeaderOptions>
            </Container>
        </AgencyManagementHeaderStyles>
    )
}