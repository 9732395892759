import { send, decode, script, args, arg, cdc } from '@onflow/fcl';
import { Address } from '@onflow/types';
import keys from '../config/keys';

const CODE = cdc`
// is_creator.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(creator: Address): Bool? {
  return ${keys.daamImport}.isCreator(creator)
}
// nil = not a creator, false = invited to be a creator, true = is a creator
`;

async function isCreator(address) {
  if (address == null) return Promise.resolve(false);

  // prettier-ignore
  // console.log(address)
  return send([script(CODE), args([arg(address, Address)])]).then(decode);
}

export default isCreator;