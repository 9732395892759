import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size:10px;
    
    /* Background */
    --background-color:#F7F7F7;

    /* Colour */

    /* Gutter */
    --x-gutter:104px;

    /* Elevation */
    --box-shadow-light:0px 2px 4px rgb(0,0,0,12%);
    --box-shadow-mediium:0px 3px 6px rgb(0,0,0,12%);
    --box-shadow-bold:0px 4px 8px rgb(0,0,0,12%);
    --box-shadow-strong:0px 4px 12px rgb(0,0,0,12%);

    /* Font */
    --font-family: 'Arial', sans-serif;
  }
  *, *:before, *:after {
    font-smooth: always;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
  }
  body {
    font-family: var(--font-family);
    padding: 0;
    margin: 0;
    background:var(--background-color);
    font-size: 1.5rem;
    line-height:2;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
  }
  h1,h2,h3,h4 {
    margin-top:0;
    margin-bottom:0;
    display:block;
    margin-block-start:0;
    margin-block-end:0;
    margin-inline-start:0;
    margin-inline-end:0;
    font-weight: bold;
}
  }
`