/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./style.css";
import { Collection } from "../../components/collection";
import { Row, Col, Image, Avatar, Menu, Space, Dropdown } from "antd";
import "antd/dist/antd.css";

import { useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useAuction } from "../../context/auctions.context";
import { useDispatch, useSelector } from "react-redux";
import { safeParse } from "../../scripts/util/safeParse";
import * as config from "../../config/keys";
import CreatorImage from "../../images/Avtar/CreatorAvtar.png";
import AdminProfile from "../../images/Avtar/adminAvtar.png";
import AgentImage from "../../images/Avtar/agentAvtar.png";
import CollectorImage from "../../images/Avtar/collectorAvtar.png";
import { MenuItem } from "../../components/collection/styles";
import {
  ArrowRightOutlined,
  CheckOutlined,
  DollarOutlined,
  EllipsisOutlined,
  StarOutlined
} from "@ant-design/icons";
import * as bimg from "../../images/collectorimage.jpg";
import { AuthContext } from "../../context/authContext";
import { getUserDetailsByAddress } from "../../redux/actions/Admin";
import { getCollection } from "../../scripts/getCollections";
import {
  TitleCollectionCount,
  TitleCollectionName
} from "../dashboard/dashboard.styled";
import { listAuctions } from "../../scripts/listAuctions";

const CreatorNew = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userId, setId] = useState(params);
  const [userTypes, setUserType] = useState("");
  const { auctions, getAuctionsforPublicURL, getAuctions } = useAuction();
  const { listOwned, owned = [] } = useContext(AuthContext);
  const { userList, auth, user } = useSelector((obj) => obj);

  const [filteredData, setFilteredData] = useState([]);
  const [filterUser, setFilterUsers] = useState([]);
  const [showCollections, setShowCollections] = useState(false);
  const [collections, setCollections] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/details/")) {
      getAuctionsforPublicURL();
      dispatch(getUserDetailsByAddress(params?.id));
      getCollection(params?.id).then((res) => {
        //console.log("COLLECTION", res);
      });
      setId(params);
    } else {
      getAuctions();
      setShowCollections(auth);
      // getCollection(params?.id).then((res) => {
      //   //console.log("COLLECTION", res);
      // });
    }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/details/")) {
      setFilterUsers(user);
    }
  }, [user]);

  useEffect(() => {
    listOwned();
    const filterDataByAddress = () => {
      // const filterData = auctions?.filter((item) => {
      //   console.log(item?.metadata?.creator, userId)
      //   if (item?.metadata?.creator === userId) {
      //     setUserType("creator");
      //     return 1;
      //   }
      //   if (item?.metadata?.agent === userId) {
      //     setUserType("agent");
      //     return 1;
      //   }
      //   return 1;
      // });
      //console.log(userId, auctions);
      const filterData = auctions?.filter(
        (item) =>
          item?.metaDataInfo?.creatorInfo?.creator === userId.id ||
          item?.auctionInfo?.creatorInfo?.creator === user.id ||
          item?.auctionInfo?.creatorInfo?.agent === user.id
      );
      //  console.log("filterData", filterData);
      setFilteredData(filterData);
    };
    filterDataByAddress();
  }, [auctions]);

  useEffect(() => {
    setId(params);
  }, [params, userId]);

  useEffect(() => {
    if (userId.id === auth.address) {
      setFilterUsers(auth);
    } else {
      const filterUserDetails = userList?.users?.find((x) => {
        return x.address === userId.id;
      });

      setFilterUsers(filterUserDetails);
    }
  }, [userList, auth]);

  const getColors = () => {
    if (
      (auth?.address === userId.id && auth?.userType === "agent") ||
      filterUser?.userType === "agent"
    )
      return "#AABBD4";
    else if (
      (auth?.address === userId.id && auth?.userType === "creator") ||
      filterUser?.userType === "creator"
    )
      return "#81D8B2";
    else if (
      (auth?.address === userId.id && auth?.userType === "admin") ||
      filterUser?.userType === "admin"
    )
      return "#E8A7A8";
    else if (
      (auth?.address === userId.id && auth?.userType === "collector") ||
      filterUser?.userType === "collector"
    )
      return "#EBD694";
    else return "#EBD694";
  };

  return (
    <div>
      <div className="m-creator-name">
        <span>
          {auth.address === userId.id
            ? auth.name
            : filterUser?.name || "No Name"}
        </span>
        <button>
          <i
            className="fa fa-play-circle-o"
            style={{ fontSize: "42px", color: "#FF4040", opacity: "0.5" }}
          ></i>
        </button>
      </div>

      <div
        className="creator-detail"
        style={
          auth.address === userId.id && auth?.heroImg
            ? {
                background:
                  "url(" +
                  `${config.serverURL}/files/images/${auth?.heroImg}` +
                  ")",
                backgroundRepeat: "no-repeat",
                // backgroundSize: "cover"
                backgroundSize: "100% 100%",
              }
            : filterUser?.heroImg
            ? {
                background:
                  "url(" +
                  `${config.serverURL}/files/images/${filterUser?.heroImg}` +
                  ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // eslint-disable-next-line no-dupe-keys
                backgroundSize: "100% 100%"
              }
            : {
                // backgroundColor: "rgb(44, 170, 187)"
                // background: 'url(../../images/collectorimage.jpg) repeat 0 0'
              }
        }
      >
        <div className="creator-name">
          <span className="userTitle">
            {auth.address === userId.id
              ? auth.name
              : filterUser?.name || "No Name"}
          </span>
          {/* <button>View presentation</button> */}
        </div>

        <div className="floatdiv">
          <div className="userType" style={{ backgroundColor: getColors() }}>
            <span>
              <Avatar
                size="small"
                src={
                  <Image
                    preview={false}
                    style={{
                      width: "80%",
                      height: "85%",
                      objectFit: "contain"
                    }}
                    src={
                      (auth.address === userId.id && auth?.isCreator) ===
                        true || filterUser?.isCreator
                        ? CreatorImage
                        : (auth.address === userId.id && auth?.isAgent) ||
                          filterUser?.isAgent
                        ? AgentImage
                        : (auth.address === userId.id && auth?.isAdmin) ||
                          filterUser?.isAdmin
                        ? AdminProfile
                        : (auth.address === userId.id && auth?.isCollector) ||
                          filterUser?.isCollector
                        ? CollectorImage
                        : { backgroundColor: "rgb(205, 208, 211)" }
                    }
                  />
                }
              />
            </span>
            <h5 className="userTypeTitle">
              {(auth.address === userId.id && auth?.userType) ||
                filterUser?.userType}
            </h5>
          </div>
          <div className="about-div">
            <h5>About</h5>
            <p>{auth.address === userId.id ? auth.bio : filterUser?.bio}</p>
          </div>
          {/* <div className="follower-div">
            <span>Followed By:</span>
            <Row className="follower-list">
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
              <Col sm={6} xs={6} xl={6} lg={6} className="follower"></Col>
            </Row>
          </div> */}
        </div>
      </div>

      <div className="main-div-wrapper">
        <div
          className="main-div"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <span className="title">Latest</span>
            <div className="">
              <Dropdown
                placement="bottomRight"
                overlay={assetMenu}
                trigger={["click"]}
                overlayStyle={{ width: "9rem" }}
              >
                <EllipsisOutlined
                  onClick={(e) => e.preventDefault()}
                  style={{
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #CDD0D3",
                    borderRadius: "5px",
                    fontSize: "2rem"
                  }}
                  size="small"
                />
              </Dropdown>
            </div>
          </div>
          <div className="collection">
            <Row className="list-collection">
              {filteredData
                ?.filter((x) => x.metaDataInfo !== null)
                .map(({ auctionInfo, metaDataInfo }) => {
                  const { creatorInfo, edition, thumbnail } = metaDataInfo;
                  const { auctionID, buyNow, mid } = auctionInfo;
                  const filterAdd = userList?.users?.find((x) => {
                    return x.address === creatorInfo?.creator;
                  });
                  return (
                    <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
                      <Collection
                        to={{
                          pathname: auth.address
                            ? `/app/art/${creatorInfo?.creator}/${auctionID}`
                            : `/art/${creatorInfo?.creator}/${auctionID}`,
                          state: true
                        }}
                        title={edition?.name}
                        price={parseFloat(buyNow)}
                        flowPrice={(parseFloat(buyNow) / 12).toFixed(2)}
                        image={thumbnail[Object.keys(thumbnail)[0]].file}
                        avatarImage={filterAdd?.avtarImg}
                        authorLink={`/app/details/${creatorInfo?.creator}`}
                        data={{ auctionInfo, metaDataInfo }}
                      />
                    </Col>
                  );
                })}

              {owned?.map(({ metadata, id }) => {
                const { creatorInfo, edition, thumbnail } = metadata;
                const filterAdd = userList?.users?.find((x) => {
                  return x.address === creatorInfo?.creator;
                });
                return (
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={Math.random()}>
                    <Collection
                      to={{
                        pathname: `/app/art/${creatorInfo?.creator}/${id}`,
                        state: true
                      }}
                      title={edition?.name}
                      price={parseFloat(0.0)}
                      // flowPrice={(parseFloat(buyNow) / 12).toFixed(2)}
                      flowPrice={"0.00"}
                      image={thumbnail[Object.keys(thumbnail)[0]].file}
                      avatarImage={filterAdd?.avtarImg}
                      authorLink={`/app/details/${creatorInfo?.creator}`}
                      data={{ metaDataInfo: metadata }}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="collection">
            {filterUser && <ShowCollections user={filterUser} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorNew;

const assetMenu = () => {
  return (
    <Menu>
      <MenuItem
        key="featured"
        style={{ font: "normal normal normal 12px Lato", color: "#2F3234" }}
      >
        <Space>
          <StarOutlined style={{ color: "red" }} />
          {`Popularity`}
        </Space>
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="edit">
        <Space>
          <DollarOutlined />
          {`Price`}
        </Space>
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="latest">
        <Space>
          <CheckOutlined />
          {`Latest`}
        </Space>
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="featured">
        <Space>
          <ArrowRightOutlined />
          {`Featured`}
        </Space>
      </MenuItem>
    </Menu>
  );
};

const ShowCollections = ({ user }) => {
  const data = user;
  return (
    <>
      <Space>
        <TitleCollectionName>Collections</TitleCollectionName>
        <TitleCollectionCount>{`(${data.collections?.length})`}</TitleCollectionCount>
      </Space>
      <Row>
        {data.collections?.length >= 1 &&
          data.collections.map((item) => {
            const metaDataInfo = {
              category: [{ name: "" }, { name: "" }],
              creatorInfo: {
                creator: data?.addr,
                agent: null,
                firstSale: null,
                status: true
              },
              description: item.description,
              edition: { name: item.collectionName, number: 1, max: 1 },
              thumbnail: {
                jpg: `${config.serverURL}/files/images/${item.collectionImage}`
              }
            };
            return (
              <Col xl={4} lg={4} md={6} sm={8} xs={24} key={Math.random()}>
                <Collection
                  isCollection={true}
                  data={{ metaDataInfo }}
                  disable={item.counter > 1}
                  // to={Routes.COLLECTIONS}
                  title={item.collectionName}
                  price={0}
                  flowPrice={(parseInt(0) / 12).toFixed(2)}
                  image={`${config.serverURL}/files/images/${item.collectionImage}`}
                  avatarImage={data?.avtarImg}
                  // authorLink={`/details/${auth?.address}`}
                  showFilters={false}
                />
              </Col>
            );
          })}
      </Row>
    </>
  );
};

// {filteredData
//   ?.filter((item, idx) => idx < 4)
//   .map(({ buyNow, metadata, auctionID }) => {
//     return (
//       <Col xl={4} lg={6} md={8} sm={12} xs={24}>
//         <Collection
//           to={{
//             pathname: `/app/art/${creatorInfo?.creator}/${auctionID}`,
//             state: true
//           }}
//           title={edition?.name}
//           price={parseFloat(buyNow)}
//           flowPrice={(parseFloat(buyNow) / 12).toFixed(2)}
//           image={thumbnail[Object.keys(thumbnail)[0]].file}
//           avatarImage={filterAdd?.avtarImg}
//           authorLink={`/app/details/${creatorInfo?.creator}`}
//           data={{ auctionInfo, metaDataInfo }}
//         />
//       </Col>
//     );
//   })}
