/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Avatar, Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { FormUnit, FormUnits, FormCollapse } from "../../../components/Form";
import { getUsersForAgent } from "../../../redux/actions/Admin";
import { getAgentCreators } from "../../../scripts/getAgentsCreator";
import { PanelWrapper } from "./Step1.styled";
import * as config from "../../../config/keys";
import { CardCreatorList } from "./uploadCollection.styled";

const CreatorListforagent = ({
  onCreatorRemoved,
  mintResult,
  onCreatorSelected
}) => {
  const { auth, userList } = useSelector((obj) => obj);
  const [creatorList, setCreatorList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersForAgent(auth.address));
  }, []);

  useEffect(() => {
    getAgentCreators(auth.address).then((re) => {
      if (userList?.users) {
        const agentUsers = userList?.users?.filter(
          (x) => re.includes(x.address) && x.agentAddress === auth.address
        );
        const s = agentUsers.map((x) => {
          return { ...x, label: x.name, value: x.address };
        });
        setCreatorList(s);
      }
      // setCreatorList(re);
    });
  }, [userList]);

  const customOptions = ({ innerRef, innerProps, data }) => {
    return (
      <div ref={innerRef} {...innerProps}>
        <CardCreatorList>
          <Card.Meta
            avatar={
              <Avatar
                src={
                  data?.avatarImage
                    ? `${config.serverURL}/files/images/${data?.avatarImage}`
                    : "https://joeschmoe.io/api/v1/random"
                }
                alt="avatar"
              />
            }
            title={data.name}
            description={data.address}
          />
        </CardCreatorList>
        <Divider style={{ margin: 0 }} />
      </div>
    );
  };

  return (
    <FormUnits fd="column">
      <FormUnit>
        <FormCollapse showArrow={true} ghost>
          <PanelWrapper showArrow={true} header="Select Creator" key="1">
              <ReactSelect
                options={creatorList}
                isClearable
                isSearchable
                components={{ Option: customOptions }}
                onChange={(selectedOption) =>
                  onCreatorSelected(selectedOption?.value)
                }
              />
          </PanelWrapper>
        </FormCollapse>
      </FormUnit>
    </FormUnits>
  );
};

export default CreatorListforagent;
