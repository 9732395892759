/* eslint-disable no-unused-vars */
import {
  NewProfile,
  NewProfileHeader,
  NewProfileHeaderRow,
  HeaderTitle,
  NewProfileForm,
  // HeaderLogo,
  FormColumn,
  FormItem,
  SubmitButton,
} from "../Profile.styled";
import Image from "../../../components/Image";
import {
  FormField,
  FormTextArea,
  FormUnit,
  FormUnitTitle,
  FormFileUpload,
  FormUnits,
  FormFileUploadContainer,
  FormRow,
} from "../../../components/Form";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// import Button from "../../../components/Button";
import SocialNetworks from "../../../components/SocialNetworks";
import { Row, Form, message, Input, Button, Col, Upload } from "antd";
import coverImage from "../../../images/creator-cover.png";
import { InstagramFilled, TwitterCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import UploadBrowseFiles from "../UploadBrowseFiles";
import * as config from "../../../config/keys";
import * as actions from "../../../redux/actions";
import * as ROUTES from "../../../Routes";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default function NewCreatorForm() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { auth } = useSelector((obj) => obj);
  const [avtarval, setAvtarVal] = useState("");
  const [heroVal, setHeroVal] = useState("");

  const [fileList, setFileList] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [hero, setHero] = useState(null);
  const [fdata, setFData] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const submit = (value) => {
    const fd = new FormData();
    fd.append("file", fileList?.file);
    Object.keys(value).forEach(
      (key) => value[key] === undefined && delete value[key]
    );
    if (avtarval) {
      value["avtarImg"] = avtarval;
    }
    if (heroVal) {
      value["heroImg"] = heroVal;
    }

    const update = {
      where: { _id: auth._id },
      update: { ...value },
    };
    dispatch(
      actions.loginActions.updateUser(update, () => {
        history.push(ROUTES.DASHBOARD);
      })
    );
    // dispatch(actions.userActions.getImages());

    // dispatch(
    //   userActions.updateUserImages({ file: fdata })
    //   // userActions.updateUserImages({ _id: auth._id, ...value, ...fileList })
    //   // userActions.updateUserImages(fd)
    // );
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const avatarChange = async (info) => {
    const { fileList, file } = info;
    setFileList(fileList);
    if (file) {
      file.preview = await getBase64(file.originFileObj);
    }
    setAvatar((prev) => ({
      ...prev,
      file: file,
      filePreview: file.preview,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      fileLastModified: file.lastModified,
    }));

    if (info.file.status === "uploading") {
      setDisableBtn(true);
    }
    if (info.file.status === "done") {
      setDisableBtn(false);
    }
    // console.log(file.status);
  };
  const heroChange = async (info) => {
    const { fileList, file } = info;
    if (file) {
      file.preview = await getBase64(file.originFileObj);
    }
    setHero((prev) => ({
      ...prev,
      file: file,
      filePreview: file.preview,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      fileLastModified: file.lastModified,
    }));

    // console.log(file.status);
  };

  const onUploadSuccess = async (info) => {
    setHeroVal(info.filename);
  };

  const onAvtarUploadSuccess = async (info) => {
    setAvtarVal(info.filename);
  };

  return (
    <Row
      type="flex"
      justify="space-around"
      className="mr-3 ml-3"
      // align="middle"
      //style={{ minHeight: "100vh" }}
    >
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={submit}
        // onFinishFailed={test}
        initialValues={{ ...auth }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <HeaderTitle>Profile</HeaderTitle>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormItem label="Name" name="name">
                  <Input
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      height: "40px",
                      background: "transparent",
                    }}
                    value={auth?.name}
                  />
                </FormItem>
                <FormItem label="About" name="bio">
                  <Input.TextArea
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      background: "transparent",
                      // height: "40px",
                    }}
                    rows={6}
                  />
                </FormItem>
                <Row>
                  <Col style={{ paddingRight: "5px" }} span={12}>
                    <Form.Item name="avatar">
                      <FormUnitTitle>Upload avatar</FormUnitTitle>
                      <FormFileUpload h={"110px"}>
                        <FormFileUploadContainer>
                          <UploadBrowseFiles
                            name="file"
                            onChange={avatarChange}
                            fileList={fileList}
                            files={avatar}
                            action={`${config.serverURL}/files/upload`}
                            onUploadSuccess={onAvtarUploadSuccess}
                          />
                        </FormFileUploadContainer>
                      </FormFileUpload>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="hero">
                      <FormUnitTitle>Hero Image</FormUnitTitle>
                      <FormFileUpload h={"110px"}>
                        <FormFileUploadContainer>
                          <UploadBrowseFiles
                            name="file"
                            onChange={heroChange}
                            fileList={fileList}
                            files={hero}
                            action={`${config.serverURL}/files/upload`}
                            onUploadSuccess={onUploadSuccess}
                          />
                        </FormFileUploadContainer>
                      </FormFileUpload>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormItem label="Email" name="email">
                  <Input
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      height: "40px",
                      background: "transparent",
                    }}
                    disabled="true"
                    value={auth?.email}
                  />
                </FormItem>
                <FormItem label="Website" name="website">
                  <Input
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      height: "40px",
                      background: "transparent",
                    }}
                  />
                </FormItem>
                <h5
                  style={{
                    lineHeight: "1.5715",
                    whiteSpace: "normal",
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "700",
                    fontFamily: "Lato",
                  }}
                >
                  Socialmedia
                </h5>
                {/* <FormItem label="Social Media" name="socialmedia"> */}
                <FormItem style={{ marginBottom: "5px" }} name="twitter">
                  <Input
                    prefix={<TwitterCircleFilled />}
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      height: "40px",
                      background: "transparent",
                    }}
                  />
                </FormItem>
                <FormItem name="instagram">
                  <Input
                    prefix={<InstagramFilled />}
                    style={{
                      border: "1px solid #999B9E",
                      borderRadius: "4px",
                      opacity: 1,
                      height: "40px",
                      background: "transparent",
                    }}
                  />
                </FormItem>
                {/* </FormItem> */}
              </Col>
            </Row>
            <Row>
              <FormItem mt="24px">
                <Button
                  type="primary"
                  htmlType="submit"
                  order="full-red"
                  size="regular"
                  disabled={disableBtn}
                >
                  Update Profile
                </Button>
              </FormItem>
            </Row>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
