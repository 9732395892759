import * as fcl from "@onflow/fcl";
import keys from "../config/keys";

export const checkDaamAcccount = async (address) => {
  const result = await fcl.query({
    cadence: `
    // get_collections.cdc

//import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport} from ${keys.daam}

pub fun main(account: Address): {String: ${keys.daamImport}.NFTCollectionDisplay} {
  let collectionRef = getAccount(account)
      .getCapability<&{${keys.daamImport}.CollectionPublic}>(${keys.daamImport}.collectionPublicPath)
      .borrow()
      ?? panic("Could not borrow capability from public collection")
  
  let list = collectionRef.getCollection()
  //var value: {String: ${keys.daamImport}.NFTCollectionDisplay} = {}
  //for col in list { value.insert(key: col.display.name, col) }
  //return value
  return list
}
        `,
    args: (arg, t) => [arg(address, t.Address)]
  });
  return result;
};
