import styled from "styled-components";

export const AgencyManagementHeaderStyles = styled.header`
    display:flex;
    justify-content:center;
    width:100%;
    margin-top:120px;
    padding-left:var(--x-gutter);
    padding-right:var(--x-gutter);
    margin-bottom:96px;
`
export const Container = styled.div`
    display:flex;
    position:relative;
    width:100%;
    margin-left:auto;
`
export const AgencyManagementHeaderOptions = styled.div`
    display:flex;
    position:absolute;
    top:0; right:0;
    margin-left:auto;
`
export const AgencyManagementHeaderOption = styled.div`
    display:flex;
    width:45px;
    height:45px;
    background-color:white;
    box-shadow:var(--box-shadow-light);
    border-radius:5px;
    margin-left:12px;
    &:first-of-type {
        margin-left:0;
    }
`