import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";

export const getAgentCreators = async (agentAddress) => {
  const result = await fcl.query({
    cadence: `
    // get_agent_creators.cdc
// Get List of Creators and their Agent

import ${keys.daamImport} from ${keys.daam}

pub fun main(agent: Address): [Address]? {
    return ${keys.daamImport}.getAgentCreators(agent: agent)
}
   `,

    args: (arg, t) => [
      arg(agentAddress, t.Address) // addr: Address,
    ]
  });
  return result;
};
