import { toast } from "react-toastify";

const defaultConfig = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
export const success = (message, customConfig) => {
  toast.success(message, { ...defaultConfig, ...customConfig });
};

export const error = (message, customConfig) => {
  toast.error(message, { ...defaultConfig, ...customConfig });
};

export const warning = (message, customConfig) => {
  toast.warning(message, { ...defaultConfig, ...customConfig });
};

export const info = (message, customConfig) => {
  toast.info(message, { ...defaultConfig, ...customConfig });
};

export const regular = (message, customConfig) => {
  toast(message, { ...defaultConfig, ...customConfig });
};
