/* eslint-disable no-unused-vars */
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography
} from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import {
  CancelButton,
  NFTArt,
  NFTDetails,
  NFTPriceDetails,
  NFTPriceDetailsTotal,
  NFTSubDetails,
  OkButton,
  Title
} from "./buyModal.styled";

const BidandBuyPop = (props) => {
  const [Price, setPrice] = useState(
    props?.buyNowPrice ? parseFloat(props?.buyNowPrice).toFixed(2) : 0
  );
  const buyNowContent = () => {
    return (
      <Col span={24}>
        <Space direction="vertical" className="w-100">
          <Space
            className="d-flex justify-content-between"
            style={{ justifyContent: "space-between" }}
          >
            <NFTPriceDetails>{"Price of piece"}</NFTPriceDetails>
            <NFTPriceDetails>
              {/* { (Price > props?.originalPrice) && props?.originalPrice}<br/> */}
              {Price && Price - Price * props?.fees}
              {/* {Price - Price * (props.croyality / 100) - Price * 0.025} */}
            </NFTPriceDetails>
          </Space>
          <Space className="d-flex justify-content-between">
            <NFTPriceDetails>
              {`Creator Royalities (${props.croyality}%)`}
            </NFTPriceDetails>
            <NFTPriceDetails>{Price * props.croyality}</NFTPriceDetails>
          </Space>
          <Space className="d-flex justify-content-between">
            <NFTPriceDetails>{`DAAM Fee (${
              props?.fees * 100
            }%)`}</NFTPriceDetails>
            <NFTPriceDetails>
              {/* {Price * props?.fees} */}
              {/* { (Price > props?.originalPrice) && props?.originalPrice * props?.fees}<br/> */}
              {Price && (Price * props?.fees).toFixed(2)}
            </NFTPriceDetails>
          </Space>
        </Space>
        <Divider />
        <Space className="d-flex justify-content-between">
          <NFTPriceDetailsTotal>{`Total`}</NFTPriceDetailsTotal>
          <NFTPriceDetailsTotal>{Price}</NFTPriceDetailsTotal>
        </Space>
      </Col>
    );
  };

  const bidContent = () => {
    return (
      <Row>
        <Col span={16}>
          <h5>Enter your bid..</h5>
          <br />
          <span>Your bid</span>
          <br />
          {/* <a>Creator Royality({props.croyality}%)</a>
        <br />
        <a>Daam Fees(2.5%)</a>
        <br />
        Total */}
        </Col>
        <Col span={8}>
          <Form>
            <Form.Item
              name="bid"
              rules={[
                {
                  required: true,
                  message: "Can not submit blank bid"
                }
              ]}
            >
              <InputNumber
                value={props.price}
                min={props.minbid}
                defaultValue={props.minbid}
                onChange={(e) => {
                  if (e && e >= props.minbid) {
                    props.setdisabledBidBtn(false);
                    props.settotalPrice(parseFloat(e).toFixed(8));
                  } else if (e && e < props.minbid) {
                    toast.error("min amount is " + props.minbid);
                  } else {
                    props.setdisabledBidBtn(true);
                    toast.error("can not be null");
                  }
                }}
              />
            </Form.Item>
          </Form>

          {props.price}
        </Col>
      </Row>
    );
  };

  return (
    <Row className="w-100" gutter={[0, 8]}>
      <Col span={24}>
        <Space className="w-100 justify-content-between">
          <Title>Confirmation</Title>
          <CloseCircleOutlined onClick={props.onCancelClick} />
        </Space>
      </Col>
      <Col span={24}>
        <NFTArt>
          <Col span={24}>
            <Space>
              <Avatar
                size={64}
                shape="square"
                src={props.image}
                style={{ borderRadius: "5px" }}
              ></Avatar>
              <Space direction="vertical">
                <NFTDetails>{props.title}</NFTDetails>
                <NFTSubDetails>{props.creator}</NFTSubDetails>
              </Space>
            </Space>
          </Col>
        </NFTArt>
      </Col>
      <Col span={20}>
        {props.action === "buy" && buyNowContent()}
        {props.action === "bid" && bidContent()}
      </Col>
      <Col span={24} className="pt-2 d-flex justify-content-end">
        <Space>
          {props.action === "buy" && (
            <CancelButton onClick={props.onCancelClick}>Cancel</CancelButton>
          )}
          {props.action === "buy" && (
            <OkButton onClick={props.onOkClick}>Buy</OkButton>
          )}
        </Space>
      </Col>
    </Row>
  );
};
export default BidandBuyPop;
