import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";
import * as types from "@onflow/types";
import { tx } from "./util/tx";

const CODE = fcl.cdc`
// but_it_now.cdc
// Used for direct purchases
import FungibleToken        from ${keys.fungibleToken}
import FUSD                 from ${keys.fusd}
import ${keys.auctionHouseImport} from ${keys.auctionHouse}
import ${keys.daamImport}         from ${keys.daam}

transaction(auction: Address, aid: UInt64, bid: UFix64)
{
    let bidder          : Address
    let auctionHouse    : &${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}
    let fusdStoragePath : StoragePath
    let collection      : &{${keys.daamImport}.CollectionPublic}
    let vaultRef        : &FUSD.Vault{FungibleToken.Provider}
    let aid             : UInt64
    let bid             : UFix64
    
    prepare(bidder: AuthAccount) {
        self.bidder = bidder.address
        self.fusdStoragePath = /storage/fusdVault
        self.vaultRef   = bidder.borrow<&FUSD.Vault{FungibleToken.Provider}>(from: self.fusdStoragePath)!
        self.collection = bidder.borrow<&{${keys.daamImport}.CollectionPublic}>(from: ${keys.daamImport}.collectionStoragePath)!
        self.auctionHouse = getAccount(auction)
            .getCapability<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>
            (${keys.auctionHouseImport}.auctionPublicPath)
            .borrow()!

        self.aid = aid
        self.bid = bid
    }

    execute {
        let amount <- self.vaultRef.withdraw(amount: self.bid)!
        self.auctionHouse.item(self.aid)!.buyItNow(bidder: self.bidder, amount: <-amount)!
    }
}
`

export const buyNow = async ({ auctionAddress, auctionID, bid }, opts = {}) => {
  const { authorization } = fcl.currentUser();
  return tx([
    fcl.transaction(CODE),
    fcl.args([
      fcl.arg(auctionAddress, types.Address),
      fcl.arg(auctionID, types.UInt64),
      fcl.arg(bid, types.UFix64),
    ]),
    fcl.proposer(authorization),
    fcl.payer(authorization),
    fcl.authorizations([
      authorization
    ]),
    fcl.limit(keys.fclLimit)
  ], opts)
}