import styled from "styled-components";

export const PDFViewerContainer = styled.div`
  & canvas {
    width: 100% !important;
    height: ${({ height }) => `${height}px`} !important;
  }
  & .react-pdf__Page__textContent {
    ${({ showPageControls }) => !showPageControls && `display:none !important`}
  }
`;
