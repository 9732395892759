import React from "react";
import { MintingRow } from "../../../components/MintingLayout/styles";
import { FormUnit, FormUnits, FormCollapse } from "../../../components/Form";
import { Col, Input, DatePicker } from "antd";
import { PanelWrapper } from "./Step1.styled";

const PhysicalObjectDetails = ({ onChange, onDateChange, mintResult }) => {
  return (
    <FormUnits fd="column">
      <FormUnit>
        <FormCollapse  showArrow={false} ghost>
          <PanelWrapper
            showArrow={true}
            header="Physical Object"
            key="1"
          >
            <MintingRow mt="1em">
              <Col span={8}>Dimensions</Col>
              <Col>
                <Input placeholder="w x h Feet" id="dimension" onChange={onChange} value={mintResult.dimension} />
              </Col>
            </MintingRow>
            <MintingRow mt="1em">
              <Col span={8}>Medium</Col>
              <Col span={12}>
                <Input placeholder="Canvas" id="medium" onChange={onChange} value={mintResult.medium}/>
              </Col>
            </MintingRow>
            <MintingRow mt="1em">
              <Col span={8}>Creation Date</Col>
              <Col span={12}>
                <DatePicker onChange={onDateChange} id="createdDate" value={mintResult.creationDate} />
              </Col>
            </MintingRow>
          </PanelWrapper>
        </FormCollapse>
      </FormUnit>
    </FormUnits>
  );
};

export default PhysicalObjectDetails;
