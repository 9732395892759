/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext } from "react";
import { Row, Col, Typography } from "antd";

import {
  CreateNFTIconsWrapper,
  CreateNFTWrapper,
  ButtonNFT,
  IconNFT,
  HandPeaceWrapper
} from "../../../containers/minting/createNFT/CreateNFT.styled";

import Image from "../../../components/Image";
import handPeace from "../../../images/hand-peace.png";
import vestPatches from "../../../images/icons/minting/vest-patches.png";
import cameraRetro from "../../../images/icons/minting/camera-retro.png";
import camcorder from "../../../images/icons/minting/camcorder.png";
import image from "../../../images/icons/minting/image.png";
import headVr from "../../../images/icons/minting/head-vr.png";
import palette from "../../../images/icons/minting/palette.png";
import turntable from "../../../images/icons/minting/turntable.png";
import userAlien from "../../../images/icons/minting/user-alien.png";
import { MintContext } from "../../../context/mintContext";
import { useSelector } from "react-redux";

const { Title } = Typography;

const StartMinting = ({ onConfirmDetails }) => {
  const [category, setCategory] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const { minting, setMintResult } = useContext(MintContext);

  const descriptionRef = useRef();
  const titleRef = useRef();

  const { filterList } = useSelector((obj) => obj);

  const categoryItem = (key, categoryInfo) => {
    return (
      <>
        <br />
        <Title
          strong
          type="danger"
          level={5}
          style={{
            font: "normal normal 600 16px/24px Oswald",
            letterSpacing: "0.77px",
            color: "#FF4040",
            textTransform: "uppercase",
            margin: "0px 15px 18px 12px"
          }}
        >
          {key}
          {/* {categoryInfo?.label} */}
        </Title>
        <Row
          gutter={[8, 8]}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {categoryInfo?.map((buttonInfo) => {
            return (
              <Col
                span={20}
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center"
                }}
              >
                <ButtonNFT
                  onClick={() => {
                    setMintResult((prev) => ({
                      ...prev,
                      mintType: buttonInfo.value,
                      mintLabel: buttonInfo.label,
                      category: buttonInfo.category,
                      allowedExtensions: buttonInfo.extension.join(", ")
                    }));
                    onConfirmDetails();
                  }}
                >
                  {/* {buttonInfo.icon} */}
                  <IconNFT src={buttonInfo.icon} alt={buttonInfo.label} className="iconImage"/>
                  <span className="iconLabel">{`  ${buttonInfo.label}`}</span>
                </ButtonNFT>
                {/* </Link> */}
              </Col>
            );
          })}
        </Row>
        <br />
      </>
    );
  };

  return (
    <CreateNFTWrapper style={{ marginTop: 0 }}>
      <Title
        level={3}
        style={{
          font: "normal normal 600 34px/51px Oswald",
          letterSpacing: "1.02px",
          color: "#1A1D1F",
          position: "relative",
          zIndex: 1,
          background: "#F7F7F7 0% 0% no-repeat padding-box",
          top: "8px",
          textAlign: "center",
          width: "459px",
          height: "61px",
          border: "1px solid #707070",
          borderRadius: "6px",
          opacity: "1",
          margin: "auto"
        }}
      >
        {`What are you minting today?`}
      </Title>
      <CreateNFTIconsWrapper>
        <HandPeaceWrapper>
          <Image src={handPeace} alt="handPeace" />
        </HandPeaceWrapper>
        <Row gutter={[64, 16]}>
          {filterList &&
            Object.entries(filterList).map((key, value) => {
              return (
                <Col
                  lg={12} xl={12} xxl={12} md={12} sm={12} xs={24}
                  key={Math.random()}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  {categoryItem(key[0], key[1])}
                </Col>
              );
            })}

          {/* {categoriesInfo.map((categoryInfo) => {
            return (
              <Col span={12} key={Math.random}>
                 {categoryItem(categoryInfo.name, categoryInfo)}
              </Col>
            );
          })} */}
        </Row>
      </CreateNFTIconsWrapper>
    </CreateNFTWrapper>
  );
};

export default StartMinting;
