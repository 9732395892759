/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spin, Steps } from "antd";
import { MintContext } from "../../context/mintContext";
import { MintingWrapper, StepsAction } from "./Minting.styled";
import UploadStep from "./uploadStep";
import ListStep from "./listStep";
import ConfirmNFTDetails from "./confirmNFTDetails";
import SubmitStep from "./submitStep";
import * as ROUTES from "../../Routes";
import StartMinting from "./startminting";
import { listToMarketplace } from "../../scripts/listToMarketplace";
import { error, success } from "../../common/toast";
import { AuthContext } from "../../context/authContext";

const StepsContainer = ({ filterParam }) => {
  const [current, setCurrent] = React.useState(0);
  const { mintResult, setMintResult } = useContext(MintContext);
  const { mintState, setMintState } = useContext(AuthContext);

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgFile, setErrorMsgFile] = useState("");
  const [errorMsgPrice, setErrorMsgPrice] = useState("");
  const [mintedData, setMintedData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [listing, setListing] = useState(null);

  const descriptionRef = useRef();
  const titleRef = useRef();
  const buyPrice = useRef();
  const fileRef = useRef();
  const history = useHistory();

  const validateInput = () => {
    const fields = [
      {
        name: "title",
        value: mintResult.title,
        message: "Title should not be blank."
      },
      {
        name: "description",
        value: mintResult.description,
        message: "Description should not be blank."
      },
      {
        name: "price",
        value: mintResult.price,
        message: "Price should not be blank."
      },
      {
        name: "file",
        value: mintResult.file,
        message: "file needs to upload"
      }
    ];

    const isNotFilled = fields.some((field) => {
      if (field.value === null || field.value === 0) {
        field.name === "price" && setErrorMsgPrice(field.message);
        field.name === "price" && buyPrice.current.focus();
        field.name === "file" && setErrorMsgFile(field.message);
        field.name === "file" && fileRef.current.focus();
        return true;
      }
      if (field.value === "") {
        setErrorMsg(field.message);
        field.name === "description"
          ? descriptionRef.current.focus()
          : titleRef.current.focus();
        return true;
      }
      if (!(field.name in mintResult) || field.value === null) {
        setErrorMsgFile(field.message);
        field.name === "file" && fileRef.current.focus();
        return true;
      }

      setErrorMsg("");
      return false;
    });
    return isNotFilled;
  };

  useEffect(() => {
    if (mintState === 0) {
      setCurrent(0);
      setMintState(null);
      setMintResult(null);
    }
  }, [mintState, setMintState]);

  const next = (event) => {
    const isInvalid = validateInput();
    if (!isInvalid) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onConfirmDetails = (val) => {
    // setMintResult((prev) => ({ ...prev, series: val }));
    if (val) {
      setMintedData(val);
      if (val?.events) {
        if (val.events.length >= 7) setListing(val.events[3].data?.auctionID);
        else if (val.events[2].data?.mid) setListing(val.events[2].data?.mid);
        else setListing(val.events[1].data?.mid);
      }
    }

    next();
  };

  const marketplaceListing = (res) => {
    setIsDisabled(true);
    const mid = res.events[1].data.mid
      ? res.events[1].data.mid
      : res.events[0].data.mid;
    listToMarketplace(
      {
        mid: mid,
        price: mintResult.price,
        ...mintResult
        //metaData.price,
      },
      { onSuccess: onListingSuccess, onError: onListingError }
    )
      .then((res) => {
        if (res) {
          setMintResult((prev) => ({ ...prev, saleType: "listed" }));
          if (res.events.length === 4)
            setListing(res.events[0].data?.auctionID);
          else if (res.events.length === 5)
            setListing(res.events[1].data?.auctionID);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const onListingSuccess = (res) => {
    setMintedData(res);
    setMintResult((prev) => ({ ...prev, saleType: "listed" }));
    if (res.events.length >= 7) {
      setListing(res.events[3].data?.auctionID);
    } else if (res.events.length === 4)
      setListing(res.events[0].data?.auctionID);
    else if (res.events.length === 5) setListing(res.events[1].data?.auctionID);
    else setListing(res.events[0].data?.auctionID);
    success("Your Listing has been Successfull");
    setIsDisabled(false);
  };

  const onListingError = (err) => {
    error(err);
    setIsDisabled(false);
  };

  const cancel = () => {
    setMintResult(null);
    setCurrent(0);
  };

  const steps = [
    {
      title: "What are you minting today",
      content: (
        <StartMinting
          onConfirmDetails={() => setCurrent(current + 1)}
        ></StartMinting>
      )
    },
    {
      title: "Upload",
      content: (
        <UploadStep
          filterParam={mintResult?.mintType}
          descriptionRef={descriptionRef}
          titleRef={titleRef}
          errorInputMessage={errorMsg}
          onCancelClick={cancel}
          onPreviousClick={prev}
          onConfirmDetails={onConfirmDetails}
          buyPrice={buyPrice}
          errorMsgPrice={errorMsgPrice}
          errorMsgFile={errorMsgFile}
          setErrorMsgPrice={setErrorMsgPrice}
          setErrorMsgFile={setErrorMsgFile}
        ></UploadStep>
      )
    },
    // {
    //   title: "Set Price",
    //   content: (
    //     <SetPrice
    //       onConfirmDetails={onConfirmDetails}
    //       onCancelClick={cancel}
    //       onPreviousClick={prev}
    //     />
    //   ),
    // },
    {
      title: "Confirm NFT Details",
      content: (
        <ConfirmNFTDetails
          onConfirm={onConfirmDetails}
          onCancelClick={cancel}
          onPreviousClick={prev}
        />
      )
    },
    // {
    //   title: "Submit",
    //   content: <SubmitStep nextStep={next}></SubmitStep>,
    // },
    {
      title: "List",
      content: (
        <ListStep
          mintedData={mintedData}
          listing={listing}
          onListToMarketplace={(res) => marketplaceListing(res)}
        ></ListStep>
      )
    }
  ];
  //Test Commit..
  return (
    <Spin spinning={isDisabled}>
      <MintingWrapper>
        <Steps current={current}>
          {/* {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))} */}
        </Steps>
        {steps[current].content}

        <StepsAction>
          {/* STEP1: NFT PRICE BUTTON  */}
          {/* {current === 0 && (
          <MintingCenter>
            <MintingButton
              // onClick={minting}
              onClick={next}
              type="primary"
              size="large"
              className="mintingNextButton"
            >
              <MintingText>
              
                Next
              </MintingText>
            </MintingButton>
          </MintingCenter>
        )} */}

          {/* {current > 0 && current < 2 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )} */}
        </StepsAction>
      </MintingWrapper>
    </Spin>
  );
};

export default StepsContainer;
