/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const ForgotPWContext = createContext();

export const ForgotPWProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0); // 0 = CREATENFT, OTHER = STEPS
  const history = useHistory();
  const [forgotPWData, setforgotPWData] = useState({
      email:"",
  });

  return (
    <ForgotPWContext.Provider value={{ forgotPWData, setforgotPWData }}>
      {children}
    </ForgotPWContext.Provider>
  );
};
