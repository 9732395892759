import * as fcl from "@onflow/fcl";
import * as keys from "../config/keys";

export const checkAuctionWallet = async (address) => {
  const result = await fcl.query({
    cadence: `
    // check_auction_wallet.cdc
// Checks to see if there is an Auction Wallet

import NonFungibleToken from ${keys.nonFungibleToken}
import ${keys.auctionHouseImport}     from ${keys.auctionHouse}

pub fun main(auction: Address): Bool {
  let auctionHouse = getAccount(auction)
      .getCapability<&${keys.auctionHouseImport}.AuctionWallet{${keys.auctionHouseImport}.AuctionWalletPublic}>
      (${keys.auctionHouseImport}.auctionPublicPath)
      .borrow()
      
  return auctionHouse != nil
}
        `,
    args: (arg, t) => [arg(address, t.Address)]
  });
  return result;
};
