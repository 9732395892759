/* eslint-disable no-unused-vars */
import React from "react";
import { Checkbox, Popover, Typography, Button, Row, Col, Space } from "antd";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { FilterByNFTWrapper } from "./FilterByNFT.styled";

const { Text } = Typography;

const FilterByNFT = (props) => {
  const plainOptions = ["NFT Request", "For sale", "Minting", "Sold"];
  const text = <Text strong>{"FILTER BY NFT"}</Text>;
  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues);
  };

  const content = (
    <FilterByNFTWrapper>
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
        <Row>
          <Col flex="auto">{"NFT Request"}</Col>
          <Col flex="5px">
            <Checkbox value="NFT Request"></Checkbox>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">{"For sale"}</Col>
          <Col flex="5px">
            <Checkbox value="For sale" />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">{"Minting"}</Col>
          <Col flex="5px">
            <Checkbox value="Minting" />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">{"Sold"}</Col>
          <Col flex="5px">
            <Checkbox value="Sold" />
          </Col>
        </Row>
        <br />
        {"List pending"}
        <br />
      </Checkbox.Group>
    </FilterByNFTWrapper>
  );
  return (
    <Popover placement="right" title={text} content={content} trigger="click">
      <Button>CLICK HERE TO TEST - FILTER BY NFT</Button>
    </Popover>
  );
};

export default FilterByNFT;
