import styled from "styled-components";
import { Button, Upload } from "antd";

export const UploadNFTBrowseFilesStyles = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  border: 1px solid #999b9e;
  padding: 16px;
  border-radius: 5px;
`;
export const UploadDropAreaContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  // border: 1px dashed #999b9e;
  background-image: repeating-linear-gradient(
      -16deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      74deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      164deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    ),
    repeating-linear-gradient(
      254deg,
      #999b9e,
      #999b9e 5px,
      transparent 5px,
      transparent 9px,
      #999b9e 9px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  padding: 8px;
  // min-height:320px;
  border-radius: 5px;
`;
export const UploadDropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // margin: 20px 0;
`;
export const AreaCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  height: 100%; */
`;
export const CenterIcon = styled.img`
  display: flex;
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => props.height && props.height};
`;
export const CenterLabel = styled.span`
  display: flex;
  color: #2f3234;
  font: normal normal bold 20px/52px Lato;
`;
export const UploadImageButton = styled(Button)`
  width: 98px;
  font: normal normal bold 12px/15px Lato;
  letter-spacing: 0.29px;
  color: #fafafa;
  text-transform: uppercase;
  background: #fc4448 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #fc4448;
  border-radius: 15px;
  opacity: 1;

  &:hover {
    background-color: #1a1d1f;
    color: #fff;
  }
`;

export const CustomUpload = styled(Upload)`
  display: flex;
  justify-content: center;
  & div {
    width: 100%;
    & .ant-upload-list-picture-card-container {
      display: none;
    }
    & .ant-upload.ant-upload-select-picture-card {
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      & .ant-upload {
        flex-direction: column;
        height: auto !important;
        margin: 25px 0;
      }
    }
  }
  // & .ant-upload-list-picture-card-container {
  //   display: none;
  // }
`;

export const CollectionTitle = styled.span`
  color: var(--unnamed-color-2f3234);
  text-align: left;
  font: normal normal bold 16px/52px Lato;
  letter-spacing: 0px;
  color: #2f3234;
  opacity: 1;
`;
