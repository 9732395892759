import { Row, Col } from "antd";
import "./index.css";
import HomeFooter from "../../containers/home/footer";
import { useLocation } from "react-router-dom";

export const AdminFooter = () => {
  const PublicFooter = useLocation();
  return (
    <>
      {PublicFooter.state && <HomeFooter />}
      <Row
        style={{
          borderTop: "1px solid #B3B4B7",
          borderBottom: "1px solid #FF4040",
          padding: "10px"
        }}
      >
        {/* <Col span={1}>
          <Image src={WordedLogo} />
        </Col> */}
        <Col xl={18} xxl={18} sm={24} md={24} xs={24}>
          <span
            style={{
              font: "normal normal bold 16px/19px Lato",
              letterSpacing: "0px",
              color: "#595E62"
            }}
          >
            {`© Copyright 2022`}
            <font
              style={{
                color: "#FF4040"
              }}
            >
              {` daam.agency`}
            </font>
          </span>
        </Col>
        <Col xl={6} xxl={6} sm={24} md={24} xs={24}>
          <span className="pp">{`Privacy Policy`}</span>
          <span className="tc">{`Terms of Service`}</span>
        </Col>
      </Row>
    </>
  );
};

export default AdminFooter;
