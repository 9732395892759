import * as fcl from "@onflow/fcl";
import keys from "../config/keys";

export const getRoyalties = async (mid) => {
  const result = await fcl.query({
    cadence: `
    // get_royalties.cdc
// Get MID Royalties (MetadataViews.Royalties)

import MetadataViews from ${keys.metadataViews}
import ${keys.daamImport}          from ${keys.daam}

pub fun main(mid: UInt64): MetadataViews.Royalties
{
    return ${keys.daamImport}.getRoyalties(mid: mid)
}
        `,
    args: (arg, types) => [arg(mid, types.UInt64)]
  });
  return result;
};
