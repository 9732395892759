import { Button, Form } from "antd";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  // max-width: 80%;
  // padding-top: 10em;
  // padding-left: 25em;
  padding-top: 1%;
`;

export const ImageWrapper = styled.div`
  max-width: 100%;
`;

export const Paragraph = styled.h3`
  display: flex;
  justify-content: center;

  text-align: center;
  font: normal normal 500 30px/106px Oswald;
  letter-spacing: 1.02px;
  color: #2f3234;
  opacity: 1;
`;

export const RegisterText = styled.div`
  font: normal normal bold 16px/76px Lato;
  letter-spacing: 0px;
  & .rlink {
    text-align: center;
    font: normal normal bold 16px/76px Lato;
    letter-spacing: 0px;
    color: #ff4040;
  }
  & .rtext {
    text-align: center;
    font: normal normal 500 16px/76px Lato;
    letter-spacing: 0px;
    color: #1a1d1f;
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
  }
  label {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    text-transform: capitalize;
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
  placeholder {
    text-align: left;
    padding: 0;

    font-family: "Poppins";
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 16px/76px;

    letter-spacing: 0.51px;
    color: #595e62;
    text-transform: capitalize;
    opacity: 1;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px/76px;
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;

  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  height: 45px;
  text-align: center;
  // font: normal normal bold 20px/24px "Lato";
  font-family: "Lato";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 20px/24px;
  letter-spacing: 0.4px;
  color: #1a1d1f;
  opacity: 1;
  background-color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
  &:hover {
    background-color: ${(props) => (props.inverse ? "#fff" : "#1a1d1f")};
    color: ${(props) => (props.inverse ? "#1a1d1f" : "#fff")};
  }
`;
