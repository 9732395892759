import styled from "styled-components";

export const SocialNetworkStyles = styled.div`
    display:flex;
    width:24px;
    height:24px;
    background-color:#ccc;
    margin-left:8px;
    &:first-of-type {
        margin-left:0;
    }
`