const clientURL = "https://marketplace.imageio.in";
const fclLimit = 9999;
const flowScanLink = `https://flow-view-source.com/testnet/tx`;

//Testnet
const fungibleToken = "0x9a0766d93b6608b7";
const nonFungibleToken = "0x631e88ae7f1d7c20";
const profile = "0x0bb80b2a4cb38cdf";
const flowToken = "0x7e60df042a9c0868";
const fusd = "0xe223d8a629e49c68";
const auctionHouse = "0x01837e15023c9249";
const daam = "0xa4ad5ea5c0bd2fba";
const metadataViews = "0x631e88ae7f1d7c20";
const categories = "0xa4ad5ea5c0bd2fba";
const multipleFungibleToken = "0xf0653a06e7de7dbd";

const daamImport = "DAAM_Mainnet";
const auctionHouseImport = "AuctionHouse_Mainnet";
const serverURL = "https://daamserver.herokuapp.com";
// const serverURL = "http://localhost:5002";

const fclConfig = {
  "flow.network": "testnet",
  "accessNode.api": "https://rest-testnet.onflow.org", // Mainnet: "https://rest-testnet.onflow.org"
  "discovery.wallet": "https://fcl-discovery.onflow.org/testnet/authn", // Mainnet: "https://fcl-discovery.onflow.org/testnet/authn"
  "discovery.authn.include": ["0x9d2e44203cb13051"], // Service account address
  "app.detail.title": "DAAM Agency",
  "app.detail.icon": `${clientURL}/static/media/worded_logo.79555dfd.svg`,
};

module.exports = {
  serverURL,
  clientURL,
  fclLimit,
  fungibleToken,
  nonFungibleToken,
  multipleFungibleToken,
  profile,
  flowToken,
  fusd,
  auctionHouse,
  daam,
  daamImport,
  auctionHouseImport,
  metadataViews,
  fclConfig,
  flowScanLink,
  categories
};
