import { Button, Row, Typography } from "antd";
import styled from "styled-components";

export const Title = styled(Typography.Text)`
  font: normal normal bold 24px/36px Oswald;
  letterspacing: 1.15px;
  color: #1a1d1f;
  opacity: 1;
`;

export const NFTArt = styled(Row)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0d3;
  borderradius: 10px;
  opacity: 1;
  padding: 5px;
`;

export const NFTDetails = styled(Typography.Text)`
  font: normal normal bold 16px/19px Lato;
  letterspacing: 0.26px;
  color: #1a1d1f;
  opacity: 1;
`;

export const NFTSubDetails = styled(Typography.Text)`
  font: normal normal bold 12px/15px Lato;
  letterspacing: 0.29px;
  color: #595e62;
  opacity: 1;
`;
export const NFTPriceDetails = styled(Typography.Text)`
  font: normal normal 500 14px/17px Lato;
  letterspacing: 0.2px;
  color: #1a1d1f;
`;

export const NFTPriceDetailsTotal = styled(Typography.Text)`
  font: normal normal bold 14px/17px Lato;
  letterspacing: 0.2px;
  color: #1a1d1f;
`;

export const OkButton = styled(Button)`
  height: 35px;
  background: #2f3234 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 5px;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0.2px;
  color: #ffffff;
  &:hover {
    background: #fc4448 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
    color: #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  height: 35px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #1a1d1f;
  border-radius: 5px;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0.2px;
  color: #1a1d1f;
  &:hover {
    background: #fc4448 0% 0% no-repeat padding-box;
    border: 1px solid #fc4448;
    color: #ffffff;
  }
`;
