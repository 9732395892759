import * as types from "../constTypes";

const initialState = {};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.USER_LOGIN:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.AUTH:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
export const redirect = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.REDIRECT:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const userList = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.ALL_USERS:
      return {
        ...state,
        users: [...action.payload],
        creator: [...action.payload.filter((x) => x.userType === "creator")],
        agent: [...action.payload.filter((x) => x.userType === "agent")]
      };
    default:
      return state;
  }
};

export const collections = (state = initialState, action) => {
  switch (action.type) {
    case types.userTypes.COLLECTIONS:
      return [...action.payload];
    default:
      return state;
  }
};
