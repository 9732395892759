import React from "react";
import { Row, Avatar, Col, Typography } from "antd";
import Card from "../../../components/Card";
import avatarImg from "../../../images/profile_placeholder.png";

const { Title } = Typography;

const AgentDashboardContainer = () => {
  const requests = [
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Agent name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Agent name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Agent name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Agent name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
  ];

  const soldPieces = [
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
  ];

  const closingPieces = [
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
    {
      price: "$12,831.05",
      flowPrice: "12,0000 FUSD",
      author: "Creator name",
      avatar: <Avatar src={avatarImg} alt="avatar" />,
    },
  ];

  const showRequests = (requests) => {
    return (
      <>
        <Title level={2}>{" Creator requests"}</Title>
        <Row>
          {requests.map(({ author, title, price, flowPrice, avatar }) => {
            return (
              <Col span={6} key={Math.random()}>
                <Card
                  author={author}
                  title={title}
                  avatar={avatar}
                  price={price}
                  flowPrice={flowPrice}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  const showSoldPieces = (soldPieces) => {
    return (
      <>
        <Title level={2}>{" Sold"}</Title>
        <Row>
          {soldPieces.map(({ author, title, price, flowPrice, avatar }) => {
            return (
              <Col span={6} key={Math.random()}>
                <Card
                  author={author}
                  title={title}
                  avatar={avatar}
                  price={price}
                  flowPrice={flowPrice}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };
  const showClosing = (closingPieces) => {
    return (
      <>
        <Title level={2}>{" Closing"}</Title>
        <Row>
          {soldPieces.map(({ author, title, price, flowPrice, avatar }) => {
            return (
              <Col span={6} key={Math.random()}>
                <Card
                  author={author}
                  title={title}
                  avatar={avatar}
                  price={price}
                  flowPrice={flowPrice}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  return (
    <>
      {showRequests(requests)}
      <br />
      {showClosing(closingPieces)}
      <br />
      {showSoldPieces(soldPieces)}
    </>
  );
};

export default AgentDashboardContainer;
