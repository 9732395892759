import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AdminMenuStyles } from "./styles";
import UsersIcon from "../../images/icons/users.svg";
import BoardIcon from "../../images/icons/board.svg";
import FlowScanIcon from "../../images/icons/flowscan_icon.png";
import Image from "../Image";
import { AuthContext } from "../../context/authContext";
import { Nuke } from "../../scripts/nuke";
import * as Routes from "../../Routes";

export default function AdminMenu() {
  const {isAgent } = useContext(AuthContext);
  const removr = async () => {
    await Nuke();
  };
  if (!isAgent) return <></>;
  return (
    <AdminMenuStyles>
      {/* <Image src={CircularLogo} w="40px" /> */}
      <Link to={Routes.MANAGE_CREATOR_AGENT}>
        <Image src={UsersIcon} w="45px" />
      </Link>

      <Image src={BoardIcon} w="45px" />
      <Image src={FlowScanIcon} w="45px" onClick={removr} />
    </AdminMenuStyles>
  );
}
