import styled from "styled-components";
import StyledButton from "antd/lib/button";

// import StyledButton from "../../components/Button";

export const Button = styled(StyledButton)`
  background: #2bce88 0% 0% no-repeat padding-box;
  border-radius: none;
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #2bce88;
  opacity: 1;
  height: 40px;
  font: normal normal bold 20px/24px Lato;
  letter-spacing: 0.4px;
  color: #ffffff;
  opacity: 1;
  &:hover {
    background: #2bce88 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border: 1px solid #1a1d1f;
    opacity: 1;
  }
`;
