/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Icon, {
  CopyOutlined,
  ExpandOutlined,
  HomeOutlined,
  InstagramOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  TwitterOutlined,
  FlagOutlined
} from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import {
  Row,
  Avatar,
  Col,
  Typography,
  Button,
  Spin,
  Popover,
  Modal,
  Space
} from "antd";
import {
  ArtDivider,
  SidesWrapper,
  ArtWrapper,
  TransactionData,
  TransactionHeader,
  //   bipul added
  NameTitle,
  ButtonDiv,
  ButtonText,
  ShareNftToggleWrapper,
  ShareNftToggleContent,
  ShareNftToggleList,
  ShareNFTIcon,
  ImageContainer,
  Images,
  ButtonWrapper,
  ButtonList,
  ButtonItem,
  PriceTitle,
  NameContainer,
  CreatorNameText,
  OwnedContainer,
  PieceNo,
  OwenedBy,
  DescriptionText,
  ButtonDivContainer,
  ButtonContainer,
  CommonTitle,
  DescriptionContainer,
  MetaDataHeader,
  MetaDataHeaderText,
  BlockchainInfoItemTitle,
  BlockchainInfoItemData,
  TransactionDataName,
  ArtistRoyalistItemTitle,
  ArtistRoyalistItemContent,
  ArtistItemData,
  BuyModal,
  UpdateConfirmation,
  CopyToClipboard,
  ShareTitle,
  AuctionEnd
} from "./Art.styled";
import Card from "../../components/Card";
import Image from "../../components/Image";
import image1 from "../../images/collection_images/item_1.png";
import image2 from "../../images/collection_images/item_2.png";
import image4 from "../../images/collection_images/item_4.png";
import imageDefault from "../../images/collection_images/nameOfPiece.png";
import avatarImg from "../../images/profile_placeholder.png";
import { useParams } from "react-router";
import fetchAuctionDetails from "../../scripts/getAuctionDetails";
import { getRoyalties } from "../../scripts/getRoyalities";
import { useAuction } from "../../context/auctions.context";
import { safeParse } from "../../scripts/util/safeParse";
import { buyNow } from "../../scripts/buyNow";
import { bidNow } from "../../scripts/bidNow";
import { buyNowAmount } from "../../scripts/buyNowAmount";
import { getFees } from "../../scripts/getFees";
import { getHistory } from "../../scripts/getHistory";
import { getAuctionLog } from "../../scripts/get_auction_log";

import { Footer } from "../../components/Footer";
import { Collapse } from "antd";
import * as toast from "../../common/toast";

import BidandBuyPop from "../BidandBuyPop";
import { VideoSrc } from "../../common/commonStyles";

import * as actions from "../../redux/actions";
import * as config from "../../config/keys";
import { OpenSeaDragonViewer, PDFViewer } from "../../components";
import { ReactComponent as DiscordSVG } from "../../images/discord.svg";
import { getSingleMetadata } from "../../scripts";
import { getMetadata } from "../../scripts/getList";

// import { toast } from "react-toastify";

const { Title, Paragraph, Text, Link } = Typography;

const ArtNew = (props) => {
  // ******************* Preparing data structure to show **************
  const [ShareToggle, setShareToggle] = useState(false);
  const imagesParts = [image1, image2, image4, image4];
  const [creatordetails, setcreatorDetails] = useState({});

  const generalInfo = {
    // imageSrc => to  convert into the 64bit format
    imageSrc: imageDefault,
    nameOfPiece: "Name of piece",
    priceUSD: "$12,831.05",
    priceFUSD: "12,0000 FUSD"
  };
  const creatorInfo = {
    avatar: avatarImg,
    name: "Name of creator"
  };
  const blockChainInfo = {
    contractAdress: "0xd1e532f23r3e324jesdkjcdklj…",
    tokenId: "4169992834958888883238",
    blockchain: "Flow"
  };
  const { id, addr } = useParams();
  ///art/${creatorInfo?.creator}/${auctionID}
  const hyperLink = `${window.location.origin}/art/${addr}/${id}`; // window.location.href;
  const { auctions, getAuctions, getAuctionsforPublicURL } = useAuction();
  const [auctionData, setAuctionData] = useState(auctions || []);
  const [data, setData] = useState(null);
  const [metadata, setMetaData] = useState(null);
  const [isbidModalVisible, setIsbidModalVisible] = useState(false);
  const [isbuyModalVisible, setIsbuyModalVisible] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState(false);
  const [saleHistory, setSaleHistory] = useState([]);
  const [totalPrice, settotalPrice] = useState(0);
  const [buynowAmount, setBuyNowAmount] = useState(0);
  const [fees, setFees] = useState(0.0);
  const [disableBidBtn, setdisabledBidBtn] = useState(false);
  const [maxBid, setMaxBid] = useState(0.0);
  const [auctionEndDate, setAuctionEndDate] = useState("");
  const [royalities, setRoyalities] = useState(0.0);
  const isBuyOnlyScreen = true;

  const { auth, userList } = useSelector((obj) => obj);

  const showModal = () => {
    setIsbidModalVisible(true);
  };

  const handleOk = () => {
    setIsbidModalVisible(false);
  };

  const handleCancel = () => {
    setIsbidModalVisible(false);
  };

  const showbuyModal = () => {
    buyNowAmount({
      auctionAddress: addr,
      auction: parseInt(data?.auctionInfo?.auctionID),
      bidderAddress: auth.address
    })
      .then((res) => {
        getFees({ mid: parseInt(data?.metaDataInfo?.mid) }).then((res) =>
          setFees(parseFloat(res))
        );
        setBuyNowAmount(parseFloat(res));
        setIsbuyModalVisible(true);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const handlebuyOk = () => {
    setIsbuyModalVisible(false);
  };

  const handlebuyCancel = () => {
    setIsbuyModalVisible(false);
  };

  useEffect(() => {
    if (auctions === null || (auctions && auctions.length <= 0)) {
      if (auth.addr) {
        getAuctions();
      } else {
        getAuctionsforPublicURL();
      }
    }
  }, []);

  const onSuccess = (data) => {

    setShowSuccessErrorModal(true);
  };

  const onBidSuccess = (data) => {
    
    toast.success("Congrats, Bid has been successfully submitted!!");
  };
  const onError = (data) => {
    
    toast.error(data);
  };

  const onComplete = (data) => {

    setTimeout(() => {
      setShowUpdateModal(false);
    }, 1000);
  };

  const onStart = (data) => {
    // toast.info("Started")
  };
  const onSubmission = (data) => {

    setShowUpdateModal(true);
  };

  useEffect(() => {
    if (auctions && auctions.length > 0) {
      const tmp = [...auctionData, ...auctions];
      let uniqueObjArray = [
        ...new Map(
          tmp.map((item) => [item.auctionInfo["auctionID"], item])
        ).values()
      ];
      setAuctionData([...auctions]);
    }
  }, [auctions]);

  useEffect(() => {
    if (auctions && id && addr) {
      let auction = auctions.find(
        (item) =>
          item.auctionInfo?.auctionID === id &&
          item.auctionInfo?.creatorInfo?.creator === addr
      );

      if (auction) {
        if (!(Object.keys(auction?.auctionInfo?.auctionLog).length === 0)) {
          var ary = Object.values(auction?.auctionInfo?.auctionLog);

          ary = ary.map(Number);
          
          setMaxBid(Math.max(...ary));
        } else {
          setMaxBid(parseFloat(auction?.auctionInfo?.startingBid));
        }

        setAuctionEndDate(
          new Date(
            (auction?.auctionInfo?.length - 200) * 1000
          ).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          })
        );
        setData(auction);

        //if its first sale then royality is zero
        if (parseFloat(auction.metaDataInfo.creatorInfo.firstSale) === 0.0) {
          setRoyalities(0.0);
        } else {
          getRoyalties(auction.metaDataInfo?.mid).then((re) => {
            let sum = 0.0;
            re.cutInfos.forEach((element) => {
              sum = sum + parseFloat(element.cut);
            });

            setRoyalities(sum.toFixed(2));
          });
        }
        getHistory(parseInt(auction.metaDataInfo?.mid)).then((re) => {
          if (re !== null) {
            const t = Object.keys(re);
            const innerVal = re[t];
            const saleHistory = Object.keys(innerVal).filter((x) => x !== "0");
            const sh = [];
            saleHistory.map((x) =>
              setSaleHistory([...saleHistory, { ...innerVal[x] }])
            );
          }
        });

        const filterAdd = userList?.users?.find(
          (x) => x.address === auction?.auctionInfo?.creatorInfo?.creator
        );
        setcreatorDetails(filterAdd);
        settotalPrice(auction?.auctionInfo?.minBid);
        setMetaData(auction?.metaDataInfo);
        setBuyNowAmount(data?.auctionInfo?.buyNow);
      } else {
        getSingleMetadata(addr, id).then((res) => {
          setData({ metaDataInfo: res });
          setMetaData(res);
          setRoyalities(0.0);
          const filterAdd = userList?.users?.find(
            (x) => x.address === res?.creatorInfo?.creator
          );

          // res.forEach((element) => {
          //   data.push({ auctionInfo: {}, metaDataInfo: element });
          // });
        });
      }
    }
  }, [auctions, auctionData, id, addr]);
  let tempData = data?.metaDataInfo;

  const CheckFundBeforeBuy = (e) => {
    Number(auth?.hasFUSDWallet) >= (data?.auctionInfo?.price / 12).toFixed(2)
      ? showbuyModal()
      : toast.error("You don't have enough FUSD please fund your wallet!");
  };

  const newImage = () => {
    return (
      // <ImageContainer>
      //   <OpenSeaDragonViewer
      //     image={tempData?.thumbnail[Object.keys(tempData?.thumbnail)[0]].file}
      //     filetype={Object.keys(tempData?.thumbnail)[0]}
      //   />
      // </ImageContainer>
      <ImageContainer>
        {
          ["Audio", "Video"].includes(data?.metaDataInfo?.category[1].name) ? (
            <VideoSrc controls>
              <source
                src={
                  tempData?.thumbnail[Object.keys(tempData?.thumbnail)[0]].file
                }
                type={Object.keys(tempData?.thumbnail)[0]}
              />
            </VideoSrc>
          ) : Object.keys(data?.metaDataInfo?.thumbnail)[0] === "pdf" ? (
            <PDFViewer
              mintResult={{
                file: tempData?.thumbnail[Object.keys(tempData?.thumbnail)[0]]
                  .file
              }}
              height={480}
            />
          ) : (
            <OpenSeaDragonViewer
              image={
                tempData?.thumbnail[Object.keys(tempData?.thumbnail)[0]].file
              }
              filetype={Object.keys(tempData?.thumbnail)[0]}
            />
          )
          // <TransformWrapper
          //   initialScale={1}
          //   initialPositionX={0}
          //   initialPositionY={0}
          // >
          //   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          //     <React.Fragment>
          //       <TransformComponent wrapperStyle={{ margin: "auto" }}>
          //         <Images
          //           src={
          //             data
          //               ? tempData?.thumbnail[
          //                   Object.keys(tempData?.thumbnail)[0]
          //                 ].file
          //               : generalInfo.imageSrc
          //           }
          //           alt="test"
          //         />
          //       </TransformComponent>

          //       <ButtonWrapper>
          //         <ButtonList>
          //           <ButtonItem onClick={() => zoomIn()}>
          //             <PlusOutlined />
          //           </ButtonItem>
          //           <ButtonItem onClick={() => zoomOut()}>
          //             <MinusOutlined />
          //           </ButtonItem>
          //           <ButtonItem>
          //             <HomeOutlined />
          //           </ButtonItem>
          //           <ButtonItem>
          //             <ExpandOutlined />
          //           </ButtonItem>
          //         </ButtonList>
          //       </ButtonWrapper>
          //     </React.Fragment>
          //   )}
          // </TransformWrapper>
        }
      </ImageContainer>
    );
  };

  const BlockChainInfo = () => {
    return (
      <>
        <CommonTitle>{"Blockchain Info"}</CommonTitle>
        <hr style={{ margin: "0.5rem 0px" }} />

        <Row style={{ marginTop: "10px" }}>
          <Col span={8}>
            <BlockchainInfoItemTitle>
              {"Contract Address:"}
            </BlockchainInfoItemTitle>
          </Col>
          <Col span={16}>
            <BlockchainInfoItemData>
              {blockChainInfo.contractAdress}
            </BlockchainInfoItemData>
          </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <Col span={8}>
            <BlockchainInfoItemTitle>{"Token ID:"}</BlockchainInfoItemTitle>
          </Col>
          <Col span={12}>
            <BlockchainInfoItemData>
              {data?.auctionInfo?.auctionID}
            </BlockchainInfoItemData>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={8}>
            <BlockchainInfoItemTitle>{"Blockchain:"}</BlockchainInfoItemTitle>
          </Col>
          <Col span={12}>
            <BlockchainInfoItemData>
              {blockChainInfo.blockchain}
            </BlockchainInfoItemData>
          </Col>
        </Row>
      </>
    );
  };

  const transactionHistory = () => {
    return (
      <>
        <CommonTitle>{"Sale history"}</CommonTitle>
        <hr style={{ margin: "0.5rem 0px" }} />

        <Row>
          <Col span={6}>
            <TransactionHeader>From</TransactionHeader>
          </Col>

          <Col span={6}>
            <TransactionHeader>To</TransactionHeader>
          </Col>
          <Col span={6}>
            <TransactionHeader>Date</TransactionHeader>
          </Col>
          <Col span={6}>
            <TransactionHeader>Price</TransactionHeader>
          </Col>
        </Row>

        {saleHistory.map((transaction) =>
          transaction?.saleHistory?.map((hist) => {
            const fromCreator = userList?.users?.find(
              (x) => x.address === hist?.from
            );
            const toCreator = userList?.users?.find(
              (x) => x.address === hist?.to
            );

            return (
              <Row>
                <Col span={6}>
                  <TransactionData>
                    <Avatar
                      src={`${config.serverURL}/files/images/${fromCreator.avtarImg}`}
                      h={40}
                      w={40}
                    />
                    <TransactionDataName>
                      {fromCreator.name}
                    </TransactionDataName>
                  </TransactionData>
                </Col>
                <Col span={6}>
                  <TransactionData>
                    <Avatar
                      src={`${config.serverURL}/files/images/${toCreator.avtarImg}`}
                      h={40}
                      w={40}
                    />
                    <TransactionDataName>{toCreator.name}</TransactionDataName>
                  </TransactionData>
                </Col>
                <Col span={6}>
                  <TransactionDataName>
                    {new Date(
                      (hist?.timestamp - 200) * 1000
                    ).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric"
                    })}
                  </TransactionDataName>
                </Col>
                <Col span={6}>
                  <Text
                    type="danger"
                    style={{ font: "normal normal 700 14px/17px Lato" }}
                  >
                    {hist.price}
                  </Text>
                  <br />
                  <Text>
                    <small>{transaction.priceF}</small>
                  </Text>
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  const TechnicalMetaData = () => {
    var creationdate = new Date(
      Number((data?.auctionInfo?.start - 200) * 1000)
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    });

    const base64String =
      data?.metaDataInfo.thumbnail[Object.keys(tempData?.thumbnail)[0]].file ||
      "hello,world";


    const stringLength = base64String.split(",")[1].length;

    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    const sizeInKb = bytesToSize(sizeInBytes);

    return (
      <>
        <CommonTitle>{"Technical metadata"}</CommonTitle>
        <hr style={{ margin: "0.5rem 0px" }} />
        <Row>
          {/* gutter={[4, 8]} */}
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader>{"Date Created:"}</MetaDataHeader>
          </Col>
          {/* // in next version we will integrate created and minted seprate dates */}
          <Col
            span={6}
            sm={12}
            xs={12}
            style={{ textAlign: "left" }}
            xl={6}
            xxl={6}
            md={6}
          >
            <MetaDataHeaderText>
              {(data?.auctionInfo && creationdate) || "-"}
            </MetaDataHeaderText>
            {/* {data && metadata.creationDate} */}
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Type:"}</MetaDataHeader>
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            {data && data?.metaDataInfo?.category[0].name === "Digital" ? (
              <MetaDataHeaderText>Digital</MetaDataHeaderText>
            ) : (
              <MetaDataHeaderText>Physical</MetaDataHeaderText>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader>{"Date Minted:"}</MetaDataHeader>
          </Col>

          <Col
            span={6}
            sm={12}
            xs={12}
            style={{ textAlign: "left" }}
            xl={6}
            xxl={6}
            md={6}
          >
            <MetaDataHeaderText>
              {(data?.auctionInfo && creationdate) || "-"}
            </MetaDataHeaderText>
          </Col>

          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Extension:"}</MetaDataHeader>
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            {/* {data && metadata.fileType} */}
            <MetaDataHeaderText>
              {data && Object.keys(data?.metaDataInfo.thumbnail)[0]}
            </MetaDataHeaderText>
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Collection:"}</MetaDataHeader>
          </Col>
          <Col span={18} sm={12} xs={12} xl={6} xxl={6} md={6}>
            {/* {technicalMetaData.collection} */}
            <MetaDataHeaderText>
              {metadata?.inCollection === null
                ? "-"
                : metadata?.inCollection || "-"}
            </MetaDataHeaderText>
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Size:"}</MetaDataHeader>
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
        
            <MetaDataHeaderText>
        
              {sizeInKb}
            </MetaDataHeaderText>
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Token ID:"}</MetaDataHeader>
          </Col>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            {/* {data?.auctionID} */}
            <MetaDataHeaderText>{data?.auctionID || "-"}</MetaDataHeaderText>
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={12} xs={12} xl={6} xxl={6} md={6}>
            <MetaDataHeader> {"Label:"}</MetaDataHeader>
          </Col>
          <Col span={18} sm={12} xs={12} xl={6} xxl={6} md={6}>
            {/* {data && metadata.fileName} */}
            <MetaDataHeaderText>
              {data && metadata?.edition.name}
            </MetaDataHeaderText>
          </Col>
        </Row>
      </>
    );
  };

  const DiscordIcon = (props) => <Icon component={DiscordSVG} {...props} />;

  const ShareNftToggle = () => {
    const sharing = [
      {
        name: "Hyperlink",
        links: hyperLink,
        copyable: true,
        ml: true
      },
      { name: "Twitter", icon: <TwitterOutlined /> },
      { name: "Discord", icon: <DiscordIcon /> },
      { name: "Instagram", icon: <InstagramOutlined /> }
    ];
    return (
      <ShareNftToggleWrapper show={ShareToggle}>
        <ShareNftToggleContent>
          {sharing.map((d) => {
            return (
              <ShareNftToggleList key={Math.random()}>
                <ShareNFTIcon>{d.icon}</ShareNFTIcon>
                {d.copyable && (
                  <CopyToClipboard
                    copyable={{
                      text: d.links,
                      icon: <CopyOutlined style={{ color: "black" }} />
                    }}
                  ></CopyToClipboard>
                )}
                <ShareTitle ml={d.ml}>{d.name}</ShareTitle>
              </ShareNftToggleList>
            );
          })}
        </ShareNftToggleContent>
      </ShareNftToggleWrapper>
    );
  };

  return (
    <Spin
      spinning={data === null}
      tip={"Getting MetaData Details...."}
      
    >
      {auctionData && data !== null && (
        <ArtWrapper>
          {newImage()}
          <Row justify="space-between" align="middle">
            <Col span={20} xxl={18} xl={18} md={16} sm={14} xs={24}>
              <NameContainer>
                <NameTitle>{data && tempData.edition.name}</NameTitle>
              </NameContainer>
            </Col>
            <Col span={4} xxl={6} xl={6} md={8} sm={10} xs={24}>
              <ButtonContainer>
                <ButtonDivContainer>
                  {((data?.auctionInfo &&
                    data?.auctionInfo?.length === "999999.00000000") ||
                    (isBuyOnlyScreen &&
                      data?.auctionInfo?.buyNow !== null)) && (
                    <ButtonDiv
                      bgColor="#2f3234"
                      onHover="#FF4040"
                      onHoverBorder={true}
                      onClick={() => CheckFundBeforeBuy()}
                    >
                      <ButtonText>Buy</ButtonText>
                    </ButtonDiv>
                  )}

                  <BuyModal
                    visible={isbuyModalVisible}
                    closable={false}
                    footer={null}
                  >
                    <BidandBuyPop
                      action="buy"
                      // croyality={metadata?.royaltyPer}
                      croyality={royalities}
                      title={metadata?.edition?.name}
                      originalPrice={parseFloat(data?.auctionInfo?.price)}
                      fees={parseFloat(data?.auctionInfo?.fee)}
                      minbid={data?.auctionInfo?.minBid}
                      buyNowPrice={data?.auctionInfo?.buyNow}
                      creator={Object.values(
                        data?.metaDataInfo?.creatorInfo
                      )?.find((e) => e !== null)}
                      image={
                        data
                          ? tempData?.thumbnail[
                              Object.keys(tempData?.thumbnail)[0]
                            ].file
                          : generalInfo.imageSrc
                      }
                      settotalPrice={settotalPrice}
                      onCancelClick={handlebuyCancel}
                      onOkClick={() => {
                        buyNow(
                          {
                            auctionAddress: addr,
                            auctionID: Number(data.auctionInfo?.auctionID),
                            bid: data.auctionInfo.buyNow
                          },
                          {
                            onStart,
                            onSubmission,
                            onSuccess,
                            onError,
                            onComplete
                          }
                        );
                        handlebuyOk();
                      }}
                    />
                  </BuyModal>
                  {data?.auctionInfo &&
                    data?.auctionInfo?.length !== "999999.00000000" && ( //&& !isBuyOnlyScreen && (
                      <>
                        <ButtonDiv
                          onClick={showModal}
                          // style={{ marginRight: "10px" }}
                          bgColor="#2f3234"
                          onHover="#FF4040"
                          onHoverBorder
                        >
                          <ButtonText>Bid</ButtonText>
                        </ButtonDiv>
                        <Modal
                          title="Confirmation"
                          visible={isbidModalVisible}
                          okText="Bid"
                          cancelText="Cancel"
                          okButtonProps={{ disabled: disableBidBtn }}
                          onOk={() => {
                            bidNow(
                              {
                                auctionAddress: addr,
                                auctionID: Number(data.auctionInfo.auctionID),
                                bid: parseFloat(totalPrice).toFixed(8)
                              },
                              {
                                onSubmission,
                                onSuccess: onBidSuccess,
                                onError,
                                onComplete
                              }
                            );
                            handleOk();
                          }}
                          onCancel={handleCancel}
                        >
                          <BidandBuyPop
                            action="bid"
                            croyality={metadata?.royaltyPer}
                            minbid={data?.auctionInfo?.minBid}
                            // imagethumbnail={metadata?.thumbnail}
                            image={
                              data
                                ? tempData?.thumbnail[
                                    Object.keys(tempData?.thumbnail)[0]
                                  ].file
                                : generalInfo.imageSrc
                            }
                            settotalPrice={(value) => settotalPrice(value)}
                            price={totalPrice}
                            setdisabledBidBtn={setdisabledBidBtn}
                            title={metadata?.edition?.name}
                            creator={Object.values(
                              data?.metaDataInfo?.creatorInfo
                            )?.find((e) => e !== null)}
                          />
                        </Modal>
                      </>
                    )}

                  <ButtonDiv
                    onHoverBorder
                    onClick={() => setShareToggle(!ShareToggle)}
                    toggle={ShareToggle}
                  >
                    <ButtonText color="#1a1d1f" onHoverColor="#FF4040">
                      Share
                    </ButtonText>
                  </ButtonDiv>

                  <ShareNftToggle />
                </ButtonDivContainer>
              </ButtonContainer>
            </Col>
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: "10px 0px" }}
          >
            <Col span={20} xxl={18} xl={18} md={16} sm={14} xs={24}>
              <PieceNo>{`${tempData?.edition?.number} of ${tempData?.edition?.max}`}</PieceNo>
            </Col>
            <Col span={4} xxl={6} xl={6} md={8} sm={10} xs={24}>
              <OwnedContainer>
                <OwenedBy>Owned by</OwenedBy>
                <CreatorNameText>{`User Name `}</CreatorNameText>
                <Avatar />
              </OwnedContainer>
            </Col>
          </Row>
          {data?.auctionInfo && (
            <Row justify="space-between" align="middle">
              <Col span={24} xxl={24} xl={24} md={24} sm={24} xs={24}>
                <PriceTitle
                  style={{
                    color: "black",
                    font: "normal normal 500 20px Oswald",
                    paddingRight: "8px"
                  }}
                >
                  {/* data?.auctionInfo?.startingBid !== null */}
                  {data?.auctionInfo?.length !== "999999.00000000"
                    ? `Current Bid  `
                    : `Buy Price`}
                  {data?.auctionInfo?.length !== "999999.00000000" && (
                    <>
                      <FlagOutlined
                        style={{ color: "#FC4448", margin: "0px 8px" }}
                      />
                      <AuctionEnd>{`Auction ends ${auctionEndDate}`}</AuctionEnd>
                    </>
                  )}
                </PriceTitle>
              </Col>
              <Col
                span={24}
                xxl={24}
                xl={24}
                md={24}
                sm={24}
                xs={24}
                style={{ marginTop: 10 }}
              >
                <PriceTitle>
                  {data?.auctionInfo?.length !== "999999.00000000"
                    ? `${maxBid.toFixed(4)} USD`
                    : `${parseFloat(data?.auctionInfo?.buyNow).toFixed(2)} USD`}
                </PriceTitle>
                <PriceTitle color="#999B9E">
                  {data?.auctionInfo?.length !== "999999.00000000"
                    ? `${(maxBid / 12).toFixed(4)} Flow`
                    : `${(parseFloat(data?.auctionInfo?.buyNow) / 12).toFixed(
                        2
                      )} FLOW`}
                </PriceTitle>
              </Col>
            </Row>
          )}
          {/*  <Row>
            <Col span={11} style={{ marginRight: "40px" }}>
              <NameContainer>
                <NameTitle>{data && tempData.edition.name}</NameTitle>
                <PieceNo>{`${tempData?.edition?.number} of ${tempData?.edition?.max}`}</PieceNo>
                <br />
                <Row justify="start">
                  <Col span={5}>
                    <PriceTitle
                      style={{
                        color: "black",
                        font: "normal normal 500 20px/49px Oswald"
                      }}
                    >
                      Buy Price
                    </PriceTitle>
                    <br />
                    <PriceTitle>
                      {parseFloat(data?.auctionInfo.buyNow).toFixed(2)}
                      {` USD`}
                    </PriceTitle>
                    <br />
                    <PriceTitle color="#999B9E" mt="10px">
                      {parseFloat(data?.auctionInfo.buyNow / 12).toFixed(2)}
                      {` Flow`}
                    </PriceTitle>
                  </Col>
                  {data?.auctionInfo.startingBid !== null && (
                    <Col span={5}>
                      <PriceTitle
                        style={{
                          color: "black",
                          font: "normal normal 500 20px/49px Oswald"
                        }}
                      >
                        Current Bid
                      </PriceTitle>
                      <br />
                      <PriceTitle>{`${maxBid.toFixed(2)} USD`}</PriceTitle>
                      <br />
                      <PriceTitle color="#999B9E" mt="10px">
                        {`${(maxBid / 12).toFixed(2)} Flow`}
                      </PriceTitle>
                    </Col>
                  )}
                  {data?.auctionInfo.startingBid !== null && (
                    <Col span={12} style={{ marginTop: "10px" }}>
                      <FlagOutlined style={{ color: "#FC4448" }} /> Auction ends{" "}
                      {auctionEndDate}
                    </Col>
                  )}
                </Row>
               
              </NameContainer>
            </Col>
            <Col span={12}>
              <ButtonContainer>
              
                <ButtonDivContainer>
                  <ButtonDiv
                    style={{ marginRight: "10px" }}
                    bgColor="#2f3234"
                    onHover="#FF4040"
                    onHoverBorder={true}
                    onClick={() => CheckFundBeforeBuy()}
                  
                  >
                    <ButtonText>Buy Now</ButtonText>
                  </ButtonDiv>
              
                  <BuyModal
                    visible={isbuyModalVisible}
                    closable={false}
                    footer={null}
                  
                  >
                    <BidandBuyPop
                      action="buy"
                      // croyality={metadata?.royaltyPer}
                      croyality={royalities}
                      title={metadata?.edition?.name}
                      originalPrice={parseFloat(data?.auctionInfo?.price)}
                      fees={parseFloat(data?.auctionInfo?.fee)}
                      minbid={data?.auctionInfo?.minBid}
                      buyNowPrice={data?.auctionInfo?.buyNow}
                      creator={Object.values(metadata.creatorInfo)?.find(
                        (e) => e !== null
                      )}
                      image={
                        data
                          ? tempData?.thumbnail[
                              Object.keys(tempData?.thumbnail)[0]
                            ].file
                          : generalInfo.imageSrc
                      }
                      settotalPrice={settotalPrice}
                      onCancelClick={handlebuyCancel}
                      onOkClick={() => {
                        buyNow(
                          {
                            auctionAddress: addr,
                            auctionID: Number(data.auctionInfo?.auctionID),
                            bid: data.auctionInfo.buyNow
                          },
                          {
                            onStart,
                            onSubmission,
                            onSuccess,
                            onError,
                            onComplete
                          }
                        );
                        handlebuyOk();
                      }}
                    />
                  </BuyModal>
                  {data?.auctionInfo.startingBid !== null && (
                    <>
                      <ButtonDiv
                        onClick={showModal}
                        style={{ marginRight: "10px" }}
                        bgColor="#2f3234"
                        onHover="#FF4040"
                        onHoverBorder
                      >
                        <ButtonText>Bid Now</ButtonText>
                      </ButtonDiv>
                      <Modal
                        title="Confirmation"
                        visible={isbidModalVisible}
                        okText="Bid"
                        cancelText="Cancel"
                        okButtonProps={{ disabled: disableBidBtn }}
                        onOk={() => {
                          bidNow(
                            {
                              auctionAddress: addr,
                              auctionID: Number(data.auctionInfo.auctionID),
                              bid: parseFloat(totalPrice).toFixed(8)
                            },
                            { onSuccess, onError, onComplete }
                          );
                          handleOk();
                        }}
                        onCancel={handleCancel}
                      >
                        <BidandBuyPop
                          action="bid"
                          croyality={metadata?.royaltyPer}
                          minbid={data?.auctionInfo?.minBid}
                          // imagethumbnail={metadata?.thumbnail}
                          image={
                            data
                              ? tempData?.thumbnail[
                                  Object.keys(tempData?.thumbnail)[0]
                                ].file
                              : generalInfo.imageSrc
                          }
                          settotalPrice={(value) => settotalPrice(value)}
                          price={totalPrice}
                          setdisabledBidBtn={setdisabledBidBtn}
                          title={metadata?.edition?.name}
                          creator={Object.values(metadata?.creatorInfo)?.find(
                            (e) => e !== null
                          )}
                        />
                      </Modal>
                    </>
                  )}

                  <ButtonDiv
                    onHoverBorder
                    onClick={() => setShareToggle(!ShareToggle)}
                    toggle={ShareToggle}
                  >
                    <ButtonText color="#1a1d1f" onHoverColor="#FF4040">
                      Share NFT
                    </ButtonText>
                  </ButtonDiv>

                  <ShareNftToggle />

                </ButtonDivContainer>
              </ButtonContainer>
            </Col>
          </Row>
*/}
          <Row gutter={[32, 32]} style={{ marginTop: 10 }}>
            <Col span={12} sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <DescriptionContainer>
                <CommonTitle color="#2F3234">{"Description"}</CommonTitle>
                <hr style={{ margin: "0.5rem 0px" }} />
                <DescriptionText>
                  {data &&
                    metadata?.description.replaceAll("(\r\n|\n)", "<br>")}
                </DescriptionText>
              </DescriptionContainer>
            </Col>
            {data?.auctionInfo?.length === "999999.00000000" && (
              <Col span={12} sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                <CommonTitle>{"Artist Royality"}</CommonTitle>
                <hr style={{ margin: "0.5rem 0px" }} />
                <Row gutter={[24, 24]}>
                  <Col span={10}>
                    <ArtistRoyalistItemTitle>Name</ArtistRoyalistItemTitle>
                  </Col>
                  <Col span={6}>
                    <ArtistRoyalistItemTitle>Total</ArtistRoyalistItemTitle>
                  </Col>
                  <Col span={8}>
                    <ArtistRoyalistItemTitle>Amount</ArtistRoyalistItemTitle>
                  </Col>
                </Row>
                <Row gutter={[24, 48]}>
                  <Col span={10}>
                    <ArtistRoyalistItemContent>
                      <Avatar
                        src={
                          <Image
                            src={`${config.serverURL}/files/images/${creatordetails?.avtarImg}`}
                            h={40}
                            w={40}
                          />
                        }
                        size={26}
                        style={{ marginRight: "3px" }}
                      />
                      {/* {data.metadata.creator} */}
                      <ArtistItemData>{creatordetails?.name}</ArtistItemData>
                    </ArtistRoyalistItemContent>
                  </Col>
                  <Col span={6}>
                    <ArtistRoyalistItemContent>
                      {/* {metadata.royaltyPer} */}
                      <ArtistItemData>{metadata?.royaltyPer}</ArtistItemData>
                    </ArtistRoyalistItemContent>
                  </Col>
                  <Col span={8}>
                    <ArtistRoyalistItemContent>
                      {/* {metadata.price} */}
                      <ArtistItemData> {metadata?.price}</ArtistItemData>
                    </ArtistRoyalistItemContent>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={12} sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              {TechnicalMetaData()}
            </Col>
            <Col span={12} sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              {transactionHistory()}
            </Col>
            <Col span={12} sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              {BlockChainInfo()}
            </Col>
          </Row>
          <div style={{ marginTop: "40px" }}>
            <Title level={4}>{"Related pieces"}</Title>
            <Row>
              {auctions
                ?.filter(
                  (a) =>
                    a.metadata?.category[0].name === metadata?.category &&
                    a.auctionID !== data?.auctionID
                )
                // eslint-disable-next-line array-callback-return
                .slice(0, 4)
                .map((mdata, index) => {
                  return (
                    <Col span={6} key={Math.random()}>
                      <Card
                        // to={`/art/${mdata?.creator}/${auctionID}`}
                        author={mdata?.metadata?.creator}
                        // {mdata?.metadata?.creator}
                        title={safeParse(mdata?.metadata?.data)?.title}
                        // {safeParse(mdata?.metadata?.data)?.title}
                        avatar={
                          <Avatar
                            src={avatarImg}
                            alt={mdata?.metadata?.creator}
                            //   {mdata?.metadata?.creator}
                          />
                        }
                        price={parseInt(mdata.buyNow)}
                        // {parseInt(mdata.buyNow)}
                        flowPrice={(parseInt(mdata.buyNow) / 12).toFixed(2)}
                        // {(parseInt(mdata.buyNow) / 12).toFixed(2)}
                        image={mdata?.metadata?.file}
                        // {mdata?.metadata?.file}
                      />
                    </Col>
                  );
                })}
            </Row>
          </div>
        </ArtWrapper>
      )}
      <BuyModal
        closable={false}
        footer={null}
        visible={showUpdateModal}
        centered
      >
        {showSuccessErrorModal ? (
          <UpdateConfirmation
            className="w-100 justify-content-between"
            direction="vertical"
          >
            <Title
              style={{
                font: "normal normal bold 24px/36px Oswald",
                letterSpacing: "1.15px",
                color: "#1A1D1F"
              }}
            >
              Congratulations
            </Title>
            <Typography.Text
              style={{
                font: "normal normal medium 14px/17px Lato",
                letterSpacing: "0.2px",
                color: "#595E62",
                textAlign: "center"
              }}
            >
              {`The item is now yours.`}
            </Typography.Text>
          </UpdateConfirmation>
        ) : (
          <UpdateConfirmation
            className="w-100 justify-content-between"
            direction="vertical"
          >
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 32, color: "red" }} />
              }
            />
            <Title
              style={{
                font: "normal normal bold 24px/36px Oswald",
                letterSpacing: "1.15px",
                color: "#1A1D1F"
              }}
            >
              Updating Item
            </Title>
            <Typography.Text
              style={{
                font: "normal normal medium 14px/17px Lato",
                letterSpacing: "0.2px",
                color: "#595E62",
                textAlign: "center"
              }}
            >
              {`Please wait while we update your item on the chain. It might take a few minutes.`}
            </Typography.Text>
          </UpdateConfirmation>
        )}
      </BuyModal>
    </Spin>
  );
};

export default ArtNew;
