
// import * as fcl from '@onflow/fcl';
import { send, decode, script, args, arg, cdc } from '@onflow/fcl';
import { Address, UInt64 } from '@onflow/types';
import keys from '../config/keys';



const CODE = cdc`
// view_metadata.cdc

import ${keys.daamImport} from ${keys.daam}

pub fun main(creator: Address, mid: UInt64): ${keys.daamImport}.MetadataHolder? {
  let metadataRef = getAccount(creator)
      .getCapability<&${keys.daamImport}.MetadataGenerator{${keys.daamImport}.MetadataGeneratorPublic}>(${keys.daamImport}.metadataPublicPath)
      .borrow()!
      
  return metadataRef.viewMetadata(mid: mid)
}
`;

export async function getSingleMetadata(address, mid) {
  if (address == null) return Promise.resolve(false);
  if (mid == null) return Promise.resolve(false);

  return send([script(CODE), args([arg(address, Address), arg(mid, UInt64)])]).then(decode);
}

